<template>
  <div class="card">
    <b-overlay :show="loading" rounded="sm">
      <div class="card-header"><b-button class="float-right" size="sm" @click="$store.commit('selectQuestion', {question: null})"> Close</b-button><h4>Question Detail</h4></div>
      <div class="card-title" v-if="sisterQuestions && sisterQuestions.length">
        <b-tabs class="matrix-tabs"  :value="activeIndex" pills card>
            <b-tab v-for="ma in matrixAreas" :key="ma.questionId" no-body @click="changeQuestion(ma.question)">
              <template #title>
                {{ma.groupName}}
                  <b-badge v-if="ma.issueCount"  class="ml-3" :variant="ma.unresolvedIssues ? 'danger' : 'success'" >{{ma.unresolvedIssues}} / {{ ma.issueCount  }}</b-badge>
                  <b-badge v-else class="ml-3" variant="secondary">0</b-badge>          
              </template>
            </b-tab>
          </b-tabs>
      </div>
      <div class="card-body question" v-if="question">
        <div class="row">
          <div class="col-12 hierarchy" >{{ question.hierarchy}}</div>
        </div>
        <div class="row">
          <div class="col-9 prompt">{{question.prompt}}</div>
          <div class="col-3 text-center" v-if="question.type == QuestionType.YesNo || question.type == QuestionType.YesNoNA">
            <yes-no-input v-model="question.value" :disabled="readonly" :allowNA="question.type == QuestionType.YesNoNA" @input="valueChanged($event)"></yes-no-input>
          </div>
          <div class="col-3" v-else-if="question.type == QuestionType.Textbox">
            <input type="text" class="form-control" :disabled="readonly" v-model.lazy.trim="question.value" @change="textValueChanged($event.target.value)">
          </div>
          <div class="col-3" v-else-if="question.type == QuestionType.LookupList">
            <LookupListInput :lookupListId="question.lookupListId" :disabled="readonly" v-model="question.textValue" @input="textValueChanged($event)"></LookupListInput>
          </div>
          <div class="col-3" v-else-if="question.type == QuestionType.FileUpload">
          </div>
          <div class="col-3" v-else>
            Invalid Type
          </div>
          <question-attachments v-if="question && question.type == QuestionType.FileUpload" :question="question" @remove-attachment="removeAttachment"></question-attachments>
        </div>
        <div class="row " v-if="question.hint">
          <div class="col-12 hint" >{{question.hint}}</div>
        </div>
        <div class="row ts-row py-3">
          <div class="col-4">
            <!-- Maybe some additional details about the question? -->
          </div>
          <div class="col-4">
            Updated: {{ question.updatedBy }}
          </div>
          <div class="col-4">
            {{question.updatedOn | moment("DD-MMM-YYYY hh:mm a") }}
          </div>
        </div>

        <div class="card mt-3">
          <div class="card-header">
            <h6 class="m-0 mr-5 d-inline-block">Issues</h6> 
            <b-button class="btn-add-rule icon-btn float-right ml-2" variant="primary" size="sm" v-if="!readonly" @click="showAddIssue()">
              <PlusBoxIcon /> Add
            </b-button>
            <b-button class="btn-undo-no icon-btn float-right" v-if="!readonly && canUndoNoResponse" size="sm" variant="warning" @click="undoNoResponse()">
              <UndoIcon /> Undo No Answer
            </b-button>
          </div>
          <div class="card-body p-0">
            <div v-if="!issues.length"><h4 class="text-center p-3">No issues Defined</h4></div>
            <div class="row no-gutters px-1 issue-row" :class="{resolved: issue.resolved}" v-for="issue in issues" :key="'issue_'+issue.id">
              <!-- <div class="col-12 header-row">
                <div class="row">
                  <div class="col-4">Issue {{issue.id}}</div>
                  <div class="col-8 text-right">
                    <b-button class="" variant="secondary" v-if="issue.resolved" size="sm" @click="resolveIssue(issue, false)">Un-Resolve</b-button>
                    <b-button class="" variant="success" v-else size="sm" @click="resolveIssue(issue, true)">Resolve</b-button>
                    <b-button class=" ml-1" variant="danger" size="sm" title="Remove Issue" @click="removeIssue(issue)"><TrashCanIcon /></b-button>
                  </div>
                </div>
              </div> -->
              <div class="issue-actions" v-if="!readonly && authLevel > 0">
                <b-button class="" variant="secondary" v-if="issue.resolved" size="sm" @click="resolveIssue(issue, false)">Un-Resolve</b-button>
                <b-button class="" variant="success" v-else size="sm" @click="resolveIssue(issue, true)">Resolve</b-button>
                <b-button class=" ml-1" variant="danger" size="sm" title="Remove Issue" @click="removeIssue(issue)"><TrashCanIcon /></b-button>
              </div>
              <div class="col-4 text-center">
                 <img :src="issue.imageUrl" v-if="issue.imageUrl" @click="$store.commit('setImageUrl', issue.imageUrl)" />
              </div>
              <div class="col-8 pl-1 ">
                <div class="row">
                  <div class="col-12 notes">{{issue.notes}}</div>
                </div>
                <div class="row no-gutters footer-row">
                  <div class="col-6 px-1 ts-created">Created: {{issue.createdBy}} | {{ issue.createdOn | moment("DD-MMM-YYYY hh:mm a") }}</div>
                  <div class="col-6 px-1 ts-updated">Updated: {{issue.updatedBy}} | {{ issue.updatedOn | moment("DD-MMM-YYYY hh:mm a") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>

    <b-modal
      :title=" (issueRequired || requireIssue) ? 'New Issue Required' : 'New Issue'"
      centered
      ref="issueModalRef"
      size="md"
      v-model="dialog.visible"
      @hidden="hideIssueDialog"
      :no-close-on-esc="issueRequired || requireIssue"
      :no-close-on-backdrop="issueRequired || requireIssue"
      :hide-header-close="issueRequired || requireIssue"
    >

      <div class="container">
        <div class="row">
          <div class="col-12">
            <b-alert 
              :show="issueRequired || requireIssue"
              variant="warning"
              >Question response requires explanation</b-alert>
            <b-form-group
              id="input-group-1"
              label="Notes"
              label-for="issue-textarea"
            >
              <b-form-textarea
                id="issue-textarea"
                class="form-control"
                placeholder="Enter description of issue"
                v-model="dialog.notes"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="row image-row" v-if="dialog.image">
          <div class="col-12">
             <b-button class="btn-remove-image" variant="danger" size="sm" title="Remove Image" @click="dialog.image = null"><TrashCanIcon /></b-button>
            <img :src="dialog.image.data" />
          </div>
        </div>

      </div>
      <div class="uploadBox" v-if="!dialog.image">
        <form role="form" enctype="multipart/form-data">
          <div class="uploadBoxMain">
            <div class="form-group">
              <div
                class="dropArea"
                :class="{'dropAreaDragging':dragging > 0 || dialog.processing}"
              >
                <div class="drop-files">Click to Browse<br/>Drag &amp; Drop or Paste file</div>
                <div class="drop-supported-files">Supported Image Types (png, jpg, gif)</div>
                <input
                  type="file"
                  id="items"
                  name="items[]"
                  required
                  accept="image/*"
                  :class="{'dropAreaDragging':dragging > 0 || dialog.processing}"

                  @drop.prevent="dropped"
                  @dragenter.prevent="dragEnter()"
                  @dragleave.prevent="dragLeave()"
                  @dragover.prevent
                  @change="fileUploadChanged($event)"
                />
                <p class="help-block"></p>
              </div>
              
            </div>
          </div>
        </form>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if="(!issueRequired && !requireIssue) || authLevel > 0" size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button v-else-if="(issueRequired || requireIssue) && authLevel == 0" size="sm" variant="secondary" @click="removeNo(cancel)">Remove No Answer</b-button>
        <b-button size="sm" variant="primary" @click="saveIssue()" :disabled="dialog.notes.length == 0">Add</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { Client, IssueDto, Operation, QuestionDto, QuestionType } from "../code/EngineeringChecklist.Api";
import LookupListInput from './inputs/LookupListInput.vue';
import YesNoInput from './inputs/YesNoInput.vue';
import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
import UndoIcon from 'vue-material-design-icons/Undo.vue';
import QuestionAttachments from './QuestionAttachments.vue';
import moment from 'moment';
// import CheckCircleIcon from 'vue-material-design-icons/CheckCircle.vue';

export default Vue.extend({
  name: 'QuestionDetail',
  components: {
    LookupListInput,
    YesNoInput,
    PlusBoxIcon,TrashCanIcon, UndoIcon, //, CheckCircleIcon
    QuestionAttachments 
  },
  props: {
    question: {type: Object, required: true},
    readonly: {type: Boolean, required: true },
    autoissue: {type: Boolean, default: false},
    issueRequired: {type: Boolean, default: false},
    sisterQuestions: {type: Array, default: null}
  },
  data() {
    return {
      QuestionType,
      patches: [],
      dragging: false,
      loading: false,
      requireIssue: false,
      dialog: {
        visible: false,
        processing: false,
        notes: "",
        image: null,
        imgData: "",
      }
    }
  },
  computed: {
    ...mapState('context', ['profile']),
    ...mapGetters('context', ['authLevel']),
    issues: function() {
      return this.question && this.question.issues ? this.question.issues : [];
    },
    activeIndex: function() {
      return this.sisterQuestions ? this.sisterQuestions.findIndex(q => q.id == this.question.id) : 0;
    },
    matrixAreas: function() {
      return this.sisterQuestions 
              ? _.map( _.filter(this.sisterQuestions, q => q != false), function(q) {
                  return { groupName: q.parent.name, questionId: q.id, question: q, issueCount: q.issues ? q.issues.length : 0, unresolvedIssues: q.issues ? _.filter(q.issues, i => !i.resolved).length : 0};
                }) 
              : null;
    },
    canUndoNoResponse: function() {
      //Allow basic users to remove their own no response / issue
      let retVal = (this.question.type == QuestionType.YesNo || this.question.type == QuestionType.YesNoNA) && 
                   this.question.value === 0 && 
                   this.question.updatedBy == this.profile.username;
      
      if (retVal) {
        let issues = this.question.issues.filter(issue => issue.updatedBy === this.profile.username && moment(issue.updatedOn).diff(this.question.updatedOn, 'minutes') < 1 ) || [];
        return issues.length > 0;
      } else {
        return false;
      }
    }
  },
  methods: {
    undoNoResponse: function() {
      this.$bvModal.msgBoxConfirm('This will reset the question and issue, Are you sure?', {
              title: 'Please Confirm',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true })
          .then(function (value) {
            if (value) {
              let issues = this.question.issues.filter(issue => issue.updatedBy === this.profile.username && moment(issue.updatedOn).diff(this.question.updatedOn, 'minutes') < 1 )
              if (issues.length > 0) {
                this.deleteIssue(issues[0]);
              }
              this.valueChanged(null);
            }
          }.bind(this));
      this.valueChanged(null);
    },
    changeQuestion: function(q) {
      this.$store.commit('selectQuestion', {question: q, sisterQuestions: this.sisterQuestions});
    },
    valueChanged(value){
      if (value === 0 ) {
        this.requireIssue = true;
        this.showAddIssue();
      }
      this.patch('replace', '/value', value);
      this.processPatches();
    },
    textValueChanged(value){
      this.patch('replace', '/textValue', value);
      this.processPatches();
    },
    patch: function(op, path, value) {

      let patch = _.find(this.patches, {op: op, path: path });
      
      if (patch) {
        patch.value = value;
      } else {
        patch = new Operation({op: op, path: path, value: value});
        this.patches.push(patch);
      }
    },
    processPatches: function() {

      if (this.patches && this.patches.length) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        c.questions_Patch(this.question.id, this.patches)
          .then(function(updatedQuestion){
            if (updatedQuestion) {
              delete updatedQuestion.issues;
              Object.assign(this.question, updatedQuestion);
            }
            this.patches = [];
          }.bind(this))
          .catch(function(error){

          })

      }
    },
    removeAttachment: function(attachment) {

      this.$bvModal.msgBoxConfirm('Are you sure?', {
              title: 'Please Confirm',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true })
          .then(function (value) {

            if (value) {
              let c = new Client(process.env.VUE_APP_API_URL, this.$http);
                c.questions_RemoveAttachment(this.question.id, attachment.id)
                  .then(function() {
                    if (attachment) {
                      this.question.attachments = _.filter(this.question.attachments, function(a) { return a.id != attachment.id; });
                    }
                  }.bind(this))
                  .catch(function(error) {
                    this.$bvToast.toast(error || error.message, {
                      title: `Error occurred while removing attachment`,
                      variant: "danger"
                    });
                    console.log(error);
                  }.bind(this));
          }
        }.bind(this));

    },
    resolveIssue(issue, resolved) {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);
      this.loading = true;
      c.issues_Patch(issue.id, [new Operation({op:'replace', path:'/resolved', value: !!resolved })])
        .then(function(updatedIssue){
          if (updatedIssue) {
            Object.assign(issue, updatedIssue);
          }
        }.bind(this))
        .catch(error => {
          console.log(error.response);
        })
        .finally(
          function() {
            this.loading = false;
          }.bind(this)
        );
    },
    removeIssue: function(issue) {
      
      this.$bvModal.msgBoxConfirm('Are you sure?', { 
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(function(value) {
        if (value) {
          this.deleteIssue(issue);
        }
      }.bind(this))

    },
    deleteIssue(issue) {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      this.loading = true;
      c.issues_Delete(issue.id)
        .then(function(newIssues) {
          let index = _.findIndex(this.question.issues, issue);
          if (index >= 0) {
            this.question.issues.splice(index,1);
          }
          this.$bvToast.toast(`Sucessfully removed issue: "${issue.notes}"`, {
              title: `Issue removed.`,
              variant: "success"
            });
        }.bind(this))
        .catch(function(error) {

          this.$bvToast.toast(`Failed to remove issue: ${error.message}`, {
              title: `Failed to remove issue.`,
              variant: "danger"
            });

          console.log(error);
        }.bind(this))
        .finally(
          function() {
            this.loading = false;
          }.bind(this)
        );  
    },  
    removeNo: function(fnCancel) {
      this.requireIssue = false;
      this.valueChanged(null);
      fnCancel();
      
      this.$store.commit('selectQuestion', {question: null});
    },
    saveIssue: function() {

      var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      this.loading = true;
      c.issues_Post(new IssueDto({
          id:0, 
          questionId: this.question.id,
          notes: this.dialog.notes,
          imageData: this.dialog.image ? this.dialog.image.data : null,
          resolved: false
        }))
        .then(function(newIssue){

          newIssue.parent = this.question;
          newIssue.hierarchy = this.question.hierarchy + ' > ' + this.question.prompt;

          if (this.question.issues) {
            this.question.issues.push(newIssue);
          } else {
            this.$set(this.question, 'issues', [newIssue]);
          }
          this.dialog.visible=false;
        }.bind(this))
        .catch(error => {
          console.log(error.response);
        })
        .finally(
          function() {
            this.loading = false;
            this.$store.state.issueRequired = false;
            this.requireIssue = false;
          }.bind(this)
        );

    },
    loadIssues: function() {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      this.loading = true;
      c.issues_GetAll(this.question.id) 
        .then(function(newIssues){
          this.$set(this.question, 'issues', newIssues);
        }.bind(this))
        .catch(error => {
          console.log(error.response);
        })
        .finally(
          function() {
            this.loading = false;
          }.bind(this)
        );
    },
    showAddIssue: function() {
      window.addEventListener("paste", this.pasteImage);

      this.dialog = {
        visible: true,
        processing: false,
        notes: "",
        image: null,
        imgData: "",
      }
      
    },
    hideIssueDialog: function() {
      this.dialog.visible = false;
      window.removeEventListener("paste", this.pasteImage);
    },
    pasteImage: async function(e) {
      e.preventDefault();
      e.stopPropagation();
      let file = e.clipboardData.items[0].getAsFile();

      if (file) {
        this.processImageFile(file);
      }

      // setTimeout(function() {
      //   this.$refs.addPartModalRef.hide();
      // }.bind(this), 750);

    },
    processImageFile:function(file) {
      let fileName = file.name.toLowerCase(),
        fileExt = fileName.match(/\.[0-9a-z]{1,5}$/i)[0];

      this.dialog.processing = true;

      let newImage = {fileName: fileName, ext: fileExt, data: null};

      // newImage.label = fileName.replace(fileExt, "");

      var reader = new FileReader();

      reader.onload = function(re) {

        newImage.data = re.target.result;

        //Result is getting return via callback, need to introduce
        //a slight pause on this thread to allow the process to complete
        setTimeout(
          function() {
            this.dialog.image = newImage;

            this.dialog.processing = false;
          }.bind(this),
          500
        );
      }.bind(this);

      reader.readAsDataURL(file);

    },

    dragEnter: function() {
      this.dragging += 1;
    },
    dragLeave: function() {
      //if (this.dragging > 0)
      this.dragging -= 1;
    },
    dropped: function(e) {

      if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length) {
        this.processImageFile(e.dataTransfer.files[0]);
      }

      if (this.dragging) {
        this.dragging = 0;
      }
    },
    fileUploadChanged: function(e) {

      if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length) {
        this.processImageFile(e.dataTransfer.files[0]);
      } else if (e.target.files && e.target.files.length) {
        this.processImageFile(e.target.files[0]);
      }
    },
  },
  watch: {
  },
  created() {
    if (this.autoissue) {
      this.showAddIssue();
    }
  }
});
</script>

<style lang="scss" scoped>
.uploadBox {
  position: relative;
  background: #eee;
}
.uploadBox h3 {
  padding-top: 1em;
}

/* Drag and drop */
.uploadBox .dropArea {
  position: relative;
  width: 100%;
  height: 300px;
  border: 3px dashed #00adce;
  text-align: center;
  //padding-top: 80px;
}

.dropAreaDragging {
  background-color: #aaa;
}
.drop-files {
  font-size: 2em;
  margin-top: 90px;
  margin-bottom: 50px
}
.drop-supported-files {
  font-size: 0.95em;
  line-height: 0.95em;
}
.uploadBox .dropArea input {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
/* End drag and drop */
/* Loader */
.uploadBox .loader {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  opacity: 0.9;
}
.uploadBox .loaderImg {
  border: 9px solid #eee;
  border-top: 9px solid #00adce;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}
.btn-remove-image {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
}

.question {
  .hierarchy { font-size: 0.7em; }
  .prompt { font-weight: bold;}
  .ts-row{ font-size: 0.8em;}
}

.issue-row {
  position: relative;
  margin: 0.5em ;
  padding: 0.5em 0;
  border: 1px solid #CCC;

  .issue-actions {
    position: absolute;
    top: 0;
    right: 0;

    display: none;
    z-index: 10;

  }
  &:hover {
    .issue-actions { 
      display: block;

    }
  }

  // .header-row{
  //   position: absolute;
  //   display: none;
  // }
  // &:hover {
  //   .header-row {
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     background-color: #DDDDDDF9;
  //     display: flex;
  //     z-index: 10;
  //   }
  // }
  &.resolved {
    background-color: #b1ddb5A0;
  }
  img {
    max-width: 100%;
    max-height: 150px;

    cursor: pointer;
  }
  .notes { font-size: 1.25em;}
  .ts-created, 
  .ts-updated {
    color: #999;
    font-size: 11px;
    font-weight: bold;
  }
}




.image-row img {
  max-width: 400px;
  max-height: 600px;
}
.image-col {

  img {
    max-width: 150px;
    max-height: 150px;
  }
}

.issue-card {
  position: relative;

  .card-header {
    display: none;
  }
  &:hover {
    .card-header {
      position: absolute;
      display: block;
      width: 100%;
      background-color: #DDDDDDEE;
    }
  }
  img {
    width: auto;
    max-width: 100%;
    max-height: 400px;
  }
}
// .masonry {
//     display: grid;
//     grid-gap: 15px;
//     grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
//     grid-auto-rows: 0;
// }


// .issue-card {
//   position: relative;
//   min-height: 100px;
//   border: 1px solid #AAA;
//   margin: 5px 0;
//   padding: 0.25em;

//   .issue-header {
//     margin: -0.25em;
//     display: none;
//   }
//   .issue-footer {
//     display: none;
//     font-size: 12px;
//     font-weight: bold;
//     text-align: center;
//   }
//   .issue-content {
//     text-align: center;
//   }
//   .notes {
//     padding: 0.5em;
//     text-align: left;
//   }
//   &:hover {
//     .issue-header {
//       position: absolute;
//       display: block;
//       width: 100%;
//       padding: 0.25em;
//       background-color: #DDDDDDEE;
//       z-index: 50;
//     }
//     .issue-footer {
//       position: absolute;
//       bottom: 0;
//       left: 0;
//       right: 0;
      
//       display: block;
//       width: 100%;
//       background-color: #DDDDDDEE;
//       z-index: 50;
//     }

//   }
//   .resolved{
//     position: absolute;
//     top: -27px;
//     right: -6px;

//     width: 0; 
//     height: 0; 
//     border-top: 40px solid transparent;
//     border-bottom: 40px solid transparent;
    
//     border-left: 40px solid #008000B0;
//     transform: rotate(-45deg);
//     z-index: 5;

//     .material-design-icon {
//       position: absolute;
//       top: -18px;
//       right: 6px;
//       transform: rotate(45deg);
//       width: 2em;
//       height: 2em;
//       color: white;
//     }
//   }
//   img {
//     width: auto;
//     max-width: 100%;
//     max-height: 400px;
//   }
// }
</style>