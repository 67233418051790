<template>
  <div class="project">
    <b-overlay :show="loading" rounded="lg" />
    <b-tabs v-model="activeTabIndex" pills card v-if="!loading" content-class="scrollable">

      <b-tab title="Project Summary" class="summary-tab-panel" v-if="!projectType.useMainChecklistSection">
        <ProjectSummary :project="project" :readonly="readonly" @project-updated="(p) => project = p" @goto-checklist="activateTab" @goto-issues="activateIssuesTab"></ProjectSummary>
      </b-tab>
      <b-tab class="main-checklists-tab-panel px-3" v-else>
        <template #title>
          Main Checklists
        </template>
        <standard-checklists :project="project" :readonly="readonly"></standard-checklists>
      </b-tab>
      <b-tab class="mech-review-tab-panel" v-if="projectType && projectType.code =='Engineering' && projectType.allowRepeatChecklists">
        <template #title>
          {{projectType.repeatSectionTabName}}
          <b-badge class="ml-2" v-if="mechIssueCount" variant="danger">{{mechIssueCount}}</b-badge>
        </template>
        <mech-review :project="project" :readonly="readonly"></mech-review>
      </b-tab>
      <b-tab class="comp-review-tab-panel"  v-if="projectType && projectType.code !='Engineering' && projectType.allowRepeatChecklists">
        <template #title>
          {{projectType.repeatSectionTabName}}
          <b-badge class="ml-2" v-if="mechIssueCount" variant="danger">{{mechIssueCount}}</b-badge>
        </template>
        <repetitive-checklists :project="project" :readonly="readonly"></repetitive-checklists>
      </b-tab>
      <template v-if="!projectType.useMainChecklistSection">
        <b-tab v-for="checklist in checklists" :key="checklist.id">
          <template #title>
            <ClipboardCheckIcon v-if="checklist.signOffAt" :size="32" fillColor="#28a745" title="Signed Off"></ClipboardCheckIcon> 
            {{checklist.name}}
            <b-badge class="ml-2" v-if="checklist.status.issue" variant="danger">{{checklist.status.issue}}</b-badge>
          </template>
          <checklist :checklist="checklist" :readonly="readonly"></checklist>
        </b-tab>
      </template>
      <b-tab class="issues-tab-panel" v-if="projectType && projectType.allowIssuesSection">
        <template #title>
          Issues
          <b-badge class="ml-2" :variant="project.status.issue ? 'danger' : 'success'">{{project.status.issue}} / {{ project.status.issueNum }}</b-badge>
        </template>
        <project-issues :project="project" :readonly="readonly"></project-issues>
      </b-tab>
    </b-tabs>
    <b-sidebar id="sidebar-no-header" aria-labelledby="sidebar-no-header-title" no-header shadow right backdrop v-model="sidebarVisible" width="40%">
      <div v-if="sidebarVisible">
        <question-detail :question="selectedQuestion" :readonly="readonly" :key="selectedQuestion ? selectedQuestion.id : -1" :autoissue="issueEntry" :issueRequired="issueRequired" :sisterQuestions="sisterQuestions"></question-detail>
      </div>
    </b-sidebar>

    <b-modal centered hide-footer hide-header scrollable ref="imageViewerModal"
             body-bg-variant="p-0"
             dialog-class="img-viewer-dialog"
             content-class="img-viewer-content"
             v-model="imageViewerVisible">
      <div class="w-100 d-flex justify-content-center align-items-center">
        <img v-show="imageLoaded" ref="activeImage" :src="activeImageUrl" @load="imgLoaded" @click="$store.commit('setImageUrl', null)" />
      </div>
    </b-modal>

    <b-modal title="Additional Info"
             ref="moreInfoModal"
             size="xl"
             centered hide-footer scrollable 
             body-bg-variant="p-0"
             dialog-class="more-info-dialog"
             content-class="more-info-content"
             v-model="moreInfoVisible">
      <div class="w-100">
        <div v-html="moreInfoHtml"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import Vue from "vue";
  import { Vuex, mapState, mapMutations, mapGetters } from "vuex";

  import { ChecklistType, Client, QuestionDto, ChecklistDto, IssueDto, Operation } from "../code/EngineeringChecklist.Api";
  import { filterObject } from "../code/conditionals"
  import { InitializeChecklistProject, InitializeChecklist, InitializeJobs } from "../code/Initializers"

  import ProjectSummary from "../components/ProjectSummary.vue";
  import Checklist from "../components/Checklist.vue";
  import MechReview from "../components/MechReview.vue";
  import QuestionDetail from "../components/QuestionDetail.vue";
  import ProjectIssues from "../components/ProjectIssues.vue";
  import StandardChecklists from "../components/StandardChecklists.vue";
  import RepetitiveChecklists from "../components/RepetitiveChecklists.vue";
  import ClipboardCheckIcon from 'vue-material-design-icons/ClipboardCheck.vue';

  export default Vue.extend({
    name: "ProjectView",
    components: {
      //JobSummary,
      ProjectSummary,
      Checklist,
      MechReview,
      StandardChecklists,
      RepetitiveChecklists,
      QuestionDetail,
      ProjectIssues,
      //ClipboardCheckIcon
    },
    data() {
      return {
        activeTabIndex: 0,
        loading: true,
        project: null,
        imageLoaded: false,
        signalREvent: false
      };
    },
    computed: {
      ...mapState(["selectedFacility", "selectedQuestion", "issueEntry","issueRequired","sisterQuestions"]),
      ...mapGetters('context', ['isAuthenticated', 'isAdmin']),
      ...mapGetters(['selectedMoreInfo','getProjectType']),
      projectType: function () {
        let retVal = {code: "", name: "", allowIssuesSection: true, allowRepeatChecklists: true, repeatSectionTabName: "Components"};
        if (this.project && this.project.projectTypeCode) {
          //this.$store.getters.getProjectType(this.project.projectTypeCode);
          return this.getProjectType(this.project.projectTypeCode) || retVal;
        }
        return retVal
      },
      readonly: function () {
        return this.project ? this.project.readonly : true;
      },
      checklists: function () {
        return this.project ? _.sortBy(_.filter(this.project.checklists, { type: ChecklistType.Standard, hidden: false }) || [], ['sortOrder']) : [];
      },
      mechChecklists: function () {
        return this.project ? _.filter(this.project.checklists, (checklist) => checklist.type != ChecklistType.Standard) : [];
      },
      mechIssueCount: function () {
        let mechChecklists = this.project ? _.filter(this.project.checklists, (checklist) => checklist.type != ChecklistType.Standard) : [];

        return _.sumBy(this.mechChecklists, checklist => checklist.status ? checklist.status.issue : 0);
      },
      statusVal: function () {
        return _.reduce(this.project ? _.filter(this.project.checklists, { visible: true }) || [] : [], function (total, checklist) {
          if (checklist && checklist.status) {
            total.blank += checklist.status.blank;
            total.issue += checklist.status.issue;
            total.issueNum += checklist.status.issueNum;
            total.accepted += checklist.status.accepted;
            total.count += checklist.status.count;
          }

          return total;
        }, { blank: 0, issue: 0, issueNum: 0, accepted: 0, count: 0 });
      },
      sidebarVisible: {
        get: function () {
          return this.selectedQuestion ? true : false;
        },
        set: function (newValue) {
          if (newValue == false) {
            this.$store.commit('selectQuestion', { question: null })
          }
        }
      },
      activeImageUrl: function () {
        return this.$store.state.activeImageUrl
      },
      selectedMoreInfo: function () {
        return this.$store.state.selectedMoreInfo
      },
      moreInfoHtml: function () {
        if (this.selectedMoreInfo) {
          return this.selectedMoreInfo.html_en;
        } else {
          return '';
        }
      },
      imageViewerVisible: {
        get: function () {
          return this.activeImageUrl ? true : false;
        },
        set: function (newValue) {
          if (newValue == false) {
            this.$store.commit('setImageUrl', null)
          }
        }
      },
      moreInfoVisible: {
        get: function () {
          return this.selectedMoreInfo ? true : false;
        },
        set: function (newValue) {
          if (newValue == false) {
            this.$store.commit('setMoreInfo', null)
          }
        }
      },
    },
    methods: {
      imgLoaded: function () {

        let imageWidth = this.$refs.activeImage.width,
          imgModal = this.$refs.imageViewerModal;

        //Dynamically set the width of the image modal
        this.$refs.imageViewerModal.$refs.dialog.style.maxWidth = imageWidth + 'px';

        this.imageLoaded = true;
      },
      activateTab(checklist) {

        let index = this.checklists.indexOf(checklist);
        if (index >= 0) {
          this.activeTabIndex = index + 2;
        } else {
          let index = this.checklists.indexOf(checklist);
        }
      },
      activateIssuesTab: function () {
        return this.activeTabIndex = this.checklists.length + 2;
      },
      initializeProjectData: function (project) {
        /**
         *
         * Prepare the project data with all its initializing data, create all the statuses/associations
         *
         */
        let $this = this;

        if (project) {
          InitializeChecklistProject(project);
          //var status = { blank: 0, issue: 0, issueNum: 0, accepted: 0, count: 0 };
//
          //project.checklists.forEach(checklist => {
          //  InitializeChecklist(checklist);
//
          //  status.blank += checklist.status.blank;
          //  status.issue += checklist.status.issue;
          //  status.issueNum += checklist.status.issueNum;
          //  status.accepted += checklist.status.accepted;
          //  status.count += checklist.status.count;
          //});

          //project.status = status;
        }

        return project;
      },
      findQuestion: function (areaId, questionId) {
        if (questionId) {
          for (const checklist of this.project.checklists) {
            for (const area of checklist.areas) {
              if (!areaId || area.id == areaId) {
                for (const question of area.questions) {
                  if (question.id == questionId) {
                    return question;
                  }
                }
              }
            }
          }
        }

        return null;
      },
      onProjectUpdated: function (updatedProject) {

        this.signalREvent = true;

        if (updatedProject) {
          delete updatedProject.checklists;
          Object.assign(this.project, updatedProject);
        }

        // this.project.name = updatedProject.name;
        // this.project.description = updatedProject.description;

        this.$nextTick(() => {
          this.signalREvent = false;
        })
      },
      onChecklistAdded: function (addedChecklist) {
        let existingCL = _.find(this.project.checklists, { id: addedChecklist.id });
        if (!existingCL) {
          this.signalREvent = true;

          var cl = ChecklistDto.fromJS(addedChecklist);
          InitializeChecklist(cl);

          this.project.checklists.push(cl);

          this.$nextTick(() => {
            this.signalREvent = false;
          })
        }
      },
      onChecklistUpdated: function (updatedChecklist) {

        for (const checklist of this.project.checklists) {
          if (checklist.id == updatedChecklist.id) {
            this.signalREvent = true;

            checklist.name = updatedChecklist.name;

            if (checklist.options != updatedChecklist.options) {
              checklist.options = updatedChecklist.options;
              checklist.rules = JSON.parse(checklist.options || '[]');
            }

            this.$nextTick(() => {
              this.signalREvent = false;
            })

            return;
          }
        }
      },
      onChecklistRemoved: function (removedChecklist) {
        this.signalREvent = true;

        let clIndex = _.findIndex(this.project.checklists, { id: removedChecklist.id });
        if (clIndex >= 0) {
          this.project.checklists.splice(clIndex, 1);
        }

        this.$nextTick(() => {
          this.signalREvent = false;
        })
      },


      onQuestionUpdated: function (updatedQ) {

        let question = this.findQuestion(updatedQ.areaId, updatedQ.id);
        if (question) {
          this.signalREvent = true;

          if (updatedQ) {
            delete updatedQ.issues;
            Object.assign(question, updatedQ);
          }

          this.$nextTick(() => {
            this.signalREvent = false;
          })
        }
      },
      onIssueAdded: function (addedIssue) {
        let question = this.findQuestion(null, addedIssue.questionId);
        if (question) {

          let existingIssue = _.find(question.issues, { id: addedIssue.id });
          if (!existingIssue) {
            this.signalREvent = true;

            var issue = IssueDto.fromJS(addedIssue);
            issue.parent = question;
            issue.hierarchy = question.hierarchy + ' > ' + question.prompt;
            question.issues.push(issue);

            this.$nextTick(() => {
              this.signalREvent = false;
            })
          }
        }
      },
      onIssueUpdated: function (updatedIssue) {

        let question = this.findQuestion(null, updatedIssue.questionId);
        if (question) {
          for (const issue of question.issues) {
            if (issue.id == updatedIssue.id) {
              this.signalREvent = true;

              if (updatedIssue) {
                Object.assign(issue, updatedIssue);
              }

              this.$nextTick(() => {
                this.signalREvent = false;
              })

              return;
            }
          }
        }
      },
      onIssueRemoved: function (removedIssue) {
        let question = this.findQuestion(null, removedIssue.questionId);
        if (question) {
          this.signalREvent = true;

          let issueIndex = _.findIndex(question.issues, { id: removedIssue.id });

          if (issueIndex >= 0) {
            question.issues.splice(issueIndex, 1);
          }

          this.$nextTick(() => {
            this.signalREvent = false;
          })
        }
      },
      updateProjectProgress: function () {
        if (this.readonly) {
          return;
        }

        //Periodically store the progress/issue count so that we can easily present correct-ish information on the active projects page instead of having to calculate it in the DB.
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        let status = this.project.status;
        if (status) {
          var patches = [
            new Operation({ op: 'replace', path: '/progress', value: Math.round((status.accepted / status.count) * 100) }),
            new Operation({ op: 'replace', path: '/unresolvedIssues', value: status.issue })
          ]
          return c.checklistProjects_Patch(this.project.id, patches)
            .then(function(updatedProject) {
              if (updatedProject.jobId)
                this.$store.commit('updateJobProjectStatus', updatedProject);                
            }.bind(this))
            .catch((error) => {
              console.log(error);
            })
        }

      },
      fetchChecklistProject(projectId) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        return c.checklistProjects_GetById(projectId, true)
          .then((project) => {
            this.project = this.initializeProjectData(project);
            this.loading = false;
            this.$projectHub.projectOpened(`project_${this.project.id}`);
            this.$store.commit('setProject', project);
          })
          .catch((error) => {
            console.log(error);
          })
      },
      fetchJobs(jobId) {
        this.loading = true;
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        c.job_GetAll(false,this.selectedFacility, jobId)
          .then(function (jobs) {

            InitializeJobs(jobs);
            
            this.$store.commit('setJobs', jobs);
          }.bind(this))
          .catch(function (error) {
            this.$bvToast.toast(`Failed to load project: ${error.message}`, {
              title: `Failed to load project.`,
              variant: "danger"
            });

          }.bind(this))
          .finally(function () {
            this.loading = false;
          }.bind(this));
      },
    },
    watch: {
      $route: function (to, from){
        
        //If there are any outstanding status change (% complete / issues) updates flush them before we navigate away
        this.debouncedUpdateProjectProgress.flush();

        this.$projectHub.projectClosed(`project_${this.project.id}`)
        this.fetchChecklistProject(to.params.projectId);
      },
      statusVal: function (status, oldStatus) {
        this.$set(this.project, 'status', status);

        //Only do this when we initiated the change, not something that came from SignalR.
        if (!this.signalREvent) {

          if (status && (this.project.unresolvedIssues != status.issue || this.project.progress != Math.round((status.accepted / status.count) * 100))) {
            //Update the DB on project load if the current progress/outstanding issues don't match
            this.debouncedUpdateProjectProgress();
          }

        }
        // else {
        //   //Skip updating the project... someone else triggerd the status updates
        //   console.log("Skipping project progress update");
        // }
      },
      activeImageUrl: function (newValue, oldValue) {
        if (!newValue) {
          this.imageLoaded = false;
        }
      }
    },
    async beforeRouteEnter(to, from, next) {
      if (to.params.projectId !== undefined) {
        var c = new Client(process.env.VUE_APP_API_URL, Vue.prototype.$http);
        var project = await c.checklistProjects_GetById(to.params.projectId, true);

        Vue.prototype.startSignalR()

        return next(vm => {
          vm.project = vm.initializeProjectData(project);
          vm.loading = false;

          vm.$projectHub.projectOpened(`project_${vm.project.id}`);
          vm.$store.commit('setProject', project);
          // vm.$projectHub.projectOpened(`project_9`);

          if (project.jobId) {
            vm.fetchJobs(project.jobId);
          }

        });
      }

      return next();
    },
    created() {

      // Listen for notifications that new answer were added
      this.$projectHub.$on('project-updated', this.onProjectUpdated);
      this.$projectHub.$on('checklist-added', this.onChecklistAdded);
      this.$projectHub.$on('checklist-updated', this.onChecklistUpdated);
      this.$projectHub.$on('checklist-removed', this.onChecklistRemoved);
      this.$projectHub.$on('question-updated', this.onQuestionUpdated);
      this.$projectHub.$on('issue-added', this.onIssueAdded);
      this.$projectHub.$on('issue-updated', this.onIssueUpdated);
      this.$projectHub.$on('issue-removed', this.onIssueRemoved);

      //Debounce the project status updates that that they only occur 20 seconds after the last change or at most every 90 seconds
      this.debouncedUpdateProjectProgress = _.debounce(this.updateProjectProgress, 20000, {
        leading: false,
        trailing: true,
        maxWait: 90000
      });


    },
    beforeDestroy() {
      // Notify the server we stopped watching the question
      // this.$projectHub.projectClosed(`project_9`)

      // Make sure to cleanup SignalR event handlers when removing the component
      this.$projectHub.$off('project-updated', this.onProjectUpdated);
      this.$projectHub.$off('checklist-added', this.onChecklistAdded);
      this.$projectHub.$off('checklist-updated', this.onChecklistUpdated);
      this.$projectHub.$off('checklist-removed', this.onChecklistRemoved);
      this.$projectHub.$off('question-updated', this.onQuestionUpdated);
      this.$projectHub.$off('issue-added', this.onIssueAdded);
      this.$projectHub.$off('issue-updated', this.onIssueUpdated);
      this.$projectHub.$off('issue-removed', this.onIssueRemoved);

      this.$projectHub.projectClosed(`project_${this.project.id}`)
        .finally(() => {
          try {
            this.$nextTick(() => Vue.prototype.stopSignalR())
          } catch (error) {
            console.log(error)
          }
        })

      this.$store.commit('setProject', null);
    },
  });
</script>

<style lang="scss">
  .modal-dialog.img-viewer-dialog {
    // max-width: auto;
    .modal-content {
      background-color: transparent;
      border: 0;
    }

    .modal-body {
      padding: 0;
    }
  }
</style>
