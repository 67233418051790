<template>
  <div class="row">
    <div class="col-1">{{user.id}}</div>
    <div class="col-2">{{user.userName}}</div>
    <div class="col-2">{{user.name}}</div>
    <div class="col-4">{{user.email}}</div>
    <div class="col-1">{{user.superUser}}</div>
    <div class="col-1">
      <a class="px-2" href="#" @click.prevent="$emit('edit-user', user)"><LeadPencilIcon title="Edit User" ></LeadPencilIcon></a> 
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';

export default Vue.extend({
  name: 'ManageUserDetail',
  components: {
    LeadPencilIcon
  },
  props: {
    user: {type: Object, required: true},
  },
  data() {
    return {}
  },
  computed: {
  },
  methods: {
  },
  watch: {
  },
  created() {
  }
});
</script>

