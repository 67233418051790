<template>
  <div class="manage-facilities">
    <b-overlay :show="loading" rounded="sm">

      <div class="row ">
        <div class="col-6">
          <div class="card">
            <div class="card-header p-2">
              <h4 class="m-0 mr-5 d-inline-block">Active Facilities</h4>
            </div>
            <div class="card-body container p-2">
              <div class="row ">
                <div class="col-3">Code</div>
                <div class="col-7">Name</div>
                <div class="col-2"></div>
              </div>
              <div v-for="facility in activeFacilities"
                   class="row no-gutters active"
                   :class="{'selected': selectedFacility == facility }"
                   :key="facility.code" 
                   @click="selectFacility(facility)">
                <div class="col-3">{{facility.code}}</div>
                <div class="col-7">{{facility.name}}</div>
                <div class="col-2 text-center">
                  <ArrowRightBoldIcon class="btn btn-arrow" :size="32" @click="archiveFacility(facility)"></ArrowRightBoldIcon>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-4">
            <div class="card-header p-2">
              <h4 class="m-0 mr-5 d-inline-block">Facility Machines</h4>
            </div>
            <div class="card-body p-2" v-if="selectedFacility && facilityMachines && facilityMachines.length">
              <div class="row">
                <div class="col-4">Code</div>
                <div class="col-8">Name</div>
              </div>
              <div class="row" v-for="machine in facilityMachines" :key="machine.id">
                <div class="col-4">{{machine.id}}</div>
                <div class="col-8">{{machine.name}}</div>
              </div>
            </div>
            <div class="card-body p-2 text-center" v-else>
              <h4>No machines found {{ selectedFacility ? 'for ' + selectedFacility.code : ''}}</h4>
            </div>
          </div>
          </div>
        <div class="col-6">
          <div class="card">
            <div class="card-header p-2">
              <h4 class="m-0 mr-5 d-inline-block">Inactive Facilities</h4>
            </div>
            <div class="card-body p-2">
              <div class="row" v-for="facility in availableFacilities" :key="facility.code">
                <div class="col-2">
                  <ArrowLeftBoldIcon class="btn btn-arrow" @click="addFacility(facility)"></ArrowLeftBoldIcon>
                </div>
                <div class="col-3">{{facility.code}}</div>
                <div class="col-7">{{facility.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapState, mapGetters } from 'vuex';
import { Client, Operation, UserDto } from "../../code/EngineeringChecklist.Api";
import ManageUserDetail from "./ManageUserDetail.vue";

import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
  import ArchiveIcon from 'vue-material-design-icons/Archive.vue';
  import ArrowRightBoldIcon from 'vue-material-design-icons/ArrowRightBold.vue';
  import ArrowLeftBoldIcon from 'vue-material-design-icons/ArrowLeftBold.vue';
export default Vue.extend({
  name: 'ManageFacilities',
  components: {
    ArrowLeftBoldIcon,
    ArrowRightBoldIcon
  },
  props: {
  },
  data() {
    return {
      loading: true,
      selectedFacility: null,
      allFacilities: [
      ],
      facilityMachines: [],
    }
  },
  computed: {
    ...mapGetters(['activeFacilities']),
    availableFacilities: function () {
      return this.sortFacilities(_.differenceWith(this.allFacilities, this.activeFacilities,
        function (a, b) {
          return a.code == b.code;
        }));
    }
  },
  methods: {
    sortFacilities(array) {
      return _.orderBy(array, 'code', 'asc');
    },
    addFacility: function (facility) {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);
      if (facility && facility.code && facility.name) {
        //Create a display name
        facility.name = facility.code + ' | ' + facility.name;

        c.facilities_Post(facility)
          .then((newfacility) => {
            this.$store.commit('addFacility', facility);
          })
          .catch((error) => {

          });
      }

    },
    archiveFacility: function (facility) {
      if (facility && facility.code) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        c.facilities_Patch(facility.code, [new Operation({ op: 'replace', path: '/archived', value: true })])
          .then(function (updatedFacility) {
            this.$store.commit('removeFacility', facility);
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          })
      }
    },
    addNewUser: function() {
      this.selectedUser = new UserDto({id: 0, superUser: false, disabled: false});
      this.$refs.checklistUserModalRef.show();

    },

    loadFacilities: function() {
      this.loading = true;

      var c = new Client(process.env.VUE_APP_API_URL, this.$http);
      c.dWPlant_GetAll()
        .then((allFacilities) => {
          this.allFacilities = allFacilities
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selectFacility: function (facility) {

      this.selectedFacility = facility;
      this.fetchMachines(facility.code);
    },
    fetchMachines: function (facilityCode) {

      this.loading = true;

      var c = new Client(process.env.VUE_APP_API_URL, this.$http);
      c.dWMachine_Get(facilityCode)
        .then((facilityMachines) => {
          this.facilityMachines = facilityMachines || []
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch: {
  },
  created() {
    this.loadFacilities();
  }
});
</script>

<style lang="scss">
  .row.selected {
    background-color: #AAA;
  }
  .row.active {
    padding: 0;
    &:hover
    {
      background-color: #EEE;
      cursor: pointer;
    }
  }
</style>