<template>
  <div class="checklist" :class="{'adv-options': showOptions}">
    <!--<b-sidebar id="checklist-options" title="Advanced Options" width="35%" right backdrop shadow>-->

    <div class="adv-options-panel" title="Advanced Options" width="35%">
      <div class="card mt-3">
        <div class="card-header p-1"><h4 class=" m-1 text-center">Checklist Advanced Options</h4></div>
        <div class="card-body px-1">

          <div class="container">
            <div class="row">
              <div class="col-6">
                <label class="col-form-label">Checklist Signoff</label>
              </div>
              <div class="col-6">
                <b-form-radio-group class=""
                                    id="btn-radios-type"
                                    button-variant="outline-primary"
                                    v-model="checklist.allowSignOff"
                                    buttons
                                    size="sm">
                  <b-form-radio :value="true">Required</b-form-radio>
                  <b-form-radio :value="false">Hidden</b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label class="col-form-label">User Editable Name</label>
              </div>
              <div class="col-6">
                <b-form-radio-group class=""
                                    id="btn-radios-type"
                                    button-variant="outline-primary"
                                    v-model="checklist.userEditableName"
                                    buttons
                                    size="sm">
                  <b-form-radio :value="true">Editable</b-form-radio>
                  <b-form-radio :value="false">Readonly</b-form-radio>
                </b-form-radio-group>
              </div>
            </div>

            <template v-if="isRepetitive">

              <div class="row">
                <div class="col-6">
                  <label class="col-form-label" for="subject-name-format">
                    Default Checklist Name
                  </label>
                </div>
                <div class="col-6">
                  <input id="subject-name-format" class="form-control" type="text" v-model="defaultChecklistName" />
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <label id="allow-suggestions" class="col-form-label" for="allow-suggestions">Checklist Suggestions</label>
                </div>
                <div class="col-6">
                  <b-form-radio-group class=""
                                      id="allow-suggestions"
                                      button-variant="outline-primary"
                                      v-model="checklist.useSuggestions"
                                      buttons
                                      size="sm">
                    <b-form-radio :value="true">Enable</b-form-radio>
                    <b-form-radio :value="false">Disable</b-form-radio>
                  </b-form-radio-group>
                </div>
                <b-popover target="allow-suggestions" triggers="hover" placement="top">
                  <template #title>
                    Enable Suggestions
                  </template>
                  Enable extra question with predefined naming suggestions when users create checklist.
                </b-popover>
              </div>
              <div class="row"  v-if="useSuggestions">
                <div class="col-6">
                  <label class="form-label" for="subject-options">Subject Options Label</label>
                </div>
                <div class="col-6">
                  <input id="subject-label" class="form-control" type="text" v-model="checklist.suggestionsLabel" />
                </div>
                <b-popover target="subject-label" triggers="hover" placement="top">
                  <template #title>
                    Suggestions Label
                  </template>
                  Label that helps identify suggestion list when a user adding this checklist.
                </b-popover>
              </div>
              <div class="row"  v-if="useSuggestions">
                <div class="col-12">
                  <label class="form-label" id="suggestions-label" for="suggestions">Suggestions</label>
                  <b-form-tags input-id="suggestions"
                               v-model="checklist.suggestions"
                               separator=" ,"
                               placeholder="Enter Options"></b-form-tags>
                </div>


              </div>
              <div class="row mt-2"  v-if="useSuggestions">
                <div class="col-6">
                  <label class="form-label" for="subject-options">Custom Suggestions</label>
                </div>
                <div class="col-6">
                  <b-form-radio-group class=""
                                      id="allow-custom-suggestions"
                                      v-model="checklist.customSuggestions"
                                      button-variant="outline-primary"
                                      buttons
                                      size="sm">
                    <b-form-radio :value="true">Enable</b-form-radio>
                    <b-form-radio :value="false">Disable</b-form-radio>
                  </b-form-radio-group>
                </div>
                <b-popover target="allow-custom-suggestions" triggers="hover" placement="top">
                  <template #title>
                    Custom Suggestions
                  </template>
                  Whether or not the user is allowed to specify something other than the predefined list.
                </b-popover>
              </div>
            </template>







            <div class="row">
              <div class="col-6">
              </div>
              <div class="col-6">
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
        <div class="container">
          <div class="row">
            <div class="col-4">
              <input class="form-control checklist-title" placeholder="Checklist Name" type="text" v-model.lazy="checklist.name">
            </div>
            <div class="col-6 pt-3 text-center">
              <b-button @click="showOptions=!showOptions">Advanced Options</b-button>
            </div>
            <div class="col-2 text-right">
              <b-button class="btn-add-area icon-btn mt-3" variant="primary" size="sm" @click="showAreaDialog()">
                <PlusBoxIcon />
                Add Area
              </b-button>
            </div>
          </div>

          <div class="row" v-if="isRepetitive">
            <div class="col-12">
              <div class="card my-1">
                <div class="card-header p-1">Checklist Access Options</div>
                <div class="card-body p-1">
                  <div class="container">
                    <div class="row">
                      <div class="col-4">Project Types</div>
                      <div class="col-4">Checklist Type</div>
                      <div class="col-4">Facility Restrictions</div>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <multiselect v-model.lazy="checklist.projectTypesAllowed"
                                 :showLabels="false"
                                 :close-on-select="false"
                                 :placeholder="'ALL PROJECT TYPES'"
                                 :searchable="false"
                                 :multiple="true"
                                 :options="projectTypeOptions"
                                 @input="patch('replace', '/projectTypesAllowed', $event)"
                                ></multiselect>
                      </div>
                      <div class="col-4">
                        <multiselect v-model.lazy="checklist.checklistTypes"
                                     :showLabels="false"
                                     :close-on-select="false"
                                     :placeholder="'ALL CHECKLIST TYPES'"
                                     :searchable="false"
                                     :multiple="true"
                                     :options="checklistTypeOptions"
                                     @input="patch('replace', '/checklistTypes', $event)"></multiselect>
                      </div>
                      <div class="col-4">
                        <multiselect v-model.lazy="checklist.facilityRestrictions"
                                     :showLabels="false"
                                     :close-on-select="false"
                                     :placeholder="'ALL FACILITIES'"
                                     :searchable="false"
                                     :multiple="true"
                                     :options="facilityOptions"
                                     @input="patch('replace', '/facilityRestrictions', $event)"></multiselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-12">
              <div class="card my-1">
                <div class="card-header p-1">
                  <h5 class="d-inline">Conditional Rules</h5>
                  <div class="add-button float-right">
                    <b-button class="btn-add-rule mx-2 icon-btn" variant="primary" size="sm" @click="showFilterDialog()">
                      <PlusBoxIcon /> Add Condition
                    </b-button>
                  </div>
                </div>
                <div class="card-body p-1">
                  <div class="container">
                    <drop-list :items="rules"
                               @reorder="$event.apply(rules)"
                               accepts-type="rule"
                               :row="true"
                               :no-animations="true">
                      <h4 class="text-center my-3" v-if="!rules || !rules.length">No Rules Defined</h4>
                      <template v-slot:item="{item}">
                        <drag tag="div" class="row conditional-rule pl-2" handle=".rule-drag-handle" :key="item.id" :data="item" type="rule">
                          <drag-horizontal-icon class="drag-handle rule-drag-handle" title="Drag Conditional Rule"></drag-horizontal-icon>
                          <div class="col-7 pl-4">
                            <b-button class="" variant="link" size="sm" @click="showFilterDialog(item)">{{ item.label }}</b-button>
                          </div>
                          <div class="col-3">
                            <b-select size="sm" v-model="item.type" :disabled="true">
                              <b-select-option value="radio">Radio Button Options</b-select-option>
                              <b-select-option value="select">Drop Down List</b-select-option>
                              <b-select-option value="checkbox">Checkbox List</b-select-option>
                            </b-select>
                          </div>
                          <div class="col-2 text-right">
                            <b-button class="btn-add-rule mx-1" variant="outline-primary" size="sm" @click="showFilterDialog(item)">
                              <LeadPencilIcon />
                            </b-button>
                            <b-button class="btn-add-rule mx-1" variant="outline-danger" size="sm" @click="removeRule(item)">
                              <TrashCanIcon />
                            </b-button>
                          </div>

                          <!-- <template v-slot:drag-image>
                          <div class="drag-image">DRAG</div>
                        </template> -->
                        </drag>
                      </template>
                      <template v-slot:feedback="{data}">
                        <div class="feedback" :key="data.id" />
                      </template>
                      <template v-slot:reordering-feedback="{}">
                        <div class="reordering-feedback" key="feedback" />
                      </template>
                    </drop-list>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <drop-list :items="areas"
                     @reorder="reorderAreas($event)"
                     accepts-type="area"
                     :row="true"
                     :no-animations="true">
            <template v-slot:item="{item}">
              <manage-area v-if="!item.groupName"
                :checklistRules="availableRules"
                :area="item"
                :key="item.id"
                :groupNames="areaGroupNames"
                @filter="editConditions"
                @edit="showAreaDialog"
                @remove="removeArea"
                @update-group-name="updateGroupName"
                @set-group-name="setGroupName"
                ></manage-area>
              <manage-area-matrix v-else
                :checklistRules="availableRules"
                :area="item"
                :areaGroup="item.groupName && areaGroups ? areaGroups[item.groupName] : null"
                :key="item.groupName"
                @filter="editConditions"
                @edit="showAreaDialog"
                @remove="removeArea"
                @add-dimension="addDimensionArea"
                @set-group-name="setGroupName"
                @update-group-name="updateGroupName"
                @shift-area="shiftArea"
                ></manage-area-matrix>
            </template>
            <template v-slot:feedback="{data}">
              <div class="feedback" :key="data.id" />
            </template>
            <template v-slot:reordering-feedback="{}">
              <div class="reordering-feedback" key="feedback" />
            </template>
          </drop-list>
        </div>

        <b-modal :title="(rule && !rule.new) ? 'Edit Conditional Rule' : 'New Conditional Rule'"
                 centered
                 ref="createRuleModalRef"
                 size="md">
          <div class="container" v-if="rule">
            <div class="row py-1">
              <div class="col-4">
                Name:
              </div>
              <div class="col-8">
                <input class="form-control" type="text" v-model="rule.label" @change="changeRuleLabel(rule, $event)">
              </div>
            </div>
            <div class="row py-1">
              <div class="col-4">
                Type:
              </div>
              <div class="col-8">
                <select class="form-control" v-model="rule.type" @change="changeRuleType(rule, $event)">
                  <option value="radio">Radio Buttons</option>
                  <option value="select">Drop Down List</option>
                  <option value="checkbox">Checkbox list</option>
                  <option value="toggle">Toggle (Yes/No)</option>
                </select>
              </div>
            </div>

            <div class="row py-1" v-if="rule.type != 'toggle'">
              <div class="col-4">
                Choices:
              </div>
              <div class="col-8 ">
                <div class="row no-gutters py-1">
                  <div class="col-10 pr-2">
                    <b-form-input class="form-control pr-5 d-inline-block" type="text" size="sm" placeholder="Specify New Item" v-model="newFilterItemName"></b-form-input>
                  </div>
                  <div class="col-2 text-center">
                    <b-button class="" size="sm" variant="primary" @click="addRuleChoice(rule)">Add</b-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters py-1">
              <div class="col-12" v-if="rule.type =='radio' || rule.type =='select'">
                <b-form-radio-group :id="`admin_${checklist.name}_${rule.label}-radio-group`"
                                    v-model="rule.value"
                                    :name="`${checklist.name}_${rule.label}`">
                  <div class="pb-1" v-for="(choice, index) in rule.choices" :key="choice.label">
                    <b-form-radio :value="choice.value">{{choice.label}}</b-form-radio>
                    <a class="float-right" href="#" @click.prevent="rule.choices.splice(index,1)"><TrashCanIcon title="" fillColor="red"></TrashCanIcon></a>
                  </div>
                </b-form-radio-group>
              </div>
              <div class="col-12" v-else-if="rule.type =='checkbox'">
                <b-form-checkbox-group :id="`admin_${checklist.name}_${rule.label}-checkbox-group`"
                                       v-model="rule.value"
                                       :name="`${checklist.name}_${rule.label}`">
                  <div class="pb-1" v-for="(choice, index) in rule.choices" :key="choice.label">
                    <b-form-checkbox :value="choice.value">{{choice.label}}</b-form-checkbox>
                    <a class="float-right" href="#" @click.prevent="rule.choices.splice(index,1)"><TrashCanIcon title="" fillColor="red"></TrashCanIcon></a>
                  </div>

                </b-form-checkbox-group>
              </div>
            </div>

          </div>
          <template v-slot:modal-footer="{ ok, cancel, hide }">
            <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
            <b-button v-if="rule.new" size="sm" variant="primary" @click="addNewRule(hide)">Add</b-button>
            <b-button v-else size="sm" variant="primary" @click="ok()">Ok</b-button>
          </template>
        </b-modal>
        <b-modal :title="newArea.id ? 'Edit Area' : 'New Area'"
                 centered
                 ref="areaModalRef"
                 size="md">
          <div class="container">
            <div class="row">
              <div class="col-4">
                Name:
              </div>
              <div class="col-8">
                <input class="form-control" type="text" v-model.lazy="newArea.name">
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                Description:
              </div>
              <div class="col-8">
                <b-form-textarea id="textarea-small"
                                 class="form-control"
                                 size="sm"
                                 placeholder="Area Description"
                                 v-model.lazy="newArea.description"></b-form-textarea>
              </div>
            </div>
          </div>
          <template v-slot:modal-footer="{ ok, cancel, hide }">
            <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
            <b-button v-if="newArea.id" size="sm" variant="primary" @click="ok()">Ok</b-button>
            <b-button v-else size="sm" variant="primary" @click="addNewArea(hide)">Add</b-button>
          </template>
        </b-modal>


        <b-modal title="Filtering Conditions"
                 centered
                 ref="filterConditionModalRef"
                 size="lg">
          <div class="container p-0" v-if="activeConditional">
            <div class="row">
              <div class="col-12">
                <h4>Conditions for: {{activeConditional.name}}</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <vue-query-builder :rules="availableRules" v-model="activeConditional.conditionsObj"></vue-query-builder>
              </div>
            </div>
          </div>
          <template v-slot:modal-footer="{ ok }">
            <b-button size="sm" variant="primary" @click="ok()">Ok</b-button>
          </template>
        </b-modal>

      </div>
</template>

<script>
  import Vue from "vue";
  import { ChecklistAreaDto, Client, Operation, QuestionDto, ChecklistType, ChecklistDto } from "../../code/EngineeringChecklist.Api";
  import { mapState, mapGetters } from "vuex";
  import VueQueryBuilder from 'vue-query-builder';
  import FilterInlineViewer from './FilterInlineViewer.vue'
  import FilterIcon from 'vue-material-design-icons/Filter.vue';
  import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
  import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
  import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
  import DragHorizontalIcon from 'vue-material-design-icons/DragHorizontalVariant.vue';
  import Multiselect from "vue-multiselect";

  import { Drag, Drop, DropList, ReorderEvent } from "vue-easy-dnd";
  import ManageAreaQuestions from "./ManageAreaQuestions.vue";
  import { functions, get } from "lodash";
  import ManageArea from "./ManageArea.vue";
  import ManageAreaMatrix from "./ManageAreaMatrix.vue";

  export default Vue.extend({
    name: "ManageChecklist",
    components: {
      VueQueryBuilder,
      Multiselect,
      // FilterInlineViewer,
      PlusBoxIcon,
      TrashCanIcon,
      LeadPencilIcon,
      DragHorizontalIcon,
      // FilterIcon,
      // ToolComponent
      DropList, Drag,
      ManageArea, ManageAreaMatrix,
      // ManageAreaQuestions,
    },
    props: {
      checklist: { type: Object, required: true },
      isRepetitive: { type: Boolean, default: false }
    },
    data() {
      return {
        showOptions: true,
        ChecklistType,
        init: true,

        rule: null,
        activeConditional: null,
        newFilter: { name: "", type: "", options: [] },
        newFilterItemName: "",
        newArea: { name: "", description: "", questions: [], matrix: false},
        newQuestionArea: null,
        newQuestion: { prompt: "", hint: "", type: "yesNo" },
        patches: [],
        areaPatches: {},
      };
    },
    computed: {
      ...mapState(['facilities']),
      ...mapGetters(['activeProjectTypes']),
      facilityOptions: function () {
        return _.map(this.facilities, function(item) { return item.code  });
      },
      projectTypeOptions: function () {
        return  this.activeProjectTypes && this.activeProjectTypes.length ? _.map(this.activeProjectTypes, function(item) { return item.code  }) : [];
      },
      checklistTypeOptions: function () {
        return Object.keys(ChecklistType);
      },
      availableRules: function () {
        return [
          {
            id: "active-facility", 
            type: "checkbox", 
            label: "Active Facility", 
            operators: ['Equals', 'Not Equals'], 
            choices: _.map(this.facilityOptions, function (item) { return { label: item, value: item }; }), value: null 
          },
          ...this.rules
        ];
      },
      allAreas: function () {
        return this.checklist ? _.sortBy(this.checklist.areas, ["sortOrder", "id"]) : [];
      },
      areas: function () {
        let uniqueAreas = _.uniqBy(_.sortBy(this.allAreas, ["sortOrder", "groupName"]), area => `${area.groupName ? area.groupName : area.id}`);
        return uniqueAreas;
        //return this.checklist ? _.sortBy(this.checklist.areas, ["sortOrder", "id"]) : [];
      },
      areaGroups: function () {
        let groupAreas = _.filter(this.allAreas, area => area.groupName);
        return _.groupBy(groupAreas, 'groupName');
      },
      areaGroupNames: function () {
        return _.uniqBy(_.filter(this.areas, area => area.groupName && area.groupName.trim() !== ''),  'groupName').map(area => area.groupName) || [];
      },
      name: {
        get: function () {
          return this.checklist ? this.checklist.name : "";
        },
        set: function (newValue) {
          this.checklist.name = newValue;
        }
      },
      description: {
        get: function () {
          return this.checklist ? this.checklist.description : "";
        },
        set: function (newValue) {
          this.checklist.description = newValue;
        }
      },
      allowSignOff: {
        get: function () {
          return this.checklist ? this.checklist.allowSignOff : true;
        },
        set: function (newValue) {
          this.checklist.allowSignOff = newValue;
        }
      },
      rules: function () {
        return this.checklist.rules;
      },
      userEditableName: {
        get: function () { return this.checklist.userEditableName || false; },
        set: function (value) { this.checklist.userEditableName = value }
      },
      useSuggestions: {
        get: function () { return this.checklist.useSuggestions || false; },
        set: function (value) { this.checklist.useSuggestions = value }
      },
      suggestionsLabel: {
        get: function () { return this.checklist.suggestionsLabel || "Option"; },
        set: function (value) { this.checklist.suggestionsLabel = value || "Option" }
      },
      suggestions: {
        get: function () { return this.checklist.suggestions || ""; },
        set: function (value) { this.checklist.suggestions = value || "" }
      },
      customSuggestions: {
        get: function () { return this.checklist.customSuggestions },
        set: function (value) { this.checklist.customSuggestions = value }
      },
      defaultChecklistName: {
        get: function () {
          let defaultChecklistName = this.checklist.defaultNameFormat || this.checklist.name;
          if (this.useSuggestions && defaultChecklistName.indexOf("{sugg}") < 0 ) {
            defaultChecklistName += " {sugg}";
          } else if (!this.useSuggestions && defaultChecklistName.indexOf("{sugg}") >= 0) {
            defaultChecklistName = _.replace(defaultChecklistName, "{sugg}", "");
          }

          defaultChecklistName = _.trim(defaultChecklistName)
           
          if (!defaultChecklistName && this.checklist.name) {
            defaultChecklistName = this.checklist.name.replace(/template/gi, "");
            defaultChecklistName = _.trim(defaultChecklistName)
          }
  
          return defaultChecklistName;
        },
        set: function (value) {

          if (!value) 
            value = ""

          if (this.useSuggestions && value.indexOf("{sugg}") < 0 ){
            value += "{sugg}";
          } else if (!this.useSuggestions && value.indexOf("{sugg}") >= 0) {
            value = _.replace(value, "{sugg}", "");
          }

          value = _.trim(value)

          if (!value && this.checklist.name) {
            value = this.checklist.name.replace(/template/gi, "");
            value = _.trim(value)
          }

          this.checklist.defaultNameFormat = value;
        }
      }
    },
    methods: {
      addTag(newTag) {
        const tag = {
          name: newTag,
          code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
        }
        this.checklist.subjectOptions.push(tag)
      },
      changeRuleLabel: function (rule, evt) {
        this.$set(rule, 'id', `${String(rule.label).toLowerCase()}-${rule.type}-field`)
      },
      changeRuleType: function (rule, evt) {
        this.$set(rule, 'id', `${String(rule.label).toLowerCase()}-${rule.type}-field`)
        rule.value = null
      },
      editConditions: function (condObj) {
        // if (condObj) {
        //   this.$set(condObj, 'conditionsObj', condObj.conditions ? JSON.parse(condObj.conditions) : {});
        // }

        this.activeConditional = condObj;

        this.$refs.filterConditionModalRef.show();
      },
      showFilterDialog: function (rule) {
        if (rule) {
          this.rule = rule;
        } else {
          this.rule = { new: true, type: "", label: "", operators: ['Equals', 'Not Equals'], choices: [], value: null };
        }

        this.$refs.createRuleModalRef.show();
      },
      addNewRule: function (hideModal) {
        if (this.rule && this.rule.type == 'toggle') {
          this.rule.choices = [
            { label: "Yes", value: "true" },
            { label: "No", value: "false" }
          ]
          //Convert this to a radio button rule
          this.rule.type = 'radio';
        }

        delete this.rule.new;

        this.rules.push(this.rule);

        if (hideModal) {
          hideModal()
        }
        // this.$refs.createRuleModalRef.hide();
      },
      addRuleChoice: function (rule) {
        rule.choices.push({ label: this.newFilterItemName, value: this.newFilterItemName });
        this.newFilterItemName = "";
      },
      removeRule: function (rule) {
        this.$bvModal.msgBoxConfirm('Are you sure?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          if (value) {
            this.remove(this.rules, rule);
          }
        }.bind(this));

      },
      remove(array, item) {
        let index = array.indexOf(item);
        if (index >= 0) {
          array.splice(index, 1);
        }
      },
      reorderAreas(e) {

        let index = 0,changes = [];
        let sortedAreas = [], groupedAreas = [], groups = [];

        //this.allAreas should be sorted by sort order, might not be sorted by groups properly...
        for (index = 0; index < this.allAreas.length; index++) {
          const area = this.allAreas[index];

          if(area.groupName) {
            if(groups.indexOf(area.groupName) >= 0) {
              //This group has already been added... Skip it. Indicates that the group is not sorted sequentially
              continue;
            }

            groups.push(area.groupName);
            //This is a grouped area... Find all areas in the group and sort them by sort order
            let groupAreas = _.sortBy(_.filter(this.allAreas, { groupName: area.groupName }), ["sortOrder", "id"]);
            sortedAreas = sortedAreas.concat(groupAreas);
            groupedAreas.push(groupAreas);
          } else {
            //This is not a grouped area... Just add it to the list
            sortedAreas.push(area);
            groupedAreas.push([area]);
          }
        }

        if(e == null || e.from == e.to) {
          //Just a reorder request, to validate/confirm the sortOrder matches the sequence of the sortedAreas array
        }
        else if( e instanceof ReorderEvent) {
          //Drag and drop event, reorder things based on groupedAreas

          let f = groupedAreas.splice(e.from, 1);
          groupedAreas.splice(e.to, 0, f[0]);

          sortedAreas = groupedAreas.flat();     
        } else {
          //This is a group shift event, reorder things based on individual areas
          /*
          * e.from: The area id to shift
          * e.to: How to shift the area within the group (-1: left, 1: right)
          */
          let fromIdx = _.findIndex(sortedAreas, {id: e.from})
          let toIdx = fromIdx + e.to;

          let f = sortedAreas.splice(fromIdx, 1);
          sortedAreas.splice(toIdx, 0, f[0]);

        }

        for (index = 0; index < sortedAreas.length; index++) {
          const area = sortedAreas[index];
          if(area.sortOrder != index + 1) {
            changes.push({ area: area, sortOrder: index + 1 });
          }
        }   

        //Commit all the sorting changes detected
        for (const change of changes) {
          this.updateAreaSortOrder(change.area, change.sortOrder)
        }
      },
      updateAreaSortOrder: function (area, sortOrder) {
        if (area && sortOrder && area.sortOrder != sortOrder) {
          this.patchArea(area.id, 'replace', '/sortOrder', sortOrder);
        }
      },


      updateAreaGroupName: function (area, newGroupName) {
        if (area && newGroupName) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          c.checklistAreas_Patch(area.id, [new Operation({ op: 'replace', path: '/groupName', value: newGroupName })])
            .then(function (updatedArea) {
              if (updatedArea) {
                delete updatedArea.questions;
                Object.assign(area, updatedArea);
              }
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            })
        }

      },
      setGroupName: function (area, newGroupName) {
        if (area) {
          this.$set(area, 'groupName', newGroupName);

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          c.checklistAreas_Patch(area.id, [new Operation({ op: 'replace', path: '/groupName', value: newGroupName })])
            .then(function (updatedArea) {
              if (updatedArea) {
                delete updatedArea.questions;
                Object.assign(area, updatedArea);
              }
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            })
        }
      },
      updateGroupName(groupName, newGroupName) {
        let areas = _.filter(this.allAreas, { groupName: groupName });

        for (const area of areas) {

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);
          c.checklistAreas_Patch(area.id, [new Operation({ op: 'replace', path: '/groupName', value: newGroupName })])
            .then(function (updatedArea) {
              if (updatedArea) {
                delete updatedArea.questions;
                Object.assign(area, updatedArea);
              }
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            })
        }
      },
      shiftArea(area, delta) {

        let e = {from: area.id, to: delta};
        this.reorderAreas(e);
      },
      addDimensionArea(area, newDimensionName) {
        
        let cloneArea = _.cloneDeep(area);
        cloneArea.id = 0;
        cloneArea.name = newDimensionName;
        
        let sibs = this.allAreas.filter(a => a.groupName == area.groupName);
        //let nextSortOrder = sibs[sibs.length-1].sortOrder + 1;
        cloneArea.sortOrder = sibs[sibs.length-1].sortOrder + 1;

        var questions = cloneArea.questions;
        cloneArea.questions = [];

        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        c.checklistAreas_Post(cloneArea)
            .then(function (newArea) {

              if (questions) {
                for (const question of questions) {
                  question.id = 0;
                  question.areaId = newArea.id;
                  c.questions_Post(question)
                    .then(function (newQuestion) {
                      newArea.questions.push(newQuestion);
                    }.bind(this))
                    .catch(function (error) {
                      console.log(error);
                    }.bind(this));
                }
              }

              this.checklist.areas.push(newArea);

              this.$bvToast.toast(`Sucessfully added dimension: "${newDimensionName}"`, {
                title: `Dimension added.`,
                variant: "success"
              });

            }.bind(this))
            .catch(function (error) {
              console.log(error);
            }.bind(this))
            .finally(function() {
              this.reorderAreas(null);
            }.bind(this));
      },

      showAreaDialog: function (area) {
        if (area) {
          this.newArea = area;
        } else {
          this.newArea = { name: "", description: "", questions: [], matrix: false};
        }

        this.$refs.areaModalRef.show();
      },
      saveArea: function (area, hideModal) {

        // area.conditions = JSON.stringify(area.conditionsObj);

        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        if (!area.id) {
          c.checklistAreas_Post(area)
            .then(function (newArea) {
              this.checklist.areas.push(newArea);
              if (hideModal) {
                hideModal();
              }
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            }.bind(this));
        } else {
          c.checklistAreas_Put(area.id, area)
            .then(function (newArea) {
              if (hideModal) {
                hideModal();
              }
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            }.bind(this));
        }

      },
      addNewArea(hideModal) {
        var area = new ChecklistAreaDto(this.newArea)
        area.id = 0;
        if (this.isRepetitive) {
          area.repetitiveChecklistId = this.checklist.id;
        } else {
          area.checklistId = this.checklist.id;
        }
        area.sortOrder = this.checklist.areas.length + 1;

        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        if (!area.id) {
          c.checklistAreas_Post(area)
            .then(function (newArea) {
              this.checklist.areas.push(newArea);
              if (hideModal) {
                hideModal();
              }
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            }.bind(this));
        }
      },
      removeArea(area) {
        if (this.checklist.areas && this.checklist.areas.length) {
          let index = this.checklist.areas.indexOf(area);

          if (area.id) {
            var c = new Client(process.env.VUE_APP_API_URL, this.$http);


            this.$bvModal.msgBoxConfirm('Are you sure?', {
              title: 'Please Confirm',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(function (value) {

              if (value) {
                c.checklistAreas_Delete(area.id)
                  .then(function (deletedArea) {
                    let index = _.findIndex(this.checklist.areas, area);
                    if (index >= 0) {
                      this.checklist.areas.splice(index, 1);
                    }
                    this.$bvToast.toast(`Sucessfully removed area: "${area.name}"`, {
                      title: `Area removed.`,
                      variant: "success"
                    });

                    this.reorderAreas(null);

                  }.bind(this))
                  .catch(function (error) {

                    this.$bvToast.toast(`Failed to remove area: ${error.message}`, {
                      title: `Failed to remove Area.`,
                      variant: "danger"
                    });

                    console.log(error);
                  }.bind(this));
              }
            }.bind(this))

          } else {
            if (index >= 0) {
              this.checklist.areas.splice(index, 1);
            }
          }
        }
      },

      addNewQuestion() {
        var question = new QuestionDto(this.newQuestion);
        question.areaId = this.newQuestionArea.id;

        this.newQuestionArea.questions.push(question);

        this.$refs.questionModalRef.hide();
      },

      patchArea: function(key, op, path, value) {
        let patch = _.find(this.areaPatches[key], {op: op, path: path });
        
        if (patch) {
          patch.value = value;
        } else {
          patch = new Operation({op: op, path: path, value: value});
          this.areaPatches[key].push(patch);
        }
        this.debouncedProcessAreaPatches();
      },
      processAreaPatches: function() {

        for (const key in this.areaPatches) {
          if (this.areaPatches.hasOwnProperty(key) && this.areaPatches[key].length) {
            var c = new Client(process.env.VUE_APP_API_URL, this.$http);
            const patches = this.areaPatches[key];
            var area = _.find(this.checklist.areas, { id: key });

            c.checklistAreas_Patch(key, patches)
            .then(function (updatedArea) {

              if (updatedArea) {
                
                let aIdx = _.findIndex(this.checklist.areas || [], {id: updatedArea.id});
                if (aIdx > -1) {
                  delete updatedArea.questions;
                  Object.assign(this.checklist.areas[aIdx], updatedArea);
                  //let a = this.checklist.areas[aIdx] = updatedArea.sortOrder;
                  //this.$set(a, 'sortOrder', updatedArea.sortOrder);
                }
              }

              this.$set(this.areaPatches, key, []);
            }.bind(this))
            .catch(function (error) {
              this.$bvToast.toast(error.message, {
                title: `Saving ${this.newArea.name} area failed`,
                variant: "danger"
              });
            }.bind(this));

          }
        }
        
      },

      patch: function (op, path, value) {
        //Don't patch while initializing
        if (this.init) return;

        let patch = _.find(this.patches, { op: op, path: path });

        if (patch) {
          patch.value = value;
        } else {
          patch = new Operation({ op: op, path: path, value: value });
          this.patches.push(patch);
        }
      },
      processChecklistPatches: function () {

        if (this.patches && this.patches.length) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http),
            patchFunc = c.checklists_Patch;

          if (this.isRepetitive) {
            patchFunc = c.repetitiveChecklists_Patch
          }

          patchFunc.apply(c, [this.checklist.id, this.patches])
            .then(function () {
              this.patches = [];
            }.bind(this))
            .catch(function (error) {
              this.$bvToast.toast(error.message, {
                title: `Saving ${this.checklist.name} checklist failed`,
                variant: "danger"
              });
            }.bind(this))
        }
      }
    },
    watch: {
      name: function (newValue, oldValue) {
        this.patch('replace', '/name', newValue);
      },
      description: function (newValue, oldValue) {
        this.patch('replace', '/description', newValue);
      },
      allowSignOff: function (newValue, oldValue) {
        this.patch('replace', '/allowSignOff', newValue);
      },
      userEditableName: function (newValue, oldValue) {
        this.patch('replace', '/userEditableName', newValue);
      },
      useSuggestions: function (newValue, oldValue) {
        if (this.isRepetitive) {
          this.patch('replace', '/useSuggestions', newValue);
        }
      },
      suggestionsLabel: function (newValue, oldValue) {
        if (this.isRepetitive) {
          this.patch('replace', '/suggestionsLabel', newValue);
        }
      },
      suggestions: function (newValue, oldValue) {
        if (this.isRepetitive) {
          this.patch('replace', '/suggestions', newValue);
        }
      },
      customSuggestions: function (newValue, oldValue) {
        if (this.isRepetitive) {
          this.patch('replace', '/customSuggestions', newValue);
        }
      },
      defaultChecklistName: function (newValue, oldValue) {
        if (this.isRepetitive) {
          this.patch('replace', '/DefaultNameFormat', newValue);
        }
      },
      rules: {
        deep: true,
        handler: function (newValue, oldValue) {
          let newOptions = JSON.stringify(newValue || []);

          this.checklist.options = newOptions
          this.patch('replace', '/options', this.checklist.options);
        }
      },
      patches: function (newValue, oldValue) {
        if (newValue && newValue.length) {
          this.debouncedProcessChecklistPatches();
        }
      },
      $route: function (to, from) {
        this.debouncedProcessChecklistPatches.flush();
        this.debouncedProcessAreaPatches.flush();
      },
      allAreas: {
        immediate: true,
        handler: function(newValue, OldValue)
        {
          let patches = {};

          newValue.forEach((ma, idx) => {
            patches[ma.id] = this.areaPatches[ma.id] || [];
          });

          this.areaPatches = patches;

          //Don't try to fix the sort order if we are initializing
          if (this.init) return;
        }
      },
      groupAreas: function (newValue, oldValue) {
        
      }
    },
    created() {
      this.$set(this.checklist, 'rules', JSON.parse(this.checklist.options || '[]'));

      //Debounce the tool changes, only verify if the tool has changed 250ms after the last call
      this.debouncedProcessChecklistPatches = _.debounce(this.processChecklistPatches, 3000, {
        leading: false,
        trailing: true
      });

      this.debouncedProcessAreaPatches = _.debounce(this.processAreaPatches, 250, {
        leading: false,
        trailing: true
      });

      setTimeout(() => {
        this.init = false;

        this.reorderAreas(null); //Force a reorder of the areas to make sure everything is consistent
      }, 1500);
    }
  });
</script>

<style lang="scss">

  .empty-card {
    // @extend .my-3;

    min-height: 3em;
    // padding: 1.5em;
  }
  .checklist .adv-options-panel {
    display: none;
  }

  .checklist.adv-options {
    display: flex;
    flex-direction: row-reverse;

    .adv-options-panel {
      display: flex;
      //float: right;
    }

    &> .container {
      //display: flex;
      //margin-left: 0;
    }
  }
  .checklist .checklist-title,
  .checklist .questions-list input {
    padding: 0;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  input.checklist-title {
    margin-bottom: 0.125em;
    font-size: 1.75em;
    height: 1.75em;
  }

  input.checklist-description {
    font-size: 0.9em;
    height: 1.5em;
  }

  .vue-query-builder .vqb-group .rule-actions {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .vue-query-builder .vqb-group .vqb-group .rule-actions {
    right: 30px;
  }

  .vue-query-builder .vqb-rule {
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #f5f5f5;
    border-color: #ddd;
    padding: 5px;
  }

  .vue-query-builder .vqb-group-heading.card-header {
    padding: 5px;
  }

  .vue-query-builder .vqb-group-body {
    padding: 0 5px;
  }

  .vue-query-builder .vqb-group.depth-1 .vqb-rule,
  .vue-query-builder .vqb-group.depth-2 {
    border-left: 4px solid #8bc34a;
  }

  .vue-query-builder .vqb-group.depth-2 .vqb-rule,
  .vue-query-builder .vqb-group.depth-3 {
    border-left: 4px solid #00bcd4;
  }

  .vue-query-builder .vqb-group.depth-3 .vqb-rule,
  .vue-query-builder .vqb-group.depth-4 {
    border-left: 4px solid #ff5722;
  }

  .vue-query-builder .close {
    opacity: 1;
    color: rgb(150,150,150);
  }

  @media (min-width: 768px) {
    .vue-query-builder .vqb-rule.form-inline .form-group {
      display: block;
    }
  }

  .drag-image {
    background-color: orangered;
    width: 75px;
    height: 75px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .reordering-feedback,
  .feedback {
    flex: 0 0 0;
    outline: 1px solid blue;
    align-self: stretch;
  }

  .drag-source {
    outline: 2px dashed black;
  }

  .conditional-rule {
    position: relative;
  }
</style>
