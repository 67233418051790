<template>
  <div class="container-fluid">
    <b-tabs @click="activeChecklist=null" >
      <b-tab title="Cavity">
        <div class="row">
          <div class="col-2">
            <b-nav class="my-2" pills vertical>
              <b-button class="mx-1 mb-2" @click="showAddMechs('cavity')" v-if="!readonly">Add Cavity Mech</b-button>
              <b-nav-item class="py-1 mx-1" :active="!cavActiveChecklist" @click="cavActiveChecklist=null">Overview</b-nav-item>
              <template v-for="menuItem in cavityMenuItems">
                <div class="card m-1" :key="menuItem.name">
                  <div class="card-header p-1" role="tab">
                    <!-- <plus-icon class="float-right"></plus-icon>
                    <minus-icon class="float-right"></minus-icon> -->
                    <b-button variant="link" block class="px-2 text-left font-weight-bold"  @click="$root.$emit('bv::toggle::collapse', 'cav_collapse_' + menuItem.name)">{{menuItem.name}}s</b-button>
                  </div>
                  <b-collapse :id="'cav_collapse_' + menuItem.name" :visible="true" role="tabpanel">
                    <div class="card-body p-1">
                      <b-nav-item link-classes="d-flex flex-row p-1 " v-for="subItem in menuItem.children" :key="subItem.name" :active="subItem==cavActiveChecklist" @click="cavActiveChecklist=subItem" >
                        <div class="col-7 p-0">
                          {{subItem.name}}
                        </div>
                        <div class="col-1 p-0">
                          <b-badge :variant="subItem.status.issue ? 'danger' : 'secondary'">{{subItem.status.issue}}</b-badge>
                        </div>
                        <div class="col-3 percent-col px-2 pt-1 text-center">
                          <b-progress v-if="subItem.status" height="1.125rem"  show-progress :max="subItem.status.count">
                            <b-progress-bar :value="subItem.status.accepted" variant="success"></b-progress-bar>
                          </b-progress>
                        </div>
                        <div class="col-1 p-0 text-center" v-if="!readonly" >
                          <a class="" href="#" @click.stop.prevent="removeChecklist(subItem)">
                            <TrashCanIcon title="" fillColor="red" ></TrashCanIcon>
                          </a>
                        </div>
                      </b-nav-item>
                    </div>
                  </b-collapse>
                </div>
              </template>
            </b-nav>
          </div>
          <div class="col-9" v-if="cavActiveChecklist">
            <transition name="fade" >
              <checklist :checklist="cavActiveChecklist" :readonly="readonly" :blockUpdates="true" :key="cavActiveChecklist.id"></checklist>
            </transition>
          </div>
          <div class="col-9 p-5 text-center" v-else>
            Imagine a cavity mechs diagram was here...
          </div>
        </div>
      </b-tab>
      <b-tab card title="Core">
        <div class="row">
          <div class="col-2">

            <b-nav class="my-2" pills vertical>
              <b-button class="mx-1 mb-2" @click="showAddMechs('core')" v-if="!readonly" block >Add Core Mech</b-button>
              <b-nav-item class="py-1 mx-1" :active="!coreActiveChecklist" @click="coreActiveChecklist=null">Overview</b-nav-item>
              <template v-for="menuItem in coreMenuItems">
                    <div class="card m-1" :key="menuItem.name">
                  <div class="card-header p-1">
                    <b-button variant="link" block class="px-2 text-left font-weight-bold"  @click="$root.$emit('bv::toggle::collapse', 'core_collapse_' + menuItem.name)">{{menuItem.name}}s</b-button>

                  </div>
                  <b-collapse :id="'core_collapse_' + menuItem.name" :visible="true" role="tabpanel">
                    <div class="card-body p-1">
                      <b-nav-item link-classes="d-flex flex-row p-1" v-for="subItem in menuItem.children" :key="subItem.name" :active="subItem==coreActiveChecklist" @click="coreActiveChecklist=subItem" >
                        <div class="col-7 p-0">
                          {{subItem.name}}
                        </div>
                        <div class="col-1 p-0">
                          <b-badge :variant="subItem.status.issue ? 'danger' : 'secondary'">{{subItem.status.issue}}</b-badge>
                        </div>
                        <div class="col-3 percent-col px-2 pt-1 text-center">
                          <b-progress v-if="subItem.status" height="1.125rem" show-progress :max="subItem.status.count">
                            <b-progress-bar :value="subItem.status.accepted" variant="success"></b-progress-bar>
                          </b-progress>
                        </div>
                        <div class="col-1 p-0 text-center" v-if="!readonly">
                          <a class="" href="#" @click.stop.prevent="removeChecklist(subItem)"><TrashCanIcon title="" fillColor="red" ></TrashCanIcon></a> 
                        </div>
                      </b-nav-item>
                    </div>
                  </b-collapse>
                </div>
              </template>
            </b-nav>
          </div>
          <div class="col-9" v-if="coreActiveChecklist">
            <transition name="fade">
              <checklist :checklist="coreActiveChecklist" :readonly="readonly" :blockUpdates="true" :key="coreActiveChecklist.id"></checklist>
            </transition>
          </div>
          <div class="col-9 p-5 text-center" v-else>
            Imagine a core mechs diagram was here...
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <!-- Generate all of the child checklists but hide them so that all the events wired up and logic works as expected -->
    <b-tabs pills vertical v-show="false" >
      <b-tab  v-for="checklist in cavityChecklists" :key="checklist.id">
        <checklist :checklist="checklist" :readonly="readonly"></checklist>
      </b-tab>
      <b-tab  v-for="checklist in coreChecklists" :key="checklist.id">
        <checklist :checklist="checklist" :readonly="readonly"></checklist>
      </b-tab>
    </b-tabs>


    <b-modal
      :title="`Add ${addMechDialog.type == 'core' ? 'Core' : 'Cavity'} Mechanism Checklist`"
      centered
      size="md"
      v-model="addMechDialog.visible"
    >
      <b-overlay :show="!addMechDialog.mechsLoaded" rounded="lg">
        <div class="container" v-if="addMechDialog.visible">
          <div class="row">
            <div class="col-4">
              Template:
            </div>
            <div class="col-8">
              <b-form-select 
                v-model="addMechDialog.selectedTemplateId"
                :options="dialogTemplates" 
                text-field="name"
                value-field="id"
                :options-field='null'
                @change="mechTemplateSelected($event)"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>Select Mechanism Template</b-form-select-option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              Mechanism Name:
            </div>
            <div class="col-8">
              <b-input class="form-control" type="text" v-model="addMechDialog.newName"></b-input>
            </div>
          </div>
        </div>
      </b-overlay>
      <template v-slot:modal-footer="{ cancel}">
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="saveMechChecklist()">Add</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { Client, Operation, QuestionDto } from "../code/EngineeringChecklist.Api";
import { InitializeChecklist } from "../code/Initializers"
import Checklist from './Checklist.vue';

// import MinusIcon from 'vue-material-design-icons/Minus.vue';
// import PlusIcon from 'vue-material-design-icons/Plus.vue';
import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';

export default Vue.extend({
  name: 'MechReview',
  components: {
      Checklist,
      TrashCanIcon
      // MinusIcon, PlusIcon,
  },
  props: {
    project: {type: Object, required: true},
    readonly: {type: Boolean, required: true }
  },
  data() {
    return {
      activeTab: { location: 'Core', item: 'Overview', type: 'overview', context: null },
      cavActiveChecklist: null,
      coreActiveChecklist: null,
      mechTemplates: [],
      patches: [],
      addMechDialog: {
        visible: false, 
        mechsLoaded: false, 
        selectedTemplateId: null,
        type: 'core',
        newName: '' 
      },
    }
  },
  computed: {

    checklists: function() {
      return this.project.checklists;
    },
    cavityChecklists: function() {
      return _.sortBy(_.filter(this.project.checklists, {type: 'cavity'}) || [], ['name']);
    },
    coreChecklists: function() {
      return _.sortBy(_.filter(this.project.checklists, {type: 'core'}) || [], ['name']);
    },
    cavityMenuItems: function() {
      return this.getMenuStructure(this.cavityChecklists);
    },
    coreMenuItems: function() {
      return this.getMenuStructure(this.coreChecklists);
    },

    dialogTemplates: function() {
      let filterCrit = {};
      filterCrit[this.addMechDialog.type] = true;

      return this.addMechDialog.mechsLoaded ? _.filter(this.mechTemplates, filterCrit) : [];
    },
    // coreTemplates: function() {
    //   return this.addMechDialog.mechsLoaded ? _.filter(this.mechTemplates, { core: true }) : [];
    // },
    // cavityTemplates: function() {
    //   return this.addMechDialog.mechsLoaded ? _.filter(this.mechTemplates, { cavity: true }) : [];
    // }

  },
  methods: {
    getMenuStructure: function(mechChecklists) {
      var groups = _.groupBy(
        mechChecklists, 
        function(checklist) {
          //Figure out the first word in head checklist... use that for grouping

          let delimIndex = checklist.name.search(/[\s-_]/);
          if (delimIndex == 0 && checklist.name && checklist.name.length > 1) {
            delimIndex = checklist.name.substr(1).search(/[\s-_]/);
            
            if (delimIndex >= 0)
              delimIndex += 1;
          }
          return delimIndex > 0 ? checklist.name.substr(0, delimIndex) : checklist.name
        });

        return _.map(Object.keys(groups), function(key) {
          return { name: key, children: groups[key] }
          // if (groups[key].length > 1) {
          //   return { name: key, children: groups[key] }
          // } else {
          //   return { name: key, checklist: groups[key][0] }
          // }
        });
    },
    mechTemplateSelected:function(mechId) {
      let mechTemplate = _.find(this.mechTemplates, {id: mechId});
      
      if (mechTemplate) {
        
        let prefix = mechTemplate.name.replace(/.?template/gi, ''),
          suffix = '',
          siblingCount = _.reduce(this[this.addMechDialog.type +'Checklists'], function(total, item){
            if (item.name && item.name.startsWith(prefix)) {
              total += 1;
            }
            return total;
          }, 0);
        
        if (this.addMechDialog.type == 'cavity') {
          //Convert it to letters
          suffix = String.fromCharCode(siblingCount + 65)
        } else {
          suffix = String(siblingCount + 1).padStart(2, '0');
        }

        this.addMechDialog.newName = `${prefix} ${suffix}`;
      }
      
    },
    showAddMechs: function(type) {

      if (!this.addMechDialog.mechsLoaded) {
        this.loadMechanismsTemplates()
          .then((data) => {
            this.addMechDialog.mechsLoaded = true;
          })
      } 

      this.addMechDialog.selectedTemplateId = null;
      this.addMechDialog.newName = "";
      this.addMechDialog.type = type;
      this.addMechDialog.visible = true;
    },
    saveMechChecklist: function() {
      this.addMechDialog.visible = false;

      var c = new Client(process.env.VUE_APP_API_URL, this.$http);
      c.repetitiveChecklists_CopyChecklistToProject(
        this.addMechDialog.selectedTemplateId, this.project.id, this.addMechDialog.newName, this.addMechDialog.type)
        .then((newChecklist) => {

          InitializeChecklist(newChecklist);

          this.project.checklists.push(newChecklist);
        })
        .catch((error)=>{
          console.log(error);
        })
    },
    loadMechanismsTemplates: function() {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);
      
      return c.repetitiveChecklists_GetAll(this.$store.state.selectedFacility, 'engineering', null)
        .then((data)=>{
          this.mechTemplates = data;
          return data;
        })
        .catch((error)=>{
          console.log(error);
        })
    },
    remove(array, item) {
      let index = array.indexOf(item);
      if (index >= 0) {
        array.splice(index, 1);
      }
    },
    removeChecklist(checklist) {
      
      this.$bvModal.msgBoxConfirm(`Remove the '${checklist.name}' checklist?`, { 
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(function(value) {

        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        if (value) {
          if (checklist && checklist.id) {
            c.checklists_Delete(checklist.id)
              .then(function(data){

                if (this.cavActiveChecklist == checklist) {
                  this.cavActiveChecklist = null;
                } else if (this.coreActiveChecklist == checklist) {
                  this.coreActiveChecklist = null
                }

                this.remove(this.project.checklists, checklist);

                this.$bvToast.toast(`Sucessfully removed checklist: "${checklist.name}"`, {
                  title: `Checklist removed.`,
                  variant: "success"
                });
              }.bind(this))
              .catch(function(error) {
                this.$bvToast.toast(`Failed to remove checklist: ${error.message}`, {
                  title: `Failed to remove checklist.`,
                  variant: "danger"
                });

              }.bind(this));
          } 
        }
      }.bind(this))
    }
  },
  watch: {
  },
  created() {
    
    // this.$set(this.area, 'conditionsObj', (this.area &&  this.area.conditions) ? JSON.parse(this.area.conditions) : {});

    // this.debouncedProcessPatches = _.debounce(this.processPatches, 3000, {
    //   leading: false,
    //   trailing: true
    // });
  }
});
</script>

<style lang="scss">
.nav-item {
  .percent-col {
    font-size: 0.25em;
  }
}
  // .fade-enter-active,
  // .fade-leave-active {
  //   transition: opacity 0.05s ease;
  // }

  // .fade-enter-from,
  // .fade-leave-to {
  //   opacity: 0;
  // }
</style>