<template>
  <div class="page">
    <div class="container-fluid">
      <div class="row py-3">
        <div class="col-2">
          <h2 class="my-0">Checklist Projects</h2>
        </div>
        <div class="col-1">
          <router-link v-if="authLevel > 0" :to="{ name: 'jobs' }" class="btn btn-sm btn-outline-primary" >Jobs List</router-link>
        </div>
        <div class="col-4">
          <b-form-radio-group class=""
                              id="btn-radios-type"
                              :options="activeProjectTypes"
                              value-field="code"
                              text-field="name"
                              button-variant="outline-primary"
                              v-model="projectType"
                              buttons
                              size="sm">
          </b-form-radio-group>

          <b-form-radio-group class="float-right"
                              id="btn-radios-archived"
                              button-variant="outline-primary"
                              v-model="showArchived"
                              buttons
                              size="sm"
                              v-if="authLevel > 0">
            <b-form-radio :value="false">Active</b-form-radio>
            <b-form-radio :value="true">Archived</b-form-radio>
          </b-form-radio-group>
        </div>
        <div class="col-2">
          <b-form-input debounce="250" v-model="searchQuery" size="sm" placeholder="Project Name Filter"></b-form-input>
        </div>
        <div class="col-3">
          <!-- <b-button class="btn-add-project icon-btn float-right mx-1" variant="primary" size="sm" @click="newProject()">
            <PlusBoxIcon /> New Project
          </b-button> -->
          <b-button class="btn-add-project icon-btn float-right mx-1" variant="primary" size="sm" @click="newJob()" v-if="authLevel > 1">
            <PlusBoxIcon /> New Job
          </b-button>
        </div>
      </div>
      <b-overlay :show="loading.projects" spinnerVariant="primary" :opacity="0.5" >
        <vue-good-table
          :columns="cols"
          :rows="projects" 
          :sort-options="sortOption"
          :pagination-options="{
            enabled: true,
            perPage: 15, perPageDropdown: [15, 30, 45, 60], //perPageDropdownEnabled: false,
            mode: 'records'
          }"
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'id'">
              <router-link :to="{ name: 'project', params: { projectId: props.row.id }}" class="">{{props.row.id}}</router-link>
            </span>
            <span v-else-if="props.column.label == 'Project Name'">
              <router-link :to="{ name: 'project', params: { projectId: props.row.id }}" class="">{{props.row.projectName}}</router-link>
            </span>
            <span v-else-if="props.column.field == 'unresolvedIssues'">
              <b-badge :variant="props.row.unresolvedIssues ? 'danger' : 'secondary'">{{ props.row.unresolvedIssues}}</b-badge>
            </span>
            <span v-else-if="props.column.field == 'progress'">
              <div class="progress-text">{{props.row.progress / 100 | numeral('0%')}}</div>
              <b-progress class="darker-progress" :max="100" height="30px">
                <b-progress-bar :value="props.row.progress" variant="success"></b-progress-bar>
              </b-progress>
            </span>
            <span class="last-updated" v-else-if="props.column.field == 'updatedOn'">
              {{ props.row.updatedOn | moment("DD-MMM-YYYY hh:mm a") }}
            </span>
            <span v-else-if="props.column.field == 'actions' && authLevel > 0">
              <b-button class="btn-remove-project mr-2" variant="outline-secondary" size="sm" @click="lockProject(props.row, !props.row.readonly)">
                <LockOpenVariantIcon v-if="props.row.readonly" title="Unlock Project" />
                <LockIcon v-else title="Lock Project" />
              </b-button>
              <b-button class="btn-remove-project m-0" variant="outline-warning" size="sm" @click="archiveProject(props.row, !props.row.archived)">
                <ArchiveArrowUpIcon v-if="props.row.archived" title="Unarchive Project" />
                <ArchiveIcon v-else title="Archive Project" />
              </b-button>
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
          <div slot="emptystate">
            <h4>Could not find any {{ showArchived ? 'Archived' : 'Active' }} {{projectType}} Projects</h4>
            <a href="#" v-if="searchQuery" @click.prevent="searchQuery=''">clear project filter</a>
          </div>
        </vue-good-table>
      </b-overlay>
    </div>



    <b-modal title="New Project From Template"
             centered
             ref="projectModalRef"
             size="md"
             v-model="addProjDialog.visible">
      
      <div class="container" v-if="addProjDialog.visible">
        <div class="row my-1">
          <div class="col-4">
            Project Type:
          </div>
          <div class="col-8">
            <select class="form-control" v-model="newProjectType">
              <option v-for="activeType in activeProjectTypes" :key="activeType.code" :value="activeType.code">{{activeType.name}}</option>
            </select>
          </div>
        </div>
        <b-overlay :show="!addProjDialog.templatesLoaded" rounded="lg">
          <template v-if="projectTemplates && projectTemplates.length">
            <div class="row my-1">
              <div class="col-4">
                Template:
              </div>
              <div class="col-8">
                <b-form-select v-model="addProjDialog.selectedTemplateId"
                               :options="projectTemplates"
                               text-field="name"
                               value-field="id"
                               :options-field='null'>
                  <template #first>
                    <b-form-select-option :value="null" disabled>Select Project Template</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <div class="row">
              
              <div class="col-4" v-if="this.newJobChecklist">Job #:</div>
              <div class="col-4" v-else>Name:</div>
              <div class="col-8">
                <input class="form-control mb-1" ref="newProjName" type="text" v-model.trim="newProjectName">
                <div style="font-size: x-small; font-weight:bold; color: red" v-if="newProjectName && newJobChecklist && !validProjectName">Invalid Job#, enter full job # i.e. 7100-C or C5000 or 16880</div>
                <div style="font-size: x-small; font-weight:bold; color: red" v-if="newProjectName && exactMatch">Duplicate Project name. Must be unique (add -1, 2, etc.)</div>

                
              </div>
            </div>
            <div class="row" v-if="similarProjects && similarProjects.length">
              <div class="col-12 mt-2 py-1 text-center" style="font-size: small; font-weight:bold; background-color: #fcc85d; color: #444">Similar checklist project(s) already exist! Use one of these?</div>
              <div class="col-4" v-for="proj in similarProjects" :key="proj.id">
                <router-link :to="{ name: 'project', params: { projectId: proj.id }}" class="small">{{proj.name}}</router-link>
              </div>
              
            </div>


          </template>
          <div v-else class="row">
            <div class="col-12 text-center my-3">
              <h4>No project templates defined</h4>
              <h6>Facility: {{selectedFacility}} Type: {{newProjectType}} </h6>
            </div>
          </div>
        </b-overlay>
        <!-- <div class="row">
    <div class="col-4">
      Description:
    </div>
    <div class="col-8">
      <b-form-textarea
        id="textarea-small"
        class="form-control"
        size="sm"
        placeholder="Project Description"
        v-model="selectedProject.description"
      ></b-form-textarea>
    </div>
  </div> -->
      </div>
      
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" @click="saveProjectTemplate()" :disabled="!canAddNewProject">Add</b-button>
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
      </template>
    </b-modal>



    <b-modal title="New Job From Template"
             centered no-close-on-backdrop
             ref="projectModalRef"
             size="xl"
             v-model="addJobDialog.visible">
      
      <div class="container" v-if="addJobDialog.visible">


        <div class="row my-1">
          <div class="col-4">
            <label for="job-search" class="form-label">Job Number Lookup</label>
            <vSelect
              id="job-search"
              v-model="activeProject"
              :options="activeProjects"
              label="projectId"
              :loading="loadingActiveProjects"
              :searchable="true"
              :clearable="true"
              track-by="projectId"
              placeholder="Enter Job Number"
              @search="debouncedSearchActiveProjects"
            >
              <template #no-options="{ search, searching, loading }">
                <span v-if="loading">Searching...</span>
                <span v-else>Nothing found, try a different Job#</span>
              </template>
            </vSelect>
          </div>  
          <div class="col-6">
            <div class="row no-gutters">
              <div class="col-4">
                <label for="job-num" class="form-label">Job Number</label>
                <input id="job-num" type="text" class="form-control  form-control-sm" v-model="addJobDialog.jobNum" placeholder="JobNum">
              </div>  
              <div class="col-2 px-1">
                <label for="wo" class="form-label">WO#</label>
                <input id="wo" type="text" class="form-control  form-control-sm" v-model="addJobDialog.wo" placeholder="WO#"></div>
              <div class="col-6">
                <label for="wo-datepicker" class="form-label">WO Date</label>
                <b-form-datepicker id="wo-datepicker" class="form-control form-control-sm" v-model="addJobDialog.woDate"></b-form-datepicker>
              </div>
            </div>            
          </div>
        </div>
        <div class="row my-1">
          <div class="col-2">
            Job Template:
          </div>
          <div class="col-5">
            <select class="form-control" v-model="addJobDialog.selectedTemplateId">
              <option v-for="job in jobTemplates" :key="job.id" :value="job.id">{{job.name}}</option>
            </select>
          </div>
        </div>
        <div class="row my-1" v-if="selectedJobTemplate">
          <div class="col-4">
            Template Questions:
          </div>
          <div class="col-8" v-if="selectedJobTemplate && selectedJobTemplate.rules && selectedJobTemplate.rules.length">
            <conditionals :checklist="selectedJobTemplate" :readonly="false"></conditionals>
          </div>
          <div class="col-8" v-else>
            <h4>No templates questions</h4>
          </div>
        </div>
        <div class="row my-1" v-else>
          <div class="col-12">
            <h3 class="text-center m-2">No job template selected</h3>
          </div>
        </div>
        <template v-if="selectedJobTemplate && selectedJobTemplate.templateJobs && jobQuestionsAnswered">
          <TemplateProjects 
            :jobName="addJobDialog.newName"
            :selectedTemplate="selectedJobTemplate"
            :addJobDialog="addJobDialog"
          >
          </TemplateProjects>
        </template>
        <div v-else-if="selectedJobTemplate" class="row">
          <div v-if="!jobQuestionsAnswered" class="col-12 text-center my-3">
            <h3>Answer all template questions</h3>
          </div>
          <div v-else class="col-12 text-center my-3">
            <h3>No template checklists found for current answers</h3>
          </div>
        </div>
      </div>
      
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" @click="saveJobTemplate()" :disabled="!canAddNewJob">Add</b-button>
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import Vue from "vue";
  import { Vuex, mapGetters, mapState, mapMutations } from "vuex";
  import axios from "axios";
  import Multiselect from "vue-multiselect";
  import vSelect from "vue-select";
  import 'vue-good-table/dist/vue-good-table.css'
  import { VueGoodTable } from 'vue-good-table';

  import { filterObject } from "../code/conditionals";
  import { ChecklistProjectDto, ChecklistType, Client, Operation, JobDto } from "../code/EngineeringChecklist.Api"
  import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
  import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
  import ArchiveIcon from 'vue-material-design-icons/Archive.vue';
  import ArchiveArrowUpIcon from 'vue-material-design-icons/ArchiveArrowUp.vue';

  import LockIcon from 'vue-material-design-icons/Lock.vue';
  import LockOpenVariantIcon from 'vue-material-design-icons/LockOpenVariant.vue';
  import Conditionals from "../components/Conditionals.vue";
  import TemplateProjects from "../components/TemplateProjects.vue";
  import moment from "moment";

  const PROJECT_TYPE = 'projectType'

  export default Vue.extend({
    name: "ActiveProjects",
    components: {
      //Multiselect,
      vSelect,
      PlusBoxIcon,
      ArchiveIcon, ArchiveArrowUpIcon,
      LockIcon, LockOpenVariantIcon,
      // TrashCanIcon
      Conditionals,
      TemplateProjects,
      VueGoodTable
    },
    data() {
      return {
        clType: "JOB",
        searchQuery: null,
        projectType: null,
        showArchived: false,
        projectTemplates: [],
        projects: [],
        similarProjects: null,
        createSimilar: false,
        newProjectType: null,
        addProjDialog: {
          visible: false,
          templatesLoaded: false,
          selectedTemplateId: null,
          newName: ''
        },
        addJobDialog: {
          visible: false,
          templatesLoaded: false,
          woDate: null,
          selectedTemplateId: null,
          newName: ''
        },
        jobTemplates: [],
        jobExists: null,
        loading: {
          facilities: true,
          projects: true,
          standard: true,
          mechanisms: true,
          newProjects: false
        },
        standardChecklists: [],
        mechanismChecklists: [],
        selectedProject: null,
        loadingActiveProjects: false,
        activeProject: null,
        activeProjects: [],
        columns: [
          { field: "facilityCode", label: "Facility", width:"120px"},
          { field: "id", label: "Id", type:"number", width:"60px" },
          // { field: "projectTypeCode", label: "Type", align: "left",},
          { field: this.projNameFn, label: "Project Name", 'td-class': "project-name"},
          { field: "woDate", label: "Tryout", width:"120px",},
          { field: "unresolvedIssues", label: "# Issues", width:"120px", type:"number", tdClass: "issues-cell" },
          { field: "progress",  label: "% Compl.", width:"120px", type:"number", tdClass: "progress-cell"},
          { field: "updatedOn", label: "Last Updated", width:"250px"},
          { field: "updatedBy", label: "Updated By", width:"250px", // filterOptions: { enabled: true }
          },
          { field: "actions", label: "Actions", width:"200px", sortable: false, align: "center"}
        ],
        sortOption:{
          enabled: true,
          multipleColumns: true,
          initialSortBy: {field: "updatedOn", type: 'desc'}
        }
      };
    },
    computed: {
      ...mapState(['selectedFacility']),
      ...mapGetters('context', ['isAdmin', 'authLevel']),
      ...mapGetters(['activeFacilities', 'activeProjectTypes']),
      cols: function () {
        return _.filter(this.columns, col => col.field != 'actions' || this.authLevel > 0);
      },
      projectTypeVal: {
        get: function () {
          return this.projectType;
        },
        set: function (newValue) {
          this.projectType = newValue;
          
        }
      },
      newProjectName: {
        get: function () {
          return this.addProjDialog.newName;
        },
        set: function (newValue) {
          if ( this.newJobChecklist && newValue) {
            this.addProjDialog.newName = String(newValue).toUpperCase();
          } else {
            this.addProjDialog.newName = newValue;
          }
        }
      },
      newJobChecklist: function () {
        return this.newProjectType != "Simple";
      },
      validProjectName: function () {
        let val = false
        if (this.newJobChecklist) {
          //Job based checklist
          let m = /(\d{4,}-[A-Z]|[A-Z]\d{4,}|\d{5,})/gi
          //let m = /\d{4,}/gi
          val = m.test(this.newProjectName);
        } else {
          val = true
        }

        //Search for similar jobs
        if (val && this.newProjectName && this.newProjectName.length > 3) {
          this.debouncedSearchProjects(this.newProjectName);
        }

        return val;
      },
      exactMatch: function () {
        return !!_.find(this.similarProjects, { name: this.newProjectName });
      },
      canAddNewProject: function () {
        return this.projectTemplates &&
          this.projectTemplates.length &&
          this.addProjDialog.selectedTemplateId &&
          this.addProjDialog.newName &&
          this.validProjectName &&
          !!this.similarProjects &&
          !this.loading.newProjects &&
          !this.exactMatch;
      },


      selectedJobTemplate: function () {
        return _.find(this.jobTemplates, { id: this.addJobDialog.selectedTemplateId }) || null;
      },
      selectedTemplateJobs: function () {
        return this.selectedJobTemplate ? this.selectedJobTemplate.templateJobs : [];
      },
      visibleTemplateJobs: function () {
        return _.filter(this.selectedTemplateJobs, { visible: true }) || [];
      },
      hasTemplateChecklistProjects: function () {
        return !!(this.visibleTemplateJobs && this.visibleTemplateJobs.length);
      },
      jobRules: function () {
        return this.selectedJobTemplate ? this.selectedJobTemplate.rules : [];
      },
      jobQuestionsAnswered: function () {
        if (this.jobRules && this.jobRules.length) {
          return _.every(this.jobRules, rule => rule.value !== null);
        } else {
          return false;
        }
      },
      JobTemplateQuestionsAnswered: function() {
        const allRulesNotNull = _.every(this.visibleTemplateJobs, template => {
          return _.every(template.rules, rule => rule.value !== null);
        });
        
        return allRulesNotNull;
      },
      validJobName: function () {
        let val = false
        if (this.newJobChecklist) {
          //Job based checklist
          let m = /(\d{4,}-[A-Z]|[A-Z]\d{4,}|\d{5,})/gi
          //let m = /\d{4,}/gi
          val = m.test(this.newProjectName);
        } else {
          val = true
        }

        //Search for similar jobs
        if (val && this.newProjectName && this.newProjectName.length > 3) {
          this.debouncedSearchJobs(this.newProjectName);
        }

        return val;
      },
      canAddNewJob: function () {
        return this.addJobDialog.selectedTemplateId &&
          this.addJobDialog.jobNum &&
          this.addJobDialog.wo &&
          this.addJobDialog.woDate &&
          this.jobQuestionsAnswered &&
          this.JobTemplateQuestionsAnswered
      },
    },
    methods: {
      projNameFn(rowObj) {
        if (/\d{4,}-[A-Z]-\d{2,2}_/.test(rowObj.name) ) {
          rowObj.projectName = rowObj.name.split('_')[0];
          rowObj.woDate = rowObj.name.split('_')[1];
        } else {
          rowObj.projectName = rowObj.name;
          rowObj.woDate = '';
        }

        return rowObj.projectName ;
      },
      // projUpdatedOnFn(rowObj) {
      //   return rowObj.updatedOn ? moment(rowObj.updatedOn).format("YYYY-MM-DD HH:mm:ss") : '';
      // },
      projWoDateFn(rowObj) {
        return rowObj.woDate; //? moment(rowObj.woDate).format("DD-MMM-YYYY") : '';
      },
      remove(array, item) {
        let index = array.indexOf(item);
        if (index >= 0) {
          array.splice(index, 1);
        }
      },
      newJob() {

        this.addJobDialog.selectedTemplateId = null;
        this.addJobDialog.newName = "";

        if (!this.addJobDialog.templatesLoaded) {
          this.loadJobTemplates()
          .then(() => {
            this.addJobDialog.visible = true;
          });
        } else {
          this.addJobDialog.visible = true;
        }
      },
      loadJobTemplates() {

        this.addJobDialog.templatesLoaded = false;
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        return c.jobTemplate_GetAll(false, this.selectedFacility)
          .then((data) => {

            data.forEach((proj) => {
              if (proj.options) {
                proj.rules = JSON.parse(proj.options);
              }
            });

            this.jobTemplates = data;
            this.addJobDialog.templatesLoaded = true;

            ////If only one template select it...
            //if (this.projectTemplates && this.projectTemplates.length == 1) {
            //  this.addProjDialog.selectedTemplateId = this.projectTemplates[0].id;
            //
            //  setTimeout(() => { this.$refs['newProjName'].focus(); }, 250);
            //  //this.$nextTick(() => { });              
            //}

            return data;
          })
          .catch((error) => {
            console.log(error);
          })
      },
      saveJobTemplate: function () {

        if ( this.selectedJobTemplate) {

          //this.addJobDialog.visible = false;

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);
          let newJob = this.selectedJobTemplate;
          //let projId = String(this.addJobDialog.newName);
          let job = new JobDto();
          job.id = 0
          job.archived = false;
          job.jobNum = this.addJobDialog.jobNum;
          job.wo = this.addJobDialog.wo;
          job.woDate = this.addJobDialog.woDate;
          job.facilityCode = this.selectedFacility;
          job.name = job.jobNum + '-' + job.wo + '_' + job.woDate.toISOString().split('T')[0];


          let templateProjects = _.filter(newJob.templateJobs, { visible: true });

          let checklistProjectId = null;
          c.job_Post(job)
            .then((jobdata) => {

              return _.forEach(templateProjects, (templateProject) => {
                templateProject.jobId = jobdata.id;
                templateProject.name = jobdata.name;

                templateProject.checklistProjectId = c.checklistProjects_CloneTemplate(
                  templateProject.checklistProjectId, templateProject.name, templateProject.projectTypeCode, false, this.selectedFacility, jobdata.id, JSON.stringify(templateProject.rules) || '[]')
                  .then((newProject) => {
                    console.log("ChecklistProject Cloned", newProject);
                    return newProject.id;
                  })
                  .catch((error) => {
                    console.log(error);
                  });

              });
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.addJobDialog.visible = false;

              if (templateProjects.length && templateProjects[0].checklistProjectId instanceof Promise) {
                templateProjects[0].checklistProjectId.then((checklistProjectId) => {
                  this.$router.push({ name: 'project', params: { projectId: checklistProjectId } })
                })
                .catch((error) => {
                  //window.location.reload();
                  console.log(error);
                });
              } else {
                this.$router.push({ name: 'project', params: { projectId: templateProjects[0].checklistProjectId } })
              }
            })
        }
      },
      searchJobs(projectName) {

        // http://localhost:5000/api/ChecklistProjects?search=7153-C&template=false&archived=false&facilityCode=SY02M&projectType=machining
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        c.project_GetById(projectName)
          .then(function (data) {
            this.jobExists = data;
          }.bind(this))
          .catch(error => {
            console.log(error.response);
          })
          .finally(function () {
              //this.$nextTick(() => { this.loading.newProjects = false; });
          }.bind(this));
      },
      newProject() {
        //If a type is selected, pre-select it in the add dialog
        if (this.projectType) {
          this.newProjectType = this.projectType;
        } else {
          this.newProjectType = null;
        }

        this.similarProjects = null;
        this.addProjDialog.selectedTemplateId = null;
        this.addProjDialog.newName = "";

        if (!this.addProjDialog.templatesLoaded) {
          this.loadProjectTemplates()
        } else if (this.projectTemplates && this.projectTemplates.length == 1) {
          this.addProjDialog.selectedTemplateId = this.projectTemplates[0].id;
        }

        this.addProjDialog.visible = true;
      },
      saveProjectTemplate: function () {
        if (this.addProjDialog.selectedTemplateId) {

          this.addProjDialog.visible = false;

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);
          c.checklistProjects_CloneTemplate(
            this.addProjDialog.selectedTemplateId, this.addProjDialog.newName, false, this.selectedFacility, this.newProjectType)
            .then((newProject) => {

              //this.projects.push(newProject);
              //{ name: 'project', params: { projectId: proj.id } }
              this.$router.push({ name: 'project', params: { projectId: newProject.id } })

            })
            .catch((error) => {
              console.log(error);
            });

        }

      },      
      loadProjectTemplates() {
        this.addProjDialog.templatesLoaded = false;
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        return c.checklistProjects_GetAll(null, true, false, this.selectedFacility, this.newProjectType)
          .then((data) => {

            this.projectTemplates = data;
            this.addProjDialog.templatesLoaded = true;

            //If only one template select it...
            if (this.projectTemplates && this.projectTemplates.length == 1) {
              this.addProjDialog.selectedTemplateId = this.projectTemplates[0].id;

              setTimeout(() => { this.$refs['newProjName'].focus(); }, 250);
              //this.$nextTick(() => { });              
            }

            return data;
          })
          .catch((error) => {
            console.log(error);
          })
      },
      searchProjects(projectName) {

        // http://localhost:5000/api/ChecklistProjects?search=7153-C&template=false&archived=false&facilityCode=SY02M&projectType=machining
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        c.checklistProjects_GetAll(projectName, false, false, this.selectedFacility, this.newProjectType)
          .then(function (data) {
            this.similarProjects = data;
          }.bind(this))
          .catch(error => {
            console.log(error.response);
          })
          .finally(
            function () {

              this.$nextTick(() => { this.loading.newProjects = false; });
              //this.loading.newProjects = false;
              
            }.bind(this)
          );
      },
      showSelectedProject(project) {
        if (!project) {
          this.selectedProject = new ChecklistProjectDto({ id: 0, isTemplate: true });
        } else {
          this.selectedProject = project;
        }

        this.$refs.projectModalRef.show();
      },
      patchProject: function (project, patch) {
        if (!project || !project.id) {
          console.log('Invalid project, cannot be patched');
          return;
        }
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        var patches = [
          patch
          // new Operation({op: 'replace', path: '/progress', value: Math.round((status.accepted / status.count) * 100) }),
          // new Operation({op: 'replace', path: '/unresolvedIssues', value: status.issue})
        ]
        return c.checklistProjects_Patch(project.id, patches)
          .catch((error) => {
            console.log(error);
          })
      },
      lockProject(project, locked) {

        this.$bvModal.msgBoxConfirm(`Are you sure you want to ${locked ? 'lock' : 'unlock'} project ${project.name}?`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          if (value) {
            this.patchProject(project, new Operation({ op: 'replace', path: '/readonly', value: !!locked }))
              .then(function () {
                project.readonly = !!locked;
                this.$bvToast.toast(`Sucessfully ${locked ? 'locked' : 'unlocked'} project: "${project.name}"`, {
                  title: `Project ${archived ? 'Locked' : 'Unlocked'}.`,
                  variant: "success"
                });

              }.bind(this));
          }
        }.bind(this))
      },
      archiveProject(project, archived) {

        this.$bvModal.msgBoxConfirm(`Are you sure you want to ${archived ? 'archive' : 'unarchive'} project ${project.name}?`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          if (value) {
            this.patchProject(project, new Operation({ op: 'replace', path: '/archived', value: !!archived }))
              .then(function () {
                this.$bvToast.toast(`Sucessfully ${archived ? 'archived' : 'unarchived'} project: "${project.name}"`, {
                  title: `Project ${archived ? 'Archived' : 'Unarchived'}.`,
                  variant: "success"
                });

                this.remove(this.projects, project);
              }.bind(this));
          }
        }.bind(this))


      },
      fetchChecklistProjects() {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        this.loading.projects = true;

        c.checklistProjects_GetAll(this.searchQuery, false, this.showArchived, this.selectedFacility, this.projectType)
          .then(function (data) {

            let projects = _.map(data, (proj) => {
              if (/\d{4,}-[A-Z]-\d{2,2}_/.test(proj.name) ) {
                proj.projectName = proj.name.split('_')[0];
                proj.woDate = proj.name.split('_')[1];
              } else {
                proj.projectName = proj.name;
                proj.woDate = '';
              }

              return proj;
            });

            this.projects = projects;
            return projects;
          }.bind(this))
          .catch(error => {
            console.log(error.response);
          })
          .finally(
            function () {
              this.loading.projects = false;
            }.bind(this)
          );
      },
      searchActiveProjects(query, loading) {

        if (!query) {
          return;
        }

        loading(true);

        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        this.loadingActiveProjects = true;
        c.activeProject_GetAll(this.selectedFacility, query)
          .then(function (data) {
            this.activeProjects = data;
          }.bind(this))
          .catch(error => {
            console.log(error.response);
          })
          .finally(
            function () {
              loading(false);
              this.loadingActiveProjects = false;
            }.bind(this)
          );
      },
    },
    watch: {
      activeProject: function (newValue, oldValue) {
        if (newValue) {
          let projId = String(newValue.projectId);

          this.addJobDialog.jobNum = projId.substring(0, projId.length - 3);
          this.addJobDialog.wo = projId.substring(projId.length - 2);
          this.addJobDialog.woDate = newValue.departure.toDate();
        }
      },
      newProjectType: function (newValue, oldValue) {
        if (newValue) {
          this.loadProjectTemplates();
        } else {
          this.projectTemplates = [];
        }
      },
      validProjectName: function () {
        //
      },
      projectType: function (newValue, oldValue) {

        window.localStorage.setItem(PROJECT_TYPE, String(newValue));

        this.loading.projects = true;
        this.debouncedfetchChecklistProjects();
      },
      showArchived: function () {
        this.loading.projects = true;
        this.debouncedfetchChecklistProjects();
      },
      searchQuery: function () {
        this.loading.projects = true;
        this.debouncedfetchChecklistProjects();
      },
      selectedFacility: function () {
        this.loading.projects = true;
        this.debouncedfetchChecklistProjects();
      },
    },
    created() {
      let projType = window.localStorage.getItem(PROJECT_TYPE);  
			this.projectType = (!projType || projType == 'null') ? 'Machining' : projType;

      this.debouncedfetchChecklistProjects = _.debounce(this.fetchChecklistProjects, 1000, {
        leading: false,
        trailing: true
      });

      this.debouncedSearchProjects = _.debounce(this.searchProjects, 1000, {
        leading: false,
        trailing: true
      });

      this.debouncedSearchJobs = _.debounce(this.searchJobs, 1000, {
        leading: false,
        trailing: true
      });

      this.debouncedSearchActiveProjects = _.debounce(this.searchActiveProjects, 500, {
        leading: false,
        trailing: true
      });

      this.loading.projects = true;
      this.debouncedfetchChecklistProjects();

    },


  });
</script>
<style>
  @import "../../node_modules/vue-select/dist/vue-select.css";
</style>
<style lang="scss">
#vgt-table {
  tr {
    &:hover {
      background-color: #f5f5f5;
    }
  }

  td {
    padding: 2px 6px;
    vertical-align: middle;
  }

  .issues-cell {
    text-align:center;
  }
  .progress-cell {
    position: relative;
  }
  .progress-text {
    position: relative;
    top: 16px;
    line-height: 0;
    color: #EEE;
    font-size: 1.125em;
    font-weight: bold;
    text-align: center;
  }
}

</style>