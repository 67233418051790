<template>
  <div class="not-found">
    <div class="container mt-3">
      <h1 id="notfound">{{ message }}</h1>
    </div>
  </div>  
</template>

<script>
export default {
  name: "NotAuthorized",
  data() {
    return {
      message: "403 - Not Authorized"
    }
  }
}
</script>

<style scoped>
#notfound {
  color: red;
  text-align: center;
}
</style>


