<template>
  <div class="row template-project-row no-gutter">
    <div class="col-12">
      <div class="card my-1">
        <div class="card-title p-2 mb-0">
          <h5 class="d-inline">{{projectType}} - {{templateName}}</h5>
        </div>
        <div class="card-body p-1">
          <div class="container questions-list" >
            <!-- <div class="row">
              <div class="col-2">Checklist Name</div>
              <div class="col-3">
                <input type="text" class="form-control" v-model.lazy="nameVal" />
              </div>
            </div> -->
            <div class="row my-1">
              <div class="col-2">Checklist Questions:</div>
              <div class="col-10">
                <conditionals :checklist="template" :readonly="false"></conditionals>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Conditionals from './Conditionals.vue';

export default Vue.extend({
  name: 'TemplateProject',
  components: {
    Conditionals
  },
  props: {
    templateProject: {type: Object, required: true},
    jobName: {type: String, required: true}
  },
  data() {
    return {
      patches: []
    }
  },
  computed: {
    isVisible: function() {
      return this.templateProject.visible
    },
    template: function() {
      return this.templateProject && this.templateProject.checklistProject ? this.templateProject.checklistProject : null;
    },
    templateName: function() {
      return this.template ? this.template.name : '';
    },
    // nameVal: {
    //   get: function() {
    //       return this.templateProject.name ? this.templateProject.projectName : '';
    //   },
    //   set: function(val) {
    //     this.templateProject.name = val;
    //   }
    // },
    name: function() {
      return this.templateProject ? this.templateProject.name : '';
    },
    projectType: function() {
      return this.templateProject ? this.templateProject.projectTypeCode : '';
    },
    rules: function() {
      return this.template ? this.template.rules : [];
    }
  },
  methods: {
  },
  watch: {

  },
  created() {
  }
});
</script>

<style lang="scss">

</style>