import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import router from "../router"
import { msalInstance } from "vue-msal-browser"
import { AccountInfo, AuthenticationResult, AccessTokenEntity } from "@azure/msal-common";
import _ from 'lodash'

export enum AuthLevel {
  BaseUser = 0,
  Leader = 1,
  ProgramManager = 2,
  Operations = 3,
  Admin = 4,
}

export interface ContextState {
  initialized: boolean
  profile: IProfile,
  jwtToken: string | null
  msalAccessToken: string | null
}
export interface IProfile {
  username: string,
  name: string, 
  email: string,
  isAdmin: boolean,
  roles: string[],
}


export interface ICredentials {
  username: string,
  password: string, 
}

const store = {
  namespaced: true,
  state: {
    initialized: false,
    profile: {} as IProfile,
    jwtToken: null,
    msalAccessToken: null,
  } as ContextState,

/**
 * Roles:
 * - role-user: Basic user
 * - role-manager: Shop Leader/Supervisor can do basic management of the checklists
 * - role-pm: Program Manager can do all the basics plus create new jobs/checklist projects
 * - role-admin: Admin can do everything
 *  */

  getters: {
    isAuthenticated: (state: ContextState) => state.profile.name || state.profile.email,
    isBasicUser: (state: ContextState) => state.profile.roles && state.profile.roles.includes('role-user'),
    isSupervisor: (state: ContextState) => state.profile.roles && state.profile.roles.includes('role-manager'),
    isProgramManager: (state: ContextState) => state.profile.roles && state.profile.roles.includes('role-pm'),
    isOperations: (state: ContextState) => state.profile.roles && state.profile.roles.includes('role-ops'),
    isAdmin: (state: ContextState) => state.profile.isAdmin || (state.profile.roles && state.profile.roles.includes('role-admin')),
    authLevel: (state: ContextState) => {
      if (state.profile.isAdmin) return AuthLevel.Admin
      if (state.profile.roles.includes('role-admin')) return AuthLevel.Admin
      if (state.profile.roles.includes('role-ops')) return AuthLevel.Operations
      if (state.profile.roles.includes('role-pm')) return AuthLevel.ProgramManager
      if (state.profile.roles.includes('role-manager')) return AuthLevel.Leader
      return AuthLevel.BaseUser
    },
    avatarInitials: (state: ContextState) => {
      if (state.profile.name) {
        const names = state.profile.name.split(' ')
        return names.length > 1 ? names[0][0] + names[names.length - 1][0] : names[0][0]
      }
      return 'N/A';
    },
    msalSession: (state: ContextState) => {
      return !!state.msalAccessToken;
    }
  },

  mutations: {
    setProfile(state: ContextState, profile: IProfile) {

      if (profile && !profile['roles']) {
        profile.roles = [];
      }

      state.profile = profile
    },
    setJwtToken(state: ContextState, jwtToken: string) {
      state.jwtToken = jwtToken

      // WARNING: storing JWT tokens in local storage or anywhere accessible by JavaScript is a security issue!
      // This is just for demonstrative purposes on how SignalR can be authenticated using JWT
      // Store token securily on a real application
      // For example, a web app can store them in cookies not accessible to JavaScript, while a native app will need to use the device secure storage
      if (jwtToken)
        window.localStorage.setItem('jwtToken', jwtToken)
      else
        window.localStorage.removeItem('jwtToken')
    },
    setMsalAccessToken(state: ContextState, msalAccessToken: string) {
      state.msalAccessToken = msalAccessToken
    }
  },

  actions: {
    // Used during startup to reload the profile from the server
    restoreContext({ commit, getters, state, dispatch }: { commit: any, getters: any, state: ContextState, dispatch: any }) {

      if (msalInstance) {
        const account = msalInstance.getActiveAccount() || undefined;
        if (account) {
          const silentRequest = {
            scopes: ['api://' + process.env.VUE_APP_SSO_CLIENT_ID + '/Checklist.Read'],
            account: account,
            forceRefresh: false
          };

          /**
           * Silently acquires an access token from cache or with a refresh token if available.
           */
          return msalInstance.acquireTokenSilent(silentRequest)
            .then((res: AuthenticationResult) => {
              return dispatch('msalProcessAuth', { msalAuthResult: res });
            })
            .finally(() => {
                state.initialized = true;
            });
        }
      }

      /**
       * Legacy login stuff, need to reevaluate this/maybe remove it
       */
      const jwtToken = window.localStorage.getItem('jwtToken')
      if (jwtToken) commit('setJwtToken', jwtToken)

      return axios.get('/account/context')
        .then(res => {
          commit('setProfile', res.data)
          // if (getters.isAuthenticated)
          //   return Vue.prototype.startSignalR(state.jwtToken)
        })
        .finally(() => {
          state.initialized = true;
        })
    },
    clearAuth({ commit }: { commit: any }) {
      commit('setProfile', {})
			commit('setJwtToken', null)
		},
    msalProcessAuth({ commit }: { commit: any }, { msalAuthResult }: { msalAuthResult: AuthenticationResult }) {

      if (!msalInstance) {
        return Promise.reject({ error: "msalInstance not initilized" });
      }
      /*
      msalAuthResult will be null if this request is trying to fetch from cache, if a login attempt occurred it will pass the result of that attempt
      */

      if (msalAuthResult.account) {

        msalInstance.setActiveAccount(msalAuthResult.account);

        const profile = {
          username: msalAuthResult.account.username,
          name: msalAuthResult.account.name,
          email: msalAuthResult.account.username,
          roles: msalAuthResult.account.idTokenClaims ? msalAuthResult.account.idTokenClaims.roles : [],
          isAdmin: msalAuthResult.account.idTokenClaims && _.includes(msalAuthResult.account.idTokenClaims.roles, 'role-admin') ? true : false
        }

        commit('setProfile', profile);
        //commit('setMsalAccessToken', msalAuthResult.idToken);
        commit('setMsalAccessToken', msalAuthResult.accessToken);

        return Promise.resolve(true);
      } else {
        console.log('Unable to fetch account from auth response'); //, msalAuthResult);
        return Promise.reject(msalAuthResult);
        //if (msalAuthResult.errorCode === 'interaction_required') {
      }
      
    },
    // Login methods. Either use cookie-based auth or jwt-based auth
    login({ state, dispatch }: { state: ContextState, dispatch: any }, { authMethod, credentials }: { authMethod: string, credentials: ICredentials }) {

      switch (authMethod) {
        case 'jwt':
          return dispatch('loginToken', credentials)
        case 'msal':
          return dispatch('loginMsal', credentials)
        default:
          return dispatch('loginCookies', credentials)
      }
        //authMethod === 'jwt'
        //? dispatch('loginToken', credentials)
        //: dispatch('loginCookies', credentials)
        
        //return loginAction; //.then(() => Vue.prototype.startSignalR(state.jwtToken))
    },
    loginMsal({ commit, dispatch }: { commit: any, dispatch: any }, msalResult: any) {
      if (msalInstance) {

        if (window.sessionStorage.getItem('msal.interaction.status')) {
          //Will cause an msal interaction is in progress error if this isn't removed... not sure why it sticks around
          window.sessionStorage.removeItem('msal.interaction.status');
				}

        //msalInstance.setActiveAccount(null);
        const popupRequest = {
          scopes: ['api://' + process.env.VUE_APP_SSO_CLIENT_ID + '/Checklist.Read'],
          prompt: "select_account", // This works but forces user to click the account they want again
        };
        
        return msalInstance.loginPopup(popupRequest)
          .then((res:AuthenticationResult) => {

            dispatch('msalProcessAuth', { msalAuthResult: res});

          })
          .catch((err: any) => {
            console.error(err)
          });

      }
		},
    loginCookies ({ commit }: { commit: any}, credentials: ICredentials) {
      return axios.post('/account/login', credentials)
        .then(res => {
          commit('setProfile', res.data)
          return res.data;
        })
        .catch((res) => {
          if (res.response && res.response.status == 401) {
            throw res.response.data;
          }

          throw res;
        })
    },
    loginToken ({ commit }: { commit: any}, credentials: ICredentials) {
      return axios.post('/account/token', credentials)
        .then(res => {
          const profile = res.data
          const jwtToken = res.data.token
          delete profile.token
          commit('setProfile', profile)
          commit('setJwtToken', jwtToken)
        })
    },
    // Logout. (With JWT the request isnt strictly necessary unless the server needs some cleanup/auditing)
    logout ({ commit, state }: { commit: any, state: ContextState}, idleTimeout: boolean = false, redirect: boolean = true) {

      if (state.jwtToken) {
        commit('setProfile', {})
        commit('setJwtToken', null)
      }
      
      if (state.msalAccessToken) {
        if (msalInstance) {
          commit('setProfile', {})
          commit('setMsalAccessToken', null)
          //router.push({ name: 'login' })

          msalInstance.logoutRedirect({
              onRedirectNavigate: (url) => {
                  // Return false if you would like to stop navigation after local logout
                  return false;
              }
          })
          .finally(() => {
            if(redirect) {
              if (idleTimeout && router && router.currentRoute.fullPath !== '/') { 
                router.push({ name: 'login', query: { redirect: router.currentRoute.fullPath } });
              } else {
                router.push({ name: 'login' })
              }
            }
          });

          //msalInstance.logoutPopup()
          //  .then(() => {
          //    //commit('setProfile', {})
          //    //commit('setMsalAccessToken', null)
          //  })
          //  .catch((err: any) => {
          //    window.sessionStorage.removeItem('msal.interaction.status');
          //  })
          //  .finally(() => router.push({ name: 'login' }));
        }
      } else {
        axios.post('/account/logout').then(() => {
          commit('setProfile', {})
          commit('setJwtToken', null)
        })
        .finally(() => {
          if(redirect) {
            if (idleTimeout && router && router.currentRoute.fullPath !== '/') { 
              router.push({ name: 'login', query: { redirect: router.currentRoute.fullPath } });
            } else {
              router.push({ name: 'login' })
            }
          }
        });
      }
    }
  }
}

export default store