<template>
  <div class="manage-users">
    <b-overlay :show="loading" rounded="sm">
      <div class="card">
        <div class="card-header p-2">
          <h4 class="m-0 mr-5 d-inline-block">Project Types</h4> 
          <b-button class="btn-add-rule icon-btn float-right" variant="primary" size="sm" @click="showProjectTypeDialog()">
            <PlusBoxIcon /> Add
          </b-button>
        </div>
        <div class="card-body p-2">
          <div class="container-fluid" v-if="projectTypes.length">
            <div class="row" v-for="projectType in projectTypes" :key="projectType.code">
              <div class="col-10">
                {{projectType.name}}
              </div>
              <div class="col-2">
                <a class="px-2" href="#" @click.prevent="showProjectTypeDialog(projectType)">
                  <LeadPencilIcon title="Edit Lookup List" ></LeadPencilIcon>
                </a>
              </div>
            </div>
          </div>
          <div class="empty-card py-4" v-else>
            <h4 class="text-center"> No Project Types</h4>
          </div>
        </div>
      </div>
    </b-overlay>

    <b-modal
      :title="(projectType && projectType.code) ? 'Edit Lookup List' : 'New Lookup List'"
      centered
      ref="projectTypeModalRef"
      size="md"
    >
      <div class="container" v-if="projectType">
        <div class="row py-1">
          <div class="col-4">
            Code:
          </div>
          <div class="col-8">
            <input class="form-control" type="text" v-model="projectType.code" v-if="projectType.new">
            <span v-else>{{projectType.code}}</span>
          </div>
        </div>
        <div class="row py-1">
          <div class="col-4">
            Name:
          </div>
          <div class="col-8">
            <input class="form-control" type="text" v-model="projectType.name">
          </div>
        </div>
        <div class="row py-1">
          <div class="col-4">
            Use Main Checklists (vertical tabs):
          </div>
          <div class="col-8">
            <input class="form-control" type="checkbox" v-model="projectType.useMainChecklistSection">
          </div>
        </div>
        <div class="row py-1">
          <div class="col-4">
            Allow Issues Section:
          </div>
          <div class="col-8">
            <input class="form-control" type="checkbox" v-model="projectType.allowIssuesSection">
          </div>
        </div>
        <div class="row py-1">
          <div class="col-4">
            Allow Repeat Checklists:
          </div>
          <div class="col-8">
            <input class="form-control" type="checkbox" v-model="projectType.allowRepeatChecklists">
          </div>
        </div>
        <div class="row py-1">
          <div class="col-4">
            Repeat Section Tab Name:
          </div>
          <div class="col-8">
            <input class="form-control" type="text" v-model="projectType.repeatSectionTabName">
          </div>
        </div>
        <div class="row py-1">
          <div class="col-4">
            Archived:
          </div>
          <div class="col-8">
            <input class="form-control" type="checkbox" v-model="projectType.archived">
          </div>
        </div>
      </div>
      <template v-slot:modal-footer="{ cancel, hide }">
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="saveProjectType(hide)">{{ projectType.code ? 'Save' :  'Add' }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { Client, Operation, ProjectTypeDto } from "../../code/EngineeringChecklist.Api";
import { DropList, Drag } from "vue-easy-dnd";

import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
import DragHorizontalIcon from 'vue-material-design-icons/DragHorizontalVariant.vue';

export default Vue.extend({
	name: 'ManageProjectTypes',
  components: {
    PlusBoxIcon,LeadPencilIcon //,DragHorizontalIcon,
  },
  props: {
  },
  data() {
    return {
      loading: true,
			projectTypes: [],
      projectType: null,
      lookupChoiceName: '',
      patches: []
    }
  },
  computed: {
  
  },
  methods: {
    //patch: function(op, path, value) {
    //  let patch = _.find(this.patches, {op: op, path: path });
      
    //  if (patch) {
    //    patch.value = value;
    //  } else {
    //    patch = new Operation({op: op, path: path, value: value});
    //    this.patches.push(patch);
    //  }
    //},
    //processPatches: function() {

    //  if (this.patches && this.patches.length) {
    //    var c = new Client(process.env.VUE_APP_API_URL, this.$http);

    //    c.checklistAreas_Patch(this.area.id, this.patches)
    //      .then(function(){

    //        this.patches = [];
    //      }.bind(this))
    //      .catch(function(error){

    //      })

    //  }
    //},

    loadProjectTypes() {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      this.loading = true;
      c.projectType_GetAll()
        .then(function(data){
					this.projectTypes = data;
        }.bind(this))
        .catch(error => {
          console.log(error.response);
        })
        .finally(
          function() {
            this.loading = false;
          }.bind(this)
        );
    },
    showProjectTypeDialog(projectType) {
      if (!projectType) {
        let projectType = new ProjectTypeDto({new: true, code: "", name:"", archived: false, useMainChecklistSection: false, allowIssuesSection: false, allowRepeatChecklists: false, repeatSectionTabName: "", sortOrder: this.projectTypes.length});

        this.projectType = projectType;
      } else {
        this.projectType = projectType;
      }

      this.$refs.projectTypeModalRef.show();
    },

    saveProjectType(hideModal) {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      this.loading = true;
      //this.projectType.options = JSON.stringify(this.projectType.choices || []);

      if (this.projectType && !this.projectType.new) {
        //Update

				c.projectType_Put(this.projectType.code, this.projectType)
          .then(function(data) {

          }.bind(this))
          .catch(error => {
            console.log(error.response);
          })
          .finally(function() {
            this.loading = false;

            this.$store.commit('setProjectTypes', this.projectTypes);
          }.bind(this));

      } else {
        //Insert

        c.projectType_Post(this.projectType)
          .then(function(newProjectType) {

            this.projectTypes.push(newProjectType);
          }.bind(this))
          .catch(error => {
            console.log(error.response);
          })
          .finally(
            function() {
              this.loading = false;

              this.$store.commit('setProjectTypes', this.projectTypes);
            }.bind(this));
      }

      if (hideModal) {
        hideModal();
      }
    },
  },
  watch: {

  },
  created() {
    this.loadProjectTypes();
  }
});
</script>

<style lang="scss">
</style>