<template>
  <drag 
    tag="div" 
    class="row py-1"
    :class="{'has-filter': hasFilter }"
    :key="checklistTemplate.id"
    :data="checklistTemplate" 
    type="templateProjects"
  >
  
    <div class="col-10">
      <b-button class="" variant="link" size="sm" @click="$emit('gotoChecklist', checklistTemplate)"><h5> {{(checklistTemplate.projectTypeCode || checklistTemplate.projectType)  + ' | ' + name}}</h5></b-button>
      <filter-inline-viewer v-if="hasFilter" :conditions="checklistTemplate.conditionsObj" :rules="checklistProjectRules"></filter-inline-viewer>
    </div>
    <div class="col-2 text-right">
      <b-button class="btn-add-rule mx-1" :variant="hasFilter ? 'success' : 'outline-primary'" size="sm" @click="$emit('filterChecklist', checklistTemplate)">
        <FilterIcon title="Filter Checklist" />
      </b-button>
      <b-button class="btn-add-rule mx-1" variant="outline-danger" size="sm" @click="$emit('removeChecklist',checklistTemplate)">
        <TrashCanIcon title="Remove Checklist" />
      </b-button>
    </div>
  </drag>
</template>

<script>
  import Vue from "vue";
  import { mapState } from "vuex";
  import { Client, Operation, ChecklistDto, ChecklistType } from "../../code/EngineeringChecklist.Api";
  // import { Vuex, mapState, mapMutations, mapGetters } from "vuex";
  import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
  import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
  import TransferIcon from 'vue-material-design-icons/Transfer.vue';
  import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
  import DragHorizontalIcon from 'vue-material-design-icons/DragHorizontalVariant.vue';
  import FilterInlineViewer from './FilterInlineViewer.vue'
  import FilterIcon from 'vue-material-design-icons/Filter.vue';

  import AddRepeatChecklist from "../modals/AddRepeatChecklist.vue"
  import ManageRule from "../modals/ManageRule.vue"

  import { Drag, Drop, DropList } from "vue-easy-dnd";
  import Multiselect from "vue-multiselect";

  export default Vue.extend({
    name: "ProjectTemplate",
    components: {
      Drag,
      FilterInlineViewer,
      FilterIcon,TrashCanIcon
    },
    props: {
      checklistTemplate: {type: Object, required: true},
      checklistProjectRules: {type: Array, required: true },
      templates: {type: Array, required: true },
    },
    data() {
      return {
        patches: [],
      };
    },
    computed : {
      hasFilter: function() {
        return this.checklistTemplate && this.checklistTemplate.conditionsObj && this.checklistTemplate.conditionsObj.children && this.checklistTemplate.conditionsObj.children.length
      },
      conditionsObj: function() {
        return this.checklistTemplate && this.checklistTemplate.conditionsObj;
      },
      template: function() {
        return this.checklistTemplate && this.checklistTemplate.checklistProjectId ? _.find(this.templates, { id: this.checklistTemplate.checklistProjectId }) : null;
      },
      name: function() {
        return this.template ? this.template.name : '';
      }
    },
    methods: {
      /*
      convertChecklist(checklist) {

        this.$bvModal.msgBoxConfirm(`Create Standard checklist template from '${checklist.name}'?`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          if (value) {
            if (checklist && checklist.id) {
              c.checklists_CreateTemplateFromChecklist(checklist.id, checklist.name + " copy")
                .then(function (data) {

                  this.$bvToast.toast(`Sucessfully created checklist template: "${data.name}"`, {
                    title: `Checklist cloned.`,
                    variant: "success"
                  });

                  //Send them over to the newly created checklist
                  this.$router.push({ name: 'manage-repetitive-checklist', params: { checklistId: data.id } });

                }.bind(this))
                .catch(function (error) {
                  this.$bvToast.toast(`Failed to create template checklist: ${error.message}`, {
                    title: `Failed to clone checklist.`,
                    variant: "danger"
                  });

                }.bind(this));
            }
          }
        }.bind(this));
      },
      patch: function (op, path, value) {
        let patch = _.find(this.patches, { op: op, path: path });

        if (patch) {
          patch.value = value;
        } else {
          patch = new Operation({ op: op, path: path, value: value });
          this.patches.push(patch);
        }
      },
      processChecklistPatches: function () {

        if (this.patches && this.patches.length) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          c.checklistProjects_Patch(this.checklistTemplate.id, this.patches)
            .then(function (updatedChecklistProject) {
              if (updatedChecklistProject) {

                delete updatedChecklistProject.checklists;

                updatedChecklistProject.conditionsObj = updatedChecklistProject.conditions ? JSON.parse(updatedChecklistProject.conditions) : {};
                Object.assign(this.checklistTemplate, updatedChecklistProject);
              }
              this.patches = [];
            }.bind(this))
            .catch(function (error) {
              this.$bvToast.toast(error.message, {
                title: `Saving ${this.project.name} failed`,
                variant: "danger"
              });
            })
        }
      },*/
      
    },
    watch: {
      name: function (newValue, oldValue) {
        //Only used for reference purposes...
        this.checklistTemplate.name = newValue;
      },
      conditionsObj: { 
        deep: true,
        handler: function(newValue, OldValue)
        {
          this.checklistTemplate.conditions = JSON.stringify(newValue);
          this.$emit('updateChecklistTemplate', this.checklistTemplate, this.checklistTemplate.sortOrder)
        }
      }
    },
    created() {
    } 
  });

</script>

<style lang="scss">

</style>