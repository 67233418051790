<template>
  <div class="container questions-list">
    <div class="row">
      <div class="col-6">Prompt</div>
      <div class="col-2 text-center">Type</div>
      <div class="col-4 matrix-answers">
        <div class="row no-gutters">
          <div class="col text-center" v-for="(a,idx) in areas" :key="idx" :class="{'col-even': idx % 2 }">{{ a.name }}</div>
        </div>  
      </div>
      <div class="col-1"></div>
    </div>

    <drop-list :items="allTabQuestions"
               @insert="onInsert($event, allTabQuestions)"
               @reorder="reorderQuestions($event)"
               mode="cut"
               :accepts-type="area.groupName + '_question'"
               :row="true"
               :no-animations="true">
      <h4 class="text-center my-3" v-if="!allTabQuestions || !allTabQuestions.length">No Questions Defined</h4>
      <template v-slot:item="{item}">
        <drag 
          tag="div" 
          class="row question pl-2" 
          :class="{'duplicate-questions': item.questions.length > item.areas.length}"
          :key="item.id" 
          :data="item" 
          @cut="$emit('remove', item)" 
          :type="area.groupName + '_question'"
          handle=".matrixquestion-drag-handle"
        >
          <drag-horizontal-icon class="drag-handle matrixquestion-drag-handle" title="Drag Question"></drag-horizontal-icon>
          <div class="col-6 pl-4">{{item.question.prompt}}</div>
          <div class="col-2 text-center">
            <select v-model="item.question.type" class="form-control" @change="patchMatrix(item,'replace', 'type', $event.target.value)">
              <option value="yesNo">Yes / No</option>
              <option value="yesNoNA">Yes / No / NA</option>
              <option value="textbox">Text Input</option>
              <option value="lookupList">Lookup List</option>
            </select>
            <select 
              v-if="item.question.type == 'lookupList'" 
              v-model="item.question.lookupListId" 
              class="form-control" 
              @change="patch('replace', 'lookupListId', $event.target.value)">
              <option :value="null" disabled>Select Lookup List</option>
              <option v-for="lookupList in lookupLists" :key="lookupList.id" :value="lookupList.id">{{lookupList.name}}</option>
            </select>   
          </div>
          <div class="col-4 matrix-answers">
            <div class="row no-gutters">
              <div class="col text-center" v-for="(a,idx) in item.areas" :key="idx" :class="{'col-even': idx % 2 }">
                <input type="checkbox" :checked="a" @change="updateMatrix(item, idx, $event)" />
              </div>
            </div>
          </div>
        </drag>
        <!-- <manage-question :checklistRules="checklistRules"
                         :question="item"
                         @filter="editConditions"
                         @edit="editQuestion"
                         @remove="removeQuestion"
                         @moreInfo="editMoreInfo"></manage-question> -->
      </template>
      <template v-slot:feedback="{data}">
        <div class="feedback" :key="data.id || data.key" />
      </template>
      <template v-slot:reordering-feedback="{}">
        <div class="reordering-feedback" key="feedback" />
      </template>
    </drop-list>
    <div class="row question add-question ">
      <div class="col col-6"><input class="form-control" type="text" placeholder="Enter question" v-model="newQuestion.prompt" /></div>
      <div class="col col-2">
        <select v-model="newQuestion.type" class="form-control">
          <option value="yesNo">Yes / No</option>
          <option value="yesNoNA">Yes / No / NA</option>
          <option value="textbox">Text Input</option>
          <option value="lookupList">Lookup List</option>
        </select>
        <select 
          v-if="questionType == 'lookupList'" 
          v-model="newQuestion.lookupListId" 
          class="form-control">
          <option :value="null" disabled>Select Lookup List</option>
          <option v-for="lookupList in lookupLists" :key="lookupList.id" :value="lookupList.id">{{lookupList.name}}</option>
        </select>        
      </div>
      <div class="col-4 matrix-answers">
        <div class="row no-gutters">
          <div class="col text-center" v-for="(a,idx) in areas" :key="a.id" :class="{'col-even': idx % 2 }"><input type="checkbox" v-model="newQuestion.selectedAreas[idx]" /></div>
        </div>  
      </div>
      <div class="col-add-matrix-question">
        <b-button class="btn-add-question" variant="primary" size="sm" @click="insertMatrixQuestion()">Add</b-button>
      </div>
    </div>

    <EditConditional 
      ref="filterConditionModalRef"
      :activeConditional="activeConditional" 
      :rules="checklistRules" 
      @change="editActiveConditional"
    />
    <b-modal title="More Info Editor"
             centered no-close-on-backdrop
             ref="moreInfoModalRef"
             size="lg">
      <b-overlay :show="loadingMoreInfo" rounded="sm">
        <div class="container p-0" v-if="activeQuestion">
          <div class="row">
            <div class="col-12">
              <h5>More Info for: {{activeQuestion.hierarchy}}</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <yimo-vue-editor v-model="activeMoreInfo.html_en" :config="config"></yimo-vue-editor>
              <!--<ckeditor :editor="editor" :config="editorConfig" v-model="activeMoreInfo.html_en"></ckeditor>-->
            </div>
          </div>
        </div>
      </b-overlay>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button v-if="!loadingMoreInfo && activeMoreInfo && activeMoreInfo.id" class="mr-auto" size="sm" variant="danger" @click="removeMoreInfo(activeQuestion, ok)">Remove</b-button>
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="saveMoreInfo(ok)">Save</b-button>
      </template>
    </b-modal>

    <b-modal title="Edit Question"
             centered no-close-on-backdrop
             ref="questionModalRef"
             size="lg">
      <div class="container" v-if="activeQuestion">
        <div class="row">
          <div class="col-4">
            Prompt:
          </div>
          <div class="col-8">
            <input class="form-control" type="text" v-model="activeQuestion.prompt">
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            Hint:
          </div>
          <div class="col-8">
            <b-form-textarea id="textarea-small"
                             class="form-control"
                             size="sm"
                             placeholder="Area Description"
                             v-model="activeQuestion.hint"></b-form-textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            Question Type:
          </div>
          <div class="col-8">
            <select v-model="activeQuestion.type">
              <option value="yesNo">Yes / No</option>
              <option value="yesNoNA">Yes / No / NA</option>
              <option value="textbox">Text Input</option>
              <option value="lookupList">Lookup List</option>
            </select>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="ok()">Ok</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import Vue from "vue";
  import { Client, ChecklistAreaDto, Question, QuestionDto, QuestionType, Operation, QuestionMoreInfoDto } from "../../code/EngineeringChecklist.Api";
  // import { Vuex, mapState, mapMutations, mapGetters } from "vuex";
  import EditConditional from "../modals/EditConditional.vue";
  import DragHorizontalIcon from 'vue-material-design-icons/DragHorizontalVariant.vue';

  import ManageQuestion from './ManageQuestion.vue'
  import { DropList, Drag } from "vue-easy-dnd";
  import YimoVueEditor from 'yimo-vue-editor'

  export default Vue.extend({
    name: "ManageAreaMatrixQuestions",
    components: {
      EditConditional,
      //ckeditor: CKEditor.component,
      YimoVueEditor,
      //ManageQuestion,
      DropList, Drag, //, Drop
      DragHorizontalIcon,
    },
    props: {
      checklistRules: { type: Array, required: true },
      areas: {type: Array, required: true}
    },
    data() {
      return {
        questionType: 'yesNo',
        lookupsLoaded: false,
        lookupLists: [],
        questionLookupListId: null,
        selectedAreas: [],

        newQuestion: {prompt: "", hint: "", type: QuestionType.YesNo, lookupListId: null, selectedAreas: []},
        
        activeConditional: null,
        activeQuestion: null,
        activeMoreInfo: { id: 0, html_en: "" },
        loadingMoreInfo: false,
        config: {
          //uploadImgUrl: '/api/upload', // upload api
          printLog: false, // disabled console.log
          useLang: 'en' // lang config
        }
      };
    },
    computed: {
      allAreasSelections: function() {
        return this.areas && this.areas.length ? this.areas.map(a => true) : [];
      },
      area: function () {
        return this.areas && this.areas.length ? this.areas[0] : null;
      },
      rules: function () {
        return this.$parent.rules;
      },
      allQuestions: function () {
        let questions = [];
        this.areas.forEach(a => {
          questions = questions.concat(a.questions);
        });

        return _.sortBy(questions, ['sortOrder', 'areaId']);
      },
      allTabQuestions: function () {
        let questions = [];
        this.areas.forEach(a => {
          questions = questions.concat(a.questions);
        });

        let groupedQuestions = _.groupBy(this.allQuestions, 'prompt');
        let uniqueQuestions = _.map(groupedQuestions, (questions, prompt) => {
          
          let sourceAreas = questions.map(q => q.areaId);
          let areaSelections = this.areas.map(a => sourceAreas.findIndex(id => id == a.id) == -1 ? false : true);          

          return {question: questions[0], areas: areaSelections, questions: questions };
        });

        return uniqueQuestions;
      },
    },
    methods: {
      resetNewQuestion: function() {
        this.newQuestion = {prompt: "", hint: "", type: QuestionType.YesNo, selectedAreas: this.allAreasSelections};
      },
      reorderQuestions(e) {
        let changes = [];
        
        let f = this.allTabQuestions.splice(e.from, 1);
        this.allTabQuestions.splice(e.to, 0, f[0]);
        

        for (let index = 0; index < this.allTabQuestions.length; index++) {
          let item = this.allTabQuestions[index];
          for (let i = 0; i < item.questions.length; i++) {
            if (item.questions[i].sortOrder != index + 1) {
              changes.push({ question: item.questions[i], sortOrder: index + 1 });
            }
          }
        }

        //Commit all the sorting changes detected
        for (const change of changes) {
          this.updateQuestionSortOrder(change.question, change.sortOrder);
        }
      },
      updateQuestionSortOrder: function (question, sortOrder) {
        if (question && sortOrder) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          c.questions_Patch(question.id, [new Operation({ op: 'replace', path: '/sortOrder', value: sortOrder })])
            .then(function (updatedQuestion) {
              if (updatedQuestion) {
                delete updatedQuestion.issues;
                Object.assign(question, updatedQuestion);
              }
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            })
        }

      },

      removeQuestion(question) {

        this.$bvModal.msgBoxConfirm('Are you sure?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          if (value) {
            if (question && question.id) {
              c.questions_Delete(question.id)
                .then(function (data) {
                  this.$bvToast.toast(`Sucessfully removed question: "${data.prompt}"`, {
                    title: `Question removed.`,
                    variant: "success"
                  });
                }.bind(this))
                .catch(function (error) {
                  this.$bvToast.toast(`Failed to remove question: ${error.message}`, {
                    title: `Failed to remove question.`,
                    variant: "danger"
                  });

                }.bind(this));
            }

            this.remove(this.area.questions, question);

          }
        }.bind(this))

      },
      editQuestion(question) {
        this.activeQuestion = question;
        this.$refs.questionModalRef.show();
        // this.$emit('edit-question', question);
      },

      insertMatrixQuestion: function() {

        for (let i = 0; i < this.newQuestion.selectedAreas.length; i++) {
          if (this.newQuestion.selectedAreas[i]) {
            let area = this.areas[i];

            let questionDto = new QuestionDto({ id: 0, areaId: area.id, prompt: this.newQuestion.prompt, hint: this.newQuestion.hint, type: this.newQuestion.type, sortOrder: area.questions.length });
            let errCount = 0;

            var c = new Client(process.env.VUE_APP_API_URL, this.$http);
            c.questions_Post(questionDto)
              .then(function (newQuestion) {
                this.$set(area.questions, area.questions.length, newQuestion);
              }.bind(this))
              .catch(function (error) {
                errCount++;
                this.$bvToast.toast(`Failed to add question: ${error.message || error}`, {
                  title: `Failed to add question.`,
                  variant: "danger"
                });
                console.log(error);
              }.bind(this))
              .finally(function () {
                if (errCount == 0) {
                  this.resetNewQuestion();
                }
              }.bind(this));
          }
        }        
      },
      patchMatrix: function(item, op, path, value) {
        let patch = _.find(item.patches, {op: op, path: path });
        
        if (patch) {
          patch.value = value;
        } else {
          patch = new Operation({op: op, path: path, value: value});
          item.patches.push(patch);
        }
      },
      updateMatrix: function(item, areaIdx, evt) {
        var area = this.areas[areaIdx];
        var value = evt.target.checked;

        var question = _.find(area.questions, { prompt: item.question.prompt });

        if (value) {
          if(question) {
            //Already exists... Just update the area
            evt.target.checked = !evt.target.checked;
            return;
          }
          //Add this question to the area
          let questionDto = new QuestionDto({ id: 0, areaId: area.id, prompt: item.question.prompt, hint: item.question.hint, type: item.question.type, sortOrder: item.question.sortOrder });

          let c = new Client(process.env.VUE_APP_API_URL, this.$http);
          c.questions_Post(questionDto)
            .then(function (newQuestion) {
              this.$set(area.questions, area.questions.length, newQuestion);
            }.bind(this))
            .catch(function (error) {
              this.$bvToast.toast(`Failed to add question: ${error.message || error}`, {
                title: `Failed to add question.`,
                variant: "danger"
              });
              console.log(error);
            }.bind(this));

            item.areas[areaIdx] = value;
        } else {
          let selections = item.areas.filter(selected => selected).length
          
          if (question) {
            if(selections <= 1) {
              //Don't remove the last question from the area without confirmation
              this.$bvModal.msgBoxConfirm('Are you sure?', {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
              }).then(function (value) {

                if (value) {
                  var c = new Client(process.env.VUE_APP_API_URL, this.$http);
                  if (question && question.id) {
                    c.questions_Delete(question.id)
                      .then(function (data) {
                        this.$bvToast.toast(`Sucessfully removed question: "${question.prompt}"`, {
                          title: `Question removed.`,
                          variant: "success"
                        });

                        this.remove(area.questions, question);
                      }.bind(this))
                      .catch(function (error) {
                        this.$bvToast.toast(`Failed to remove question: ${error.message}`, {
                          title: `Failed to remove question.`,
                          variant: "danger"
                        });

                      }.bind(this));
                  }

                  this.remove(this.area.questions, question);
                  item.areas[areaIdx] = evt.target.checked;
                } else {
                  evt.target.checked = !evt.target.checked;
                }
              }.bind(this))
            } else {
              let c = new Client(process.env.VUE_APP_API_URL, this.$http);

              
                c.questions_Delete(question.id)
                .then(function (newQuestion) {
                  this.remove(area.questions, question);
                }.bind(this))
                .catch(function (error) {
                  this.$bvToast.toast(`Failed to remove question: ${error.message || error}`, {
                    title: `Failed to remove question.`,
                    variant: "danger"
                  });
                  console.log(error);
                }.bind(this));
                
                item.areas[areaIdx] = value;
            }
          }
        }
      },





      editActiveConditional: function() {
        this.$refs.filterConditionModalRef.hide();
      },
      editConditions: function (condObj) {
        if (condObj && !condObj.conditions) {
          this.$set(condObj, 'conditions', {});
        }
        this.activeConditional = condObj;

        this.$refs.filterConditionModalRef.show();
      },
      editMoreInfo: function (question) {

        this.activeQuestion = question;
        if (question && !question.moreInfoId) {
          //Brand new moreinfo
          Object.assign(this.activeMoreInfo, { id: 0, html_en: "" });

        } else {

          this.loadingMoreInfo = true;
          Object.assign(this.activeMoreInfo, { id: question.moreInfoId, html_en: "" });

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);
          c.moreInfo_GetById(question.moreInfoId)
            .then(function (moreInfo) {
              Object.assign(this.activeMoreInfo, moreInfo);
              //this.activeMoreInfo = moreInfo;
            }.bind(this))
            .catch(error => {
              console.log(error.response);
            })
            .finally(
              function () {
                this.loadingMoreInfo = false;
              }.bind(this)
            );
        }

        this.$refs.moreInfoModalRef.show();
      },
      saveMoreInfo: function (okCallback) {

        if (this.activeMoreInfo) {

          this.loadingMoreInfo = true;

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);
          var saveFunc = null

          if (!this.activeMoreInfo.id) {
            saveFunc = c.moreInfo_Post(this.activeMoreInfo);
          } else {
            saveFunc = c.moreInfo_Put(this.activeMoreInfo.id, this.activeMoreInfo);
          }

          saveFunc.then(function (moreInfo) {
            this.activeQuestion.moreInfoId = moreInfo.id

            c.questions_Patch(this.activeQuestion.id, [new Operation({ op: 'replace', path: '/moreInfoId', value: moreInfo.id })])
              .then(function (data) {
                okCallback();
              }.bind(this))
              .catch(error => {
                console.log(error.response);
              });
          }.bind(this))
            .catch(error => {
              console.log(error.response);
            })
            .finally(
              function () {
                this.loadingMoreInfo = false;

              }.bind(this)
            );

        } else {
          okCallback();
        }
      },
      removeMoreInfo: function (question, okCallback) {
        if (question && question.moreInfoId) {
          //Remove the association...
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);
          c.questions_Patch(question.id, [new Operation({ op: 'replace', path: '/moreInfoId', value: null })])
            .then(function (data) {
              Object.assign(question, data);

              okCallback();
            }.bind(this))
            .catch(error => {
              console.log(error.response);
            });
        }

        //this.$refs.moreInfoModalRef.show();
      },
      remove(array, value) {
        let index = array.indexOf(value);
        array.splice(index, 1);
      },
      onInsert(event, items) {
        //items.splice(event.index, 0, event.data);
      },
      onDrop(event, items) {
        items.push(event.data);
      },
      // async loadQuestions(areaId) {
      //   var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      //   c.questions_GetAll(areaId)
      //     .then(function(questions) {
      //       this.questions = questions;
      //     }.bind(this))
      //     .catch(function(error){
      //       console.log(error);
      //     }.bind(this));
      // }
    },
    watch: {
      areas: function(newValue, oldValue) {
        this.newQuestion.selectedAreas = this.allAreasSelections;
        //this.resetNewQuestion();
      },
      questionType: {
        immediate: true,
        handler: function(newValue,oldValue) {
          if (newValue == 'lookupList' && !this.lookupsLoaded) {
            this.$store.dispatch('fetchLookupLists')
              .then(function(lookupLists){
                this.lookupsLoaded = true;
                this.lookupLists = lookupLists;
              }.bind(this));
          }
        }
      },
    },
    created() {
    }
  });
</script>

<style lang="scss">
  .question {
    &.duplicate-questions {
      background-color: #fdaeae;
    }
    &.has-filter {
      //border-left: 4px solid #0d74b0;
      //background-color: honeydew;
      // border-top: 2px solid #5cdd5c;
      // border-bottom: 2px solid #5cdd5c;
    }

    input, select {
      background-color: transparent;
      padding: 0;
      /* line-height: 1em; */
      height: 1.5em;
      border: 0;
      border-bottom: 1px dashed #BBB;
      border-radius: 0;
    }

    input[type="File"] {
      height: 2em;
      line-height: 1.5em;
    }
    &.add-question {
      
      border-top: 2px dashed #999;
      //border-bottom: 2px solid #5cdd5c;
      .col{
        padding-top: 10px;
      }     
      &.col-even {

        background-color: #f0fff0;
      }
    }
  }
  .col-even {
    background-color: #EEE;
  }
  .question-hint {
    font-size: 0.7em;
    color: #999;
  }
  .col-add-matrix-question {
    position: absolute;
    top: 5px;
    right: -60px;
  }
  .matrix-answers > .row {
    height: 100%;
  }
  
  .matrix-tabs .question-drag-handle {
    display: none;
  }
  // .question-conditions {
  //   border-top:3px solid #5cdd5c;
  //   background-color: #f0fff0;
  // }
</style>
