<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body p-0">
      <div class="attachments">
        <question-attachment v-for="attachment in attachments" 
          :key="attachment.id || attachment.key" 
          :attachment="attachment" 
          :readonly="readonly"
          @remove-attachment="onRemoveAttachment"></question-attachment>
        <h5 class="text-center m-4" v-if="!hasAttachments">No Attachments Uploaded</h5>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Client, QuestionAttachmentDto, QuestionDto, QuestionType } from "../code/EngineeringChecklist.Api";
import QuestionAttachment from './QuestionAttachment.vue';

export default {
  components: { QuestionAttachment },
  name: "QuestionAttachments",
  props: {
    question: { type: Object, default: null},
    readonly: { type: Boolean, required: true}
  },
  data() {
    return {
      attachmentsLoaded: false
    };
  },
  computed: {
    hasAttachments: function() {
      return this.attachments && this.attachments.length;
    },
    attachments: function() {
      if (this.question && this.attachmentsLoaded) {
        return this.question.attachments;
      } else {
        return [];
      }
    },
  },
  methods: {
    onRemoveAttachment: function(attachment) {
      this.$emit('remove-attachment', attachment);
    },
    fetchAttachments() {
      if (this.question && this.question.id) {
        let c = new Client(process.env.VUE_APP_API_URL, this.$http);
        c.questions_GetAttachments(this.question.id)
          .then(function(attachments) {
            this.$set(this.question,'attachments', attachments);
            this.attachmentsLoaded = true;
          }.bind(this))
          .catch(function(err) {
            console.error(err);
          }.bind(this));
      }
    },

  },
  created() {
    if (this.question && this.question.attachments && this.question.attachments.length) {
      this.attachmentsLoaded = true;
    } else {
      this.fetchAttachments();
    }
  }
};
</script>
<style lang="scss">

</style>