<template>
  <b-modal :title="(newRule && !newRule.new) ? 'Edit Conditional Rule' : 'New Conditional Rule'"
           centered
           size="md">
    <div class="container" v-if="newRule">
      <div class="row py-1">
        <div class="col-4">
          Name:
        </div>
        <div class="col-8">
          <input class="form-control" type="text" v-model="newRule.label" @change="changeRuleLabel(newRule, $event)">
        </div>
      </div>
      <div class="row py-1">
        <div class="col-4">
          Type:
        </div>
        <div class="col-8">
          <select class="form-control" v-model="newRule.type" @change="changeRuleType(newRule, $event)">
            <option value="radio">Radio Buttons</option>
            <option value="select">Drop Down List</option>
            <option value="checkbox">Checkbox list</option>
            <option value="toggle">Toggle (Yes/No)</option>
          </select>
        </div>
      </div>

      <div class="row py-1" v-if="newRule.type != 'toggle'">
        <div class="col-4">
          Choices:
        </div>
        <div class="col-8 ">
          <div class="row no-gutters py-1">
            <div class="col-10 pr-2">
              <b-form-input class="form-control pr-5 d-inline-block" type="text" size="sm" placeholder="Specify New Item" v-model="newFilterItemName"></b-form-input>
            </div>
            <div class="col-2 text-center">
              <b-button class="" size="sm" variant="primary" @click="addRuleChoice(newRule)">Add</b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters py-1">
        <div class="col-12" v-if="newRule.type =='radio' || newRule.type =='select'">
          <b-form-radio-group :id="`admin_${parentName}_${newRule.label}-radio-group`"
                              v-model="newRule.value"
                              :name="`${parentName}_${newRule.label}`">
            <div class="pb-1" v-for="(choice, index) in newRule.choices" :key="choice.label">
              <b-form-radio :value="choice.value">{{choice.label}}</b-form-radio>
              <a class="float-right" href="#" @click.prevent="newRule.choices.splice(index,1)"><TrashCanIcon title="" fillColor="red"></TrashCanIcon></a>
            </div>
          </b-form-radio-group>
        </div>
        <div class="col-12" v-else-if="newRule.type =='checkbox'">
          <b-form-checkbox-group :id="`admin_${parentName}_${newRule.label}-checkbox-group`"
                                 v-model="newRule.value"
                                 :name="`${parentName}_${newRule.label}`">
            <div class="pb-1" v-for="(choice, index) in newRule.choices" :key="choice.label">
              <b-form-checkbox :value="choice.value">{{choice.label}}</b-form-checkbox>
              <a class="float-right" href="#" @click.prevent="newRule.choices.splice(index,1)"><TrashCanIcon title="" fillColor="red"></TrashCanIcon></a>
            </div>

          </b-form-checkbox-group>
        </div>
      </div>

    </div>
    <template v-slot:modal-footer="{ }">
      <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
      <b-button size="sm" variant="primary" @click="ok()">{{ rule && newRule.new ? 'Add Rule' : 'Ok' }}</b-button>
    </template>
  </b-modal>
</template>

<script>
  import Vue from 'vue';
  import { ChecklistDto, ChecklistProjectDto } from "../../code/EngineeringChecklist.Api";
  import Multiselect from "vue-multiselect";
  import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';

  export default Vue.extend({
    name: 'ManageRule',
    components: {

      TrashCanIcon
      //Multiselect
    },
  props: {
    rule: {
      type: Object,
      required: false
    },
    parent: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      newFilterItemName: '',
      newRule: null
    };
  },
  computed: {
    modal: function () {
      return this.$children[0];
    },
    parentName: function () {
      let p = this.parent;
      
      if (p instanceof ChecklistProjectDto) {
        return 'CLP_' + p.id;
      } else if (p instanceof ChecklistDto) {
        return 'CL_' + p.id;
      }

      return p.name;
    }
  },
  methods: {
    changeRuleLabel: function (rule, evt) {
      this.$set(rule, 'id', `${String(rule.label).toLowerCase()}-${rule.type}-field`)
    },
    changeRuleType: function (rule, evt) {
      this.$set(rule, 'id', `${String(rule.label).toLowerCase()}-${rule.type}-field`)
      rule.value = null
    },
    addRuleChoice: function (rule) {
        this.newRule.choices.push({ label: this.newFilterItemName, value: this.newFilterItemName });
        this.newFilterItemName = "";
      },

    reset: function () {
      this.newRule = Object.assign({}, this.rule);
      this.newFilterItemName = '';
    },
    show: function () {

      this.$nextTick(() => {
        this.reset();
        this.modal.show();
      });
    },
    hide: function () {
      this.modal.hide();
    },
    cancel() {
      // Handle cancel action
      this.hide()
    },
    ok() {
      // Handle ok action
      this.$emit('change', this.newRule);

      this.hide();
    }

  },
  watch: {
  },
  created() {
  }
});
</script>
