<template>
  <div class="rule-group">
    <span class="group-op" :class="[ op ]" :title="`${(op || '').toUpperCase()} -- Applicable when ${op} of the rules are true`">{{opSymbol}}</span>
    <template v-for="(child, index) in children">
      <FilterGroup v-if="child.type == 'query-builder-group'" :rules="rules" :group="child.query" :key="index"></FilterGroup>
      <FilterRule v-else :rules="rules" :rule="child.query" @remove-rule="dropRule" :key="index"></FilterRule>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';


import FilterGroup from './FilterGroup.vue'
import FilterRule from './FilterRule.vue'


export default Vue.extend({
  name: 'FilterGroup',
  components: {
    FilterGroup, 
    FilterRule,
  },
  props: {
    rules: {type: Array, required: true},
    group: {type: Object, required: true},
  },
  data() {
    return {}
  },
  computed: {
    op: function() {
      return this.group ? this.group.logicalOperator : '';
    },
    opSymbol: function() {
      return this.op ? this.op == 'all' ? '&' : '*' : '';
    },
    children: function() {
      return this.group ? this.group.children : [];
    }
  },
  methods: {
    dropRule: function(childRule) {
      let idx = _.findIndex(this.group.children, { query: childRule })
      if (idx >= 0) {
        this.group.children.splice(idx,1);
      }
    }

  },
  watch: {
  },
  created() {
  }
});
</script>

<style lang="scss">



.card-header.filter-header {
  background-color: honeydew;

  .filter-icon{ 
    margin: 0.25em;
    display: inline-block;
    vertical-align: top;}
}

.rule-group {
  position: relative;
  padding-left: 20px;
}

.filter-inline-viewer{
  display: inline-block;
  font-size: 0.7em;
  margin-left: 1em;

  .group-op {
    position: absolute;
    left: 5px;
    top: 50%;
    
    &.any {
      font-size: 2.5em;
      transform: translateY(-35%);
    }
    &.all {
      font-size: 1.25em;
      font-weight: bold;
      transform: translateY(-50%);
    }
  }
  .rule-group, .rule {
    border-left: 3px solid #8bc34a;

    .rule-group, .rule {
      border-left: 3px solid #00bcd4;
    }
  }
}
</style>

