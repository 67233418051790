<template>
  <div class="container questions-list">
    <div class="row" v-if="questions && questions.length">
      <div class="col-8">Question Prompt</div>
      <div class="col-2">Type</div>
      <div class="col-2"></div>
    </div>
    <drop-list :items="questions"
               @insert="onInsert($event, questions)"
               @reorder="reorderQuestions($event)"
               mode="cut"
               accepts-type="question"
               :row="true"
               :no-animations="true">
      <h4 class="text-center my-3" v-if="!questions || !questions.length">No Questions Defined</h4>
      <template v-slot:item="{item}">
        <manage-question :checklistRules="checklistRules"
                         :question="item"
                         @filter="editConditions"
                         @edit="editQuestion"
                         @remove="removeQuestion"
                         @moreInfo="editMoreInfo"></manage-question>
      </template>
      <template v-slot:feedback="{data}">
        <div class="feedback" :key="data.id || data.key" />
      </template>
      <template v-slot:reordering-feedback="{}">
        <div class="reordering-feedback" key="feedback" />
      </template>
    </drop-list>
    <div class="row">
      <div class="col-12" v-if="questions && questions.length">
        <b-button class="insert-question" variant="link" size="sm" @click="insertQuestion()">Insert Question</b-button>
      </div>
      <div class="col-12 text-center" v-else>
        <b-button class="btn-add-question m-2" variant="primary" size="sm" @click="insertQuestion()">Add Question</b-button>
      </div>
    </div>
    <EditConditional 
      ref="filterConditionModalRef"
      :activeConditional="activeConditional" 
      :rules="checklistRules" 
      @change="editActiveConditional"
    />
    <b-modal title="More Info Editor"
             centered no-close-on-backdrop
             ref="moreInfoModalRef"
             size="lg">
      <b-overlay :show="loadingMoreInfo" rounded="sm">
        <div class="container p-0" v-if="activeQuestion">
          <div class="row">
            <div class="col-12">
              <h5>More Info for: {{activeQuestion.hierarchy}}</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <yimo-vue-editor v-model="activeMoreInfo.html_en" :config="config"></yimo-vue-editor>
              <!--<ckeditor :editor="editor" :config="editorConfig" v-model="activeMoreInfo.html_en"></ckeditor>-->
            </div>
          </div>
        </div>
      </b-overlay>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button v-if="!loadingMoreInfo && activeMoreInfo && activeMoreInfo.id" class="mr-auto" size="sm" variant="danger" @click="removeMoreInfo(activeQuestion, ok)">Remove</b-button>
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="saveMoreInfo(ok)">Save</b-button>
      </template>
    </b-modal>

    <b-modal title="Edit Question"
             centered no-close-on-backdrop
             ref="questionModalRef"
             size="lg">
      <div class="container" v-if="activeQuestion">
        <div class="row">
          <div class="col-4">
            Prompt:
          </div>
          <div class="col-8">
            <input class="form-control" type="text" v-model="activeQuestion.prompt">
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            Hint:
          </div>
          <div class="col-8">
            <b-form-textarea id="textarea-small"
                             class="form-control"
                             size="sm"
                             placeholder="Area Description"
                             v-model="activeQuestion.hint"></b-form-textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            Question Type:
          </div>
          <div class="col-8">
            <select v-model="activeQuestion.type">
              <option value="yesNo">Yes / No</option>
              <option value="yesNoNA">Yes / No / NA</option>
              <option value="textbox">Text Input</option>
              <option value="lookupList">Lookup List</option>
            </select>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="ok()">Ok</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import Vue from "vue";
  import { Client, ChecklistAreaDto, Question, QuestionDto, QuestionType, Operation, QuestionMoreInfoDto } from "../../code/EngineeringChecklist.Api";
  // import { Vuex, mapState, mapMutations, mapGetters } from "vuex";
  import EditConditional from "../modals/EditConditional.vue";

  import ManageQuestion from './ManageQuestion.vue'
  import { DropList, Drag } from "vue-easy-dnd";
  import YimoVueEditor from 'yimo-vue-editor'

  export default Vue.extend({
    name: "ManageAreaQuestions",
    components: {
      EditConditional,
      //ckeditor: CKEditor.component,
      YimoVueEditor,
      ManageQuestion,
      DropList, //Drag , Drop

    },
    props: {
      checklistRules: { type: Array, required: true },
      area: { type: Object, required: true },
      // questions: {type: Array, required: true },
    },
    data() {
      return {
        activeConditional: null,
        activeQuestion: null,
        activeMoreInfo: { id: 0, html_en: "" },
        loadingMoreInfo: false,
        config: {
          //uploadImgUrl: '/api/upload', // upload api
          printLog: false, // disabled console.log
          useLang: 'en' // lang config
        }
      };
    },
    computed: {
      rules: function () {
        return this.$parent.rules;
      },
      questions: function () {
        return _.sortBy(this.area.questions, ['sortOrder']);
      },

      editorConfig: function () {
        return {
          plugins: [
            FontColor,
            FontSize,
            Alignment,
            Bold,
            Italic,
            Heading,
            Indent,
            IndentBlock,
            List,
            PasteFromOffice,
            Table,
            TableProperties,
            TableToolbar,
            TableCellProperties,
            Underline,
            Essentials,
            Paragraph
          ],
          toolbar: [
            "heading",
            "|",
            "fontSize",
            "fontColor",
            "alignment",
            "|",
            "bold",
            "italic",
            "underline",

            "bulletedList",
            "numberedList",
            "|",
            "indent",
            "outdent",
            "|",
            "insertTable",
            "pageBreak",
            "undo",
            "redo"
          ],
          table: {
            contentToolbar: [
              "tableColumn",
              "tableRow",
              "mergeTableCells",
              "tableProperties",
              "tableCellProperties"
            ]
          }
        };
      }
    },
    methods: {
      reorderQuestions(e) {
        let changes = [],
          start = e.from, end = e.to, step = 0;

        if (e.to > e.from) {
          //Subtract one... (1 based index, index is already -1)
          start = e.from;
          end = e.to;
          step = 0; // -1
        } else {
          //Increment 1 (and add 1 for 1 based index)
          start = e.to;
          end = e.from;
          step = 2; // +1
        }

        for (let index = 0; index < this.questions.length; index++) {
          let q = this.questions[index];

          if (index == e.from) {
            if (q.sortOrder != e.to + 1) {
              changes.push({ question: q, sortOrder: e.to + 1 });
            }
          } else if (index >= start && index <= end) {
            //One of the items impacted by the move... Move it up or down
            if (q.sortOrder != (index + step)) {
              changes.push({ question: q, sortOrder: index + step });
            }
          } else {
            //Outside of the ones we are shifting... Just make sure it's 1 based index
            if (q.sortOrder != (index + 1)) {
              changes.push({ question: q, sortOrder: index + 1 });
            }
          }
        }

        for (const change of changes) {
          this.updateQuestionSortOrder(change.question, change.sortOrder);
        }
      },
      updateQuestionSortOrder: function (question, sortOrder) {
        if (question && sortOrder) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          c.questions_Patch(question.id, [new Operation({ op: 'replace', path: '/sortOrder', value: sortOrder })])
            .then(function (updatedQuestion) {
              if (updatedQuestion) {
                delete updatedQuestion.issues;
                Object.assign(question, updatedQuestion);
              }
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            })
        }

      },

      removeQuestion(question) {

        this.$bvModal.msgBoxConfirm('Are you sure?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          if (value) {
            if (question && question.id) {
              c.questions_Delete(question.id)
                .then(function (data) {
                  this.$bvToast.toast(`Sucessfully removed question: "${question.name}"`, {
                    title: `Question removed.`,
                    variant: "success"
                  });
                }.bind(this))
                .catch(function (error) {
                  this.$bvToast.toast(`Failed to remove question: ${error.message}`, {
                    title: `Failed to remove question.`,
                    variant: "danger"
                  });

                }.bind(this));
            }

            this.remove(this.area.questions, question);

          }
        }.bind(this))

      },
      editQuestion(question) {
        this.activeQuestion = question;
        this.$refs.questionModalRef.show();
        // this.$emit('edit-question', question);
      },
      insertQuestion: function () {

        if (this.area.id) {
          let maxOrder = 1
          if (this.questions && this.questions.length) {
            maxOrder = this.questions[this.questions.length - 1].sortOrder + 1;
          }

          let questionDto = new QuestionDto({ id: 0, areaId: this.area.id, prompt: "", hint: "", type: QuestionType.YesNo, sortOrder: maxOrder });

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          c.questions_Post(questionDto)
            .then(function (newQuestion) {
              this.area.questions.push(newQuestion);

              this.$nextTick(() => {
                // let qEl = this.$refs['question_'+newQuestion.id];
                let inputs = this.$el.querySelectorAll(".question .prompt")

                if (inputs && inputs.length) {
                  inputs[inputs.length - 1].select();
                }
              })

            }.bind(this))
            .catch(function (error) {
              console.log(error);
            }.bind(this));
        }

      },
      editActiveConditional: function() {
        this.$refs.filterConditionModalRef.hide();
      },
      editConditions: function (condObj) {
        if (condObj && !condObj.conditions) {
          this.$set(condObj, 'conditions', {});
        }
        this.activeConditional = condObj;

        this.$refs.filterConditionModalRef.show();
      },
      editMoreInfo: function (question) {

        this.activeQuestion = question;
        if (question && !question.moreInfoId) {
          //Brand new moreinfo
          Object.assign(this.activeMoreInfo, { id: 0, html_en: "" });

        } else {

          this.loadingMoreInfo = true;
          Object.assign(this.activeMoreInfo, { id: question.moreInfoId, html_en: "" });

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);
          c.moreInfo_GetById(question.moreInfoId)
            .then(function (moreInfo) {
              Object.assign(this.activeMoreInfo, moreInfo);
              //this.activeMoreInfo = moreInfo;
            }.bind(this))
            .catch(error => {
              console.log(error.response);
            })
            .finally(
              function () {
                this.loadingMoreInfo = false;
              }.bind(this)
            );
        }

        this.$refs.moreInfoModalRef.show();
      },
      saveMoreInfo: function (okCallback) {

        if (this.activeMoreInfo) {

          this.loadingMoreInfo = true;

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);
          var saveFunc = null

          if (!this.activeMoreInfo.id) {
            saveFunc = c.moreInfo_Post(this.activeMoreInfo);
          } else {
            saveFunc = c.moreInfo_Put(this.activeMoreInfo.id, this.activeMoreInfo);
          }

          saveFunc.then(function (moreInfo) {
            this.activeQuestion.moreInfoId = moreInfo.id

            c.questions_Patch(this.activeQuestion.id, [new Operation({ op: 'replace', path: '/moreInfoId', value: moreInfo.id })])
              .then(function (data) {
                okCallback();
              }.bind(this))
              .catch(error => {
                console.log(error.response);
              });
          }.bind(this))
            .catch(error => {
              console.log(error.response);
            })
            .finally(
              function () {
                this.loadingMoreInfo = false;

              }.bind(this)
            );

        } else {
          okCallback();
        }
      },
      removeMoreInfo: function (question, okCallback) {
        if (question && question.moreInfoId) {
          //Remove the association...
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);
          c.questions_Patch(question.id, [new Operation({ op: 'replace', path: '/moreInfoId', value: null })])
            .then(function (data) {
              Object.assign(question, data);

              okCallback();
            }.bind(this))
            .catch(error => {
              console.log(error.response);
            });
        }

        //this.$refs.moreInfoModalRef.show();
      },
      remove(array, value) {
        let index = array.indexOf(value);
        array.splice(index, 1);
      },
      onInsert(event, items) {
        items.splice(event.index, 0, event.data);
      },
      onDrop(event, items) {
        items.push(event.data);
      },
      // async loadQuestions(areaId) {
      //   var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      //   c.questions_GetAll(areaId)
      //     .then(function(questions) {
      //       this.questions = questions;
      //     }.bind(this))
      //     .catch(function(error){
      //       console.log(error);
      //     }.bind(this));
      // }
    },
    watch: {
    },
    created() {
    }
  });
</script>

<style lang="scss">
  .question {
    &.has-filter {
      //border-left: 4px solid #0d74b0;
      //background-color: honeydew;
      // border-top: 2px solid #5cdd5c;
      // border-bottom: 2px solid #5cdd5c;
    }

    input, select {
      background-color: transparent;
      padding: 0;
      /* line-height: 1em; */
      height: 1.5em;
      border: 0;
      border-bottom: 1px dashed #BBB;
      border-radius: 0;
    }

    input[type="File"] {
      height: 2em;
      line-height: 1.5em;
    }
  }

  .question-hint {
    font-size: 0.7em;
    color: #999;
  }
  // .question-conditions {
  //   border-top:3px solid #5cdd5c;
  //   background-color: #f0fff0;
  // }
</style>
