import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import App from "./App.vue";
import axios from 'axios'
import { InteractionRequiredAuthError } from "@azure/msal-common";

import router from "./router";
import ProjectHub from './code/ProjectHub'

import _ from "lodash";
import VueLodash from "vue-lodash";

import BootstrapVue from "bootstrap-vue";
import VueNumeralFilterInstaller from 'vue-numeral-filter';
import VueMoment from "vue-moment";
import i18n from "./i18n"

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { default as msalPlugin, msalInstance } from "vue-msal-browser";
const msalConfig = {
  auth: {
    tenant: '816b6260-a1cc-4386-9fa5-5cb6a0840d72',
    clientId: process.env.VUE_APP_SSO_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/816b6260-a1cc-4386-9fa5-5cb6a0840d72',
    scopes: ['api://' + process.env.VUE_APP_SSO_CLIENT_ID + '/Checklist.Read']
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
    //storeAuthStateInCookie: true, // set to true for IE 11
  },
  //graph: {
  //  url: '<your-graph-api-url>',
  //  scopes: '<your-graph-api-scopes>',
  //  response_type: "blob"
  //},
  mode: "popup"
}
Vue.use(msalPlugin, msalConfig);


import { store } from './stores/store'
import context from './stores/context'

Vue.config.productionTip = false
Vue.prototype.$http = axios

Vue.use(ProjectHub)
Vue.use(BootstrapVue);
Vue.use(VueLodash);
Vue.use(VueNumeralFilterInstaller, { });
Vue.use(VueMoment);
  
axios.interceptors.request.use(req => {
  req.headers.Locale = store.getters.locale;

  if (Vue.prototype.$projectHub) {
    //Seed the known SignalR connection/group Ids with each request.
    req.headers.SnlrGroupId = Vue.prototype.$projectHub.GroupId();
    req.headers.SnlrConnectionId = Vue.prototype.$projectHub.ConnectionId();

    if (context.state.msalAccessToken)
      req.headers.Authorization = `Bearer ${context.state.msalAccessToken}`
  }
  
  // Important: request interceptors **must** return the request.
  return req;
});
axios.interceptors.response.use(res => {
  return res;
}, error => {
  if (401 === error.response.status) {

    if (error.response.config.url == '/account/logout' || error.response.config.url == '/account/login' || error.response.config.url == '/account/context') {
      //Dont keep redirecting them if they are already on the login page
      return Promise.resolve();
    } else {
      store.dispatch('context/restoreContext')
       .catch(({ error }) => {
          router.push({ name: 'login', query: { redirect: router.currentRoute.fullPath } });
       });
      //store.dispatch('context/logout')

      //context.actions.logout(context.mutations, context.state);
      //router.push({ name: 'login', query: { redirect: router.currentRoute.fullPath } });
      return Promise.reject(error)
    }

  } else {
    return Promise.reject(error);
  }
});

router.beforeEach((to, from, next) => {

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  // Set active acccount on page load
  /*if (msalInstance) {
    msalInstance.handleRedirectPromise()
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.error(err);
      });


    const accounts = msalInstance.getAllAccounts();
    if (accounts && accounts.length > 0) {
      msalInstance?.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event) => {
      // set active account after redirect
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event?.payload?.account;
        msalInstance?.setActiveAccount(account);
      }
    }, error => {
      console.log('error', error);
    });

    console.log('get active account', msalInstance.getActiveAccount());

    // handle auth redired/do all initial setup for msal
    msalInstance.handleRedirectPromise().then(authResult => {
      // Check if user signed in 
      const account = msalInstance.getActiveAccount();
      if (!account) {
        // redirect anonymous user to login page 
        msalInstance.loginRedirect();
      }
    }).catch(err => {
      // TODO: Handle errors
      console.log(err);
    });
     
  }*/


  
  //@ts-ignore
  const init = store.state.context.initialized;

  if (!init) {
     store.dispatch('context/restoreContext')
       .catch(({ error }) => {

          if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails

          //return await msalInstance.acquireTokenPopup(request).catch(error => {
          //  if (error instanceof InteractionRequiredAuthError) {
          //    // fallback to interaction when silent call fails
          //    return msalInstance.acquireTokenRedirect(request)
          //  }
          //});
          }

        //Not authenticated
         if (error && error.status != 401) {
          console.log(error);
        }
      })
      .finally(() => {
        const isAuth = store.getters["context/isAuthenticated"];
        const isAdmin = store.getters["context/isAdmin"];

        const loginRoute = { name: 'login', query: null as any};
        if (to.path  != '/') {
          loginRoute.query = { redirect: to.path };
        }

        if (to.name !== 'login' && !isAuth) {
          next(loginRoute)
        } else if (to.path && to.path.toLowerCase().startsWith('/admin') && !isAdmin) {
          next({ name: 'not-authorized', query: { path: to.path } }) 
        } else if (to.name === 'login' && isAuth) {
          const redirect = to.query['redirect'] as string | undefined;
          if (redirect) {
            next({ path: redirect });
          } else {
            next({ name: 'home' })
          }
        } else {
          next();
        }
      })
  } else {
    const isAuth = store.getters["context/isAuthenticated"];
    const isAdmin = store.getters["context/isAdmin"];

    if (to.name !== 'login' && !isAuth) {
      next({ name: 'login', query: { redirect: to.path } })
    } else if (to.path && to.path.toLowerCase().startsWith('/admin') && !isAdmin) {
      next({ name: 'not-authorized', query: { path: to.path } }) 
    } else if (to.name === 'login' && isAuth) {
      const redirect = to.query['redirect'] as string | undefined;
      if (redirect) {
        next({ path: redirect });
      } else {
        next({ name: 'home' })
      }
    } else {
      next();
    }
  }
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
