<template>
  <div class="row question" :class="{'has-filter': hasFilter, 'altered': isAltered}">
    <div :class="isAttachmentQuestion ? 'col-8' : 'col-10'">
      <b-button v-if="hasIssues"
                size="sm"
                class="issues-btn"
                variant="outline-secondary"
                @click.prevent="$store.commit('selectQuestion', {question: question})">
        <b-badge :variant="issuesOutstandingCount ? 'danger' : 'secondary'">{{issuesOutstandingCount}}</b-badge>
        &nbsp;&nbsp;
        <FormatListChecksIcon></FormatListChecksIcon>
      </b-button>
      <div class="prompt">
        <a href="" @click.prevent="$store.commit('selectQuestion', {question: question})">{{question.prompt}}</a>      
        <CommentQuestionIcon v-if="hasMoreInfo" class="more-info-btn" @click.prevent="$store.dispatch('fetchMoreInfo', question.moreInfoId)"></CommentQuestionIcon>
      </div>
      <div class="hint" v-if="question.hint">
        {{question.hint}}
      </div>
    </div>
    <div class="col-2" v-if="question.type == QuestionType.YesNo || question.type == QuestionType.YesNoNA">
      <yes-no-input v-model="question.value" :disabled="readonly" :allowNA="question.type == QuestionType.YesNoNA" @input="valueChanged($event)"></yes-no-input>
    </div>
    <div class="col-2" v-else-if="question.type == QuestionType.Textbox">
      <input type="text" class="form-control mb-1" :disabled="readonly" v-model.lazy.trim="question.textValue" @change="textValueChanged($event.target.value)">
    </div>
    <div class="col-2" v-else-if="question.type == QuestionType.LookupList">
      <LookupListInput v-if="question.lookupListId" :lookupListId="question.lookupListId" :disabled="readonly" v-model="question.textValue" @input="textValueChanged($event)"></LookupListInput>
      <span v-else>Invalid Lookup List</span>
    </div>
    <div class="col-4" v-else-if="question.type == QuestionType.FileUpload">
      <input type="file" class="form-control" multiple accept="image/*" :disabled="readonly" @change="fileUploadChanged($event)">
    </div>
    <div class="col-2" v-else>
      Invalid Type
    </div>
    <question-attachments v-if="question && question.type == QuestionType.FileUpload" 
      :question="question" 
      :readonly="readonly"
      @remove-attachment="removeAttachment"></question-attachments>
  </div>
</template>

<script>
import Vue from 'vue';
import { Client, Operation, QuestionAttachmentDto, QuestionDto, QuestionType } from "../code/EngineeringChecklist.Api";
import YesNoInput from './inputs/YesNoInput.vue';
import LookupListInput from './inputs/LookupListInput.vue';
import FormatListChecksIcon from 'vue-material-design-icons/FormatListChecks.vue';
import CommentQuestionIcon from 'vue-material-design-icons/CommentQuestion.vue';
import QuestionAttachments from './QuestionAttachments.vue';

export default Vue.extend({
  name: 'Question',
  components: {
    YesNoInput,
    LookupListInput,
    FormatListChecksIcon,
    CommentQuestionIcon,
    QuestionAttachments
  },
  props: {
    parent: {type: Object, required: true},
    question: {type: Object, required: true},
    readonly: {type: Boolean, required: true}
  },
  data() {
    return {
      QuestionType,
      patches: []
    }
  },
  computed: {
    checklistProjectId: function() {
      return this.$route.params.projectId;
      //return this.$store.state.context.project.id;
    },
    hasMoreInfo: function () {
      return this.question && this.question.moreInfoId;
    },
    hasIssues: function() {
      return this.question && this.question.issues && this.question.issues.length;
    },
    isAttachmentQuestion: function() {
      return this.question && this.question.type == QuestionType.FileUpload;
    },
    isAltered: function() {
      return this.question && this.question.updatedOn ? this.question.updatedOn.isAfter(this.parent.parent.signOffAt) : false;
    },
    issuesOutstanding: function() {
      return _.filter(this.question.issues, {resolved:false}) || this.question.issues;
    },
    issuesOutstandingCountVal: function() {
      return this.issuesOutstanding ? this.issuesOutstanding.length : 0;
    },
    issuesOutstandingCount: function() {
      return this.question ? this.question.issuesOutstandingCount : 0;
    },
    hasFilter: function() {
      return this.question && this.question.conditionsObj && this.question.conditionsObj.children && this.question.conditionsObj.children.length
    },
    conditionsObj: function() {
      return this.question.conditionsObj;
    },
    hierarchyVal: function() {
      if (this.parent) {
        return `${this.parent.hierarchy} > ${this.parent.name}`;
      } else {
        return '';
      }
    }
  },
  methods: {
    valueChanged: function(value) {
      if (value === 0 ) {
        this.$store.commit('selectQuestion', {question: this.question, issueEntry: true, issueRequired: true})
      }

      this.patch('replace', '/value', value);
    },
    textValueChanged: function(value) {
      this.patch('replace', '/textValue', value);
    },
    removeAttachment: function(attachment) {

      this.$bvModal.msgBoxConfirm('Are you sure?', {
              title: 'Please Confirm',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true })
          .then(function (value) {

            if (value) {
              let c = new Client(process.env.VUE_APP_API_URL, this.$http);
                c.questions_RemoveAttachment(this.question.id, attachment.id)
                  .then(function() {
                    if (attachment) {
                      this.question.attachments = _.filter(this.question.attachments, function(a) { return a.id != attachment.id; });
                    }
                  }.bind(this))
                  .catch(function(error) {
                    this.$bvToast.toast(error || error.message, {
                      title: `Error occurred while removing attachment`,
                      variant: "danger"
                    });
                    console.log(error);
                  }.bind(this));
          }
        }.bind(this));

      

    },
    fileUploadChanged: function(evt) {

      let files = evt.target.files;
      if (files && files.length) {
        if(!this.question.attachments)
        {
          this.$set(this.question, 'attachments', []);
        }

        for (let i = 0; i < files.length; i++) {
          this.getBase64(files[i])
            .then(function(encoded){

              let idx = this.question.attachments.length;
              this.question.attachments.push( 
                new QuestionAttachmentDto({
                  id: 0, 
                  questionId: this.question.id, 
                  fileName: files[i].name, 
                  fileType: files[i].type, 
                  attachmentData: encoded,
                  key: performance.now() 
                })
              );

              let c = new Client(process.env.VUE_APP_API_URL, this.$http);
              c.questions_AddAttachment(this.question.id, this.checklistProjectId, this.question.attachments[idx])
                .then(function(attachment) {
                  if (attachment) {
                    this.$set(this.question.attachments, idx, attachment);
                  }

                  this.$bvToast.toast(`File ${attachment.fileName} added`, {
                    title: `Attachment added successfully`,
                    variant: "success"
                  });
                }.bind(this))
                .catch(function(error) {
                  this.$bvToast.toast(error || error.message, {
                    title: `Error occurred while saving attachment`,
                    variant: "danger"
                  });
                  console.log(error);
                }.bind(this));
              

            }.bind(this))
            .catch(function(error){
              console.log(error);
            })
        }
      }
    },
    getBase64: function(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
          if ((encoded.length % 4) > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
        };
        reader.onerror = error => reject(error);
      });
    },
    patch: function(op, path, value) {
      if (this.readonly) return;

      let patch = _.find(this.patches, {op: op, path: path });
      
      if (patch) {
        patch.value = value;
      } else {
        patch = new Operation({op: op, path: path, value: value});
        this.patches.push(patch);
      }

    },
    processPatches: function() {

      if (this.patches && this.patches.length) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        c.questions_Patch(this.question.id, this.patches)
          .then(function(updatedQuestion){
            if (updatedQuestion) {
              delete updatedQuestion.issues;
              Object.assign(this.question, updatedQuestion);
            }

            this.patches = [];
          }.bind(this))
          .catch(function(error){

            this.$bvToast.toast(error.message, {
              title: `Error occurred while saving question`,
              variant: "danger"
            });

          })

      }
    },

  },
  watch: {
    $route: function (to, from) {
      this.debouncedProcessPatches.flush();
    },
    hierarchyVal: function(newValue, oldValue) {
      this.question.hierarchy = newValue;
      for (const issue of this.question.issues) {
        this.$set(issue, 'hierarchy', `${newValue} > ${this.question.prompt}`);
      }
    },
    issuesOutstandingCountVal: function(newValue, OldValue)
    {
      this.question.issuesOutstandingCount = newValue;
    },
    patches: function(newValue, oldValue) {
      if (newValue && newValue.length) {
        this.debouncedProcessPatches();
      }
    },
    conditionsObj: {
      deep: true,
      handler: function(newValue, OldValue)
      {
        this.question.conditions = JSON.stringify(newValue)
        this.patch('replace', '/conditions', this.question.conditions);
      }
    }
  },
  created() {

    this.debouncedProcessPatches = _.debounce(this.processPatches, 100, {
      leading: false,
      trailing: true
    });
  }
});
</script>

<style lang="scss" scoped>
  .question {
    a {
      color: #333;
      //font-weight: bold;
      //font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
    

    .more-info-btn {
      top: 2px;
      color: #007bff;
      height: 20px;
      width: 24px;

      &:hover {
        cursor: pointer;
        color: #0069d9;
      }
    }

    .issues-btn {
      float: right;
      padding: 2px 4px;
    }
    &:hover {
      background-color: #EEE !important;
    }
    &.altered {
      //outline: 3px solid #ffda8b;
      //border-left: 5px solid #ffda8b;
      background-color: #ffe5ae;
    }
  }
</style>