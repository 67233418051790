import _ from 'lodash';

interface IQueryBuilderGroup {
    logicalOperator: 'all' | 'any'
    type: 'query-builder-group'
    children: Array<IQueryBuilderRule | IQueryBuilderGroup>
}
interface IQueryBuilderRule {
    type: 'query-builder-rule'
    query: IQueryRuleQuery
}
interface IQueryRuleQuery {
        // "children":[{"type":"query-builder-rule","query":{"rule":"radio-field","operator":"Equals","operand":"Location","value":"Core"}}]}
    rule: string;
    operator: 'Equals' | 'Not Equals';
    operand: string;
    value: string | boolean
}

export interface IConditionalRule {
    label: string;
    value: string | boolean
}

export interface IConditionalObject {
    conditions: string
    conditionsObj: IQueryBuilderGroup
}

/**
 * 
 * @param condObj A conditional object, something that might require being hidden
 * @param rules Current rules/variables that conditional objects will be compared against
 * 
 * @returns Returns true if the object passes validation; false if the object should be filtered/hidden.
 */
export function filterObject(condObj: IConditionalObject, rules: Array<IConditionalRule>) : boolean {
  if (!condObj) {
    return false; //Don't filter it
  } else if (condObj.conditions && !condObj.conditionsObj) {
    condObj.conditionsObj = JSON.parse(condObj.conditions) || {}
  }

  return processConditionGroup(condObj.conditionsObj, rules);
}



function processConditionGroup(qbGroup: IQueryBuilderGroup, rules: Array<IConditionalRule>) : boolean {

  let result = true; //Don't filter by default

  if (!qbGroup) {
    return result;
  }

  if (qbGroup.children && qbGroup.children.length) {
    for (let index = 0; index < qbGroup.children.length; index++) {
      const child = qbGroup.children[index];
      
      if (child.type == 'query-builder-rule') {
        result = processConditionRule(child.query, rules)
      } else {
        result = processConditionGroup(child, rules)
      }

      if (qbGroup.logicalOperator == 'all' && !result) {
        //All children have to evaluate to true in order to pass validation, any false values will disquality object.
        break;
      } else if (qbGroup.logicalOperator == 'any' && result) {
        //Any child has to evaluate to true in order to pass validation, break out on the first one that passes validation
        break;
      }
    }
  }

  return result;
}


/**
 * 
 * @param qbRule 
 * @param rules 
 * 
 * @returns Returns true if it passes validation criteria; false if it fails to meet the validation criteria
 */
function processConditionRule(qbRule: IQueryRuleQuery, rules: Array<IConditionalRule>) : boolean {
  // {"logicalOperator":"all",
  // "children":[{"type":"query-builder-rule","query":{"rule":"radio-field","operator":"Equals","operand":"Location","value":"Core"}}]}

  let result = true; //Don't filter by default

  if (!qbRule) {
    return result;
  }

  //Do rules with the same names exist in both lists?
  const rule = _.find(rules, {label: qbRule.operand})
  if (rule) {

    //Is the same value found on both sides?
    const intersect = _.intersection(
      _.isArray(rule.value) ? rule.value : [rule.value],
      _.isArray(qbRule.value) ? qbRule.value : [qbRule.value] 
    );

    if (qbRule.operator == 'Equals' && intersect && intersect.length) {
      //We found a matching value, this passes validation 
      result = true;
    } else if (qbRule.operator != 'Equals' && !(intersect && intersect.length)) {
      //No matches, but we didn't want to find any...
      return true;
    } else {
      //Filter this, a matching rule was found but the values did not meet the criterion
      //Either we didn't find a match, or operator is "Not Equals' and we found a match
      result = false;
    }
  }

  return result;
}
