<template>
  <div class="issues-list">
    <b-form-radio-group
      class="float-right"
      id="btn-radios-1"
      button-variant="outline-primary"
      v-model="onlyUnResolved"
      buttons
    >
      <b-form-radio :value="false">All Issues</b-form-radio>
      <b-form-radio :value="true">Oustanding Issues Only</b-form-radio>
    </b-form-radio-group>

    <div class="w-25 mx-2 float-right">
      <b-form-input list="locations-list" id="location-filter" debounce="500" v-model="locationFilter" placeholder="Location Filter"></b-form-input>
      <b-form-datalist id="locations-list" :options="issueLocations"></b-form-datalist>
    </div>    
    <h2 class="d-inline-block mr-5">Project Issues</h2>
    <!-- <b-form-radio-group
      class=""
      id="btn-radios-2"
      button-variant="outline-primary"
      v-model="issuesViewMode"
      buttons
    >
      <b-form-radio value="list"><ViewListIcon></ViewListIcon></b-form-radio>
      <b-form-radio value="grid"><ViewModuleIcon></ViewModuleIcon></b-form-radio>
      <b-form-radio value="masonry"><ViewDashboardVariantIcon></ViewDashboardVariantIcon></b-form-radio>
    </b-form-radio-group> -->

    <b-overlay class=" mt-3" :show="loading" rounded="sm">
      <div class="container-fluid" v-if="issuesViewMode=='list'">
        <div class="row issue-header-row">
          <div class="col-1 px-1 image-col">Image</div>
          <div class="col-4 px-1 notes-col">Notes</div>
          <div class="col-4 px-1 location-col">Location</div>
          <div class="col-2 px-1 timestamp-col">Time Stamps</div>
          <div class="col-2 px-1 actions-col"></div>
        </div>
        <div class="row issue-row" v-for="issue in filteredIssues" :key="'issue_'+issue.id" :class="{resolved: issue.resolved}">
          <div class="col-1 px-1 image-col">
            <img v-show="issue.imageUrl" :src="issue.imageUrl" @click="$store.commit('setImageUrl', issue.imageUrl)" style="max-width:100%">
          </div>
          <div class="col-4 px-1 notes-col">
            {{issue.notes}}
          </div>
          <div class="col-4 px-1 location-col">
            <a href="#" @click.prevent="$store.commit('selectQuestion', {question: issue.parent})">{{issue.hierarchy}}</a>
          </div>
          <div class="col-2 px-1 timestamp-col">
            <div class="px-1 ts-created">Created: {{issue.createdBy}} | {{ issue.createdOn | moment("DD-MMM-YYYY hh:mm a") }}</div>
            <div class="px-1 ts-updated">Updated: {{issue.updatedBy}} | {{ issue.updatedOn | moment("DD-MMM-YYYY hh:mm a") }}</div>
          </div>
          <div class="col-1 px-1 actions-col text-right" v-if="!readonly && authLevel > 0">
            <b-button variant="secondary" v-if="issue.resolved" size="sm" @click="resolveIssue(issue, false)">Un-Resolve</b-button>
            <b-button variant="success" v-else size="sm" @click="resolveIssue(issue, true)">Resolve</b-button>
          </div>
        </div>
      </div>
      <!-- <div class="container-fluid" v-else-if="issuesViewMode=='grid'">
        <div class="row" >
          <div class="col-4 issue-block p-1" v-for="issue in filteredIssues" :key="'bissue_'+issue.id">
            <div class="row ">
              <div class="col-12 "><b-button variant="link" @click="$store.commit('selectQuestion', {question: issue.parent})">{{issue.hierarchy}}</b-button></div>
            </div>
            <div class="row ">
              <div class="col-6 image-col">
                <img v-show="issue.imageUrl" :src="issue.imageUrl" @click="$store.commit('setImageUrl', issue.imageUrl)" style="max-width:100%">
              </div>
              <div class="col-6 col-content">
                {{issue.notes}}
              </div>
            </div>
            <div class="footer">
              <div class="ts-created">Created: pbeaulieu | 01-Jan-2021 9:40 am</div>
              <div class="ts-updated">Updated: pbeaulieu | 01-Jan-2021 9:45 am</div>
            </div>
          </div>
        </div>
      </div>
      <masonry v-else :cols="4" :gutter="10">
        <div class="issue-card" v-for="issue in filteredIssues" :key="'aissue_'+issue.id">
          <div class="issue-header">
            <b-button class="float-right" variant="secondary" v-if="issue.resolved" size="sm" @click="resolveIssue(issue, false)">Un-Resolve</b-button>
            <b-button class="float-right" variant="success" v-else size="sm" @click="resolveIssue(issue, true)">Resolve</b-button>

            <b-button variant="link" @click="$store.commit('selectQuestion', {question: issue.parent})">{{issue.hierarchy ? issue.hierarchy : 'Issue' + issue.id }}</b-button>
          </div>
          <div class="resolved" v-if="issue.resolved"><CheckCircleIcon></CheckCircleIcon></div>
          <div class="issue-content">
            <div><b-button variant="link" @click="$store.commit('selectQuestion', {question: issue.parent})">{{issue.hierarchy}}</b-button></div>
            <img :src="issue.imageUrl" v-if="issue.imageUrl" />
            <p class="notes">{{issue.notes}}</p>

          </div>
          <div class="issue-footer">
            <div>Created: pbeaulieu | 01-Jan-2021 9:40 am</div>
            <div>Updated: pbeaulieu | 01-Jan-2021 9:45 am</div>
          </div>
        </div>
      </masonry> -->

      <div v-if="!filteredIssues.length && locationFilter"><h4 class="text-center p-3">No issues for filter criteria <a href="#" @click.prevent="locationFilter=''">clear location filter</a></h4></div>
      <div v-else-if="!filteredIssues.length && allIssues.length && onlyUnResolved"><h4 class="text-center p-3">No Unresolved issues <a href="#" @click.prevent="onlyUnResolved=false">Show all issues</a></h4></div>
      <div v-else-if="!filteredIssues.length"><h4 class="text-center p-3">No issues Defined</h4></div>

    </b-overlay>
  </div>

</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Client, IssueDto, Operation } from "../code/EngineeringChecklist.Api";
// import VueMasonry from 'vue-masonry-css'

// import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
// import CheckCircleIcon from 'vue-material-design-icons/CheckCircle.vue';
// import ViewListIcon from 'vue-material-design-icons/ViewList.vue';
// import ViewModuleIcon from 'vue-material-design-icons/ViewModule.vue';
// import ViewDashboardVariantIcon from 'vue-material-design-icons/ViewDashboardVariant.vue';



// Vue.use(VueMasonry);

export default Vue.extend({
  name: 'ProjectIssues',
  components: {
    // CheckCircleIcon,
    // ViewListIcon,
    // ViewModuleIcon,
    // ViewDashboardVariantIcon,
  },
  props: {
    project: {type: Object, required: true},
    readonly: {type: Boolean, required: true }
  },
  data() {
    return {
      issues: [],
      loading: false,
      issuesViewMode: 'list',
      onlyUnResolved: true,
      locationFilter: ''
    }
  },
  computed: {
    ...mapGetters('context', ['authLevel']),
    allIssues: function() {
      let allIssues = [];

      this.project.checklists.forEach(checklist => {
        checklist.areas.forEach(area=> {
          if (!area.visible) return;
          
          area.questions.forEach(question => {
            if (!question.visible) return;

            question.issues.forEach(issue => {
              allIssues.push(issue);
            });
          });
        });
      });

      return allIssues;
    },
    issueLocations: function() {
      let locations = []

      this.allIssues.forEach(issue => {
        locations.push(issue.hierarchy);
      });

      return _.sortedUniq(locations);
    },
    filteredIssues: function() {
      if (this.onlyUnResolved || this.locationFilter) {
        return _.filter(this.allIssues, function(issue) {

          if (this.onlyUnResolved && issue.resolved) {
            return false;
          } else if (this.locationFilter && issue.hierarchy.indexOf(this.locationFilter) == -1) {
            return false;
          }
          return true;
        }.bind(this));
      } else {
        return this.allIssues;
      }
    }
  },
  methods: {
    resolveIssue(issue, resolved) {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);
      this.loading = true;
      c.issues_Patch(issue.id, [new Operation({op:'replace', path:'/resolved', value: !!resolved })])
        .then(function(updatedIssue){
          if (updatedIssue) {
            Object.assign(issue, updatedIssue);
          }
        }.bind(this))
        .catch(error => {
          console.log(error.response);
        })
        .finally(
          function() {
            this.loading = false;
          }.bind(this)
        );
    },
    removeIssue: function(issue) {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);
      this.loading = true;
      c.issues_Delete(issue.id)         
        .then(function(newIssues){
          let index = _.findIndex(this.question.issues, issue);
          if (index >= 0) {
            this.question.issues.splice(index,1);
          }
        }.bind(this))
        .catch(error => {
          console.log(error.response);
        })
        .finally(
          function() {
            this.loading = false;
          }.bind(this)
        );
    },
    loadIssues: function() {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      this.loading = true;
      c.issues_GetAll(null, this.project.id) 
        .then(function(newIssues){
          this.issues = newIssues;
        }.bind(this))
        .catch(error => {
          console.log(error.response);
        })
        .finally(
          function() {
            this.loading = false;
          }.bind(this)
        );
    },
  },
  watch: {
  },
  created() {
    // this.loadIssues();
  }
});
</script>

<style lang="scss" scoped>

// .issue-card {
//   position: relative;

//   .card-header {
//     display: none;
//   }
//   &:hover {
//     .card-header {
//       position: absolute;
//       display: block;
//       width: 100%;
//       background-color: #DDDDDDEE;
//     }
//   }
//   img {
//     width: auto;
//     max-width: 100%;
//     max-height: 400px;
//   }
// }
.issue-header-row {
  border-bottom: 1px solid #BBB;
  border-top: 1px solid #BBB;
  font-weight: bold;
}
.issue-row {
  
  min-height: 50px;
  padding: 0.25em 0;
  border-bottom: 1px solid #BBB;
  &.resolved{
    background-color: #00800030;
  }
  .image-col {
    text-align: center;
    img {
      max-height: 75px;
      max-width: 100%;
    }
  }  
  // .location-col{}
  .timestamp-col{
    font-size: 0.7em;
  }
}
.issue-block {
  position: relative;
  border: 1px solid #000;
  box-sizing: border-box;

  .image-col{
    text-align: center;
    img{
      max-height: 250px;
      max-width: 100%;
    }
  }
  .col-content{margin-bottom: 3em;}
  .footer {
    position: absolute;
    bottom: 0;
    left: 0; 
    right: 0;

    padding-left: 50%;
    text-align: center;
  }
  .ts-created,
  .ts-updated {
    font-size: 10px;
  }
}

.issue-card {
  position: relative;
  min-height: 100px;
  border: 1px solid #AAA;
  margin: 5px 0;
  padding: 0.25em;

  .issue-header {
    margin: -0.25em;
    display: none;
  }
  .issue-footer {
    display: none;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
  .issue-content {
    text-align: center;
  }
  .notes {
    padding: 0.5em;
    text-align: left;
  }
  &:hover {
    .issue-header {
      position: absolute;
      display: block;
      width: 100%;
      padding: 0.25em;
      background-color: #DDDDDDEE;
      z-index: 50;
    }
    .issue-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      
      display: block;
      width: 100%;
      background-color: #DDDDDDEE;
      z-index: 50;
    }

  }
  .resolved{
    position: absolute;
    top: -27px;
    right: -6px;

    width: 0; 
    height: 0; 
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    
    border-left: 40px solid #008000B0;
    transform: rotate(-45deg);
    z-index: 5;

    .material-design-icon {
      position: absolute;
      top: -18px;
      right: 6px;
      transform: rotate(45deg);
      width: 2em;
      height: 2em;
      color: white;
    }
  }
  img {
    width: auto;
    max-width: 100%;
    max-height: 400px;
  }
}
</style>