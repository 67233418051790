<template>
  <div class="admin-settings container-fluid py-5">
    <b-tabs v-model="activeTabIndex" vertical pills>
      <b-tab lazy title="Job Templates" active>
        <ManageJobTemplates></ManageJobTemplates>
      </b-tab>
      <b-tab lazy title="Project Templates">
        <ManageProjectTemplates></ManageProjectTemplates>
      </b-tab>
      <b-tab lazy title="Standard Templates">
        <ManageRepetitiveChecklists :isRepetitive="false"></ManageRepetitiveChecklists>
      </b-tab>
      <b-tab lazy title="Repeat Templates" href="#">
        <ManageRepetitiveChecklists></ManageRepetitiveChecklists>
      </b-tab>
      <b-tab lazy title="Lookup Lists">
        <ManageLookupLists></ManageLookupLists>
      </b-tab>
      <b-tab lazy title="Users">
        <manage-users></manage-users>
      </b-tab>
      <b-tab lazy title="Facilities">
        <manage-facilities></manage-facilities>
      </b-tab>
      <b-tab lazy title="Project Types">
        <ManageProjectTypes></ManageProjectTypes>
      </b-tab>
    </b-tabs>
<!--     
    <div class="tabs row">
      <b-nav vertical pills class="col-auto">
        <b-nav-item to="#JobTemplates" :active="$route.hash === '#JobTemplates' || $route.hash === ''">
          Job Templates
        </b-nav-item>
        <b-nav-item to="#ChecklistProjectTemplates" :active="$route.hash === '#ChecklistProjectTemplates'">
          Project Templates
        </b-nav-item>
        <b-nav-item to="#StandardTemplate" :active="$route.hash === '#StandardTemplate'">
          Standard Templates
        </b-nav-item>
        <b-nav-item to="#RepeatTemplates" :active="$route.hash === '#RepeatTemplates'">
          Repeat Templates
        </b-nav-item>
        <b-nav-item to="#LookupLists" :active="$route.hash === '#LookupLists'">
          Lookup Lists
        </b-nav-item>
        <b-nav-item to="#Users" :active="$route.hash === '#Users'">
          Users
        </b-nav-item>
        <b-nav-item to="#Facilities" :active="$route.hash === '#Facilities'">
          Facilities
        </b-nav-item>
        <b-nav-item to="#ProjectTypes" :active="$route.hash === '#ProjectTypes'">
          ProjectTypes
        </b-nav-item>
      </b-nav>
      <div class="tab-content flex-grow-1">
        <div :class="['tab-pane', { 'active': $route.hash === '#JobTemplates' || $route.hash === '' }]" class="px-2">
          <ManageJobTemplates></ManageJobTemplates>
        </div>
        <div :class="['tab-pane', { 'active': $route.hash === '#ChecklistProjectTemplates' }]" class="px-2">
          <ManageProjectTemplates></ManageProjectTemplates>
        </div>
        <div :class="['tab-pane', { 'active': $route.hash === '#StandardTemplate' }]" class="px-2">
          <ManageRepetitiveChecklists :isRepetitive="false"></ManageRepetitiveChecklists>
        </div>
        <div :class="['tab-pane', { 'active': $route.hash === '#RepeatTemplates' }]" class="px-2">
          <ManageRepetitiveChecklists></ManageRepetitiveChecklists>
        </div>
        <div :class="['tab-pane', { 'active': $route.hash === '#LookupLists' }]" class="px-2">
          <ManageLookupLists></ManageLookupLists>
        </div>
        <div :class="['tab-pane', { 'active': $route.hash === '#Users' }]" class="px-2">
          <manage-users></manage-users>
        </div>
        <div :class="['tab-pane', { 'active': $route.hash === '#Facilities' }]" class="px-2">
          <manage-facilities></manage-facilities>
        </div>
        <div :class="['tab-pane', { 'active': $route.hash === '#ProjectTypes' }]" class="px-2">
          <ManageProjectTypes></ManageProjectTypes>
        </div>
      </div>
    </div>    -->
  </div>
</template>

<script>
  import Vue from "vue";
  import { Vuex, mapState, mapMutations, mapGetters } from "vuex";

  import { Client } from "../code/EngineeringChecklist.Api";
  
  import ManageJobTemplates from "../components/admin/ManageJobTemplates.vue";
  import ManageProjectTemplates from "../components/admin/ManageProjectTemplates.vue";
  import ManageRepetitiveChecklists from "../components/admin/ManageRepetitiveChecklists.vue";
  import ManageLookupLists from "../components/admin/ManageLookupLists.vue";
  import ManageUsers from "../components/admin/ManageUsers.vue";
  import ManageFacilities from "../components/admin/ManageFacilities.vue";
	import ManageProjectTypes from "../components/admin/ManageProjectTypes.vue";

export default Vue.extend({
  name: "AdminView",
  components: {
    ManageJobTemplates,
    ManageProjectTemplates,
    ManageRepetitiveChecklists,
    ManageLookupLists,
    ManageUsers,
    ManageFacilities,
		ManageProjectTypes,
  },
  data() {
    return {
      activeTabIndex: 0,
      loading: true,
      project: null,
    };
  },
  computed: {
    checklists: function() {
      return this.project ? this.project.checklists : [];
    }
  },
  methods: {

  },
  watch: {

  },
  created() {

  }
});
</script>

<style lang="scss">
  @import "../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
</style>
