<template>
  <div class="row question-matrix no-gutters" :class="{'altered': isAltered}">
    <div class="col-7">
      <b-button v-if="hasIssues"
                size="sm"
                class="issues-btn"
                variant="outline-secondary"
                @click.prevent="$store.commit('selectQuestion', {question: issuesQuestion, sisterQuestions: questions})">
        <b-badge :variant="issuesOutstandingCountVal ? 'danger' : 'secondary'">{{issuesOutstandingCountVal}}</b-badge>
        &nbsp;&nbsp;
        <FormatListChecksIcon></FormatListChecksIcon>
      </b-button>
      <div class="prompt">
        <a href="" @click.prevent="$store.commit('selectQuestion', {question: leadQuestion, sisterQuestions: questions})">{{leadQuestion.prompt}}</a>      
        <CommentQuestionIcon v-if="hasMoreInfo" class="more-info-btn" @click.prevent="$store.dispatch('fetchMoreInfo', leadQuestion.moreInfoId)"></CommentQuestionIcon>
      </div>
      <div class="hint" v-if="leadQuestion.hint">
        {{leadQuestion.hint}}
      </div>
    </div>
    <div class="col-5 matrix-answers">
      <div class="row no-gutters">
        <div class="col text-center" v-for="(question,idx) in questions" :key="question.id" :class="{'col-even': idx % 2 }">
          <yes-no-input v-if="question.type == QuestionType.YesNo || question.type == QuestionType.YesNoNA"
            v-model="question.value" :disabled="readonly" :allowNA="question.type == QuestionType.YesNoNA" @input="valueChanged(question.id, $event)"></yes-no-input>
          <input v-else-if="question.type == QuestionType.Textbox" 
            type="text" class="form-control mb-1" :disabled="readonly" v-model.lazy.trim="question.textValue" @change="textValueChanged(question.id, $event.target.value)">
          <LookupListInput v-if="question.type == QuestionType.LookupList && question.lookupListId" 
            :lookupListId="question.lookupListId" :disabled="readonly" v-model="question.textValue" @input="textValueChanged(question.id, $event)"></LookupListInput>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Client, Operation, QuestionAttachmentDto, QuestionDto, QuestionType } from "../code/EngineeringChecklist.Api";
import YesNoInput from './inputs/YesNoInput.vue';
import LookupListInput from './inputs/LookupListInput.vue';
import FormatListChecksIcon from 'vue-material-design-icons/FormatListChecks.vue';
import CommentQuestionIcon from 'vue-material-design-icons/CommentQuestion.vue';


export default Vue.extend({
  name: 'QuestionMatrix',
  components: {
    YesNoInput,
    LookupListInput,
    FormatListChecksIcon,CommentQuestionIcon
  },
  props: {
    parent: {type: Object, required: true},
    leadQuestion: {type: Object, required: true},
    questions: {type: Array, default: null},
    readonly: {type: Boolean, required: true}
  },
  data() {
    return {
      QuestionType,
      patches: {}
    }
  },
  computed: {
    checklistProjectId: function() {
      return this.$route.params.projectId;
      //return this.$store.state.context.project.id;
    },
    hasMoreInfo: function () {
      return this.leadQuestion && this.leadQuestion.moreInfoId;
    },
    allIssues: function() {
      let matrixIssues = { issues: 0, unresolved: 0, issuesQuestion: null };

      if (this.questions) {
        matrixIssues = this.questions.reduce((acc, q) => {
          if(q) {
            let unresolved = 0;
            
            if (q.issues && q.issues.length) {
              acc.issues += q.issues.length;
              acc.issues += q && q.issues ? q.issues.length : 0;
              
              unresolved = q.issues.filter(i => !i.resolved).length;
              acc.unresolved += unresolved;
            }
  
            if (unresolved != q.issuesOutstandingCount) {
              this.$set(q, 'issuesOutstandingCount', unresolved);
            }
  
            if (unresolved && acc.issuesQuestion === null) {
              acc.issuesQuestion = q;
            }
          }
          
          return acc;
        }, matrixIssues);
      }

      return matrixIssues;
    },

    issuesQuestion: function() {
      return this.allIssues ? this.allIssues.issuesQuestion || this.leadQuestion : null;
    },
    issuesCount: function() {
      return this.allIssues ? this.allIssues.issues : 0;
    },
    hasIssues: function() {
      return this.issuesCount > 0;
    },
    issuesOutstandingCountVal: function() {
      return this.allIssues ? this.allIssues.unresolved : 0;
    },
    issuesOutstandingCount: function() {
      return this.leadQuestion ? this.leadQuestion.issuesOutstandingCount : 0;
    },
    isAltered: function() {
      return _.some(this.questions, q => q.updatedOn && q.updatedOn.isAfter(this.parent.parent.signOffAt));
    },
    hasFilter: function() {
      return this.leadQuestion && this.leadQuestion.conditionsObj && this.leadQuestion.conditionsObj.children && this.leadQuestion.conditionsObj.children.length
    },
    conditionsObj: function() {
      return this.leadQuestion.conditionsObj;
    },
    hierarchyVal: function() {
      if (this.parent) {
        return `${this.parent.hierarchy} > ${this.parent.groupName}`;
      } else {
        return '';
      }
    }
  },
  methods: {
    valueChanged: function(key, value) {
      if (value === 0 ) {
        let mq = _.find(this.questions, {id: key});
        this.$store.commit('selectQuestion', {question: mq, issueEntry: true, issueRequired: true, sisterQuestions: this.questions});
      }

      this.patch(key,'replace', '/value', value);
    },
    textValueChanged: function(key, value) {
      this.patch(key,'replace', '/textValue', value);
    },
    
    patch: function(key, op, path, value) {
      let patch = _.find(this.patches[key], {op: op, path: path });
      
      if (patch) {
        patch.value = value;
      } else {
        patch = new Operation({op: op, path: path, value: value});
        this.patches[key].push(patch);
      }
      this.debouncedProcessPatches();
    },
    processPatches: function() {

      for (const key in this.patches) {
        if (this.patches.hasOwnProperty(key) && this.patches[key].length) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);
          const patches = this.patches[key];

          c.questions_Patch(key, patches)
            .then(function(updatedQuestion){
              if (updatedQuestion) {

                let qIdx = _.findIndex(this.questions, {id: updatedQuestion.id});
                if (qIdx > -1) {
                  delete updatedQuestion.issues;
                  //Object.assign(this.questions[qIdx], updatedQuestion);
                  this.$set(this.questions[qIdx], updatedQuestion);
                }
              }

              this.$set(this.patches, key, []);
            }.bind(this))
            .catch(function(error){

              this.$bvToast.toast(error.message, {
                title: `Error occurred while saving question`,
                variant: "danger"
              });

          }.bind(this));
        }
      }
      
    },
  },
  watch: {
    $route: function (to, from) {
      this.debouncedProcessPatches.flush();
    },
    questions: {
      immediate: true,
      handler: function(newValue, OldValue)
      {
        let patches = {};
        newValue.forEach((mq, idx) => {
          patches[mq.id] = this.patches[mq.id] || [];
        });

        this.patches = patches;
      }
    },
    hierarchyVal: function(newValue, oldValue) {
      this.leadQuestion.hierarchy = newValue;
      for (const issue of this.leadQuestion.issues) {
        this.$set(issue, 'hierarchy', `${newValue} > ${this.leadQuestion.prompt}`);
      }
    },
    patches: function(newValue, oldValue) {
      if (newValue && newValue.length) {
        this.debouncedProcessPatches();
      }
    },
  },
  created() {

    this.debouncedProcessPatches = _.debounce(this.processPatches, 100, {
      leading: false,
      trailing: true
    });
  }
});
</script>

<style lang="scss" scoped>
  .question-matrix {
    a {
      color: #333;
      //font-weight: bold;
      //font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
    

    .more-info-btn {
      top: 2px;
      color: #007bff;
      height: 20px;
      width: 24px;

      &:hover {
        cursor: pointer;
        color: #0069d9;
      }
    }

    .issues-btn {
      float: right;
      padding: 2px 4px;
    }
    &:hover {
      background-color: #EEE !important;
    }
    .col-even {
      background-color: #3333331A;
    }
    &.altered {
      //outline: 3px solid #ffda8b;
      //border-left: 5px solid #ffda8b;
      background-color: #ffe5ae;
    }
  }
  .matrix-answers > .row {
    height: 100%;
  }
</style>