<template>
  <drag tag="div" class="row" handle=".area-drag-handle" :data="area" @cut="$emit('drop', area)" type="area">
    <div class="col-12">
      <div class="card my-1">
        <div class="drag-handle area-drag-handle card-header p-1">
          <b-button class="" variant="link" size="sm" @click="$emit('edit', area)"><h5 class="d-inline">{{area.name}}</h5></b-button>
          <div class="add-button float-right">
            <vSelect
              class="area-grouping"
              :input="groupName"
              :options="areaGroupNames"
              placeholder="Area Group Name"
              taggable
              @option:created="$emit('set-group-name', area, $event)"
              @option:selected="$emit('set-group-name', area, $event)"
            >
              <template #no-options="{ search, searching, loading }">
                <span>No groups created. <br /> Enter a value to create one</span>
              </template>
            </vSelect>
            <b-button class="btn-filter-question mx-2" variant="primary" size="sm" @click="$emit('filter', area)" v-if="checklistRules && checklistRules.length">Filter</b-button>
            <!-- <b-button class="btn-add-question mx-2" variant="primary" size="sm" @click="showNewQuestionDialog(item)">Add Question</b-button> -->
            <b-button class="btn-remove-area" variant="danger" size="sm" title="Remove Area" @click="$emit('remove', area)"><TrashCanIcon /></b-button>
          </div>
        </div>
        <div class="card-header filter-header p-1" v-if="hasFilter" >
          <FilterInlineViewer :rules="checklistRules" :conditions="area.conditionsObj"></FilterInlineViewer>
        </div>
        <div class="card-body p-1">
          <manage-area-questions :checklistRules="checklistRules" :area="area"></manage-area-questions>
        </div>
      </div>
    </div>

    <template v-slot:drag-image>
      <div class="drag-image">DRAG</div>
    </template>
  </drag>
</template>

<script>
import Vue from 'vue';
import vSelect from "vue-select";
import { Client, Operation, QuestionDto } from "../../code/EngineeringChecklist.Api";
import VueQueryBuilder from 'vue-query-builder';
import { Drag, DropMask } from "vue-easy-dnd";


import FilterInlineViewer from './FilterInlineViewer.vue'
import FilterIcon from 'vue-material-design-icons/Filter.vue';
import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
import ManageAreaQuestions from "./ManageAreaQuestions.vue";

export default Vue.extend({
  name: 'ManageArea',
  components: {
    FilterInlineViewer,
    Drag, //DropMask,
    TrashCanIcon,
    ManageAreaQuestions,
    vSelect
  },
  props: {
    checklistRules: {type: Array, required: true },
    area: {type: Object, required: true},
    groupNames: {type: Array, default: null}
  },
  data() {
    return {
      patches: []
    }
  },
  computed: {
    areaGroupNames: function() {
      return this.groupNames ? this.groupNames : [];
    },
    hasFilter: function() {
      return this.area && this.area.conditionsObj && this.area.conditionsObj.children && this.area.conditionsObj.children.length
    },
    name: function() {
      return  this.area.name;
    },
    description: function() {
      return  this.area.description;
    },
    groupName: function() {
      return this.area ? this.area.groupName : '';
    },
    conditionsObj: function() {
      return this.area.conditionsObj;
    }
  
  },
  methods: {
    patch: function(op, path, value) {
      let patch = _.find(this.patches, {op: op, path: path });
      
      if (patch) {
        patch.value = value;
      } else {
        patch = new Operation({op: op, path: path, value: value});
        this.patches.push(patch);
      }
    },
    processPatches: function() {

      if (this.patches && this.patches.length) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        c.checklistAreas_Patch(this.area.id, this.patches)
          .then(function(){

            this.patches = [];
          }.bind(this))
          .catch(function(error){

          })

      }
    },
  },
  watch: {
    name: function(newValue, oldValue) {
      this.patch('replace', '/name', newValue);
    },
    // groupName: function(newValue, oldValue) {
    //   this.patch('replace', '/groupName', newValue);
    // },
    description: function(newValue, oldValue) {
      this.patch('replace', '/description', newValue);
    },
    conditionsObj: {
      deep: true,
      handler: function(newValue, OldValue)
      {
        if (this.area.conditions != JSON.stringify(newValue)) {
          this.area.conditions = JSON.stringify(newValue)
          this.patch('replace', '/conditions', this.area.conditions);
        }
      }
    },
    patches: function(newValue, oldValue) {
      if (newValue && newValue.length) {
        this.debouncedProcessPatches();
      }
    },
  },
  beforeDestroy() {
    this.debouncedProcessPatches.flush();
  },
  created() {
    
    this.$set(this.area, 'conditionsObj', (this.area &&  this.area.conditions) ? JSON.parse(this.area.conditions) : {});

    this.debouncedProcessPatches = _.debounce(this.processPatches, 3000, {
      leading: false,
      trailing: true
    });
  }
});
</script>

<style lang="scss">
.area-grouping {
  width: 200px;
  display: inline-block;
}
</style>