<template>
  <div class="conditionals">
    <div class="container-fluid">
      <div class="row" v-for="(rule, index) in rules" :key="index" >
          <div class="col-3">
            {{rule.label}}
          </div>
          <div class="col-9" v-if="rule.type =='radio'">
            <b-form-radio-group
              :id="`${checklist.name}_${rule.label}-radio-group-${index}`"
              v-model="rule.value"
              :options="rule.choices"
              :name="`${checklist.name}_${rule.label}`"
              text-field="label"
              :disabled="readonly"
            ></b-form-radio-group>
          </div>
          <div class="col-8" v-else-if="rule.type =='checkbox'">
            <b-form-checkbox-group
              :id="`${checklist.name}_${rule.label}-checkbox-group-${index}`"
              v-model="rule.value"
              :options="rule.choices"
              :name="`${checklist.name}_${rule.label}`"
              text-field="label"
              :disabled="readonly"
            ></b-form-checkbox-group>
          </div>
          <div class="col-8" v-else-if="rule.type =='select'">
            <div class="form-group">
              <select class="form-control" v-model="rule.value" :disabled="readonly">
                <option :value="null">---</option>
                <option v-for="(choice, index) in rule.choices" :key="index" :value="choice.value" :selected="choice.selected">{{choice.label}}</option>
              </select>
            </div>
          </div>
      </div>
    </div>    
  </div>
</template>

<script>
import Vue from "vue";

/*
[{
  "type": "checkbox",
  "label": "Plates",
  "operators": [
    "Equals",
    "Not Equals"
  ],
  "choices": [
    {"label": "Top Plate","value": "Top Plate"},
    {"label": "Top QMC Plate","value": "Top QMC Plate"},
    {"label": "Cavity Stripper Plate","value": "Cavity Stripper Plate"},
    {"label": "Manifold Plate","value": "Manifold Plate"},
    {"label": "Manifold Spacer Plate","value": "Manifold Spacer Plate"},
    {"label": "Cavity Support Plate","value": "Cavity Support Plate"},
    {"label": "Cavity Side Insulator Plate","value": "Cavity Side Insulator Plate"},
    {"label": "Core Stripper Plate","value": "Core Stripper Plate"},
    {"label": "Core Support Plate","value": "Core Support Plate"},
    {"label": "Retainer Plate","value": "Retainer Plate"},
    {"label": "Ejector Plate","value": "Ejector Plate"},
    {"label": "Rails","value": "Rails"},
    {"label": "Bottom Plate","value": "Bottom Plate"},
    {"label": "Bottom QMC Plate","value": "Bottom QMC Plate"},
    {"label": "Core Side Insulator Plate","value": "Core Side Insulator Plate"}
  ]
},
{
  "type": "radio",
  "label": "Water",
  "operators": ["Equals","Not Equals"],
  "choices": [
    {"label": "Yes","value": "true"},
    {"label": "No","value": "false"}
  ]
}]
*/
export default Vue.extend({
  name: "Conditionals",
  components: {
  },
  props:{
    checklist: {type: Object, required: true },
    readonly: {type: Boolean, required: true },
  },
  data() {
    return {

    };
  },
  computed: {
    rules: function() {
      return this.checklist.rules;
    }
  },
  methods: {

  },
  watch: {
    rules: function(newValue, oldValue) {

      this.$emit('rules-changed', this.checklist);
      
      // if (this.checklist.options) {
      //   this.rules = JSON.parse(this.checklist.options || '[]');
      // }
    }
  },
  created() {
    // if (this.checklist.options) {
    //   this.rules = JSON.parse(this.checklist.options || '[]');
    // }

  }
});
</script>

<style lang="scss">


</style>
