import { filterObject } from "./conditionals"
import { ChecklistDto, ChecklistType } from './EngineeringChecklist.Api';
import _ from 'lodash';
import { store } from "../stores/store";

export function InitializeJobs(jobs: any) {
  if (!jobs) {
    return;
  }

  jobs.forEach((job:any) => {
    let progressTotal = 0, unresolvedIssuesTotal = 0;
    
    if (job.checklistProjects && job.checklistProjects.length) {
      job.checklistProjects.forEach((checklistProject:any) => {
        progressTotal += checklistProject.progress || 0;
        unresolvedIssuesTotal += checklistProject.unresolvedIssues || 0;
      });  

      job.progress = (progressTotal / job.checklistProjects.length) || 0;
      job.unresolvedIssues = unresolvedIssuesTotal || 0;
    }
  });

  return jobs;
}

export function InitializeChecklistProject(project: any) {
  if (!project) {
    return;
  }
  project.options = project.options || '[]';
  project.rules = _.map(JSON.parse(project.options), function (item, index) {
    if (!item.hasOwnProperty('value')) {
      item.value = null
    }
    return item;
  });

  project.allRules = [
    { "id": "active-facility", "label": "Active Facility", "value": store.state.selectedFacility },
    ...project.rules
  ];

  project.conditionsObj = (project && project.conditions) ? JSON.parse(project.conditions) : {};
  
  const status = { blank: 0, issue: 0, issueNum: 0, accepted: 0, count: 0 };

  project.checklists.forEach((checklist:any) => {
    
    InitializeChecklist(checklist);

    //Allow for the visibility to be overriden through the hidden property null or undefined will default to the filterObject logic
    if (checklist && checklist.hidden !== null && checklist.hidden !== undefined)
      checklist.visible = !checklist.hidden;
    else {
      checklist.visible = filterObject(checklist, project.allRules);
    }

    status.blank += checklist.status.blank;
    status.issue += checklist.status.issue;
    status.issueNum += checklist.status.issueNum;
    status.accepted += checklist.status.accepted;
    status.count += checklist.status.count;
  });

  project.status = status;
}

export function InitializeChecklist(checklist: any) {
  if (!checklist) {
    return;
  }

  checklist.options = checklist.options || '[]';
  checklist.rules = _.map(JSON.parse(checklist.options), function (item, index) {
    if (!item.hasOwnProperty('value')) {
      item.value = null
    }
    return item;
  });

  checklist.allRules = [
    { "id": "active-facility", "label": "Active Facility", "value": store.state.selectedFacility },
    ...checklist.rules
  ];
  
  checklist.conditionsObj = (checklist && checklist.conditions) ? JSON.parse(checklist.conditions) : {};

  checklist.areas.forEach((area: any) => {
    area.parent = checklist;
    area.conditionsObj = (area && area.conditions) ? JSON.parse(area.conditions) : {};
    area.hierarchy = checklist.type == ChecklistType.Standard ? checklist.name : 'Cmpnt > ' + checklist.name;
    area.visible = filterObject(area, checklist.allRules)

    area.questions.forEach((question: any) => {
      question.parent = area;
      question.conditionsObj = (question && question.conditions) ? JSON.parse(question.conditions) : {};
      question.hierarchy = area.hierarchy + ' > ' + area.name;
      question.visible = filterObject(question, checklist.allRules);

      question.issuesOutstandingCount = _.countBy(question.issues, issue => issue.resolved).false || 0;
      //  _.sumBy(question.issues, ( issue ) => Number(issue.resolved)) || 0;              
      // _.countBy(question.issues, 'resolved');
      question.issues.forEach((issue: any) => {
        issue.parent = question;
        issue.hierarchy = question.hierarchy + ' > ' + question.prompt;
      });
    })

    const visibleQuestions = _.filter(area.questions, { visible: true })
    area.status = _.reduce(visibleQuestions, function (total, question) {

      if (question.type == 'textbox' || question.type == 'lookupList') {
        if (question.textValue) {
          total.accepted += 1;
        } else {
          total.blank += 1;
        }
      } else {
        if (question.value === null) {
          total.blank += 1;
        } else {
          total.accepted += 1;
        }
      }

      total.issue += question.issuesOutstandingCount
      total.issueNum += question.issues.length

      return total;
    }, { blank: 0, issue: 0, issueNum: 0, accepted: 0, count: 0 });

    area.status.count = visibleQuestions.length;

  })

  checklist.status = _.reduce(
    _.filter(checklist.areas, { visible: true }),
    function (total, area) {
      if (area && area.status) {
        total.blank += area.status.blank;
        total.issue += area.status.issue;
        total.issueNum += area.status.issueNum;
        total.accepted += area.status.accepted;
        total.count += area.status.count;
      }

      return total;
    }, { blank: 0, issue: 0, issueNum: 0, accepted: 0, count: 0 });
}