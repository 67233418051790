<template>
  <span class="question-attachment">
    <b-overlay class="attachment-overlay" :show="isNew" variant="transparent" opacity="0.25" spinner-variant="primary" >

      <b-button class="btn-remove-attachment" variant="outline-danger" :disabled="readonly"  size="sm" @click="$emit('remove-attachment',attachment)">
        <TrashCanIcon title="Remove Attachment" />
      </b-button>
      <img :id="`qa-thumb-${attachment.id || attachment.key}`" v-if="isNew" :src="'data:' + attachment.fileType + ';base64,' + attachment.attachmentData" class="img-thumbnail" @click="$store.commit('setImageUrl', imgSrc)" />
      <img :id="`qa-thumb-${attachment.id || attachment.key}`" v-else :src="thumbSrc" class="img-thumbnail" @click="$store.commit('setImageUrl', imgSrc)" />

      <!-- <b-popover :target="`qa-thumb-${attachment.id || attachment.key}`" triggers="hover" placement="bottom">

        <img :id="`qa-thumb-${attachment.id || attachment.key}`" v-if="isNew" :src="`data:${attachment.fileType};base64,${attachment.attachmentData}`" class="img-zoom" />
        <img :id="`qa-thumb-${attachment.id || attachment.key}`" v-else :src="thumbSrc" class="img-zoom" />
      </b-popover> -->
    </b-overlay>


    <!-- <label>{{attachment.fileName}}</label> -->
  </span>
</template>

<script>
import Vue from 'vue';

import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';

export default {
  name: "QuestionAttachment",
  components: { 
    TrashCanIcon 
  },
  props: {
    attachment: { type: Object, default: null },
    readonly: {type: Boolean, required: true}
  },
  data() {
    return {
      options: []
    };
  },
  computed: {
    isNew: function() {
      return this.attachment && this.attachment.id === 0;
    },
    thumbSrc: function() {
      return this.attachment ? this.attachment.filePath + 'thumbs/' + this.attachment.fileName : '';
    },
    imgSrc: function() {
      return this.attachment ? this.attachment.filePath + this.attachment.fileName : '';
    },
  },
  methods: {
    switched(value) {
      if (this.disabled)
        return;

      if (value == this.syncValue) {
        //unselect it if they click on the already selected item
        this.$emit("input", null);
      } else {
        this.$emit("input", value);
      }
    }
  },
  created() {}
};
</script>
<style lang="scss">
  .attachment-overlay{
    display: inline-block;
  }
  .btn-remove-attachment.btn-remove-attachment {
    display: none;
  }
  .attachment-overlay{

    &:hover {
      position: relative;

      .btn-remove-attachment {
        background-color: #FFFFFF;
        position: absolute;
        top: 0;
        right: 0;
        margin-top:10px;
        margin-right:10px;
        display: block;
        z-index: 1010;

        &:hover {
          background-color: #F00;
        }
      }
      .img-thumbnail {
        cursor: pointer;
      }
    }
  }
  .img-thumbnail {
    max-width:90px; 
    max-height: 90px; 
    min-width: 50px;
    min-height: 50px;
    margin: 5px;
  }
  .img-zoom {
    width: 150px;
  }

</style>