<template>
  <div class="row area-row">
    <div class="col-12">
      <div class="card my-1">
        <div class="card-title area-title p-2" :class="{'complete': areaCompleted}">
          <h5 class="d-inline">{{area.name}}</h5>
          <span class="float-right">
            <b-badge :variant="statusVal.issue ? 'danger' : 'secondary'">{{statusVal.issue}}</b-badge> | {{ (statusVal.accepted / questions.length) | numeral('0.[0]%') }}</span>
        </div>
        <div class="card-body p-1">
          <div class="container questions-list" >
            <question 
              v-for="question in questions"
              :key="question.id"
              :parent="area"
              :question="question" 
              :readonly="readonly"
            ></question>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Client, Operation, QuestionDto, QuestionType, ChecklistType } from "../code/EngineeringChecklist.Api";
import { filterObject } from "../code/conditionals";
import Question from "./Question.vue";

export default Vue.extend({
  name: 'Area',
  components: {
    Question,
  },
  props: {
    checklist: {type: Object, required: true},
    rules: {type: Array, required: true },
    area: {type: Object, required: true},
    readonly: {type: Boolean, required: true}
  },
  data() {
    return {
      patches: []
    }
  },
  computed: {
    isVisible: function() {
      return this.area.visible
    },
    hasFilter: function() {
      return this.area && this.area.conditionsObj && this.area.conditionsObj.children && this.area.conditionsObj.children.length
    },
    name: function() {
      return  this.area.name;
    },
    description: function() {
      return  this.area.description;
    },
    conditionsObj: function() {
      return this.area.conditionsObj;
    },
    questions: function() {
      // return this.area.questions;
      return _.sortBy(_.filter(this.area.questions, function(question){
        
        return question.visible;

      }.bind(this)), ['sortOrder']);
    },
    areaCompleted: function() {
      return this.questions.length == this.statusVal.accepted;
    },
    statusVal: function() {
      let status = _.reduce(this.questions, function(total, question) {

        if (question.type == QuestionType.Textbox || question.type == QuestionType.LookupList) {
          if ( question.textValue) {
            total.accepted +=1;
          } else {
            total.blank +=1;
          }
        } else if (question.type == QuestionType.FileUpload) {
          if (question.attachments && question.attachments.length) {
            total.accepted +=1;
          } else {
            total.blank +=1;
          }
        } else {
          if (question.value === null) {
            total.blank +=1;
          } else {
            total.accepted +=1;
          }
        }

        total.issue += question.issuesOutstandingCount;
        total.issueNum += question.issues.length;

        return total;
      }, {blank: 0, issue: 0, issueNum: 0, accepted: 0, count: 0});
      
      status.count = this.questions.length;

      return status;
    },
    hierarchyVal: function() {
      return this.checklist.type == ChecklistType.Standard ? this.checklist.name : 'Mechs > ' + this.checklist.name
    }
  },
  methods: {
  },
  watch: {
    hierarchyVal: function(newValue, oldValue) {
        this.$set(this.area, 'hierarchy', newValue);
    },
    statusVal: function(newValue, oldValue) {
      this.$set(this.area, 'status', newValue);
    },
    rules: {
      deep: true,
      // immediate: true,
      handler: function() {
        for (const question of this.area.questions) {
          this.$set(question, 'visible', filterObject(question, this.rules));
        }
      }
    }
  },
  created() {
    // this.$set(this.area, 'parent', this.checklist);
  }
});
</script>

<style lang="scss">
.area-title {
  // text-align: center;
  border-bottom: 2px solid #BBB;

  &.complete{
    color: #FEFEFE;
    background-color: #28a745;
  }
}
</style>