<template>
  <div class="manage-users">
    <div class="card">
      <div class="card-header p-2">
        <h4 class="m-0 mr-5 d-inline-block">Users</h4> 

        <b-button class="btn-add-project icon-btn float-right" variant="primary" size="sm" @click="addNewUser()">
          <PlusBoxIcon /> Add
        </b-button>

        <b-form-radio-group class="float-right mr-5"
                              id="btn-radios-archived"
                              button-variant="outline-primary"
                              v-model="filterDisabled"
                              buttons
                              size="sm">
            <b-form-radio :value="false">Active</b-form-radio>
            <b-form-radio :value="true">Disabled</b-form-radio>
          </b-form-radio-group>
          
          <select v-model="filterFacility" class="form-control-sm float-right mr-5">
            <option value="">All</option>
            <option :value="null">None</option>
            <option v-for="facility in activeFacilities" :key="facility.code" :value="facility.code">{{facility.name}}</option> 
          </select>
          <span class="float-right mr-2">Facility:</span>
      </div>
      <div class="card-body p-2">
        <!-- <div class="row">
          <div class="col-1">Id</div>
          <div class="col-2">User Name</div>
          <div class="col-2">Name</div>
          <div class="col-4">Email</div>
          <div class="col-1">Admin</div>
          <div class="col-1"></div>
        </div>
        <manage-user-detail v-for="user in users" :key="user.id" :user="user" @edit-user="editUser"></manage-user-detail> -->
        <!-- :rows="groupedUsers" 
          :group-options="{ enabled: true}" -->
        <vue-good-table
          :columns="columns"
          :rows="filteredUsers" 
          :pagination-options="{
            enabled: true,
            perPage: 15, perPageDropdown: [15, 25, 50, 100], //perPageDropdownEnabled: false,
            mode: 'records'
          }"
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'id'">
              <b-button class="mr-2" variant="link" size="sm" @click="editUser(props.row)">{{props.row.id}}</b-button>
            </span>
            <span v-else-if="props.column.field == 'facilityCode'">
              <select v-model="props.row.facilityCode" class="form-control" disabled>
                <option :value="null">None</option>
                <option v-for="facility in activeFacilities" :key="facility.code" :value="facility.code">{{facility.name}}</option> 
              </select>
            </span>
            <span v-else-if="props.column.field == 'role'">
              <select v-model="props.row.role" class="form-control" disabled>
                <option value="user">Base User</option>
                <option value="manager">Manager</option>
                <option value="pm">Program Manager</option>
                <option value="ops">Operations</option>
                <option value="admin">Admin</option>
              </select>
            </span>
            
            <span class="last-updated" v-else-if="props.column.field == 'updatedOn'">
              {{ props.row.updatedOn | moment("DD-MMM-YYYY hh:mm a") }}
            </span>
            <span v-else-if="props.column.field == 'actions'">
              <b-button class="btn-remove-project mr-2" variant="outline-secondary" size="sm" @click="editUser(props.row)">
                <LeadPencilIcon title="Edit User" />
              </b-button>
              <!-- <b-button class="btn-remove-project m-0" variant="outline-warning" size="sm" @click="archiveProject(props.row, !props.row.archived)">
                <ArchiveArrowUpIcon v-if="props.row.archived" title="Unarchive Project" />
                <ArchiveIcon v-else title="Archive Project" />
              </b-button> -->
            </span>
            <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
          <div slot="emptystate">
              <h4>Could not find any users... Check / remove filters</h4>
          </div>
        </vue-good-table>      
      
      
      
      </div>
    </div>


    <b-modal
      :title="`${selectedUser && selectedUser.id ? 'Edit' : 'Create'} Checklist User`"
      centered
      ref="checklistUserModalRef"
      size="md"
    >
      <div class="container" v-if="selectedUser">
        <div class="row pb-1">
          <div class="col-4">
            Username:
          </div>
          <div class="col-8">
            <b-input class="form-control" placeholder="Username" type="text" v-model="selectedUser.userName"></b-input>
          </div>
        </div>
        <div class="row pb-1" v-if="!selectedUser.id">
          <div class="col-4">
            New Password:
          </div>
          <div class="col-8">
            <b-input class="form-control" placeholder="Password" type="password" v-model="selectedUser.newPassword"></b-input>
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-4">
            Name:
          </div>
          <div class="col-8">
            <b-input class="form-control" placeholder="Name" type="text" v-model="selectedUser.name"></b-input>
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-4">
            Email:
          </div>
          <div class="col-8">
            <b-input class="form-control" placeholder="Email" type="text" v-model="selectedUser.email"></b-input>
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-4">
            Facility
          </div>
          <div class="col-8">
            <select v-model="selectedUser.facilityCode" class="form-control">
              <option :value="null">All</option>
              <option v-for="facility in activeFacilities" :key="facility.code" :value="facility.code">{{facility.name}}</option> 
            </select>
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-4">
            Role
          </div>
          <div class="col-8">
            <select v-model="selectedUser.role" class="form-control">
              <option value="user">Base User</option>
              <option value="manager">Manager</option>
              <option value="pm">Program Manager</option>
              <option value="ops">Operations</option>
              <option value="admin">Admin</option>
            </select>
          </div>
        </div>
        <div class="row py-1">
          <div class="col-6 text-center">
            <b-form-checkbox v-model="selectedUser.superUser">Is Admin</b-form-checkbox>
          </div>
          <div class="col-6 text-center">
            <b-form-checkbox v-model="selectedUser.disabled">Account Disabled</b-form-checkbox>
          </div>
        </div>
        <div class="row pt-3" v-if="selectedUser.id">
          <div class="col-4">
            Reset Password:
          </div>
          <div class="col-8">
            <b-input class="form-control" placeholder="Password" type="password" v-model="selectedUser.newPassword"></b-input>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer="{ cancel, hide }">
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="saveUser(hide)"> {{`${selectedUser && selectedUser.id ? 'Save' : 'Add'} User`}}</b-button>
      </template>
    </b-modal>


  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Client, Operation, UserDto } from "../../code/EngineeringChecklist.Api";
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import ManageUserDetail from "./ManageUserDetail.vue";

import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';

export default Vue.extend({
  name: 'ManageUsers',
  components: {
    VueGoodTable,
    //ManageUserDetail,
    PlusBoxIcon, LeadPencilIcon
    // TrashCanIcon,
  },
  props: {
  },
  data() {
    return {
      loading: true,
      selectedUser: null,
      filterDisabled: false,
      filterFacility: "",
      users: [
        // { id: 1, username: 'admin', name: 'Phil Beaulieu', email: 'pbeaulieu@concourstechnologies.com', isAdmin: true},
        // { id: 2, username: 'pbeaulieu', name: 'Phil Test', email: 'pbeaulieu@concourstechnologies.com', isAdmin: false}
      ],
      patches: [],
      columns: [
        { field: "id", label: "Id", type:"number", width:"60px" },
        { field: "facilityCode", label: "Facility", width:"120px"},
        { field: "role", label: "Role", width:"150px"},
        // { field: "projectTypeCode", label: "Type", align: "left",},
        { field: "userName", label: "User Name", 'td-class': "user-name", filterOptions: { enabled: true }},
        { field: "name", label: "Name", width:"200px", filterOptions: { enabled: true }},
        { field: "email", label: "Email", width:"120px", filterOptions: { enabled: true }},
        { field: "superUser",  label: "Admin", width:"150px", type:"boolean"},
        // { field: "updatedOn", label: "Last Updated", width:"250px"},
        // { field: "updatedBy", label: "Updated By", width:"250px", // filterOptions: { enabled: true }
        // },
        { field: "disabled", label: "disabled", width:"120px", filterOptions: { enabled: true }},
        { field: "actions", label: "Actions", width:"200px", sortable: false, align: "center"}
      ],
      sortOption:{
        enabled: true,
        multipleColumns: true,
        initialSortBy: {field: "updatedOn", type: 'desc'}
      }
    }
  },
  computed: {
    ...mapGetters(['activeFacilities']),
    filteredUsers: function() {
      if (this.filterFacility !== '')
        return this.users.filter(user => user.disabled == this.filterDisabled && user.facilityCode == this.filterFacility);
      else
        return this.users.filter(user => user.disabled == this.filterDisabled );
    },
    groupedUsers: function () {
      let usersGrouped = _.groupBy(this.filteredUsers, 'facilityCode');

      let rows = _.map(usersGrouped, (value, key) => {
        return {mode: 'span', label: key ? key : 'All', html: false, children: value};
      });

      return rows;
    },
  },
  methods: {
    addNewUser: function() {
      this.selectedUser = new UserDto({id: 0, superUser: false, disabled: false});
      this.$refs.checklistUserModalRef.show();

    },
    editUser: function(user) {
      this.selectedUser = user;
      this.$refs.checklistUserModalRef.show();
    },
    saveUser: function(hide) {
      if (!this.selectedUser) {
        return;
      }

      var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      if (this.selectedUser.id) {
        //Update
        c.users_Put(this.selectedUser.id, this.selectedUser)
          .then((updatedUser) => {
            if (updatedUser) {
              Object.assign(selectedUser, updatedUser)
            }
          })
          .catch((errpr) => {

          });
      } else {
        //Insert
        c.users_Post(this.selectedUser)
          .then((newUser) => {
            this.users.push(newUser);
          })
          .catch((errpr) => {

          });
      }

      hide();

    },
    saveProjectTemplate() {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      this.loading.projects = true;
      if (this.selectedProject && this.selectedProject.id) {
        //Update

        c.checklistProjects_Put(this.selectedProject.id).then(function(data){
          var index = this.projects.indexOf(this.selectedProject)
          if (index > -1) {
            this.projects[index] = data;
          } else {
            this.projects.push(data);
          }
        }.bind(this))
        .catch(error => {
          console.log(error.response);
        })
        .finally(
          function() {
            this.loading.projects = false;
          }.bind(this)
        );

      } else {
        //Insert

        c.checklistProjects_Post(this.selectedProject).then(function(data){
          this.projects.push(data);
        }.bind(this))
        .catch(error => {
          console.log(error.response);
        })
        .finally(
          function() {
            this.loading.projects = false;
          }.bind(this)
        );
      }


    },

    loadUsers: function() {
      this.loading = true;

      var c = new Client(process.env.VUE_APP_API_URL, this.$http);
      c.users_GetAll()
        .then((users) => {
          this.users = users 
        })
        .finally(() => {
          this.loading = false;
        });
    }

    // patch: function(op, path, value) {
    //   let patch = _.find(this.patches, {op: op, path: path });
      
    //   if (patch) {
    //     patch.value = value;
    //   } else {
    //     patch = new Operation({op: op, path: path, value: value});
    //     this.patches.push(patch);
    //   }
    // },
    // processPatches: function() {

    //   if (this.patches && this.patches.length) {
    //     var c = new Client(process.env.VUE_APP_API_URL, this.$http);

    //     c.checklistAreas_Patch(this.area.id, this.patches)
    //       .then(function(){

    //         this.patches = [];
    //       }.bind(this))
    //       .catch(function(error){

    //       })

    //   }
    // },
  },
  watch: {

  },
  created() {
    this.loadUsers();
  }
});
</script>

<style lang="scss">

</style>