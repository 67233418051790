<template>
  <div class="project">
    <b-overlay :show="loading" rounded="lg" />
    <b-tabs v-model="activeTabIndex" pills card v-if="!loading">
      <b-tab title="Details" class="hours-tab-panel py-1">
        <project-detail :project="project" @goto-checklist="activateTab"></project-detail>
      </b-tab>
      <b-tab  v-for="checklist in checklists" :title="checklist.name" :key="checklist.id">
        <manage-checklist :checklist="checklist"></manage-checklist>
      </b-tab>
    </b-tabs>



  </div>
</template>

<script>
import Vue from "vue";
import { Vuex, mapState, mapMutations, mapGetters } from "vuex";
import { InitializeChecklistProject, InitializeChecklist } from "../../code/Initializers";
import { Client } from "../../code/EngineeringChecklist.Api";

import ProjectDetail from "../../components/admin/ProjectDetail.vue";
import ManageChecklist from "../../components/admin/ManageChecklist.vue";


export default Vue.extend({
  name: "ManageChecklistProject",
  components: {
    ProjectDetail,
    ManageChecklist
  },
  data() {
    return {
      activeTabIndex: 0,
      loading: true,
      project: null,
    };
  },
  computed: {
    checklists: function() {
      //return this.project ? _.sortBy(_.filter(this.project.checklists, { hidden: false }) ,["sortOrder"]) : [];
      return this.project ? _.sortBy(this.project.checklists, ["sortOrder"]) : [];
    }
  },
  methods: {
    activateTab(checklist) {
      
      let index = this.checklists.indexOf(checklist);
      if (index >= 0) {
        this.activeTabIndex = index + 1;
      }
    }
  },
  watch: {

  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.projectId !== undefined) {
      var c = new Client(process.env.VUE_APP_API_URL, Vue.prototype.$http);
      var project = await c.checklistProjects_GetById(to.params.projectId, true);
      
      return next(vm => {

        //Initialise the rules array if it doesn't exist.
        InitializeChecklistProject(project);

        vm.project = project;
        vm.loading = false;

        vm.$store.commit('setProject', project);
      });
    }

    return next();
  },
  // beforeRouteLeave(to, from, next) {
  //   if (this.hasChanged) {
  //     if (
  //       confirm(
  //         "Tool Changes have not been saved, are you sure you want to leave?"
  //       )
  //     ) {
  //       next();
  //     } else {
  //       next(false);
  //     }
  //   } else {
  //     next();
  //   }
  // },
  async beforeRouteUpdate(to, from, next) {
    // // react to route changes...
    // // don't forget to call next()

    // let tool = null;
    // if (to.params.tool) {
    //   //If a tool was passed in... Use it.
    //   tool = to.params.tool;
    // } else {
    //   tool = await fetchTool(to.params.toolId);
    // }

    // to.meta.title = tool.description + " | " + "Tool " + tool.toolId;
    // console.log("dumping this", this);

    // if (tool) {
    //   if (this.$store && this.$store.state && !this.$store.state[tool.toolId]) {
    //     this.$store.registerModule(tool.toolId, ToolStore);
    //     this.$store.commit(tool.toolId + "/setTool", tool);
    //     console.log("Registered module: ", tool.toolId);
    //   } else {
    //     console.log("Reusing existing module: ", tool.toolId);
    //   }
    // } else {
    //   console.log("Unable to register module... No tool?");
    // }

    // this.tool = tool;
    // return next();

  },
  created() {

  }
});
</script>

<style lang="scss">

</style>
