<template>
  <drag tag="div" class="row" handle=".area-drag-handle" :data="area" @cut="$emit('drop', area)" type="area">
    <div class="col-12 mt-3">
      <div class="card my-1">
        <b-tabs class="matrix-tabs"  v-model="activeTab" align="right">
            <b-tab title="ALL">
              <template #title>
                <label>ALL</label>
                <input class="matrix-tab-input" v-show="activeTab == -1" />
              </template>
              <manage-area-matrix-questions :checklistRules="checklistRules" :areas="areaGroup"></manage-area-matrix-questions>
            </b-tab>
            <b-tab v-for="(ma, idx) in areaGroup" :key="ma.id" no-body>
              <template #title>
                <label v-if="!editingTabName && activeTab == idx + 1" @click="editTabName">{{ma.name}}</label>
                <label v-else-if="activeTab != idx + 1">{{ma.name}}</label>
                <input class="matrix-tab-input" v-show="editingTabName && activeTab == idx+1" v-model.trim.lazy="ma.name" placeholder="Enter Area name" ref="nameInput" @blur="updateTabName(idx, ma.name)" />
                <a v-if="!editingTabName && activeTab == idx + 1" class="btn-link float-right ml-3" href="#" @click.prevent="$emit('set-group-name', ma, '')"><TrashCanIcon title="Remove From Matrix Area" fillColor="red"></TrashCanIcon></a>
                <div class="tab-order-btns" v-if="!editingTabName && activeTab == idx + 1" >
                  <b-button :disabled="idx == 0 ? true : false" variant="link" size="sm" @click="$emit('shift-area', ma, -1)">
                    <ArrowLeftIcon title="Move Tab Left"></ArrowLeftIcon>
                  </b-button>
                  <b-button :disabled="idx == areaGroup.length - 1 ? true : false" variant="link" size="sm" @click="$emit('shift-area', ma, 1)">
                    <ArrowRightIcon title="Move Tab Right"></ArrowRightIcon>
                  </b-button>
                </div>
              </template>
              <manage-area-questions :checklistRules="checklistRules" :area="ma"></manage-area-questions>
            </b-tab>
            <!-- <b-tab>
              <template #title>
                <b-spinner type="grow" small></b-spinner> I'm <i>custom</i> <strong>title</strong>
              </template>
            </b-tab> -->

            <!-- New Tab Button (Using tabs-end slot) -->
            <template #tabs-start>
              <div class="matrix-area-header drag-handle area-drag-handle ">
                <input class="form-control w-25 m-1" :value="area.groupName" placeholder="Enter area group name" ref="groupNameInput" @change="$emit('update-group-name',area.groupName, $event.target.value)" />
                <!-- <b-button class="" variant="link" size="sm" @click="$emit('edit', activeArea)"><h5 class="d-inline">{{ area.groupName }}</h5></b-button> -->
              </div>
            </template>
            <template #tabs-end>
              <b-nav-item role="presentation" @click.prevent="$emit('add-dimension', activeArea || area ,'new name')" href="#"><b>+ Add</b></b-nav-item>
            </template>
          </b-tabs>
          <div class="card-header filter-header p-1" v-if="hasFilter" >
          <FilterInlineViewer :rules="checklistRules" :conditions="area.conditionsObj"></FilterInlineViewer>
        </div>
      </div>
    </div>

    <template v-slot:drag-image>
      <div class="drag-image">DRAG</div>
    </template>
  </drag>
</template>

<script>
import Vue from 'vue';
import { Client, Operation, QuestionDto } from "../../code/EngineeringChecklist.Api";
import VueQueryBuilder from 'vue-query-builder';
import { Drag, DropMask } from "vue-easy-dnd";


import FilterInlineViewer from './FilterInlineViewer.vue'
import FilterIcon from 'vue-material-design-icons/Filter.vue';
import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft.vue';
import ArrowRightIcon from 'vue-material-design-icons/ArrowRight.vue';
import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
import ManageAreaQuestions from "./ManageAreaQuestions.vue";
import ManageAreaMatrixQuestions from './ManageAreaMatrixQuestions.vue';

export default Vue.extend({
  name: 'ManageAreaMatrix',
  components: {
    FilterInlineViewer,
    Drag,
    TrashCanIcon, ArrowLeftIcon, ArrowRightIcon,
    ManageAreaQuestions,
    ManageAreaMatrixQuestions,
  },
  props: {
    checklistRules: {type: Array, required: true },
    area: {type: Object, required: true},
    areaGroup: {type: Array, default: null}
    
  },
  data() {
    return {
      editingTabName: false,
      activeTab: 0,
      input: null,
      patches: {}
    }
  },
  computed: {
    activeIndex: function() {
      return this.activeTab -1;
    },
    activeArea: function() {
      return this.areaGroup[this.activeIndex];
    },
    hasFilter: function() {
      return this.area && this.area.conditionsObj && this.area.conditionsObj.children && this.area.conditionsObj.children.length
    },
    name: function() {
      return  this.area.name;
    },
    description: function() {
      return  this.area.description;
    },
    conditionsObj: function() {
      return this.area.conditionsObj;
    }
  
  },
  methods: {

    editTabName: function () {
        this.editingTabName = true;
        //this.$nextTick(() => this.input.focus())
        this.$nextTick(() => this.$refs.nameInput[this.activeIndex].focus())
    },
    updateGroupName: function(name) {
      this.patch(this.activeArea.id, 'replace', '/groupName', name);
    },
    updateTabName: function(idx, name) {
      this.editingTabName = false;
      this.patch(this.activeArea.id, 'replace', '/name', name);
      this.$set(this.areaGroup[idx], 'name', name);
    },
    newTab() {
      this.$bvToast.confirm
      this.$bvToast.toast('New Tab', {
        title: 'New Tab',
        autoHideDelay: 5000,
        appendToast: true
      })
    },
    patch: function(key, op, path, value) {
      let patch = _.find(this.patches[key], {op: op, path: path });
      
      if (patch) {
        patch.value = value;
      } else {
        patch = new Operation({op: op, path: path, value: value});
        this.patches[key].push(patch);
      }
      this.debouncedProcessPatches();
    },
    processPatches: function() {

      for (const key in this.patches) {
        if (this.patches.hasOwnProperty(key) && this.patches[key].length) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);
          const patches = this.patches[key];
          c.checklistAreas_Patch(key, patches)
            .then(() => {
              this.$set(this.patches, key, []);
            })
            .catch((error) => {
              // Handle error
            });
        }
      }
      
    },
  },
  watch: {
    areaGroup: function(newValue, oldValue) {
      let patches = {};
      newValue.forEach((ma, idx) => {
        patches[ma.id] = this.patches[ma.id] || [];
      });

      this.patches = patches;
    },
    // name: function(newValue, oldValue) {
    //   this.patch('replace', '/name', newValue);
    // },
    // description: function(newValue, oldValue) {
    //   this.patch('replace', '/description', newValue);
    // },
    // conditionsObj: {
    //   deep: true,
    //   handler: function(newValue, OldValue)
    //   {
    //     if (this.area.conditions != JSON.stringify(newValue)) {
    //       this.area.conditions = JSON.stringify(newValue)
    //       this.patch('replace', '/conditions', this.area.conditions);
    //     }
    //   }
    // },
    // patches: function(newValue, oldValue) {
    //   //if (newValue && newValue.length) {
    //     this.debouncedProcessPatches();
    //   //}
    // },
  },
  created() {
    
    this.$set(this.area, 'conditionsObj', (this.area &&  this.area.conditions) ? JSON.parse(this.area.conditions) : {});

    this.debouncedProcessPatches = _.debounce(this.processPatches, 3000, {
      leading: false,
      trailing: true
    });
  }
});
</script>

<style lang="scss">
  .matrix-tabs{
    .nav-link {
      position: relative;
    }
  }
  .matrix-area-header{
    flex:auto;
    background-color: rgba(0, 0, 0, 0.03);
    // position: absolute;
    // left: 15px;
    // top: 5px;
    // margin: 0;
    // font-size: 1.5em;
    // line-height: 1.5em;
  }
  .matrix-tab-input {
    width: 75px;
  }
  .tab-order-btns {
    position:absolute;
    bottom: -10px;

    .btn-link {
      padding: 1px 5px;
      margin: 0;
      border-radius: 4px;
      &:hover {
        background-color: #00000030;
      }
      &:disabled{
        cursor: not-allowed;
        svg {
          fill: #00000060;
        }
      } 
    }
    
  }

</style>