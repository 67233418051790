<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light my-navbar py-1 px-2" v-if="isAuthenticated">
    <router-link :to="{ name: 'home' }" class="navbar-brand">
      <img class="nav-logo mb-1" src="../assets/logo.png" /> SyBridge Checker</router-link>

    <div class="navbar-title mx-auto">
      <!-- {{projectName}} -->
      
      <div class="project-heading" v-if="project">
        <span class="mx-1" >{{ jobNum }}</span>
        <span v-if="job" style="display:inline-block; min-width: 250px; max-width:350px;" >
          <select class="form-control" name="jobs" id="jobs" :value="checklistProjectID" @change="navigateTo($event)">
            <option v-for="project in checklistProjects" :key="project.id" :value="project.id">{{ project.projectTypeCode }} | {{project.name}}</option>
          </select>
        </span>
        <span v-else class="navbar-project-name">{{ project ? project.name : '' }}</span>

        <span class="navbar-project-issues" v-if="project.status">
          <b-badge :variant="project.status.issue ? 'danger' : 'secondary'" @click="$emit('goto-issues', '')">{{project.status.issue}}</b-badge>
        </span>
        <span class="navbar-project-progress" v-if="project.status">
          {{project.status.accepted / project.status.count | numeral('0%')}}
          <b-progress height="6px" class="" :max="project.status.count">
            <b-progress-bar :value="project.status.accepted" variant="success"></b-progress-bar>
          </b-progress>
        </span>
      </div>
    </div>
  
    <ul class="navbar-nav">
      <li class="mx-2">
        <select class="form-control-sm" :value="selectedFacility" @change="$store.dispatch('changeFacility', $event.target.value)" :disabled="preventFacilityChange">
          <option v-if="isAdmin" :value="null">All Facilities</option>
          <option v-else disabled :value="null">-- SELECT FACILITY --</option>
          <option v-for="facility in activeFacilities" :key="facility.code" :value="facility.code">{{facility.name}}</option>
        </select>
      </li>
      <li>
        <select class="form-control-sm" :value="$i18n.locale" @change="$store.dispatch('changeLocale', {lang: $event.target.value, i18n: $i18n})">
          <option v-for="(lang) in langs" :key="lang.key" :value="lang.key">{{lang.value}}</option>
        </select>
      </li>
      <li class="nav-item ml-2" v-if="isAdmin">
        <router-link :to="{ name: 'admin-view' }" class="btn btn-md btn-outline-primary pr-3 pt-2">
          <ToolsIcon :size="36" title="Admin" />
        </router-link>
      </li>
      <!--<li class="nav-item mx-2">
    <a class="btn btn-sm btn-outline-secondary"
       href="/swagger"
       target="_blank">{{$t('nav.swagger')}}</a>
  </li>-->
      
      <b-nav-item-dropdown right no-caret class="profile-dropdown" toggle-class="ml-2 p-0" >
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <b-avatar variant="primary" >{{avatarInitials}}</b-avatar>
          </template>
          <b-dropdown-text class="p-0" tag="div" text-class="profile-popout" >
            <a href="#" class="btn btn-sm btn-outline-primary float-right p-1" @click.prevent="logout(false)">{{ $t('nav.logout') }}</a>
            <div class="profile-name">{{ profile.name }}</div>
            <div class="profile-username">{{profile.username}}</div>
          </b-dropdown-text>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="#" @click.prevent="msalSignin()">Sign in with different O365 account</b-dropdown-item>
        </b-nav-item-dropdown>
    </ul>
    <Vidle
          @remind="onremind"
          :loop="true"
          :reminders="[0]"
          :duration="900" />

    <b-modal
      :title="`Are you still here?`"
      centered
      size="md"
      v-model="sessionTimeoutDialogVisible"
    >
      <h5 class="text-center">
          Your session is about to expire due to inactivity.<br /><br />
      </h5>
      <h6 class="text-center">
        You will be logged out in <Vidle class="session-timer" @idle="onidle" :wait="0" :duration="300" />.
      </h6>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="logout()">Log Me out</b-button>
        <b-button size="sm" variant="primary" @click="cancel()">I'm Still here</b-button>
      </template>
    </b-modal>
  </nav>
</template>

<script>
  import Vue from "vue";
  import { Vuex, mapGetters, mapState, mapActions, mapMutations } from "vuex";
  import Vidle from 'v-idle';
  import ToolsIcon from 'vue-material-design-icons/Tools.vue';

  export default Vue.extend({
    name: "Navbar",
    components: {
      Vidle, ToolsIcon
    },
    data() {
      return {
        preventFacilityChange: false,
        sessionTimeoutDialogVisible: false,
        seconds: 60
      };
    },
    computed: {
      ...mapState('context', ['profile']),
      ...mapGetters('context', ['isAuthenticated', 'isAdmin', 'authLevel','avatarInitials']),
      ...mapState(['selectedFacility', 'project', 'job']),
      ...mapGetters(['activeFacilities', 'langs', 'projectName']),
      jobNum() {
        return this.job && this.job.jobNum || '';
      },
      checklistProjectID() {
        return this.$route.params.projectId;
      },
      checklistProjects() {
        return this.job && this.job.checklistProjects ? this.job.checklistProjects : [];
      }
    },
    methods: {
      ...mapActions('context', ['logout'],),
      msalSignin() {
        
        window.localStorage.setItem('changeuser', 'true');
        this.logout(false,true);

      },
      onidle() {
        this.sessionTimeoutDialogVisible = false;
        this.logout(true);
      },
      onremind(time) {
        this.sessionTimeoutDialogVisible = true;
      },
      initializeFacilities() {
        this.$store.dispatch('fetchFacilities')
          .then(function (data) {
            this.$store.commit('setFacility', window.localStorage.getItem('facilityCode') || 'SY02M');
          }.bind(this));
      },
      initializeProjectTypes() {
        this.$store.dispatch('fetchProjectTypes');
      },
      navigateTo(event) {

        this.$router.push({ name: 'project', params: { projectId: event.target.value } });
      }
    },
    watch: {
      $route(to, from) {
        if (to.name == 'project') {
          this.preventFacilityChange = true;
        } else {
          this.preventFacilityChange = false;
        }
      }
    },
    created() {
      this.initializeFacilities();
      this.initializeProjectTypes();
    }
});
</script>
<style lang="scss">
.nav-logo {
  height: 1.5em;
}
.my-navbar {
  border-bottom: 1px solid #ddd;
}
.navbar-nav .form-control-sm {
    height: 40px;
}
.navbar-brand {
  font-weight: bold;
  font-size: 130%;
  &:hover {
    color: #0252bb !important;
  }
}
.navbar-title {
  flex-grow: 1;
  
  font-weight: bold;
  font-size: 115%;
  text-align: center;
}
.navbar-project-name,
.navbar-project-issues,
.navbar-project-progress{
  display: inline-block;
  margin: 0 0.35em;
}
.navbar-project-name,
.navbar-project-issues {
  position:relative;
  top: -0.4em;
}
.navbar-project-progress{
  width: 110px;
}
.v-idle{
  display: none;

  &.session-timer {
    display: inline-block;
  }
}
.material-design-icon.tools-icon {
  height: 1.75em;
  width: 1.75em;
 & > .material-design-icon__svg {
    height: 1.75em;
    width: 1.75em;
  }
}

.profile-dropdown .profile-popout {
  padding: 0 0.5em;
  font-weight: bold;
  width: 320px;

  .profile-name{
    font-size: 1.25em;
  }
  .profile-username{
    font-size: 0.85em;
    color: #999;
  }
}

</style>
