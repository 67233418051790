<template>
  <div class="jobs m-4">
    <b-overlay :show="loading" spinnerVariant="primary" :opacity="0.5" >
      <div class="container-fluid px-0">
        <div class="row no-gutters">
          <div class="col-6">
            <h2>{{ showArchived ? 'Archived' : 'Active' }} Jobs</h2>
          </div>
          <div class="col-6">
            <div class="p-1" v-if="authLevel > 0">
              <b-button class="float-right ml-3" variant="primary" @click="fetchJobs" size="sm">Refresh</b-button>
              <b-form-radio-group class="float-right"
                              id="btn-radios-archived"
                              button-variant="outline-primary"
                              v-model="showArchived"
                              buttons
                              size="sm"
                              v-if="authLevel > 0">
            <b-form-radio :value="false">Active</b-form-radio>
            <b-form-radio :value="true">Archived</b-form-radio>
          </b-form-radio-group>
            </div>
          </div>  
        </div>  
      </div>

      <vue-good-table
        :columns="cols"
        :rows="groupedJobs" 
        :group-options="{ enabled: true}" 
        >
        <!-- , collapsable: true  -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'id'">
            <router-link v-if="rowFirstChecklistProject(props.row)" :to="{ name: 'project', params: { projectId: props.row.checklistProjectId }}" class="">{{props.row.id}}</router-link>
            <span v-else>{{props.row.id}}</span>
          </span>
          <span v-else-if="props.column.label == 'Project Name'">
            <router-link :to="{ name: 'project', params: { projectId: props.row.id }}" class="">{{props.row.projectName}}</router-link>
          </span>
          <span v-else-if="props.column.field == 'wo'">
            {{ props.row.wo }}
          </span>
          <span v-else-if="props.column.field == 'woDate'">
            {{ props.row.woDate | moment("DD-MMM-YYYY") }}
          </span>
          <span v-else-if="props.column.field == 'unresolvedIssues'">
            <h5><b-badge :variant="rowUnresolvedIssues(props.row) ? 'danger' : 'secondary'" >{{props.row.unresolvedIssues}}</b-badge></h5>
          </span>
          <span v-else-if="props.column.field == 'progress'">
            <div class="progress-text">{{ rowProgress(props.row) / 100 | numeral('0%')}}</div>
            <b-progress class="darker-progress" :max="100" height="30px">
              <b-progress-bar :value="props.row.progress" variant="success"></b-progress-bar>
            </b-progress>
          </span>
          <span class="last-updated" v-else-if="props.column.field == 'updatedOn'">
            {{ props.row.updatedOn | moment("DD-MMM-YYYY hh:mm a") }}
          </span>
          <span v-else-if="props.column.field == 'actions' && authLevel > 2">
            <b-button class="btn-remove-project m-0" variant="outline-warning" size="sm" @click="archiveJob(props.row, !props.row.archived)">
              <ArchiveArrowUpIcon v-if="props.row.archived" title="Unarchive Job" />
              <ArchiveIcon v-else title="Archive Job" />
            </b-button>
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
        <div slot="emptystate">
          <h4>Could not find any {{ showArchived ? 'Archived' : 'Active' }} Projects</h4>
        </div>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
  import Vue from "vue";
  import { Vuex, mapState, mapMutations, mapGetters } from "vuex";

  import { ChecklistType, Client, QuestionDto, ChecklistDto, IssueDto, Operation } from "../code/EngineeringChecklist.Api";
  import { filterObject } from "../code/conditionals"
  import { InitializeChecklistProject, InitializeChecklist, InitializeJobs } from "../code/Initializers"

  import LockIcon from 'vue-material-design-icons/Lock.vue';
  import ArchiveIcon from 'vue-material-design-icons/Archive.vue';
  import ArchiveArrowUpIcon from 'vue-material-design-icons/ArchiveArrowUp.vue';

  import 'vue-good-table/dist/vue-good-table.css'
  import { VueGoodTable } from 'vue-good-table';

  import ClipboardCheckIcon from 'vue-material-design-icons/ClipboardCheck.vue';

  export default Vue.extend({
    name: "Jobs",
    components: {
      VueGoodTable,
      ArchiveIcon, ArchiveArrowUpIcon//, LockOpenVariantIcon,
      //JobSummary,
      //ClipboardCheckIcon
    },
    data() {
      return {
        showArchived: false,
        jobs: [],
        loading: true,
        currentJob: {id: 0},
        project: {id: 0},
        columns: [
          { field: "jobNum", label: "JobNum", width:"120px",},
          { field: "facilityCode", label: "Facility", width:"120px"},
          { field: "id", label: "Id", type:"number", width:"60px", thClass: 'vgt-center-align', tdClass: 'vgt-center-align'},
          // { field: "projectTypeCode", label: "Type", align: "left",},
          { field: "name", label: "Job Name", align: "left",},
          { field: "wo", label: "WO", width:"75px", thClass: 'vgt-center-align', tdClass: 'vgt-center-align'},
          { field: "woDate", label: "WO Date", width:"120px",},
          { field: "unresolvedIssues", label: "# Issues", width:"120px", type:"number", thClass: 'vgt-center-align', tdClass: 'vgt-center-align' },
          { field: "progress",  label: "% Compl.", width:"120px", type:"number" },
          { field: "createdBy", label: "Created By", width:"250px", // filterOptions: { enabled: true }
          },
          { field: "actions", label: "Actions", width:"200px", sortable: false, align: "center"}
        ],
        sortOption:{
          enabled: true,
          multipleColumns: true,
          initialSortBy: {field: "updatedOn", type: 'desc'}
        }
      };
    },
    computed: {
      ...mapState(["selectedFacility"]),
      ...mapGetters('context', ['isAuthenticated', 'isAdmin', 'authLevel']),
      ...mapGetters(['selectedMoreInfo','getProjectType']),
      filteredJobs: function () {

        if (!this.jobs) {
          return [];
        } else {
          return _.filter(this.jobs, job => job.archived == this.showArchived);
        }        
      },
      cols: function () {
        if (this.authLevel > 2) {
          return this.columns;
        } else {
          return this.columns.filter(col => col.field != 'actions');
        }
      },
      groupedJobs: function () {
        if (this.jobs && this.filteredJobs.length == 0) {
          return [];
          
        } else {
          let grpJobs = _.groupBy(this.filteredJobs, 'jobNum');

          let rows = _.map(grpJobs, (value, key) => {
            return {mode: 'span', label: key, html: false, children: value};
          });

          return rows;
        }
      },
      checklists: function () {
        return this.project ? _.sortBy(_.filter(this.project.checklists, { type: ChecklistType.Standard, hidden: false }) || [], ['sortOrder']) : [];
      },
      mechChecklists: function () {
        return this.project ? _.filter(this.project.checklists, (checklist) => checklist.type != ChecklistType.Standard) : [];
      },
      mechIssueCount: function () {
        let mechChecklists = this.project ? _.filter(this.project.checklists, (checklist) => checklist.type != ChecklistType.Standard) : [];

        return _.sumBy(this.mechChecklists, checklist => checklist.status ? checklist.status.issue : 0);
      },
    },
    methods: {
      archiveJob(job, archive) {
        if( archive ) {
          this.$bvModal.msgBoxConfirm(`Are you sure you want to archive the project: ${job.name}?`, {
            title: 'Archive Project',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
            if (value) {
              let c = new Client(process.env.VUE_APP_API_URL, this.$http);
              c.job_ArchiveJob(job.id)
                .then(function(updateJob) {
                  
                  let index = this.jobs.findIndex(j => j.id == updateJob.id)
                  if (index > -1) {
                    this.jobs.splice(index, 1);
                  }
                }.bind(this));
            }
          });
        } else {
          let c = new Client(process.env.VUE_APP_API_URL, this.$http);
          c.job_UnarchiveJob(job.id)
            .then(function(updateJob) {
              let index = this.jobs.findIndex(j => j.id == updateJob.id)
              if (index > -1) {
                this.jobs.splice(index, 1);
              }
            }.bind(this));

        }
      },
      rowProgress(row) {
        let totalProgress = 0;
        row.checklistProjects.forEach(project => {
          totalProgress += project.progress || 0;
        });
        row.progress = totalProgress / row.checklistProjects.length;

        return row.progress || 0;
      },
      rowUnresolvedIssues(row) {
        let totalUnresolvedIssues = 0;
        row.checklistProjects.forEach(project => {
          totalUnresolvedIssues += project.unresolvedIssues || 0;
        });
        row.unresolvedIssues = totalUnresolvedIssues;
        return row.unresolvedIssues || 0;
      },
      rowFirstChecklistProject(row) {
        row.checklistProjectId = row.checklistProjects && row.checklistProjects.length ? row.checklistProjects[0].id : 0;
        return row.checklistProjectId;
      },
      fetchJobs() {
        this.loading = true;
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        c.job_GetAll(this.showArchived)
          .then(jobs => {
            this.jobs = InitializeJobs(jobs);
            this.loading = false;
          });
      },
    },
    watch: {     
      showArchived: function(newValue, oldValue) {
        this.fetchJobs();
      },
    },
    async beforeRouteEnter(to, from, next) {
      var c = new Client(process.env.VUE_APP_API_URL, Vue.prototype.$http);
      var jobs = await c.job_GetAll(false);

      return next(vm => {
        vm.jobs = InitializeJobs(jobs);
        vm.loading = false;

      });
    },
    created() {

    },
    beforeDestroy() {
      // Notify the server we stopped watching the question
      // this.$projectHub.projectClosed(`project_9`)
    },
  });
</script>

<style lang="scss">
  .modal-dialog.img-viewer-dialog {
    // max-width: auto;
    .modal-content {
      background-color: transparent;
      border: 0;
    }

    .modal-body {
      padding: 0;
    }
  }
</style>
