<template>
  <div class="manage-users">
    <b-overlay :show="loading" rounded="sm">
      <div class="card">
        <div class="card-header p-2">
          <h4 class="m-0 mr-5 d-inline-block">Job Templates</h4>

          <b-button class="btn-add-job icon-btn float-right " variant="primary" size="sm" @click="showSelectedJob()">
            <PlusBoxIcon /> Add
          </b-button>

          <b-form-radio-group class="float-right mx-1 mr-4"
                              id="btn-radios-archived"
                              button-variant="outline-primary"
                              v-model="filterArchived"
                              buttons
                              size="sm">
            <b-form-radio :value="false">Active</b-form-radio>
            <b-form-radio :value="true">Archived</b-form-radio>
          </b-form-radio-group>
          <select class="form-control-sm float-right mx-1" v-model="filterFacilityCode">
            <option :value="null">All Facilities</option>
            <option v-for="facility in $store.getters.activeFacilities" :key="facility.code" :value="facility.code">{{facility.name}}</option>
          </select>
        </div>
        <div class="card-body p-2">
          <div class="container-fluid" v-if="jobs.length">
            <div class="row">
              <div class="col-8">Job</div>
              <div class="col-2 text-center">Facilities</div>
              <div class="col-2 text-center"></div>
            </div>
            <div class="row py-1" v-for="job in jobs" :key="job.id">
              <div class="col-8">
                <router-link :to="{ name: 'manage-job', params: { jobId: job.id }}" class="">{{ job.id }} | {{job.name}}</router-link>
              </div>
              <div v-if="job.facilityRestrictions && job.facilityRestrictions.length" class="col-2 text-center">
                <div v-for="facility in job.facilityRestrictions" :key="facility">{{facility}}</div>
              </div>
              <div v-else class="col-2 text-center">ALL</div>
              <div class="col-2 text-center">
                <b-button class="btn-remove-job m-0" variant="outline-warning" size="sm" @click="archiveJob(job, !job.archived)">
                  <ArchiveArrowUpIcon v-if="job.archived" title="Unarchive Job" />
                  <ArchiveIcon v-else title="Archive Job" />
                </b-button>
                <b-button v-if="job.archived" class="btn-remove-job mx-1" variant="outline-danger" size="sm" @click="removeJob(job)">
                  <TrashCanIcon title="Remove Job forever!" />
                </b-button>
                <b-button v-else class="btn-remove-job mx-1" variant="outline-primary" size="sm" @click="cloneJob(job)">
                  <ContentDuplicateIcon title="Clone Job" />
                </b-button>
                
              </div>
            </div>
          </div>
          <div class="empty-card py-4" v-else>
            <h4 class="text-center"> No Job Templates</h4>
          </div>
        </div>
      </div>
    </b-overlay>
    <b-modal title="New Job Template"
             centered
             no-close-on-backdrop
             ref="projectModalRef"
             size="md">
      <div class="container" v-if="selectedJob">
        <div class="row py-1">
          <div class="col-4">
            Name:
          </div>
          <div class="col-8">
            <input class="form-control" type="text" v-model="selectedJob.name">
          </div>
        </div>
        <div class="row py-1">
          <div class="col-4">
            Description:
          </div>
          <div class="col-8">
            <b-form-textarea id="textarea-small"
                             class="form-control"
                             size="sm"
                             placeholder="Job Description"
                             v-model="selectedJob.description"></b-form-textarea>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer="{ cancel, hide }">
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="saveJobTemplate(hide)">Add</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import { Client, Operation, JobTemplateDto } from "../../code/EngineeringChecklist.Api";
  import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
  import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
  import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
  import ArchiveIcon from 'vue-material-design-icons/Archive.vue';
  import ArchiveArrowUpIcon from 'vue-material-design-icons/ArchiveArrowUp.vue';
  import ContentDuplicateIcon from 'vue-material-design-icons/ContentDuplicate.vue';
  
  export default Vue.extend({
    name: 'ManageJobTemplates',
    components: {
      PlusBoxIcon,
      TrashCanIcon,
      ArchiveIcon,
      ArchiveArrowUpIcon,
      ContentDuplicateIcon
    },
    props: {
    },
    data() {
      return {
        loading: true,
        jobs: [],
        selectedJob: null,
        patches: [],
        filterArchived: false,
        filterFacilityCode: null,
        filterProjType: null
      }
    },
    computed: {
    },
    methods: {
      patchJob: function (job, patch) {
        if (!job || !job.id) {
          console.log('Invalid job, cannot be patched');
          return;
        }
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        var patches = [ patch ]
        return c.jobTemplate_Patch(job.id, patches)
          .catch((error) => {
            console.log(error);
          })
      },
      remove(array, item) {
        let index = array.indexOf(item);
        if (index >= 0) {
          array.splice(index, 1);
        }
      },
      loadjobs() {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        this.loading = true;
        c.jobTemplate_GetAll(this.filterArchived, this.filterFacilityCode)
          .then(function (data) {
            this.jobs = data;
          }.bind(this))
          .catch(error => {
            console.log(error.response);
          })
          .finally(function () {
            this.loading = false;
          }.bind(this));
      },
      showSelectedJob(job) {
        if (!job) {
          this.selectedJob = new JobTemplateDto({ id: 0, archived: this.filterArchived });
        } else {
          this.selectedJob = job;
        }
        this.$refs.projectModalRef.show();
      },
      saveJobTemplate() {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        this.loading = true;
        if (this.selectedJob && this.selectedJob.id) {
          //Update
          c.jobTemplate_Put_Put(this.selectedJob.id).then(function (data) {
            var index = this.jobs.indexOf(this.selectedJob)
            if (index > -1) {
              this.jobs[index] = data;
            } else {
              this.jobs.push(data);
            }
            this.$refs.projectModalRef.hide();
          }.bind(this))
            .catch(error => {
              console.log(error.response);
            })
            .finally(
              function () {
                this.loading = false;
              }.bind(this)
            );
        } else {
          //Insert
          c.jobTemplate_Post(this.selectedJob)
            .then(function (data) {
              this.jobs.push(data);
              this.$refs.projectModalRef.hide();
            }.bind(this))
            .catch(error => {
              console.log(error.response);
            })
            .finally(
              function () {
                this.loading = false;
              }.bind(this)
            );
        }
      },
      archiveJob(job, archived) {
        this.$bvModal.msgBoxConfirm(`Are you sure you want to ${archived ? 'archive' : 'unarchive'} job '${job.name}'?`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {
          if (value) {
            this.patchJob(job, new Operation({ op: 'replace', path: '/archived', value: !!archived }))
              .then(function () {
                this.$bvToast.toast(`Sucessfully ${archived ? 'archived' : 'unarchived'} job: '${job.name}'`, {
                  title: `Job ${archived ? 'Archived' : 'Unarchived'}.`,
                  variant: "success"
                });
                this.remove(this.jobs, job);
              }.bind(this));
          }
        }.bind(this))
      },
      cloneJob(job) {
        this.$bvToast.toast(`Template Job: ${job.name} has not been cloned.`, {
            title: 'Clone not implemented',
            variant: "warning"
          });

        // var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        // this.$bvModal.msgBoxConfirm(`Are you sure you want to clone '${job.name}'?`, {
        //   title: 'Please Confirm',
        //   size: 'sm',
        //   buttonSize: 'sm',
        //   okVariant: 'primary',
        //   okTitle: 'YES',
        //   cancelTitle: 'NO',
        //   footerClass: 'p-2',
        //   hideHeaderClose: false,
        //   centered: true
        // }).then(function (value) {
        //   if (value) {
        //     if (job && job.id) {
        //       c.jobTchecklistProjects_CloneTemplate(job.id, job.name + " Copy", "", true)
        //         .then(function (data) {
        //           this.$bvToast.toast(`Template Job: ${job.name} has been cloned.`, {
        //             title: 'Successfully cloned job',
        //             variant: "success"
        //           });

        //           this.jobs.push(data);
        //         }.bind(this))
        //         .catch(function (error) {
        //           this.$bvToast.toast(`Cloned failed for: ${error.message}`, {
        //             title: `Job: ${job.name} failed to clone.`,
        //             variant: "danger"
        //           });
        //         }.bind(this));
        //     }
        //   }
        // }.bind(this))
      },
      removeJob(job) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        this.$bvModal.msgBoxConfirm(`Are you sure you want to permanently remove '${job.name}'? This cannot be undone!`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {
          if (value) {
            if (job && job.id) {
              c.jobTemplate_Delete(job.id)
                .then(function (data) {
                  this.$bvToast.toast(`Template Job: ${job.name} removed.`, {
                    title: 'Successfully removed job',
                    variant: "success"
                  });
                }.bind(this))
                .catch(function (error) {
                  this.$bvToast.toast(`Save failed for: ${error.message}`, {
                    title: `Job: ${job.name} failed to delete.`,
                    variant: "danger"
                  });
                  this.jobs.push(job);
                }.bind(this));
            }
            this.remove(this.jobs, job);
          }
        }.bind(this))
      },
    },
    watch: {
      filterArchived: function () {
        this.loadjobs();
      },
      filterFacilityCode: function () {
        this.loadjobs();
      },
    },
    created() {
      this.loadjobs();
    }
  });
</script>
<style lang="scss">
</style>
