import { HubConnectionBuilder, LogLevel, HubConnection, JsonHubProtocol,  } from '@microsoft/signalr'

export default {
  install (Vue: any) {
    // use a new Vue instance as the interface for Vue components to receive/send SignalR events
    // this way every component can listen to events or send new events using this.$questionHub
    const projectHub = new Vue()
    Vue.prototype.$projectHub = projectHub

    // Provide methods to connect/disconnect from the SignalR hub
    let connection : HubConnection
    let startedPromise: Promise<any> | null = null
    let manuallyClosed = false
    
    let groupId : string = ''
  

    Vue.prototype.startSignalR = () => {
        
        connection = new HubConnectionBuilder()
            .withUrl(`/project-hub`)
            .configureLogging(LogLevel.Information)
            .build();
            


      // Forward hub events through the event, so we can listen for them in the Vue components
      connection.on('ProjectUpdated', (project) => {
        projectHub.$emit('project-updated', project)
      })

      connection.on('ChecklistAdded', (checklist) => {
        projectHub.$emit('checklist-added', checklist)
      })
      connection.on('ChecklistUpdated', (checklist) => {
        projectHub.$emit('checklist-updated', checklist)
      })
      connection.on('ChecklistRemoved', (checklist) => {
        projectHub.$emit('checklist-removed', checklist)
      })

      connection.on('QuestionAdded', (question) => {
        projectHub.$emit('question-added', question)
      })
      connection.on('QuestionUpdated', (question) => {
        projectHub.$emit('question-updated', question)
      })
      connection.on('QuestionValueChange', (questionId, value) => {
        projectHub.$emit('question-value-changed', { questionId, value })
      })

      connection.on('IssueAdded', issue => {
        projectHub.$emit('issue-added', issue)
      })
      connection.on('IssueUpdated', issue => {
        projectHub.$emit('issue-updated', issue)
      })
      connection.on('IssueRemoved', issue => {
        projectHub.$emit('issue-removed', issue)
      })

      connection.on('LiveChatMessageReceived', (username, text) => {
        projectHub.$emit('chat-message-received', { username, text })
      })
      
      // You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
      // Docs recommend listening onclose and handling it there.
      // This is the simplest of the strategies
      function start () : any {
        startedPromise = connection.start()
          .catch(err => {
            console.error('Failed to connect with hub', err)
            return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000))
          })
        return startedPromise
      }
      
      connection.onclose(() => {
        if (!manuallyClosed) 
          start()
      })

      // Start everything
      manuallyClosed = false
      start()
    }
    Vue.prototype.stopSignalR = () => {
      if (!startedPromise) return

      manuallyClosed = true
      return startedPromise
        .then(() => {
          connection.stop()
        })
        .then(() => { startedPromise = null })
    }

    projectHub.ConnectionId = () => {
      if (!connection) return null
      return connection.connectionId;
    }
    projectHub.GroupId = () => groupId;

    // Provide methods for components to send messages back to server
    // Make sure no invocation happens until the connection is established
    projectHub.projectOpened = (projectId:string) => {
      if (!startedPromise) return
      
      return startedPromise
        .then(() => {
          groupId = projectId;
          return connection.invoke('JoinProjectGroup', projectId)
        })
        .catch(console.error)
    }
    projectHub.projectClosed = (projectId:string) => {
      if (!startedPromise) return

      return startedPromise
        .then(() => {
          groupId = '';
          return connection.invoke('LeaveProjectGroup', projectId);
        })
        .catch(console.error)
    }
  }
}
