<template>
  <div class="yes-no-input">
    <div class="yes" @click="switched(1)" :class="{selected: syncValue === 1, disabled: disabled}">Yes</div>
    <div class="no" @click="switched(0)" :class="{selected: syncValue === 0, disabled: disabled}">No</div>
    <div class="na" v-if="allowNA" @click="switched(-1)" :class="{selected: syncValue === -1, disabled: disabled}">N/A</div>
  </div>
</template>

<script>
export default {
  name: "YesNoInput",
  props: {
    value: { type: Number, default: null },
    allowNA: { type: Boolean, default: false },
    disabled: {type: Boolean, default: false },
    addDefault: Boolean
  },
  data() {
    return {
      options: []
    };
  },
  computed: {
    syncValue: {
      get: function() {
        return this.value;

        // if (this.value === null || this.value === "null") {
        //   return null;
        // } else if (this.value === "false") {
        //   return false;
        // } else {
        //   return true;
        // }
      },
      set: function(newVal) {
        if (!this.disabled) {
          this.$emit("input", newVal);
        }
      }
    }
  },
  methods: {
    switched(value) {
      if (this.disabled)
        return;

      if (value == this.syncValue) {
        //unselect it if they click on the already selected item
        this.$emit("input", null);
      } else {
        this.$emit("input", value);
      }
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.yes,
.no,
.na {
  height: 26px;
  min-width: 40px;
  padding: 2px;
  margin: 1px;
  text-align: center;

  display: inline-block;
  background-color: #eee;
  border: 1px solid #999;
  cursor: pointer;

  &.selected {
    
    color: #fff;
  }
  &.disabled {
    cursor: not-allowed;
  }
}
.yes.selected {
  background-color: #91b493;  
}
.no.selected {
  background-color: #bd5a5a;
}
.na.selected {
  background-color: #999;
} 
</style>