<template>
  <div class="manage-users">
    <b-overlay :show="loading" rounded="sm">
      <div class="card">
        <div class="card-header p-2">
          <h4 v-if="isRepetitive" class="m-0 mr-5 d-inline-block">Repetitive Checklist Templates</h4>
          <h4 v-else class="m-0 mr-5 d-inline-block">Standard Checklist Templates</h4>
          <b-button class="btn-add-rule icon-btn float-right" variant="primary" size="sm" @click="showRepeatChecklistDialog()">
            <PlusBoxIcon /> Add
          </b-button>

          <b-form-radio-group class="float-right mx-1 mr-4"
                              id="btn-radios-archived"
                              button-variant="outline-primary"
                              v-model="filterArchived"
                              buttons
                              size="sm">
            <b-form-radio :value="false">Active</b-form-radio>
            <b-form-radio :value="true">Archived</b-form-radio>
          </b-form-radio-group>
          <select class="form-control-sm float-right mx-1" v-model="filterFacilityCode">
            <option :value="null">All Facilities</option>
            <option v-for="facility in $store.getters.activeFacilities" :key="facility.code" :value="facility.code">{{facility.name}}</option>
          </select>
          <select class="form-control-sm float-right mx-1" v-model="filterProjType">
            <option :value="null">All Types</option>
            <option v-for="activeType in activeProjectTypes" :key="activeType.code" :value="activeType.code">{{activeType.name}}</option>
          </select>
        </div>
        <div class="card-body p-2">
          <div class="container-fluid" v-if="repetitiveChecklists.length">
            <div class="row">
              <div class="col-4">Template Name</div>
              <div class="col-2 text-center">Projects</div>
              <div class="col-2 text-center">Checklists</div>
              <div class="col-2 text-center">Facilities</div>
              <div class="col-2"></div>
            </div>
            <div class="row py-1" v-for="checklist in repetitiveChecklists" :key="checklist.id">
              <div class="col-4 py-1">
                <router-link :to="{ name: 'manage-repetitive-checklist', params: { checklistId: checklist.id }}" class="">{{checklist.name}}</router-link>
              </div>
              <div v-if="checklist.projectTypesAllowed && checklist.projectTypesAllowed.length" class="col-2 text-center">
                <span v-for="pType in checklist.projectTypesAllowed" :key="pType" class="m-1">{{pType}}</span>
              </div>
              <div v-else class="col-2 text-center">ALL</div>
              <div v-if="checklist.checklistTypes && checklist.checklistTypes.length" class="col-2 text-center">
                <span v-for="clType in checklist.checklistTypes" :key="clType" class="m-1">{{clType}}</span>
              </div>
              <div v-else class="col-2 text-center">ALL</div>
              <div v-if="checklist.facilityRestrictions && checklist.facilityRestrictions.length" class="col-2 text-center">
                <span v-for="facility in checklist.facilityRestrictions" :key="facility" class="m-1">{{facility}}</span>
              </div>
              <div v-else class="col-2 text-center">ALL</div>
              <div class="col text-right">
                <b-button class="btn-remove-project m-0" variant="outline-warning" size="sm" @click="archiveRepeatChecklist(checklist, !checklist.archived)">
                  <ArchiveArrowUpIcon v-if="checklist.archived" title="Unarchive Checklist" />
                  <ArchiveIcon v-else title="Archive Checklist" />
                </b-button>
                <b-button v-if="!checklist.archived" class="btn-remove-project mx-1" variant="outline-primary" size="sm" @click="cloneRepeatChecklist(checklist)">
                  <ContentDuplicateIcon title="Clone Checklist" />
                </b-button>
                <b-button v-if="checklist.archived" class="btn-remove-project mx-1" variant="outline-danger" size="sm" @click="removeRepeatChecklist(checklist)">
                  <TrashCanIcon title="Remove Checklist forever!" />
                </b-button>
              </div>
            </div>

          </div>
          <div class="empty-card py-4" v-else>
            <h4 v-if="isRepetitive" class="text-center"> No Repetitive Checklist Templates</h4>
            <h4 v-else class="text-center"> No Standard Checklist Templates</h4>
          </div>
        </div>
      </div>
    </b-overlay>

    <b-modal :title=" isRepetitive ? 'New Repetitive Checklist Template' : 'New Standard Checklist Template'"
             centered
             no-close-on-backdrop
             ref="repeatChecklistModalRef"
             size="md">

      <div class="container" v-if="repeatChecklist">
        <!--projectTypesAllowed: [], checklistTypes: [], facilityRestrictions: []-->
        <div class="row">
          <div class="col-4">
            Checklist Name:
          </div>
          <div class="col-8">
            <b-input class="form-control" placeholder="i.e. Insert Template" type="text" v-model="repeatChecklist.name"></b-input>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            Description:
          </div>
          <div class="col-8">
            <b-form-textarea id="textarea-small"
                             class="form-control"
                             size="sm"
                             placeholder="Description"
                             v-model="repeatChecklist.description"></b-form-textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            Project Types:
          </div>
          <div class="col-8">
            <multiselect v-model.lazy="repeatChecklist.projectTypesAllowed"
                         :showLabels="false"
                         :close-on-select="false"
                         :placeholder="'ALL PROJECT TYPES'"
                         :searchable="false"
                         :multiple="true"
                         :options="projectTypeOptions"
                         ></multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            For Facility:
          </div>
          <div class="col-8">
            <multiselect v-model.lazy="repeatChecklist.facilityRestrictions"
                         :showLabels="false"
                         :close-on-select="false"
                         :placeholder="'ALL FACILITIES'"
                         :searchable="false"
                         :multiple="true"
                         :options="facilityOptions"></multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            Checklist Type:
          </div>
          <div class="col-8">
            <multiselect v-model.lazy="repeatChecklist.checklistTypes"
                         :showLabels="false"
                         :close-on-select="false"
                         :placeholder="'ALL CHECKLIST TYPES'"
                         :searchable="false"
                         :multiple="true"
                         :options="checklistTypeOptions"></multiselect>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer="{ cancel, hide }">
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="saveMechChecklistTemplate(hide)">Add</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { Client, Operation, RepetitiveChecklistDto, ChecklistType } from "../../code/EngineeringChecklist.Api";
  import Multiselect from "vue-multiselect";

  import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
  import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
  import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
  import ArchiveIcon from 'vue-material-design-icons/Archive.vue';
  import ArchiveArrowUpIcon from 'vue-material-design-icons/ArchiveArrowUp.vue';
  import ContentDuplicateIcon from 'vue-material-design-icons/ContentDuplicate.vue';

  import { mapState, mapGetters } from 'vuex';

  export default Vue.extend({
    name: 'ManageRepetitiveChecklists',
    components: {
      Multiselect,
      PlusBoxIcon,
      TrashCanIcon,
      ArchiveIcon,
      ArchiveArrowUpIcon,
      ContentDuplicateIcon
    },
    props: {
      isRepetitive: { type: Boolean, default: true }
    },
    data() {
      return {
        ChecklistType,
        loading: true,
        repetitiveChecklists: [],
        repeatChecklist: null,
        patches: [],
        filterArchived: false,
        filterFacilityCode: null,
        filterProjType: null
      }
    },
    computed: {
      ...mapState(['facilities']),
      ...mapGetters(['activeProjectTypes']),
      projectTypeOptions: function () {
        return  this.activeProjectTypes && this.activeProjectTypes.length ? _.map(this.activeProjectTypes, function(item) { return item.code  }) : [];
      },
      checklistTypeOptions: function () {
        return Object.keys(ChecklistType);
      },
      facilityOptions: function () {
        return _.map(this.facilities, function (facility) {
          return facility.code;
        });
      }
    },
    methods: {
      patch: function (op, path, value) {
        let patch = _.find(this.patches, { op: op, path: path });

        if (patch) {
          patch.value = value;
        } else {
          patch = new Operation({ op: op, path: path, value: value });
          this.patches.push(patch);
        }
      },
      processPatches: function () {

        if (this.patches && this.patches.length) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          c.checklistAreas_Patch(this.area.id, this.patches)
            .then(function () {

              this.patches = [];
            }.bind(this))
            .catch(function (error) {

            })

        }
      },
      patchChecklist: function (checklist, patch) {
        if (!checklist || !checklist.id) {
          console.log('Invalid checklist, cannot be patched');
          return;
        }
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        var patches = [
          patch
        ]
        return c.repetitiveChecklists_Patch(checklist.id, patches)
          .catch((error) => {
            console.log(error);
          })
      },
      remove(array, item) {
        let index = array.indexOf(item);
        if (index >= 0) {
          array.splice(index, 1);
        }
      },
      loadRepetitiveChecklists() {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        var validChecklistTypes = Object.keys(ChecklistType);
        if (!this.isRepetitive) {
          validChecklistTypes = ['Standard'];
        } else {
          validChecklistTypes = _.differenceWith(validChecklistTypes, ['Standard'])
        }

        this.loading = true;
        c.repetitiveChecklists_GetAll(this.filterFacilityCode, this.filterProjType, null, this.filterArchived)
          .then(function (data) {
            
            var filteredChecklists = _.filter(data, (c) => {
              var valids = _.intersectionWith(validChecklistTypes, c.checklistTypes, _.isEqual);
              return valids && valids.length;
            });
            this.repetitiveChecklists = filteredChecklists;
          }.bind(this))
          .catch(error => {
            console.log(error.response);
          })
          .finally(
            function () {
              this.loading = false;
            }.bind(this));
      },
      showRepeatChecklistDialog() {

        this.repeatChecklist = new RepetitiveChecklistDto({
          id: 0,
          name: '',
          archived: false,
          sortOrder: 0,
          checklistTypes: this.isRepetitive ? [ChecklistType.Repetitive] : [ChecklistType.Standard],
          projectTypesAllowed: [],
          facilityRestrictions: [],
          allowSignOff: false,
          useSuggestions: false
        });

        this.$refs.repeatChecklistModalRef.show();
      },
      saveMechChecklistTemplate(saveCallback) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        this.loading = true;
        if (this.repeatChecklist && this.repeatChecklist.id) {
          //Update

          c.repetitiveChecklists_Put(this.repeatChecklist.id, this.repeatChecklist)
            .then(function (data) {
              saveCallback();
            }.bind(this))
            .catch(error => {
              console.log(error.response);
            })
            .finally(function () {
              this.loading = false;
            }.bind(this));

        } else {
          //Insert

          c.repetitiveChecklists_Post(this.repeatChecklist)
            .then(function (newChecklist) {
              this.repetitiveChecklists.push(newChecklist);
              saveCallback();
            }.bind(this))
            .catch(error => {
              console.log(error.response);
            })
            .finally(
              function () {
                this.loading = false;
              }.bind(this));
        }
      },
      archiveRepeatChecklist(checklist, archived) {
        this.$bvModal.msgBoxConfirm(`Are you sure you want to ${archived ? 'archive' : 'unarchive'} checklist '${checklist.name}'?`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {
          if (value) {
            this.patchChecklist(checklist, new Operation({ op: 'replace', path: '/archived', value: !!archived }))
              .then(function () {
                this.$bvToast.toast(`Sucessfully ${archived ? 'archived' : 'unarchived'} checklist: '${checklist.name}'`, {
                  title: `Checklist ${archived ? 'Archived' : 'Unarchived'}.`,
                  variant: "success"
                });

                this.remove(this.repetitiveChecklists, checklist);
              }.bind(this));
          }
        }.bind(this))
      },
      cloneRepeatChecklist(checklist) {
        this.$bvModal.msgBoxConfirm(`Are you sure you want to clone '${checklist.name}'?`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {
          
          if (value) {
            var c = new Client(process.env.VUE_APP_API_URL, this.$http);
            c.repetitiveChecklists_DuplicateRepetitiveChecklist(checklist.id, checklist.name + 'Copy')
              .then(function (data) {
                this.$bvToast.toast(`Template Checklist: ${checklist.name} has been cloned.`, {
                  title: 'Successfully cloned checklist',
                  variant: "success"
                });

                this.repetitiveChecklists.push(data);
              }.bind(this));
          }
        }.bind(this))
      },
      removeRepeatChecklist(checklist) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        this.$bvModal.msgBoxConfirm(`Permanently delete '${checklist.name}' checklist?`, {
          title: 'Please Confirm',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          if (value) {
            if (checklist && checklist.id) {
              c.repetitiveChecklists_Delete(checklist.id)
                .then(function (data) {
                  this.$bvToast.toast(`Successfully removed : ${checklist.name}`, {
                    title: `Repetitive checklist removed.`,
                    variant: "success"
                  });
                }.bind(this))
                .catch(function (error) {
                  this.$bvToast.toast(`Save failed for: ${error.message}`, {
                    title: `Repetitive checklist failed to delete.`,
                    variant: "danger"
                  });
                  this.repetitiveChecklists.push(checklist);
                }.bind(this));
            }

            this.remove(this.repetitiveChecklists, checklist);
          }
        }.bind(this))
      },
    },
    watch: {
      filterArchived: function () {
        this.loadRepetitiveChecklists();
      },
      filterFacilityCode: function () {
        this.loadRepetitiveChecklists();
      },
      filterProjType: function () {
        this.loadRepetitiveChecklists();
      }
    },
    created() {
      this.loadRepetitiveChecklists();
    }
  });
</script>

<style lang="scss">
</style>