<template>
  <div class="row summary-checklist-row" :class="{'has-filter': hasFilter, 'filtered': !filtered, 'filter-override': filterOverride }">
    <div class="col-7">
      <h5 class="d-inline">{{checklist.name}}</h5><ClipboardCheckIcon class="ml-2" v-if="checklist.signOffAt" :size="38" fillColor="#28a745" title="Signed Off"></ClipboardCheckIcon>
      <ClipboardCheckIcon class="ml-2" v-if="checklist.leaderSignOffAt" :size="32" fillColor="#5cb0eb" title="Leader Signed Off"></ClipboardCheckIcon>
      <!-- <b-button v-if="checklist.visible"  variant="link" size="sm" @click="gotoChecklist(checklist)">
        <h5 class="d-inline">{{checklist.name}}</h5><ClipboardCheckIcon class="ml-2" v-if="checklist.signOffAt" :size="38" fillColor="#28a745" title="Signed Off"></ClipboardCheckIcon>
      </b-button>
      <h5 v-else class="d-inline">{{checklist.name}}</h5> -->
      <filter-inline-viewer :conditions="checklist.conditionsObj" :rules="checklistProjectRules" v-if="hasFilter"></filter-inline-viewer>
    </div>
    <div class="col-2 text-center" v-if="checklist.status">
      <b-badge :variant="checklist.status.issue ? 'danger' : 'secondary'" @click="$emit('goto-issues', '')">
        {{checklist.status.issue}}
      </b-badge>
    </div>
    <div class="col-2 text-center" v-if="checklist.status">

      {{checklist.status.accepted / checklist.status.count | numeral('0%')}}
      <b-progress height="6px" class="" :max="checklist.status.count">
        <b-progress-bar :value="checklist.status.accepted" variant="success"></b-progress-bar>
      </b-progress>
    </div>
    <div class="col-1 text-center">
      <b-button class="btn-remove-project ml-2" :variant="checklist.visible ? 'outline-primary':'primary'" size="sm" @click="overrideVisibilty">
        <ClipboardTextOffIcon v-if="checklist.visible" title="Hide Checklist" />
        <ClipboardEditIcon v-else class="" title="Restore Checklist" />
      </b-button>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import { mapState } from "vuex";
  import { Client, Operation, ChecklistDto, ChecklistType, ChecklistProjectType } from "../code/EngineeringChecklist.Api";
  // import { Vuex, mapState, mapMutations, mapGetters } from "vuex";
  import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
  import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
  import TransferIcon from 'vue-material-design-icons/Transfer.vue';
  import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
  import DragHorizontalIcon from 'vue-material-design-icons/DragHorizontalVariant.vue';
  import FilterInlineViewer from './admin/FilterInlineViewer.vue'
  import FilterIcon from 'vue-material-design-icons/Filter.vue';
  import ClipboardTextOffIcon from 'vue-material-design-icons/ClipboardTextOff.vue';
  import ClipboardEditIcon from 'vue-material-design-icons/ClipboardEdit.vue';
  import ClipboardCheckIcon from 'vue-material-design-icons/ClipboardCheck.vue';

  import AddRepeatChecklist from "./modals/AddRepeatChecklist.vue"
  import ManageRule from "./modals/ManageRule.vue"

  import { Drag, Drop, DropList } from "vue-easy-dnd";
  import Multiselect from "vue-multiselect";
  import { filterObject } from "../code/conditionals";

  export default Vue.extend({
    name: "ProjectSummaryChecklist",
    components: {
      FilterInlineViewer,
      ClipboardTextOffIcon, ClipboardEditIcon, ClipboardCheckIcon
    },
    props: {
      checklist: {type: Object, required: true},
      checklistProjectRules: {type: Array, required: true },
    },
    data() {
      return {
        patches: [],
      };
    },
    computed : {
      hasFilter: function() {
        return this.checklist && this.checklist.conditionsObj && this.checklist.conditionsObj.children && this.checklist.conditionsObj.children.length
      },
      filtered: function() {
        return filterObject(this.checklist, this.checklistProjectRules);
      },
      filterOverride: function() {
        return this.checklist ? this.checklist.hidden === true || this.checklist.hidden === false : false;
      },
      conditionsObj: function() {
        return this.checklist && this.checklist.conditionsObj;
      },
    },
    methods: {
      overrideVisibilty() {

        if(this.filterOverride) {
          this.checklist.hidden = null;
          this.patch('replace', '/hidden', this.checklist.hidden);
        } else {
          this.checklist.hidden = this.checklist.visible;
          this.patch('replace', '/hidden', this.checklist.hidden);
        }

        if (this.checklist && this.checklist.hidden !== null && this.checklist.hidden !== undefined)
          this.$set(this.checklist, 'visible', !this.checklist.hidden);
        else {
          this.$set(this.checklist, 'visible', filterObject(this.checklist, this.checklistProjectRules));
        }

      },
      /*
      convertChecklist(checklist) {

        this.$bvModal.msgBoxConfirm(`Create Standard checklist template from '${checklist.name}'?`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          if (value) {
            if (checklist && checklist.id) {
              c.checklists_CreateTemplateFromChecklist(checklist.id, checklist.name + " copy")
                .then(function (data) {

                  this.$bvToast.toast(`Sucessfully created checklist template: "${data.name}"`, {
                    title: `Checklist cloned.`,
                    variant: "success"
                  });

                  //Send them over to the newly created checklist
                  this.$router.push({ name: 'manage-repetitive-checklist', params: { checklistId: data.id } });

                }.bind(this))
                .catch(function (error) {
                  this.$bvToast.toast(`Failed to create template checklist: ${error.message}`, {
                    title: `Failed to clone checklist.`,
                    variant: "danger"
                  });

                }.bind(this));
            }
          }
        }.bind(this));
      },*/
      patch: function (op, path, value) {
        let patch = _.find(this.patches, { op: op, path: path });

        if (patch) {
          patch.value = value;
        } else {
          patch = new Operation({ op: op, path: path, value: value });
          this.patches.push(patch);
        }
      },
      processChecklistPatches: function () {

        if (this.patches && this.patches.length) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          c.checklists_Patch(this.checklist.id, this.patches)
            .then(function (updatedChecklist) {
              if (updatedChecklist) {

                delete updatedChecklist.areas;
                updatedChecklist.conditionsObj = updatedChecklist.conditions ? JSON.parse(updatedChecklist.conditions) : {};
                Object.assign(this.checklist, updatedChecklist);
              }
              this.patches = [];
            }.bind(this))
            .catch(function (error) {
              this.$bvToast.toast(error.message, {
                title: `Saving ${this.project.name} failed`,
                variant: "danger"
              });
            })
        }
      },
      
    },
    watch: {
      patches: function (newValue, oldValue) {
        if (newValue && newValue.length) {
          this.debouncedProcessChecklistPatches();
        }
      },
      conditionsObj: {
        deep: true,
        handler: function(newValue, OldValue)
        {
          let newConditional = JSON.stringify(newValue);
          if (this.checklist && this.checklist.conditions != newConditional) {
            this.checklist.conditions = newConditional
            this.patch('replace', '/conditions', this.checklist.conditions);
          }
        }
      }
    },
    created() {
      this.debouncedProcessChecklistPatches = _.debounce(this.processChecklistPatches, 1000, {
       leading: false,
       trailing: true
      });
    } 
  });

</script>

<style lang="scss">
  .filtered  {
    background-color: #EEE;
  }
  .filter-override {
    background-color: #ff99001F;
    border-left: 3px solid #ff9900;
    border-right: 3px solid #ff9900;
  }
</style>