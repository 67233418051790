<template>
  <div class="row area-row">
    <div class="col-12">
      <div class="card my-1">
        <div class="card-title signoff-title p-2" :class="{'complete': signedOff}">
          <h5 class="d-inline">Final Checklist Sign Off</h5>
          <span class="float-right" v-if="signedOff">
            {{signedBy}} | {{signOffAt | moment("DD-MMM-YYYY hh:mm a")}}
          </span>
        </div>
        <div class="card-body p-1">
          <div class="container">
            <div class="row">
              <div class="col-10">SignOff Notes / Comments</div>
              <div class="col-2 text-center"></div>
            </div>
            <div class="row">
              <div class="col-10">
                <b-textarea v-model="checklist.signOffNotes" :disabled="readonly"></b-textarea>
              </div>
              <div class="col-2 text-center">
                <b-button class="p-3" @click="signOffChecklist()" :disabled="readonly" :variant="canSignOff ? 'success' : 'warning'"> <PencilIcon class="mr-2"></PencilIcon> Sign Off</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapState } from 'vuex';
  import { Client, Operation, QuestionDto, QuestionType, ChecklistType } from "../code/EngineeringChecklist.Api";

  import PencilIcon from 'vue-material-design-icons/Pencil.vue';
import moment from 'moment';

  export default Vue.extend({
    name: 'ChecklistSignOff',
    components: {
      PencilIcon
    },
    props: {
      checklist: { type: Object, required: true },
      readonly: { type: Boolean, required: true }
    },
    data() {
      return {
        patches: [],
      }
    },
    computed: {
      ...mapState('context', ['profile']),
      isVisible: function () {
        return this.checklist.allowSignOff;
      },
      signedOff: function () {
        return !!this.checklist.signOffAt;
      },
      signOffAt: function () {
        return this.checklist.signOffAt;
      },
      signedBy: function () {
        return this.checklist.signOffBy;
      },
      signOffName: function () {
        return this.profile.name;
      },
      signOffUserName: function () {
        return this.profile.username;
      },
      checklistStatus: function () {
        return this.checklist ? this.checklist.status : null;
      },
      hasIssues: function () {
        return  this.checklistStatus ? this.checklistStatus.issue > 0 : false;
      },
      checklistComplete: function () {
        return this.checklistStatus ? this.checklistStatus.accepted == this.checklistStatus.count : false;
      },
      canSignOff: function () {
        return !this.hasIssues && this.checklistComplete;
      }
    },
    methods: {
      signOffChecklist: function () {

        if (!this.checklistComplete) {
          this.$bvModal.msgBoxOk('You cannot sign off on this checklist until all questions have been responded to.', {
            title: 'Complete checklist before sign off',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: 'OK',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          });
          return;
        }
        else if (this.hasIssues) {
          this.$bvModal.msgBoxOk('You cannot sign off on this checklist until all issues have been resolved.', {
            title: 'Resolve issues before sign off',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: 'OK',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          });
          return;
        }

        this.$bvModal.msgBoxConfirm(`${this.signOffName} do you confirm that all of the questions have been answered accurately?`, {
          title: 'Final Checklist Sign Off',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          if (value) {
            var c = new Client(process.env.VUE_APP_API_URL, this.$http);
            c.checklists_Patch(this.checklist.id,
              [
                new Operation({ op: 'replace', path: '/signOffAt', value: new moment() }),
                new Operation({ op: 'replace', path: '/signOffNotes', value: this.checklist.signOffNotes })
              ]
            )
              .then(function (updatedChecklist) {
                if (updatedChecklist) {
                  delete updatedChecklist.areas
                  Object.assign(this.checklist, updatedChecklist);
                }
              }.bind(this))
              .catch(error => {
                console.log(error.response);
              });
          }
        }.bind(this));
      },
    },
    watch: {
    },
    created() {
    }
  });
</script>

<style lang="scss">
  .signoff-title {
    // text-align: center;
    border-bottom: 2px solid #BBB;

    &.complete {
      color: #FEFEFE;
      background-color: #28a745;
    }
  }
</style>