<template>
  <div class="checklist">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card my-3">
            <div class="card-header p-1">
              <div class="row">
                <div class="col-8">
                  <h2>Edit Project Template</h2>
                </div>
                <div class="col-4 px-4 text-right">
                  <h3>{{project.projectId}}</h3>
                </div>
              </div>
            </div>
            <div class="card-body p-1">
              <div class="container">
                <div class="row">
                  <div class="col-4">
                    Template Name
                  </div>
                  <div class="col-8">
                    <input class="proj-name" v-model.trim.lazy="project.name" @change="patch('replace', '/name', $event.target.value)" placeholder="Enter Template Project name" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    Facility Restrictions
                  </div>
                  <div class="col-8">
                    Description
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    
                    <multiselect v-model.lazy="project.facilityRestrictions"
                                 :showLabels="false"
                                 :close-on-select="false"
                                 :placeholder="'ALL FACILITIES'"
                                 :searchable="false"
                                 :multiple="true"
                                 :options="facilityOptions"
                                 @input="patch('replace', '/facilityRestrictions', $event)"></multiselect>
                  </div>
                  <div class="col-8">
                    <b-form-textarea class="project-description"
                           size="sm"
                           rows="2"
                           max-rows="10"
                           placeholder="Project Description"
                           v-model="project.description"
                           @change="patch('replace', '/description', $event)"></b-form-textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card my-1">
            <div class="card-header p-1">
              <h5 class="d-inline">Conditional Rules</h5>
              <div class="add-button float-right">
                <b-button class="btn-add-rule mx-2 icon-btn" variant="primary" size="sm" @click="showManageRuleModel()">
                  <PlusBoxIcon /> Add Condition
                </b-button>
              </div>
            </div>
            <div class="card-body p-1">
              <div class="container">
                <drop-list :items="rules"
                            @reorder="$event.apply(rules)"
                            accepts-type="rule"
                            :row="true"
                            :no-animations="true">
                  <h4 class="text-center my-3" v-if="!rules || !rules.length">No Rules Defined</h4>
                  <template v-slot:item="{item}">
                    <drag tag="div" class="row conditional-rule pl-2" handle=".rule-drag-handle" :key="item.id" :data="item" type="rule">
                      <drag-horizontal-icon class="drag-handle rule-drag-handle" title="Drag Conditional Rule"></drag-horizontal-icon>
                      <div class="col-7 pl-4">
                        <b-button class="" variant="link" size="sm" @click="showManageRuleModel(item)">{{ item.label }}</b-button>
                      </div>
                      <div class="col-3">
                        <b-select size="sm" v-model="item.type" :disabled="true">
                          <b-select-option value="radio">Radio Button Options</b-select-option>
                          <b-select-option value="select">Drop Down List</b-select-option>
                          <b-select-option value="checkbox">Checkbox List</b-select-option>
                        </b-select>
                      </div>
                      <div class="col-2 text-right">
                        <b-button class="btn-add-rule mx-1" variant="outline-primary" size="sm" @click="showManageRuleModel(item)">
                          <LeadPencilIcon />
                        </b-button>
                        <b-button class="btn-add-rule mx-1" variant="outline-danger" size="sm" @click="removeRule(item)">
                          <TrashCanIcon />
                        </b-button>
                      </div>

                      <!-- <template v-slot:drag-image>
                      <div class="drag-image">DRAG</div>
                    </template> -->
                    </drag>
                  </template>
                  <template v-slot:feedback="{data}">
                    <div class="feedback" :key="data.id" />
                  </template>
                  <template v-slot:reordering-feedback="{}">
                    <div class="reordering-feedback" key="feedback" />
                  </template>
                </drop-list>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="card">
        <div class="card-header p-2">
          <h5 class="d-inline">Template Checklist Projects</h5>
          <b-button class="btn-add-area icon-btn ml-2 float-right" variant="primary" size="sm" @click="showChecklistProjectEditor('New Template Checklist', null)">
            <PlusBoxIcon /> New Template Checklist
          </b-button>
        </div>
        <div class="card-body">
          <drop-list :items="templateProjectsSorted"
                     @reorder="reorderChecklistTemplate($event)"
                     accepts-type="template-projects">
            <template v-slot:item="{item}">
              <ProjectTemplate 
                :checklistTemplate="item" 
                :checklistProjectRules="availableRules" 
                :templates="allTemplateProjects"
                :key="item.projectId+'_'+item.checklistProjectId" 
                @gotoChecklist="showChecklistProjectEditor('Edit Template Checklist', item)"
                @filterChecklist="filterChecklist"
                @removeChecklist="removeChecklistTemplate"
                @updateChecklistTemplate="updateChecklistTemplate"
              ></ProjectTemplate>
            </template>
            <template v-slot:feedback="{data}">
              <div class="feedback" :key="data.id" />
            </template>
            <template v-slot:reordering-feedback="{}">
              <div class="reordering-feedback" key="feedback123" />
            </template>
          </drop-list>
        </div>
      </div>
    </div>











    <b-modal :title="checklistEditorTitle"
             centered
             ref="checklistEditorRef"
             size="md">
      <div class="container" v-if="selectedTemplateChecklistProject">
        <div class="row">
          <div class="col-4">
            Project Type:
          </div>
          <div class="col-8">
            <select class="form-control-sm float-right mx-1" v-model="selectedTemplateChecklistProject.projectType">
              <option :value="null" disabled>Select Project Type</option>
              <option v-for="activeType in activeProjectTypes" :key="activeType.code" :value="activeType.code">{{activeType.name}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            Template:
          </div>
          <div class="col-8" v-if="activeTemplateProjects && activeTemplateProjects.length">
            <select class="form-control-sm float-right mx-1" v-model="selectedTemplateChecklistProject.checklistProjectId">
              <option :value="null" disabled>Select Template</option>
              <option v-for="template in activeTemplateProjects" :key="template.id" :value="template.id">{{ template.name }}</option>
            </select>
          </div>
          <div class="col-8" v-else>
            Unable to locate templates for project type: {{ selectedTemplateChecklistProject.projectType }}.
          </div>
        </div>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="addNewChecklistProject()">Add</b-button>
      </template>
    </b-modal>

    <ManageRule ref="ManageRuleModal"
                :rule="selectedRule"
                :parent="project" 
                @change="updateRule">
    </ManageRule>

    <EditConditional ref="filterConditionModalRef"
                     :activeConditional="activeConditional"
                     :rules="availableRules"
                     @change="updateConditional">
    </EditConditional>
  </div>
</template>

<script>
  import Vue from "vue";
  import { mapGetters, mapState } from "vuex";
  import { Client, Operation, ChecklistDto, ChecklistType, ChecklistProjectType, ProjectChecklistProjectDto } from "../../code/EngineeringChecklist.Api";
  // import { Vuex, mapState, mapMutations, mapGetters } from "vuex";
  import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
  import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
  import TransferIcon from 'vue-material-design-icons/Transfer.vue';
  import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
  import DragHorizontalIcon from 'vue-material-design-icons/DragHorizontalVariant.vue';
  import FilterInlineViewer from './FilterInlineViewer.vue'
  import FilterIcon from 'vue-material-design-icons/Filter.vue';

  import AddRepeatChecklist from "../modals/AddRepeatChecklist.vue"
  import ManageRule from "../modals/ManageRule.vue"
  import EditConditional from "../modals/EditConditional.vue"
  import ProjectTemplate from "./ProjectTemplate.vue"

  import { Drag, Drop, DropList } from "vue-easy-dnd";
  import Multiselect from "vue-multiselect";

  export default Vue.extend({
    name: "ProjectDetail",
    components: {
      Drag,
      // Drop,
      DropList,
      //FilterIcon,
      PlusBoxIcon,
      TrashCanIcon,
      //TransferIcon,
      DragHorizontalIcon,
      LeadPencilIcon,
      Multiselect,
      ManageRule,
      //FilterInlineViewer,
      ProjectTemplate,
      EditConditional
    },
    props: {
      project: { type: Object, required: true }
    },
    data() {
      return {
        activeConditional: null,
        ChecklistType,
        loading: false,
        rule: null,
        patches: [],
        checklistEditorTitle: 'Add Checklist',
        loadingTemplateProjects: false,
        allTemplateProjects: [],
        selectedTemplateChecklistProject: null,
        selectedRule: {},
        projectTypeOverride: null,
      };
    },
    computed: {
      ...mapState(['facilities', 'job']),
      ...mapGetters(['activeProjectTypes']),
      facilityOptions: function () {
        return _.map(this.facilities, function (item) { return item.code });
      },
      selectedProjectType: function () {
        return this.selectedTemplateChecklistProject && this.selectedTemplateChecklistProject.projectType ? this.selectedTemplateChecklistProject.projectType : null;
      },
      activeTemplateProjects : function () {
        return this.selectedProjectType ? _.filter(this.allTemplateProjects, (project) => project.projectTypesAllowed.includes(this.selectedProjectType)) : [];
      },

      templateProjects: function () {
        return this.project ? this.project.templateProjects : [];
      },
      templateProjectsSorted: function () {
        return _.sortBy(this.templateProjects, ["sortOrder"]);
      },
      availableRules: function () {
        return [
          {
            id: "active-facility", 
            type: "checkbox", 
            label: "Active Facility", 
            operators: ['Equals', 'Not Equals'], 
            choices: _.map(this.facilityOptions, function (item) { return { label: item, value: item }; }), value: null 
          },
          ...this.rules
        ];
      },
      rules: function () {
        return this.project ? this.project.rules || [] : [];
      },
    },
    methods: {
      fetchTemplateChecklistProjects: function () {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        
        this.loadingTemplateProjects = true;

        c.checklistProjects_GetAll(null,true,false,null,null)
          .then(function (checklistProjects) {
            this.allTemplateProjects = checklistProjects || [];
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          })
          .finally(function () {
            this.loadingTemplateProjects = false;
          }.bind(this));
      },




      showManageRuleModel: function (rule) {
        if (rule) {
          this.selectedRule = rule;
        } else {
          this.selectedRule = { new: true, type: "", label: "", operators: ['Equals', 'Not Equals'], choices: [], value: null };
        }

        this.$refs['ManageRuleModal'].show();
      },
      updateRule: function (rule) {
        console.log('Rule Updated', rule);

        if (rule && rule.type == 'toggle') {
          rule.choices = [
            { label: "Yes", value: "true" },
            { label: "No", value: "false" }
          ]
          //Convert this to a radio button rule
          rule.type = 'radio';
        }

        if (rule.new) {
          delete rule.new;
          this.rules.push(rule);
        } else {
          let idx = _.findIndex(this.rules, { id: this.selectedRule.id });
          this.$set(this.rules, idx, rule);
        }

        this.selectedRule = null;
      },

      saveRepeatChecklist: function (addOpts) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        c.repetitiveChecklists_CopyChecklistToProject(
          addOpts.templateId, this.project.id, addOpts.name, addOpts.checklistType)
          .then(function (newChecklist) {

            //InitializeChecklist(newChecklist);

            this.project.checklists.push(newChecklist);

            this.gotoChecklist(newChecklist);

          }.bind(this))
          .catch((error) => {
            console.log(error);
          })
      },
      gotoChecklist: function(checklist) {
        this.$emit('goto-checklist', checklist);
      },
      filterChecklist: function (checklist) {
        // if (condObj) {
        //   this.$set(condObj, 'conditionsObj', condObj.conditions ? JSON.parse(condObj.conditions) : {});
        // }

        this.activeConditional = checklist;

        this.$refs.filterConditionModalRef.show();
      },

      showFilterDialog: function (rule) {
        if (rule) {
          this.rule = rule;
        } else {
          this.rule = { new: true, type: "", label: "", operators: ['Equals', 'Not Equals'], choices: [], value: null };
        }

        this.$refs.createRuleModalRef.show();
      },
      addNewRule: function (hideModal) {
        if (this.rule && this.rule.type == 'toggle') {
          this.rule.choices = [
            { label: "Yes", value: "true" },
            { label: "No", value: "false" }
          ]
          //Convert this to a radio button rule
          this.rule.type = 'radio';
        }

        delete this.rule.new;

        this.rules.push(this.rule);

        if (hideModal) {
          hideModal()
        }
        // this.$refs.createRuleModalRef.hide();
      },
      addRuleChoice: function (rule) {
        rule.choices.push({ label: this.newFilterItemName, value: this.newFilterItemName });
        this.newFilterItemName = "";
      },
      removeRule: function (rule) {
        this.$bvModal.msgBoxConfirm('Are you sure?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          if (value) {
            this.remove(this.rules, rule);
          }
        }.bind(this));

      },

      remove(array, item) {
        let index = array.indexOf(item);
        if (index >= 0) {
          array.splice(index, 1);
        }
      },
      reorderChecklistTemplate(e) {
        let changes = [],
          start = e.from, end = e.to, step = 0;

        if (e.to > e.from) {
          //Subtract one... (1 based index, index is already -1)
          start = e.from;
          end = e.to;
          step = 0; // -1
        } else {
          //Increment 1 (and add 1 for 1 based index)
          start = e.to;
          end = e.from;
          step = 2; // +1
        }

        for (let index = 0; index < this.templateProjects.length; index++) {
          let cl = this.templateProjects[index];

          if (index == e.from) {
            if (cl.sortOrder != e.to + 1) {
              changes.push({ checklist: cl, sortOrder: e.to + 1 });
            }
          } else if (index >= start && index <= end) {
            //One of the items impacted by the move... Move it up or down
            if (cl.sortOrder != (index + step)) {
              changes.push({ checklist: cl, sortOrder: index + step });
            }
          } else {
            //Outside of the ones we are shifting... Just make sure it's 1 based index
            if (cl.sortOrder != (index + 1)) {
              changes.push({ checklist: cl, sortOrder: index + 1 });
            }
          }
        }

        for (const change of changes) {
          this.updateChecklistTemplate(change.checklist, change.sortOrder)
            .then(function (updatedChecklistTemplate) {
              if (updatedChecklistTemplate) {
                change.checklist.sortOrder = updatedChecklistTemplate.sortOrder;
              }
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            })
        }
      },
      updateConditional: function (condObj) {
        console.log('Conditional Updated', condObj);

        if (condObj) {
          let newConditional = JSON.stringify(condObj.conditionsObj);
          if (this.activeConditional.conditions != newConditional) {

            //this.updateChecklistContions(this.activeConditional, newConditional);
            this.$set(this.activeConditional, 'conditionsObj', newConditional ? JSON.parse(newConditional) : {});
            this.activeConditional.conditions = newConditional;

            this.updateChecklistTemplate(this.activeConditional)
              .then(function (updatedChecklistTemplate) {
                if (updatedChecklistTemplate) {
                  Object.assign(this.activeConditional, updatedChecklistTemplate);

                  this.$bvToast.toast(`Sucessfully updated conditional: "${this.activeConditional.name}"`, {
                    title: `Conditional updated.`,
                    variant: "success"
                  });
                  
                }
              }.bind(this))
              .finally(function () {
                this.activeConditional = null;
              }.bind(this));
          }
        }
      },
      updateChecklistTemplate: function (checklistTemplate, sortOrder) {
        console.log('Conditional Updated', checklistTemplate);

        if (!checklistTemplate) {
          return Promise.reject('checklistTemplate is required field');
        }

        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        return c.project_UpsertTemplateProject(
            checklistTemplate.projectId, 
            checklistTemplate.checklistProjectId, 
            checklistTemplate.projectType, 
            checklistTemplate.conditions, 
            (!sortOrder && sortOrder !== 0) ? checklistTemplate.sortOrder : sortOrder)
          .then(function (updatedChecklistTemplate) {
            if (updatedChecklistTemplate) {
              
              updatedChecklistTemplate.conditionsObj = updatedChecklistTemplate.conditions ? JSON.parse(updatedChecklistTemplate.conditions) : {};
              return updatedChecklistTemplate 
            }
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });

      },
/*
      updateChecklistProjectSortOrder: function (checklistTemplate, sortOrder) {
        if (checklistTemplate && sortOrder) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          c.checklists_Patch(checklistTemplate.id, [new Operation({ op: 'replace', path: '/sortOrder', value: sortOrder })])
            .then(function (updatedChecklist) {
              if (updatedChecklist) {
                delete updatedChecklist.areas;
                Object.assign(checklistTemplate, updatedChecklist);
              }
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            })
        }

      },
 */
      showChecklistProjectEditor: function (title, checklist) {
        this.checklistEditorTitle = title;

        if (checklist) {
          this.selectedTemplateChecklistProject = checklist;
        } else {
          this.selectedTemplateChecklistProject = new ProjectChecklistProjectDto ({ projectId: this.project.projectId,  id: 0, projectType: null, sortOrder: 1 });
        }

        this.$refs.checklistEditorRef.show();
      },
      addNewChecklistProject() {
        var checklistProject = new ProjectChecklistProjectDto(this.selectedTemplateChecklistProject);

        checklistProject.projectId = this.project.projectId;
        checklistProject.sortOrder = this.project.templateProjects.length + 1;

        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        c.project_UpsertTemplateProject(checklistProject.projectId, checklistProject.checklistProjectId, checklistProject.projectType, checklistProject.conditions, checklistProject.sortOrder)
          .then(function (newChecklistProject) {
            this.project.templateProjects.push(newChecklistProject);

            this.$refs.checklistEditorRef.hide();
            this.selectedTemplateChecklistProject = null;

          }.bind(this))
          .catch(function (error) {
            console.log(error);
          }.bind(this));
      },
      removeChecklistTemplate(checklistTemplate) {

        this.$bvModal.msgBoxConfirm(`Remove the '${checklistTemplate.name}' checklist?`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          if (value) {
            if (checklistTemplate && checklistTemplate.projectId) {
              c.project_RemoveTemplateProject(checklistTemplate.projectId, checklistTemplate.checklistProjectId)
                .then(function (data) {

                  this.remove(this.project.templateProjects, checklistTemplate);

                  this.$bvToast.toast(`Sucessfully removed checklist: "${checklistTemplate.name}"`, {
                    title: `Checklist removed.`,
                    variant: "success"
                  });
                }.bind(this))
                .catch(function (error) {
                  this.$bvToast.toast(`Failed to remove checklist: ${error.message}`, {
                    title: `Failed to remove checklist.`,
                    variant: "danger"
                  });

                }.bind(this));
            }
          }
        }.bind(this))
      },
      // convertChecklist(checklist) {

      //   this.$bvModal.msgBoxConfirm(`Create Standard checklist template from '${checklist.name}'?`, {
      //     title: 'Please Confirm',
      //     size: 'sm',
      //     buttonSize: 'sm',
      //     okVariant: 'primary',
      //     okTitle: 'YES',
      //     cancelTitle: 'NO',
      //     footerClass: 'p-2',
      //     hideHeaderClose: false,
      //     centered: true
      //   }).then(function (value) {

      //     var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      //     if (value) {
      //       if (checklist && checklist.id) {
      //         c.checklists_CreateTemplateFromChecklist(checklist.id, checklist.name + " copy")
      //           .then(function (data) {

      //             this.$bvToast.toast(`Sucessfully created checklist template: "${data.name}"`, {
      //               title: `Checklist cloned.`,
      //               variant: "success"
      //             });

      //             //Send them over to the newly created checklist
      //             this.$router.push({ name: 'manage-repetitive-checklist', params: { checklistId: data.id } });

      //           }.bind(this))
      //           .catch(function (error) {
      //             this.$bvToast.toast(`Failed to create template checklist: ${error.message}`, {
      //               title: `Failed to clone checklist.`,
      //               variant: "danger"
      //             });

      //           }.bind(this));
      //       }
      //     }
      //   }.bind(this))
      // },


      patch: function (op, path, value) {
        let patch = _.find(this.patches, { op: op, path: path });

        if (patch) {
          patch.value = value;
        } else {
          patch = new Operation({ op: op, path: path, value: value });
          this.patches.push(patch);
        }
      },
      processProjectPatches: function () {

        if (this.patches && this.patches.length) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          c.project_Patch(this.project.projectId, this.patches)
            .then(function (updatedProject) {
              if (updatedProject) {
                delete updatedProject.templateProjects;
                delete updatedProject.checklistProjects;
                Object.assign(this.project, updatedProject);
              }
              this.patches = [];
            }.bind(this))
            .catch(function (error) {
              this.$bvToast.toast(error.message, {
                title: `Saving ${this.project.name} failed`,
                variant: "danger"
              });
            })
        }
      },
    },
    watch: {
      patches: function (newValue, oldValue) {
        if (newValue && newValue.length) {
          this.debouncedProcessProjectPatches();
        }
      },
      rules: {
        deep: true,
        handler: function (newValue, oldValue) {
          let newOptions = JSON.stringify(newValue || []);

          if (this.project.options != newOptions) {
            this.project.options = newOptions
            this.patch('replace', '/options', this.project.options);
          }
        }
      },
    },
    created() {
      console.log('Project.vue deprecated... Should not be calling this', this.project);
      debbuger;
      this.debouncedProcessProjectPatches = _.debounce(this.processProjectPatches, 1000, {
        leading: false,
        trailing: true
      });

      this.fetchTemplateChecklistProjects();
      // this.unsubscribeFromStore = this.$store.subscribe(
      //   function(mutation, state) {
      //     //$store.commit(`${toolId}/showMechanismDialog`, item)

      //     if (mutation.type == 'showChecklistProjectEditorDialog') {

      //       this.$bvModal.show('ChecklistProjectEditor');

      //       // this.editMoldComponent(mutation.payload);
      //     } else if (mutation.type == `${this.toolId}/showMechanismDialog`) {
      //       // this.editMechanism(mutation.payload);
      //     }
      //   }.bind(this)
      // );
    },
    beforeDestroy() {
      this.$store.commit('setProject', null);
    },
  });
</script>

<style lang="scss">
  .proj-heading{
    display: flex;
  }
  .proj-name {
    margin-left: 0.25em;
    width: 100%;
  }
</style>

