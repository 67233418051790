<template>
  <div class="login-page container h-100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-6 my-3 mx-auto">
        <b-overlay :show="authenticating" rounded="sm" variant="transparent">
          <div class="jumbotron">
            <h3 class="pb-4 text-center">SyBridge Checklist</h3>
            <div class="text-center">
              <a class="btn btn-outline-primary px-3 font-weight-bold" href="#" @click="MsalLogin">Login Using O365 Account</a>
            </div>
            <hr class="my-3" />
            <b-alert :show="!!authFailedError">{{authFailedError}}</b-alert>
            <b-form @submit.prevent="onSubmit" @reset.prevent="onCancel">
              <b-form-group label="User Id:" label-for="userIdInput">
                <b-form-input id="userIdInput"
                              type="text"
                              v-model="form.username"
                              required
                              placeholder="Enter your SyBridge user id">
                </b-form-input>
              </b-form-group>
              <b-form-group label="Password:" label-for="passwordInput">
                <b-form-input id="passwordInput"
                              type="password"
                              v-model="form.password"
                              required
                              placeholder="Enter your password">
                </b-form-input>
              </b-form-group>
              <button class="btn btn-primary float-right ml-2" type="submit">Login</button>
              <button class="btn btn-secondary float-right" type="reset">Cancel</button>
            </b-form>
            <br />
          </div>
        </b-overlay>
      </div>
    </div>
  </div>  
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "Login",
  components: {
  },
  data() {
    return {
      authenticating: false,
      failureCount: 0,
      authFailedError: '',
      form: {
        username: '',
        password: ''
      },
      authMode: 'cookie',
      authOptions: [
        { text: 'Cookie', value: 'cookie' },
        { text: 'JWT Bearer', value: 'jwt' }
      ]
    }
  },
  created () {
    if (window.localStorage.getItem('changeuser') === 'true') {
      window.localStorage.removeItem('changeuser')
      this.MsalLogin();
    }
  },
  computed: {
    ...mapGetters('context', [ 'isAuthenticated', 'msalSession' ]),
    // showError: function() {
    //   return 
    // }
  },
  watch: {
    isAuthenticated: function(newValue, oldValue) {
      if (newValue) {
        this.$router.push(this.$route.query.redirect || '/');
        // this.$router.push('/');
      }
    }
  },
  methods: {
    ...mapActions('context', [
      'restoreContext', 'login', 'msalProcessAuth', 'loginMsal', 'logout'
    ]),
    MsalLogin() {
			this.authenticating = true;

      this.loginMsal()
      .catch((err) => {
          console.log(err);
        })
      .finally(() => {  
        this.authenticating = false;
      });
        /*
  this.login({ authMethod: "msal", msal: this.$msal})
      .finally(() => {
				this.authenticating = false;
      });      
  */ 


      // if (window.sessionStorage.getItem('msal.interaction.status')) {
      //   //Will cause an msal interaction is in progress error if this isn't removed... not sure why it sticks around
      //   window.sessionStorage.removeItem('msal.interaction.status');
			// }

      // this.$msal.authenticate()
      //   .then((res) => {
			// 		this.msalProcessAuth({ msalAuthResult: res})
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      
		},
    onSubmit (evt) {
      this.authenticating = true;
      this.authFailedError = '';

      setTimeout(() => {
        this.login({ authMethod: this.authMode, credentials: this.form })
        .catch((res) => {
          if (!res.success) {
            this.authFailedError = res.error;
          }
          this.failureCount +=1;
        })
        .finally(() => {
          this.authenticating = false;
        });
      }, this.failureCount * 250);

    },
    onCancel (evt) {
      this.authFailedError = '';
    },
  },
}
</script>

<style lang="scss" scoped>
  .login-page {
    min-height: 100vh;
    position: fixed;
    left: 0;
    right: 0;
  }
  #app {
    background-color: red;
    height: 100%;

  }
</style>

