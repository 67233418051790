import { render, staticRenderFns } from "./FilterRule.vue?vue&type=template&id=ed8cb202&scoped=true"
import script from "./FilterRule.vue?vue&type=script&lang=js"
export * from "./FilterRule.vue?vue&type=script&lang=js"
import style0 from "./FilterRule.vue?vue&type=style&index=0&id=ed8cb202&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed8cb202",
  null
  
)

export default component.exports