<template>
  <div class="rule" :class="{'error': !condition, 'warn': invalidValues }">
    <span class="detail">
      {{operand}} {{op}} {{value}}
    </span>
    <a class="mx-2" href="#" v-if="!condition" @click.prevent="$emit('remove-rule', rule)">Remove -- Condition no longer valid</a>
    <a class="mx-2" href="#" v-if="invalidValues" @click.prevent="clearInvalidValues">Remove Invalid Values</a>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'FilterRule',
  props: {
    rules: {type: Array, required: true},
    rule: {type: Object, required: true},
  },
  data() {
    return {}
  },
  computed: {
    op: function() {
      return this.rule ? this.rule.operator : '';
    },
    operand: function() {
      return this.rule ? this.rule.operand : '';
    },
    value: function() {
      return this.rule ? this.rule.value : [];
    },
    condition: function() {
      return _.find(this.rules, {id: this.rule.rule });
    },
    invalidValues: function() {
      let invalidValues = null;

      if (this.condition) {
        if (_.isArray(this.rule.value)) {
          let diff = _.differenceBy(this.rule.value, this.condition.choices || [this.condition.value], (val) => typeof val === "object" ? val.value : val)
          if( diff.length > 0) 
            invalidValues = diff
        } else if (!_.find(this.condition.choices, {value: this.rule.value })) {
          invalidValues = this.rule.value
        }
      }

      return invalidValues;
    },
  },
  methods: {
    clearInvalidValues: function() {
      if (_.isArray(this.invalidValues)) {
        let values = _.clone(this.rule.value);
        for (const val of this.invalidValues) {
          let idx = _.indexOf(values, val)
          if (idx >= 0) {
            values.splice(idx,1);
          }
        }

        this.rule.value = values;
      } else {
        this.rule.value = "";
      }
    }
  },
  watch: {
  },
  created() {
  }
});
</script>

<style lang="scss" scoped>
.rule {
  &.warn .detail {
    background-color: orange;
    color: #EEE;
  }
  &.error .detail{
    background-color: red;
    color: #EEE;
  }

}
</style>

