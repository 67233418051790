<template>
  <drag 
    tag="div" 
    class="row question pl-2" 
    :class="{'has-filter': hasFilter }" 
    :key="question.id" 
    :data="question" 
    @cut="$emit('remove', question)" 
    type="question"
    handle=".question-drag-handle"
  >
    <drag-horizontal-icon class="drag-handle question-drag-handle" title="Drag Question"></drag-horizontal-icon>
    <div class="col-8 pl-4">
      <input 
        type="text" 
        class="prompt form-control" 
        v-model="question.prompt" 
        @change="patch('replace', 'prompt', $event.target.value)" >
      <div class="question-hint" v-if="question.hint">{{question.hint}}</div>
      <div class="question-conditions" v-if="hasFilter">
        <FilterInlineViewer :rules="checklistRules" :conditions="conditionsObj"></FilterInlineViewer>
      </div>
    </div>
    <div class="col-2">
      <select v-model="question.type" class="form-control" @change="patch('replace', 'type', $event.target.value)">
        <option value="yesNo">Yes / No</option>
        <option value="yesNoNA">Yes / No / NA</option>
        <option value="textbox">Text Input</option>
        <option value="lookupList">Lookup List</option>
        <option value="fileUpload">File Upload</option>
      </select>
      <select 
        v-if="question.type == 'lookupList'" 
        v-model="question.lookupListId" 
        class="form-control" 
        @change="patch('replace', 'lookupListId', $event.target.value)">
        <option :value="null" disabled>Select Lookup List</option>
        <option v-for="lookupList in lookupLists" :key="lookupList.id" :value="lookupList.id">{{lookupList.name}}</option>
      </select>
      
    </div>
    <div class="col-2 text-center">
      <a class="px-2" href="#" @click.prevent="$emit('moreInfo', question)"><CommentQuestionIcon title="More Info dialog" :fillColor="hasMoreInfo ? '#5cdd5c' : 'currentColor'"></CommentQuestionIcon></a>
      <a class="px-2" href="#" @click.prevent="$emit('filter', question)"><FilterIcon title="Conditional Display Criteria" :fillColor="hasFilter ? '#5cdd5c' : 'currentColor'"></FilterIcon></a>
      <a class="px-2" href="#" @click.prevent="$emit('edit', question)"><LeadPencilIcon title=""></LeadPencilIcon></a>
      <a class="px-2" href="#" @click.prevent="$emit('remove', question)"><TrashCanIcon title="" fillColor="red"></TrashCanIcon></a>
    </div>
    <template v-slot:drag-image>
      <div class="drag-image">DRAG</div>
    </template>
  </drag>
</template>

<script>
import Vue from 'vue';
import { Client, Operation, QuestionDto } from "../../code/EngineeringChecklist.Api";
import VueQueryBuilder from 'vue-query-builder';
import { Drag, DropMask } from "vue-easy-dnd";


import FilterInlineViewer from './FilterInlineViewer.vue'
import FilterIcon from 'vue-material-design-icons/Filter.vue';
import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
  import DragHorizontalIcon from 'vue-material-design-icons/DragHorizontalVariant.vue';
  import CommentQuestionIcon from 'vue-material-design-icons/CommentQuestion.vue';
  

export default Vue.extend({
  name: 'ManageQuestion',
  components: {
    FilterInlineViewer,
    Drag, //DropMask,
    DragHorizontalIcon, FilterIcon, TrashCanIcon, LeadPencilIcon, CommentQuestionIcon
  },
  props: {
    checklistRules: {type: Array, required: true },
    question: {type: Object, required: true},
  },
  data() {
    return {
      init: true,
      patches: [],
      lookupsLoaded: false,
      lookupLists: []
    }
  },
  computed: {
    hasFilter: function() {
      return this.question && this.question.conditionsObj && this.question.conditionsObj.children && this.question.conditionsObj.children.length
    },
    hasMoreInfo: function () {
      return this.question && this.question.moreInfoId;
    },
    conditionsObj: function() {
      return this.question.conditionsObj;
    },
    type: function() {
      return this.question.type
    },
  },
  methods: {
    patch: function(op, path, value) {
      //Don't patch while initializing
      if (this.init) return;

      let patch = _.find(this.patches, {op: op, path: path });
      
      if (patch) {
        patch.value = value;
      } else {
        patch = new Operation({op: op, path: path, value: value});
        this.patches.push(patch);
      }

    },
    processPatches: function() {

      if (this.patches && this.patches.length) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        c.questions_Patch(this.question.id, this.patches)
          .then(function(){
            this.patches = [];
          }.bind(this))
          .catch(function(error){
            this.$bvToast.toast(error.message, {
              title: `Saving question (id: ${this.question.id}) failed`,
              variant: "danger"
            });
          })

      }
    },
  },
  watch: {
    type: {
      immediate: true,
      handler: function(newValue,oldValue) {
        if (newValue == 'lookupList' && !this.lookupsLoaded) {
          this.$store.dispatch('fetchLookupLists')
            .then(function(lookupLists){
              this.lookupsLoaded = true;
              this.lookupLists = lookupLists;
            }.bind(this));
        }
      }
    },
    patches: function(newValue, oldValue) {
      if (newValue && newValue.length) {
        this.debouncedProcessPatches();
      }
    },
    conditionsObj: {
      deep: true,
      handler: function(newValue, OldValue)
      {
        if (this.question.conditions != JSON.stringify(newValue)) {
          this.question.conditions = JSON.stringify(newValue)
          this.patch('replace', '/conditions', this.question.conditions); 
        }
      }
    }
  },
  created() {
    this.$set(this.question, 'conditionsObj', JSON.parse(this.question.conditions || '{}'));

    this.debouncedProcessPatches = _.debounce(this.processPatches, 3000, {
      leading: false,
      trailing: true
    });

    setTimeout(() => {
      this.init = false;
    }, 1500);
  }
});
</script>

<style lang="scss">
.question {
  position: relative;
}
.drag-handle {
  cursor: grab;

  &.material-design-icon {
    position: absolute;
    left: 0.125em;
    top: -.125em;
    z-index: 1000;
    height: 1.5em;
    width: 1.5em;
  }  
}
</style>