<template>
  <div class="container-fluid">
    <!--<b-tabs @click="activeChecklist=null" >-->

    <div class="row">
      <div class="col-3">
        <b-nav class="my-2" pills vertical>
          <b-button class="mx-1 mb-2" @click="showAddChecklists()" v-if="!readonly">Add Component Checklist</b-button>
          <b-nav-item class="py-1 mx-1" :active="!activeChecklist" @click="activeChecklist=null">Overview</b-nav-item>
          <template v-for="menuItem in repeatMenuItems">
            <div class="card m-1" :key="menuItem.name">
              <div class="card-header p-1" role="tab">
                <!-- <plus-icon class="float-right"></plus-icon>
              <minus-icon class="float-right"></minus-icon> -->
                <b-button variant="link" block class="px-2 text-left font-weight-bold" @click="$root.$emit('bv::toggle::collapse', 'cav_collapse_' + menuItem.name)">{{menuItem.name}}s</b-button>
              </div>
              <b-collapse :id="'cav_collapse_' + menuItem.name" :visible="true" role="tabpanel">
                <div class="card-body p-1">
                  <b-nav-item link-classes="d-flex flex-row p-1 " v-for="subItem in menuItem.children" :key="subItem.name" :active="subItem==activeChecklist" @click="activeChecklist=subItem">
                    <div class="col-8 p-0">
                      {{subItem.name}}<ClipboardCheckIcon class="ml-2" v-if="subItem.signOffAt" :size="32" fillColor="#28a745" title="Signed Off"></ClipboardCheckIcon>
                      <ClipboardCheckIcon class="ml-2" v-if="subItem.leaderSignOffAt" :size="32" fillColor="#5cb0eb" title="Leader Signed Off"></ClipboardCheckIcon>
                    </div>
                    <div class="col-1 p-0">
                      <b-badge :variant="subItem.status.issue ? 'danger' : 'secondary'">{{subItem.status.issue}}</b-badge>
                    </div>
                    <div class="col-2 percent-col px-1 pt-1 text-center">
                      <b-progress v-if="subItem.status" height="1.125rem" show-progress :max="subItem.status.count">
                        <b-progress-bar :value="subItem.status.accepted" variant="success"></b-progress-bar>
                      </b-progress>
                    </div>
                    <div class="col-1 p-0 text-center" v-if="!readonly">
                      <a class="" href="#" @click.stop.prevent="removeChecklist(subItem)">
                        <TrashCanIcon title="" fillColor="red"></TrashCanIcon>
                      </a>
                    </div>
                  </b-nav-item>
                </div>
              </b-collapse>
            </div>
          </template>
        </b-nav>
      </div>
      <div class="col-9" v-if="activeChecklist">
        <transition name="fade">
          <checklist :checklist="activeChecklist" :readonly="readonly" :blockUpdates="true" :key="activeChecklist.id"></checklist>
        </transition>
      </div>
      <div class="col-9 p-5 text-center" v-else>
        Select one of the checklists on the left.
      </div>
    </div>


    <!-- Generate all of the child checklists but hide them so that all the events wired up and logic works as expected -->
    <b-tabs pills vertical v-show="false">
      <b-tab v-for="checklist in repetitiveChecklists" :key="checklist.id">
        <checklist :checklist="checklist" :readonly="readonly"></checklist>
      </b-tab>
    </b-tabs>


    <AddRepeatChecklist ref="AddRepeatChecklistModal"
                        :project="project"
                        :checklistType="ChecklistType.Repetitive"
                        :forceSuffix="true"
                        @add-checklist="saveRepeatChecklist">
    </AddRepeatChecklist>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { Client, Operation, QuestionDto, ChecklistType } from "../code/EngineeringChecklist.Api";
  import { InitializeChecklist } from "../code/Initializers"
  import Checklist from './Checklist.vue';
  import AddRepeatChecklist from "./modals/AddRepeatChecklist.vue"
  import ClipboardCheckIcon from 'vue-material-design-icons/ClipboardCheck.vue';

// import MinusIcon from 'vue-material-design-icons/Minus.vue';
// import PlusIcon from 'vue-material-design-icons/Plus.vue';
import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';

export default Vue.extend({
  name: 'RepetitiveChecklists',
  components: {
    Checklist,
    TrashCanIcon,
    AddRepeatChecklist,
    ClipboardCheckIcon
  },
  props: {
    project: {type: Object, required: true},
    readonly: {type: Boolean, required: true }
  },
  data() {
    return {
      ChecklistType,
      activeTab: { location: 'Core', item: 'Overview', type: 'overview', context: null },
      activeChecklist: null,
      templatesLoaded: false,
      repetitiveTemplates: [],


      patches: [],
      addChecklistDialog: {
        visible: false, 
        selectedTemplateId: null,
        type: 'Repetitive',
        newName: '' 
      },

    }
  },
  computed: {

    checklists: function() {
      return this.project.checklists;
    },
    repetitiveChecklists: function() {
      return _.sortBy(_.filter(this.project.checklists, {type: 'repetitive'}) || [], ['name']);
    },
    repeatMenuItems: function() {
      return this.getMenuStructure(this.repetitiveChecklists);
    },


    dialogTemplates: function() {
      let filterCrit = {};
      filterCrit[this.addChecklistDialog.type] = true;

      return this.templatesLoaded ? _.filter(this.repetitiveTemplates, filterCrit) : [];
    },
    // coreTemplates: function() {
    //   return this.templatesLoaded ? _.filter(this.repetitiveTemplates, { core: true }) : [];
    // },
    // cavityTemplates: function() {
    //   return this.templatesLoaded ? _.filter(this.repetitiveTemplates, { cavity: true }) : [];
    // }

  },
  methods: {
    getMenuStructure: function(mechChecklists) {
      var groups = _.groupBy(
        mechChecklists, 
        function(checklist) {
          //Figure out the first word in head checklist... use that for grouping

          let delimIndex = checklist.name.search(/[\s-_]/);
          if (delimIndex == 0 && checklist.name && checklist.name.length > 1) {
            delimIndex = checklist.name.substr(1).search(/[\s-_]/);
            
            if (delimIndex >= 0)
              delimIndex += 1;
          }
          return delimIndex > 0 ? checklist.name.substr(0, delimIndex) : checklist.name
        });

        return _.map(Object.keys(groups), function(key) {
          return { name: key, children: groups[key] }
          // if (groups[key].length > 1) {
          //   return { name: key, children: groups[key] }
          // } else {
          //   return { name: key, checklist: groups[key][0] }
          // }
        });
    },
    showAddChecklists: function() {
      this.$refs['AddRepeatChecklistModal'].show();
    },
    saveRepeatChecklist: function (addOpts) {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      c.repetitiveChecklists_CopyChecklistToProject(
        addOpts.templateId, this.project.id, addOpts.name, addOpts.checklistType)
        .then(function (newChecklist) {

          InitializeChecklist(newChecklist);

          this.project.checklists.push(newChecklist);

          //Go to newly created checklist
          setTimeout(function () {
            this.activeChecklist = newChecklist;
          }.bind(this), 100)

        }.bind(this))
        .catch((error) => {
          console.log(error);
        })
    },
    remove(array, item) {
      let index = array.indexOf(item);
      if (index >= 0) {
        array.splice(index, 1);
      }
    },
    removeChecklist(checklist) {
      
      this.$bvModal.msgBoxConfirm(`Remove the '${checklist.name}' checklist?`, { 
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(function(value) {

        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        if (value) {
          if (checklist && checklist.id) {
            c.checklists_Delete(checklist.id)
              .then(function(data){

                if (this.activeChecklist == checklist) {
                  this.activeChecklist = null;
                } else if (this.coreActiveChecklist == checklist) {
                  this.coreActiveChecklist = null
                }

                this.remove(this.project.checklists, checklist);

                this.$bvToast.toast(`Sucessfully removed checklist: "${checklist.name}"`, {
                  title: `Checklist removed.`,
                  variant: "success"
                });
              }.bind(this))
              .catch(function(error) {
                this.$bvToast.toast(`Failed to remove checklist: ${error.message}`, {
                  title: `Failed to remove checklist.`,
                  variant: "danger"
                });

              }.bind(this));
          } 
        }
      }.bind(this))
    }
  },
  watch: {
  },
  created() {
    
  }
});
</script>

<style lang="scss">
.nav-item {
  .percent-col {
    font-size: 0.25em;
  }
}
  // .fade-enter-active,
  // .fade-leave-active {
  //   transition: opacity 0.05s ease;
  // }

  // .fade-enter-from,
  // .fade-leave-to {
  //   opacity: 0;
  // }
</style>