<template>
  <div class="checklist">
    <div class="container">
      <div class="row">
        <div class="col-9">
          <h2 class="proj-heading"><label class="proj-label" v-if="project.isTemplate">Template: </label><label v-else>Project: </label> <input class="proj-name" v-model.trim.lazy="project.name" @change="patch('replace', '/name', $event.target.value)" placeholder="Enter Template Project name" /></h2>
        </div>
        <div class="col-3 text-right">
          <router-link :to="{ name: 'project', params: { projectId: project.id }}" v-if="!project.isTemplate">
            <b-button class="btn-review-project ml-2" variant="success" size="sm">
              Return to Checklist
            </b-button>
          </router-link>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-12">
          <b-form-textarea class="project-description"
                           size="sm"
                           rows="2"
                           max-rows="10"
                           placeholder="Project Description"
                           v-model="project.description"
                           @change="patch('replace', '/description', $event)"></b-form-textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card my-1">
            <div class="card-header p-1">Project Access Options</div>
            <div class="card-body p-1">
              <div class="container">
                <div class="row">
                  <div class="col-6">Project Types</div>
                  <div class="col-6">Facility Restrictions</div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <multiselect v-model.lazy="project.projectTypesAllowed"
                                 :showLabels="false"
                                 :close-on-select="false"
                                 :placeholder="'ALL PROJECT TYPES'"
                                 :searchable="false"
                                 :multiple="true"
                                 :options="projectTypeOptions"
                                 @input="patch('replace', '/projectTypesAllowed', $event)"
                                ></multiselect>
                  </div>
                  <div class="col-6">
                    <multiselect v-model.lazy="project.facilityRestrictions"
                                 :showLabels="false"
                                 :close-on-select="false"
                                 :placeholder="'ALL FACILITIES'"
                                 :searchable="false"
                                 :multiple="true"
                                 :options="facilityOptions"
                                 @input="patch('replace', '/facilityRestrictions', $event)"></multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div class="row">
        <div class="col-12">
          <div class="card my-1">
            <div class="card-header p-1">
              <h5 class="d-inline">Conditional Rules</h5>
              <div class="add-button float-right">
                <b-button class="btn-add-rule mx-2 icon-btn" variant="primary" size="sm" @click="showManageRuleModel()">
                  <PlusBoxIcon /> Add Condition
                </b-button>
              </div>
            </div>
            <div class="card-body p-1">
              <div class="container">
                <drop-list :items="rules"
                            @reorder="$event.apply(rules)"
                            accepts-type="rule"
                            :row="true"
                            :no-animations="true">
                  <h4 class="text-center my-3" v-if="!rules || !rules.length">No Rules Defined</h4>
                  <template v-slot:item="{item}">
                    <drag tag="div" class="row conditional-rule pl-2" handle=".rule-drag-handle" :key="item.id" :data="item" type="rule">
                      <drag-horizontal-icon class="drag-handle rule-drag-handle" title="Drag Conditional Rule"></drag-horizontal-icon>
                      <div class="col-7 pl-4">
                        <b-button class="" variant="link" size="sm" @click="showManageRuleModel(item)">{{ item.label }}</b-button>
                      </div>
                      <div class="col-3">
                        <b-select size="sm" v-model="item.type" :disabled="true">
                          <b-select-option value="radio">Radio Button Options</b-select-option>
                          <b-select-option value="select">Drop Down List</b-select-option>
                          <b-select-option value="checkbox">Checkbox List</b-select-option>
                        </b-select>
                      </div>
                      <div class="col-2 text-right">
                        <b-button class="btn-add-rule mx-1" variant="outline-primary" size="sm" @click="showManageRuleModel(item)">
                          <LeadPencilIcon />
                        </b-button>
                        <b-button class="btn-add-rule mx-1" variant="outline-danger" size="sm" @click="removeRule(item)">
                          <TrashCanIcon />
                        </b-button>
                      </div>

                      <!-- <template v-slot:drag-image>
                      <div class="drag-image">DRAG</div>
                    </template> -->
                    </drag>
                  </template>
                  <template v-slot:feedback="{data}">
                    <div class="feedback" :key="data.id" />
                  </template>
                  <template v-slot:reordering-feedback="{}">
                    <div class="reordering-feedback" key="feedback" />
                  </template>
                </drop-list>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="card">
        <div class="card-header p-2">
          <h5 class="d-inline">Checklists</h5>
          <b-button class="btn-add-area icon-btn ml-2 float-right" variant="primary" size="sm" @click="showChecklistEditor('New Checklist', null)">
            <PlusBoxIcon /> New Checklist
          </b-button>

          <b-dropdown id="dropdown-1" text="Add Standard Checklist" variant="primary" size="sm" class="btn-add-area icon-btn ml-2 float-right">
            <template #button-content>
              <PlusBoxIcon class="mr-1" /> Add Standard Checklist
            </template>
            <b-dropdown-item v-for="ptype in project.projectTypesAllowed" :key="ptype" @click="showAddChecklists(ptype)">{{ptype}}</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="card-body">
          <drop-list :items="checklists"
                     @reorder="reorderChecklists($event)"
                     accepts-type="checklist">
            <template v-slot:item="{item}">
              <ProjectDetailChecklist 
                :checklist="item" 
                :checklistProjectRules="availableRules" 
                :key="item.id" 
                @gotoChecklist="gotoChecklist"
                @filterChecklist="filterChecklist"
                @convertChecklist="convertChecklist"
                @removeChecklist="removeChecklist"
              ></ProjectDetailChecklist>
            </template>
            <template v-slot:feedback="{data}">
              <div class="feedback" :key="data.id" />
            </template>
            <template v-slot:reordering-feedback="{}">
              <div class="reordering-feedback" key="feedback123" />
            </template>
          </drop-list>
        </div>
      </div>
    </div>

    <b-modal title="New Checklist"
             centered
             ref="checklistEditorRef"
             size="md">
      <div class="container" v-if="selectedChecklist">
        <div class="row">
          <div class="col-4">
            Name:
          </div>
          <div class="col-8">
            <input class="form-control" type="text" v-model="selectedChecklist.name">
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            Description:
          </div>
          <div class="col-8">
            <b-form-textarea id="textarea-small"
                             class="form-control"
                             size="sm"
                             placeholder="Area Description"
                             v-model="selectedChecklist.description"></b-form-textarea>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="addNewChecklist()">Add</b-button>
      </template>
    </b-modal>

    <AddRepeatChecklist ref="AddStdChecklistModal"
                        :project="project"
                        :checklistType="ChecklistType.Standard"
                        :forceSuffix="false"
                        :projectTypeOverride="projectTypeOverride"
                        @add-checklist="saveRepeatChecklist">
    </AddRepeatChecklist>

    <ManageRule ref="ManageRuleModal"
                :rule="selectedRule"
                :parent="project" 
                @change="updateRule">
    </ManageRule>

    <EditConditional ref="filterConditionModalRef"
                     :activeConditional="activeConditional"
                     :rules="availableRules"
                     @change="updateConditional">
    </EditConditional>
  </div>
</template>

<script>
  import Vue from "vue";
  import { mapGetters, mapState } from "vuex";
  import { Client, Operation, ChecklistDto, ChecklistType, ChecklistProjectType } from "../../code/EngineeringChecklist.Api";
  // import { Vuex, mapState, mapMutations, mapGetters } from "vuex";
  import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
  import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
  import TransferIcon from 'vue-material-design-icons/Transfer.vue';
  import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
  import DragHorizontalIcon from 'vue-material-design-icons/DragHorizontalVariant.vue';
  import FilterInlineViewer from './FilterInlineViewer.vue'
  import FilterIcon from 'vue-material-design-icons/Filter.vue';

  import AddRepeatChecklist from "../modals/AddRepeatChecklist.vue"
  import ManageRule from "../modals/ManageRule.vue"
  import EditConditional from "../modals/EditConditional.vue"
  import ProjectDetailChecklist from "./ProjectDetailChecklist.vue"

  import { Drag, Drop, DropList } from "vue-easy-dnd";
  import Multiselect from "vue-multiselect";

  export default Vue.extend({
    name: "ProjectDetail",
    components: {
      Drag,
      // Drop,
      DropList,
      //FilterIcon,
      PlusBoxIcon,
      TrashCanIcon,
      //TransferIcon,
      DragHorizontalIcon,
      LeadPencilIcon,
      Multiselect,
      AddRepeatChecklist,
      ManageRule,
      //FilterInlineViewer,
      ProjectDetailChecklist,
      EditConditional
    },
    props: {
      project: { type: Object, required: true }
    },
    data() {
      return {
        activeConditional: null,
        ChecklistType,
        loading: false,
        rule: null,
        patches: [],
        checklistEditorTitle: 'Add Checklist',
        selectedChecklist: null,
        selectedRule: {},
        projectTypeOverride: null,
      };
    },
    computed: {
      ...mapState(['facilities']),
      ...mapGetters(['activeProjectTypes']),
      projectTypeOptions: function () {
        return  this.activeProjectTypes && this.activeProjectTypes.length ? _.map(this.activeProjectTypes, function(item) { return item.code  }) : [];
      },
      facilityOptions: function () {
        return _.map(this.facilities, function (item) { return item.code });
      },


      checklists: function () {
        return this.project ? _.sortBy(this.project.checklists, ["sortOrder"]) : [];
      },
      availableRules: function () {
        return [
          {
            id: "active-facility", 
            type: "checkbox", 
            label: "Active Facility", 
            operators: ['Equals', 'Not Equals'], 
            choices: _.map(this.facilityOptions, function (item) { return { label: item, value: item }; }), value: null 
          },
          ...this.rules
        ];
      },
      rules: function () {
        return this.project.rules || [];
      },
    },
    methods: {
      showManageRuleModel: function (rule) {
        if (rule) {
          this.selectedRule = rule;
        } else {
          this.selectedRule = { new: true, type: "", label: "", operators: ['Equals', 'Not Equals'], choices: [], value: null };
        }

        this.$refs['ManageRuleModal'].show();
      },
      updateRule: function (rule) {
        console.log('Rule Updated', rule);

        if (rule && rule.type == 'toggle') {
          rule.choices = [
            { label: "Yes", value: "true" },
            { label: "No", value: "false" }
          ]
          //Convert this to a radio button rule
          rule.type = 'radio';
        }

        if (rule.new) {
          delete rule.new;
          this.rules.push(rule);
        } else {
          let idx = _.findIndex(this.rules, { id: this.selectedRule.id });
          this.$set(this.rules, idx, rule);
        }

        this.selectedRule = null;
      },
      showAddChecklists: function (projectType) {
        this.projectTypeOverride = projectType;
        this.$refs['AddStdChecklistModal'].show()
      },
      saveRepeatChecklist: function (addOpts) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        c.repetitiveChecklists_CopyChecklistToProject(
          addOpts.templateId, this.project.id, addOpts.name, addOpts.checklistType)
          .then(function (newChecklist) {

            //InitializeChecklist(newChecklist);

            this.project.checklists.push(newChecklist);

            this.gotoChecklist(newChecklist);

          }.bind(this))
          .catch((error) => {
            console.log(error);
          })
      },
      gotoChecklist: function(checklist) {
        this.$emit('goto-checklist', checklist);
      },
      filterChecklist: function (checklist) {
        // if (condObj) {
        //   this.$set(condObj, 'conditionsObj', condObj.conditions ? JSON.parse(condObj.conditions) : {});
        // }

        this.activeConditional = checklist;

        this.$refs.filterConditionModalRef.show();
      },

      showFilterDialog: function (rule) {
        if (rule) {
          this.rule = rule;
        } else {
          this.rule = { new: true, type: "", label: "", operators: ['Equals', 'Not Equals'], choices: [], value: null };
        }

        this.$refs.createRuleModalRef.show();
      },
      addNewRule: function (hideModal) {
        if (this.rule && this.rule.type == 'toggle') {
          this.rule.choices = [
            { label: "Yes", value: "true" },
            { label: "No", value: "false" }
          ]
          //Convert this to a radio button rule
          this.rule.type = 'radio';
        }

        delete this.rule.new;

        this.rules.push(this.rule);

        if (hideModal) {
          hideModal()
        }
        // this.$refs.createRuleModalRef.hide();
      },
      addRuleChoice: function (rule) {
        rule.choices.push({ label: this.newFilterItemName, value: this.newFilterItemName });
        this.newFilterItemName = "";
      },
      removeRule: function (rule) {
        this.$bvModal.msgBoxConfirm('Are you sure?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          if (value) {
            this.remove(this.rules, rule);
          }
        }.bind(this));

      },

      remove(array, item) {
        let index = array.indexOf(item);
        if (index >= 0) {
          array.splice(index, 1);
        }
      },
      reorderChecklists(e) {
        let checklistChanges = [],
          start = e.from, end = e.to, step = 0;

        if (e.to > e.from) {
          //Subtract one... (1 based index, index is already -1)
          start = e.from;
          end = e.to;
          step = 0; // -1
        } else {
          //Increment 1 (and add 1 for 1 based index)
          start = e.to;
          end = e.from;
          step = 2; // +1
        }

        for (let index = 0; index < this.checklists.length; index++) {
          let cl = this.checklists[index];

          if (index == e.from) {
            if (cl.sortOrder != e.to + 1) {
              checklistChanges.push({ checklist: cl, sortOrder: e.to + 1 });
            }
          } else if (index >= start && index <= end) {
            //One of the items impacted by the move... Move it up or down
            if (cl.sortOrder != (index + step)) {
              checklistChanges.push({ checklist: cl, sortOrder: index + step });
            }
          } else {
            //Outside of the ones we are shifting... Just make sure it's 1 based index
            if (cl.sortOrder != (index + 1)) {
              checklistChanges.push({ checklist: cl, sortOrder: index + 1 });
            }
          }
        }

        for (const change of checklistChanges) {
          this.updateChecklistSortOrder(change.checklist, change.sortOrder)
        }
      },
      updateConditional: function (condObj) {
        console.log('Conditional Updated', condObj);

        if (condObj) {
          let newConditional = JSON.stringify(condObj.conditionsObj);
          if (this.activeConditional.conditions != newConditional) {

            //this.updateChecklistContions(this.activeConditional, newConditional);
            this.$set(this.activeConditional, 'conditionsObj', newConditional ? JSON.parse(newConditional) : {});
            //this.activeConditional.conditions = newConditional;
            //this.patch('replace', '/conditions', this.activeConditional.conditions);
          }
        }

        this.activeConditional = null;
      },
      updateChecklistConditions: function (checklist) {
        console.log('Conditional Updated', checklist);

        if (checklist) {
          let newConditional = JSON.stringify(checklist.conditionsObj);
          if (this.activeConditional && this.activeConditional.conditions != newConditional) {

            var c = new Client(process.env.VUE_APP_API_URL, this.$http);

            c.checklists_Patch(this.activeConditional.id, [new Operation({ op: 'replace', path: '/conditions', value: newConditional })])
              .then(function (updatedChecklist) {
                if (updatedChecklist) {
                  delete updatedChecklist.areas;
                  updatedChecklist.conditionsObj = updatedChecklist.conditions ? JSON.parse(updatedChecklist.conditions) : {};
                  Object.assign(this.activeConditional, updatedChecklist);
                }
              }.bind(this))
              .catch(function (error) {
                console.log(error);
              })
              .finally(function () {
                this.activeConditional = null;
              }.bind(this));
          }
        }
      },
      updateChecklistSortOrder: function (checklist, sortOrder) {
        if (checklist && sortOrder) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          c.checklists_Patch(checklist.id, [new Operation({ op: 'replace', path: '/sortOrder', value: sortOrder })])
            .then(function (updatedChecklist) {
              if (updatedChecklist) {
                delete updatedChecklist.areas;
                Object.assign(checklist, updatedChecklist);
              }
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            })
        }

      },

      showChecklistEditor: function (title, checklist) {
        this.checklistEditorTitle = title;

        if (checklist) {
          this.selectedChecklist = checklist;
        } else {
          this.selectedChecklist = new ChecklistDto({ id: 0, type: ChecklistType.Standard, areas: [] });
        }

        this.$refs.checklistEditorRef.show();
      },
      addNewChecklist() {
        var checklist = new ChecklistDto(this.selectedChecklist);

        checklist.projectId = this.project.id;
        checklist.sortOrder = this.project.checklists.length + 1;

        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        c.checklists_Post(checklist)
          .then(function (newChecklist) {
            this.project.checklists.push(newChecklist);

            this.$refs.checklistEditorRef.hide();
            this.selectedChecklist = null;
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          }.bind(this));
      },
      removeChecklist(checklist) {

        this.$bvModal.msgBoxConfirm(`Remove the '${checklist.name}' checklist?`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          if (value) {
            if (checklist && checklist.id) {
              c.checklists_Delete(checklist.id)
                .then(function (data) {

                  this.remove(this.project.checklists, checklist);

                  this.$bvToast.toast(`Sucessfully removed checklist: "${checklist.name}"`, {
                    title: `Checklist removed.`,
                    variant: "success"
                  });
                }.bind(this))
                .catch(function (error) {
                  this.$bvToast.toast(`Failed to remove checklist: ${error.message}`, {
                    title: `Failed to remove checklist.`,
                    variant: "danger"
                  });

                }.bind(this));
            }
          }
        }.bind(this))
      },
      convertChecklist(checklist) {

        this.$bvModal.msgBoxConfirm(`Create Standard checklist template from '${checklist.name}'?`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          if (value) {
            if (checklist && checklist.id) {
              c.checklists_CreateTemplateFromChecklist(checklist.id, checklist.name + " copy")
                .then(function (data) {

                  this.$bvToast.toast(`Sucessfully created checklist template: "${data.name}"`, {
                    title: `Checklist cloned.`,
                    variant: "success"
                  });

                  //Send them over to the newly created checklist
                  this.$router.push({ name: 'manage-repetitive-checklist', params: { checklistId: data.id } });

                }.bind(this))
                .catch(function (error) {
                  this.$bvToast.toast(`Failed to create template checklist: ${error.message}`, {
                    title: `Failed to clone checklist.`,
                    variant: "danger"
                  });

                }.bind(this));
            }
          }
        }.bind(this))
      },


      patch: function (op, path, value) {
        let patch = _.find(this.patches, { op: op, path: path });

        if (patch) {
          patch.value = value;
        } else {
          patch = new Operation({ op: op, path: path, value: value });
          this.patches.push(patch);
        }
      },
      processProjectPatches: function () {

        if (this.patches && this.patches.length) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          c.checklistProjects_Patch(this.project.id, this.patches)
            .then(function (updatedProject) {
              if (updatedProject) {
                delete updatedProject.checklists;
                Object.assign(this.project, updatedProject);
              }
              this.patches = [];
            }.bind(this))
            .catch(function (error) {
              this.$bvToast.toast(error.message, {
                title: `Saving ${this.project.name} failed`,
                variant: "danger"
              });
            })
        }
      },
    },
    watch: {
      patches: function (newValue, oldValue) {
        if (newValue && newValue.length) {
          this.debouncedProcessProjectPatches();
        }
      },
      rules: {
        deep: true,
        handler: function (newValue, oldValue) {
          let newOptions = JSON.stringify(newValue || []);

          if (this.project.options != newOptions) {
            this.project.options = newOptions
            this.patch('replace', '/options', this.project.options);
          }
        }
      },
    },
    created() {
      this.debouncedProcessProjectPatches = _.debounce(this.processProjectPatches, 1000, {
        leading: false,
        trailing: true
      });

      // this.unsubscribeFromStore = this.$store.subscribe(
      //   function(mutation, state) {
      //     //$store.commit(`${toolId}/showMechanismDialog`, item)

      //     if (mutation.type == 'showChecklistProjectEditorDialog') {

      //       this.$bvModal.show('ChecklistProjectEditor');

      //       // this.editMoldComponent(mutation.payload);
      //     } else if (mutation.type == `${this.toolId}/showMechanismDialog`) {
      //       // this.editMechanism(mutation.payload);
      //     }
      //   }.bind(this)
      // );
    },
    beforeDestroy() {
      this.$store.commit('setProject', null);
    },
  });
</script>

<style lang="scss">
  .proj-heading{
    display: flex;
  }
  .proj-name {
    margin-left: 0.25em;
    width: 100%;
  }
</style>

