<template>
  <div class="container-fluid">
    <!--<b-tabs @click="activeChecklist=null" >-->

    <div class="row">
      <div class="col-3 px-1">
        <b-nav class="my-2 nav-tree" pills vertical v-if="currentJob">
          <div class="card m-1" v-for="job in jobs" :key="job.id">

            <div class="card-header job p-1 bold" :class="{active: (job.id == currentJob.id) }" role="tab">
              <b-button variant="link" block class="px-2 text-left font-weight-bold" @click="$root.$emit('bv::toggle::collapse', 'job_collapse_' + job.id)">{{ job.jobNum + '-' + job.wo}} TO: {{ job.woDate | moment("DD-MMM-YYYY") }}
                <span class="float-right" v-if="job.id == currentJob.id"><b-badge :variant="currentJob.unresolvedIssues ? 'danger' : 'secondary'">{{currentJob.unresolvedIssues || 0}}</b-badge> | {{ currentJob.progress / 100 | numeral('0%') }}</span>
                <span class="float-right" v-else><b-badge :variant="job.unresolvedIssues ? 'danger' : 'secondary'">{{job.unresolvedIssues || 0}}</b-badge> | {{ job.progress / 100 | numeral('0.[0]%') }}</span>
              </b-button>
            </div>
            <b-collapse class="job-panel" :id="'job_collapse_' + job.id" :visible="job.id == currentJob.id" role="tabpanel">
              <div class="card-body p-1 " v-if="job.id == currentJob.id">
                <div class="card m-1" v-for="proj in job.checklistProjects" :key="proj.id">
                  <div class="card-header checklist-project p-1" :class="{active:proj.id == project.id}" role="tab">

                    <div class="row mx-1" v-if="proj.id == project.id">
                      <div class="col-9 p-0">
                        <b-button variant="link" block class=" text-left font-weight-bold" @click="$root.$emit('bv::toggle::collapse', 'chproj_collapse_' + proj.name)">{{ proj.projectTypeCode }}</b-button>
                      </div>
                      <div class="col-1 p-0">
                        <b-badge :variant="project.status.issue ? 'danger' : 'secondary'">{{project.status.issue}}</b-badge>
                      </div>
                      <div class="col-2 percent-col px-1 text-center">
                        <div class="progress-text">{{project.status.accepted / project.status.count | numeral('0%')}}</div>
                        <b-progress class="darker-progress" :max="project.status.count" height="20px">
                          <b-progress-bar :value="project.status.accepted" variant="success"></b-progress-bar>
                        </b-progress>
                      </div>
                    </div>
                    <div class="row mx-1" v-else>
                      <div class="col-9 p-0">
                        <router-link :to="{ name: 'project', params: { projectId: proj.id }}" >{{ proj.projectTypeCode }}</router-link>
                      </div>
                      <div class="col-1 p-0">
                        <b-badge :variant="proj.unresolvedIssues ? 'danger' : 'secondary'">{{proj.unresolvedIssues}}</b-badge>
                      </div>
                      <div class="col-2 percent-col px-1 text-center">
                        <div class="progress-text">{{proj.progress / 100 | numeral('0%')}}</div>
                        <b-progress class="darker-progress" :max="100" height="20px">
                          <b-progress-bar :value="proj.progress" variant="success"></b-progress-bar>
                        </b-progress>

                      </div>
                    </div>
                  </div>
                  <b-collapse :id="'chproj_collapse_' + proj.name" :visible="proj.id == project.id" role="tabpanel">
                    <div class="card-body p-1" v-if="proj.id == project.id">
                      <div class="row no-gutters mb-1">
                        <div class="col-6">
                          <b-button class="" :variant="!activeChecklist ? 'primary':'outline-primary'" block @click="activeChecklist=null">Overview</b-button>
                        </div>
                        <div class="col-6 pl-1">
                            <b-button class="" variant="outline-primary" block @click="showAddChecklists()" v-if="!readonly">Add Checklist</b-button>
                        </div>
                      </div>
                      

                      <b-nav-item link-classes="d-flex flex-row p-1 " v-for="subItem in standardChecklists" :key="subItem.name" :active="subItem==activeChecklist" @click="activeChecklist=subItem">
                        <div class="col-9 p-0">
                          {{subItem.name}}<ClipboardCheckIcon class="ml-2" v-if="subItem.signOffAt" :size="32" fillColor="#28a745" title="Signed Off"></ClipboardCheckIcon>
                          <ClipboardCheckIcon class="ml-2" v-if="subItem.leaderSignOffAt" :size="32" fillColor="#5cb0eb" title="Leader Signed Off"></ClipboardCheckIcon>
                        </div>
                        <div class="col-1 p-0">
                          <b-badge :variant="subItem.status.issue ? 'danger' : 'secondary'">{{subItem.status.issue}}</b-badge>
                        </div>
                        <div class="col-2 percent-col px-1 pt-1 text-center">
                          <b-progress v-if="subItem.status" height="1.125rem" show-progress :max="subItem.status.count">
                            <b-progress-bar :value="subItem.status.accepted" variant="success"></b-progress-bar>
                          </b-progress>
                        </div>
                      </b-nav-item>
                    </div>
                  </b-collapse>
                </div>
              </div>
              <div class="card-body p-1" v-else>
                <div class="card m-1" v-for="proj in job.checklistProjects" :key="proj.id">
                  <div class="card-header checklist-project p-1" role="tab">
                    <div class="row mx-1">
                      <div class="col-9 p-0">
                        <router-link v-if="proj.id != project.id" :to="{ name: 'project', params: { projectId: proj.id }}" >{{ proj.projectTypeCode }}</router-link>
                      </div>
                      <div class="col-1 p-0">
                        <b-badge :variant="proj.unresolvedIssues ? 'danger' : 'secondary'">{{proj.unresolvedIssues}}</b-badge>
                      </div>
                      <div class="col-2 percent-col px-1 text-center">
                        <div class="progress-text">{{proj.progress / 100 | numeral('0%')}}</div>
                        <b-progress class="darker-progress" height="20px" :max="100">
                          <b-progress-bar :value="proj.progress"  variant="success"></b-progress-bar>
                        </b-progress>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </b-nav>
        <b-nav class="my-2 nav-tree" pills vertical v-else>
          <div class="card m-1" v-for="proj in checklistProjects" :key="proj.id">
            <div class="card-header checklist-project p-1" :class="{active:proj.id == project.id}" role="tab">

              <div class="row mx-1" v-if="proj.id == project.id">
                <div class="col-9 p-0">
                  <b-button variant="link" block class=" text-left font-weight-bold" @click="$root.$emit('bv::toggle::collapse', 'chproj_collapse_' + proj.name)">{{ proj.projectTypeCode }}</b-button>
                </div>
                <div class="col-1 p-0">
                  <b-badge :variant="project.status.issue ? 'danger' : 'secondary'">{{project.status.issue}}</b-badge>
                </div>
                <div class="col-2 percent-col px-1 text-center">
                  <div class="progress-text">{{project.status.accepted / project.status.count | numeral('0%')}}</div>
                  <b-progress class="darker-progress" :max="project.status.count" height="20px">
                    <b-progress-bar :value="project.status.accepted" variant="success"></b-progress-bar>
                  </b-progress>
                </div>
              </div>
              <div class="row mx-1" v-else>
                <div class="col-9 p-0">
                  <router-link :to="{ name: 'project', params: { projectId: proj.id }}" >{{ proj.projectTypeCode }}</router-link>
                </div>
                <div class="col-1 p-0">
                  <b-badge :variant="proj.unresolvedIssues ? 'danger' : 'secondary'">{{proj.unresolvedIssues}}</b-badge>
                </div>
                <div class="col-2 percent-col px-1 text-center">
                  <div class="progress-text">{{proj.progress / 100 | numeral('0%')}}</div>
                  <b-progress class="darker-progress" :max="100" height="20px">
                    <b-progress-bar :value="proj.progress" variant="success"></b-progress-bar>
                  </b-progress>

                </div>
              </div>
            </div>
            <b-collapse :id="'chproj_collapse_' + proj.name" :visible="proj.id == project.id" role="tabpanel">
              <div class="card-body p-1" v-if="proj.id == project.id">
                <div class="row no-gutters mb-1">
                  <div class="col-6">
                    <b-button class="" :variant="!activeChecklist ? 'primary':'outline-primary'" block @click="activeChecklist=null">Overview</b-button>
                  </div>
                  <div class="col-6 pl-1">
                      <b-button class="" variant="outline-primary" block @click="showAddChecklists()" v-if="!readonly">Add Checklist</b-button>
                  </div>
                </div>
                

                <b-nav-item link-classes="d-flex flex-row p-1 " v-for="subItem in standardChecklists" :key="subItem.name" :active="subItem==activeChecklist" @click="activeChecklist=subItem">
                  <div class="col-9 p-0">
                    {{subItem.name}}<ClipboardCheckIcon class="ml-2" v-if="subItem.signOffAt" :size="32" fillColor="#28a745" title="Signed Off"></ClipboardCheckIcon>
                  </div>
                  <div class="col-1 p-0">
                    <b-badge :variant="subItem.status.issue ? 'danger' : 'secondary'">{{subItem.status.issue}}</b-badge>
                  </div>
                  <div class="col-2 percent-col px-1 pt-1 text-center">
                    <b-progress v-if="subItem.status" height="1.125rem" show-progress :max="subItem.status.count">
                      <b-progress-bar :value="subItem.status.accepted" variant="success"></b-progress-bar>
                    </b-progress>
                  </div>
                </b-nav-item>
              </div>
            </b-collapse>
          </div>
        </b-nav>
      </div>
      <div class="col-9 px-1" v-if="activeChecklist">
        <transition name="fade">
          <checklist :checklist="activeChecklist" :readonly="readonly" :blockUpdates="true" :key="activeChecklist.id"></checklist>
        </transition>
      </div>
      <div class="col-9 p-5" v-else>
        <div class="text-center mb-3">Select one of the checklists on the left.</div>
        
        <ProjectSummary :project="project" :readonly="readonly" v-if="authLevel > 0"></ProjectSummary>
      </div>
    </div>


    <!-- Generate all of the child checklists but hide them so that all the events wired up and logic works as expected -->
    <b-tabs pills vertical v-show="false">
      <b-tab v-for="checklist in standardChecklists" :key="checklist.id">
        <checklist :checklist="checklist" :readonly="readonly"></checklist>
      </b-tab>
    </b-tabs>


    <AddRepeatChecklist ref="AddRepeatChecklistModal"
                        :project="project"
                        :checklistType="ChecklistType.Standard"
                        :forceSuffix="true"
                        @add-checklist="saveRepeatChecklist">
    </AddRepeatChecklist>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapGetters, mapState } from 'vuex';
  import { Client, Operation, QuestionDto, ChecklistType } from "../code/EngineeringChecklist.Api";
  import { InitializeChecklist } from "../code/Initializers"
  import Checklist from './Checklist.vue';
  import ProjectSummary from './ProjectSummary.vue';
  import AddRepeatChecklist from "./modals/AddRepeatChecklist.vue"
  import ClipboardCheckIcon from 'vue-material-design-icons/ClipboardCheck.vue';

// import MinusIcon from 'vue-material-design-icons/Minus.vue';
// import PlusIcon from 'vue-material-design-icons/Plus.vue';
import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
import moment from 'moment';

export default Vue.extend({
  name: 'StandardChecklists',
  components: {
    ProjectSummary,
    Checklist,
    //TrashCanIcon,
    AddRepeatChecklist,
    ClipboardCheckIcon
  },
  props: {
    project: {type: Object, required: true},
    readonly: {type: Boolean, required: true }
  },
  data() {
    return {
      ChecklistType,
      activeTab: { location: 'Core', item: 'Overview', type: 'overview', context: null },
      activeChecklist: null,
      templatesLoaded: false,
      standardTemplates: [],


      patches: [],
      addChecklistDialog: {
        visible: false, 
        selectedTemplateId: null,
        type: 'Repetitive',
        newName: '' 
      },

    }
  },
  computed: {
    ...mapGetters('context', ['authLevel']),
    ...mapState(['jobs']),
    projectStatus: function() {
      return this.project ? this.project.status : null;
    },
    jobStatusVal: function() {
      let status = {progress: 0, unresolvedIssues: 0};
      let projStatus = this.projectStatus;

      if (!this.currentJob) {
        // status.progress = projStatus.progress;
        // status.unresolvedIssues = projStatus.unresolvedIssues;
        return status;
      }

      this.currentJob.checklistProjects.forEach(function(p) {
        if (p.id == this.project.id) {
          status.progress += (projStatus.accepted / projStatus.count) * 100;
          status.unresolvedIssues += projStatus.issue;   
        } else {
          status.progress += p.progress || 0;
          status.unresolvedIssues += p.unresolvedIssues || 0;  
        }
      }.bind(this));

      status = {progress: status.progress / this.checklistProjects.length, unresolvedIssues: status.unresolvedIssues};

      return status;
    },
    currentJob: function() {
      return this.jobs ? this.jobs.find(j => j.id == this.project.jobId) : null;
    },
    checklists: function() {
      return this.project.checklists;
    },
    standardChecklists: function () {
      return _.sortBy(_.filter(this.project.checklists, { type: ChecklistType.Standard, visible: true }) || [], ['sortOrder']);
    },
    hasRelatedProjects: function () {
      return this.currentJob && this.currentJob.checklistProjects && this.currentJob.checklistProjects.length > 1;
    },
    checklistProjects: function () {
      return this.currentJob && this.currentJob.checklistProjects ? this.currentJob.checklistProjects : [ this.project ];
    },

    dialogTemplates: function() {
      let filterCrit = {};
      filterCrit[this.addChecklistDialog.type] = true;

      return this.templatesLoaded ? _.filter(this.standardTemplates, filterCrit) : [];
    },
    // coreTemplates: function() {
    //   return this.templatesLoaded ? _.filter(this.standardTemplates, { core: true }) : [];
    // },
    // cavityTemplates: function() {
    //   return this.templatesLoaded ? _.filter(this.standardTemplates, { cavity: true }) : [];
    // }

  },
  methods: {

    showAddChecklists: function() {
      this.$refs['AddRepeatChecklistModal'].show();
    },
    saveRepeatChecklist: function (addOpts) {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      c.repetitiveChecklists_CopyChecklistToProject(
        addOpts.templateId, this.project.id, addOpts.name, addOpts.checklistType)
        .then(function (newChecklist) {

          InitializeChecklist(newChecklist);

          this.project.checklists.push(newChecklist);

          //Go to newly created checklist
          setTimeout(function () {
            this.activeChecklist = newChecklist;
          }.bind(this), 100)

        }.bind(this))
        .catch((error) => {
          console.log(error);
        })
    },

  },
  watch: {
    jobStatusVal: function(newValue, oldValue) {
      if(this.currentJob ) {
        
        if(this.currentJob.progress != newValue.progress)
          this.$set(this.currentJob, 'progress', newValue.progress);
        
        if(this.currentJob.unresolvedIssues != newValue.unresolvedIssues)
          this.$set(this.currentJob, 'unresolvedIssues', newValue.unresolvedIssues);
      }
    },
    $route: function (to, from){
      this.activeChecklist = null;
    },

  },
  // async beforeRouteUpdate(to, from, next) {

  // },
  created() {
    
  }
});
</script>

<style lang="scss">
.nav-item {
  .percent-col {
    font-size: 0.25em;
  }
}
.darker-progress.progress {
  background-color: #777;
}

.nav-tree {
  .job{
    &.active {
      background-color: #007bff20;
      color: #FFF;
      button {
        color: #666;
      }
    }
  }
  .card-header.checklist-project{
    //background-color: #0a5fb5;
    padding: 0 0.25rem !important;
    &.active {
      background-color: #007bff20;
      color: #FFF;
      color: #da8600
      button {
        color: #666;
      }
    }
    .progress {
      margin-top: -16px;
    }
    .progress-text {
      position: relative;
      margin-top: 9px;
      line-height: 10px;

      color: #EEE;
      font-size: 0.85em;
      font-weight: bold;
    }
    .percent-col {
      
    }
  }

}


</style>