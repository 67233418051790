<template>
  <b-modal title="Filtering Conditions"
           centered no-close-on-backdrop
           ref="filterConditionModalRef"
           size="lg">
    <div class="container p-0" v-if="newConditional">
      <div class="row">
        <div class="col-12">
          <h4>Conditions for: {{newConditional.name}}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <vue-query-builder :rules="rules" v-model="newConditional.conditionsObj"></vue-query-builder>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer="{ }">
      <b-button size="sm" variant="primary" @click="ok()">Ok</b-button>
    </template>
  </b-modal>
</template>

<script>
  import Vue from "vue";

  import VueQueryBuilder from 'vue-query-builder';
  
  export default Vue.extend({
  name: 'EditConditional',
  components: {
    VueQueryBuilder,
  },
  props: {
    activeConditional: {
      type: Object,
    },
    rules: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      newConditional: null,
    };
  },
  computed : {
    modal: function () {
      return this.$children[0];
    },
  },
  methods: {
    reset: function () {
      
      this.newConditional = _.cloneDeep(this.activeConditional); // Object.assign({}, this.activeConditional);
      this.newConditional.conditionsObj = (this.newConditional && this.newConditional.conditions) ? JSON.parse(this.newConditional.conditions) : {};
      //this.newFilterItemName = '';
    },
    show: function () {

      this.$nextTick(() => {
        this.reset();
        this.modal.show();
      });
    },
    hide: function () {
      this.modal.hide();
    },
    cancel() {
      // Handle cancel action
      this.hide()
    },
    ok() {
      // Handle ok action
      this.$emit('change', this.newConditional);

      this.hide();
    }
  },
  watch: {
  },
  created() {
  } 
});
</script>

<style lang="scss">

</style>