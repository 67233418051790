<template>
  <div class="mechanism-checklist">
    <b-overlay :show="loading" rounded="lg" />
    <manage-checklist v-if="!loading" :checklist="repeatChecklist" :isRepetitive="true" ></manage-checklist>
  </div>
</template>

<script>
import Vue from "vue";
import { Vuex, mapState, mapMutations, mapGetters } from "vuex";

import { Client } from "../code/EngineeringChecklist.Api";
import ManageChecklist from "../components/admin/ManageChecklist.vue";


export default Vue.extend({
  name: "ManageRepetitiveChecklist",
  components: {
    ManageChecklist
  },
  data() {
    return {
      activeTabIndex: 0,
      loading: true,
      repeatChecklist: null,
    };
  },
  computed: {
  },
  methods: {
  },
  watch: {
  },
  beforeRouteEnter(to, from, next) {

    if (to.params.checklistId !== undefined) {
      var c = new Client(process.env.VUE_APP_API_URL, Vue.prototype.$http);

      c.repetitiveChecklists_GetById(to.params.checklistId, true)
        .then(function(data) {
          next(vm => {
            vm.repeatChecklist = data;
            vm.loading = false;
          });
        })
        .catch(function(error) {
          next();
        });
    } else {
      next();
    }

  },
  created() {
  }
});
</script>

<style lang="scss">

</style>
