<template>
  <b-overlay :show="!lookupsLoaded" rounded="sm">
    <b-form-select 
      v-if="lookupList"
      v-model="syncValue"
      :options="lookupList.choices"
      :disabled="disabled"
    >
      <template #first>
        <b-form-select-option :value="null">Select Value</b-form-select-option>
      </template>
    </b-form-select>
  </b-overlay>
</template>

<script>
export default {
  name: "LookupListInput",
  props: {
    lookupListId: { type: Number, required: true },
    disabled: {type: Boolean, default: false },
    value: { type: [String, null], default: null },
    
  },
  data() {
    return {
      lookupsLoaded: false,
      lookupLists: [],
    };
  },
  computed: {
    lookupList: function() {
      if (this.lookupListId && this.lookupsLoaded) {
        return _.find(this.lookupLists, {id: this.lookupListId});
      } else {
        return null;
      }
    },
    syncValue: {
      get: function() {
        return this.value;
      },
      set: function(newVal) {
        this.$emit("input", newVal);
      }
    }
  },
  methods: {
    switched(value) {
      if (value == this.syncValue) {
        //unselect it if they click on the already selected item
        this.$emit("input", null);
      } else {
        this.$emit("input", value);
      }
    }
  },
  created() {
    this.$store.dispatch('fetchLookupLists')
      .then((lookupLists)=>{
        this.lookupLists = lookupLists;
        this.lookupsLoaded = true;
      })
  }
};
</script>
<style lang="scss" scoped>

</style>