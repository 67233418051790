<template>
  <div class="manage-users">
    <b-overlay :show="loading" rounded="sm">
      <div class="card">
        <div class="card-header p-2">
          <h4 class="m-0 mr-5 d-inline-block">Lookup Lists</h4> 
          <b-button class="btn-add-rule icon-btn float-right" variant="primary" size="sm" @click="showLookupListDialog()">
            <PlusBoxIcon /> Add
          </b-button>
        </div>
        <div class="card-body p-2">
          <div class="container-fluid" v-if="lookupLists.length">
            <div class="row" v-for="lookupList in lookupLists" :key="lookupList.id">
              <div class="col-10">
                {{lookupList.name}}
              </div>
              <div class="col-2">
                <a class="px-2" href="#" @click.prevent="showLookupListDialog(lookupList)">
                  <LeadPencilIcon title="Edit Lookup List" ></LeadPencilIcon>
                </a>
              </div>
            </div>
          </div>
          <div class="empty-card py-4" v-else>
            <h4 class="text-center"> No Lookup Lists</h4>
          </div>
        </div>
      </div>
    </b-overlay>

    <b-modal
      :title="(lookupList && lookupList.id) ? 'Edit Lookup List' : 'New Lookup List'"
      centered
      ref="lookupListModalRef"
      size="md"
    >
      <div class="container" v-if="lookupList">
        <div class="row py-1">
          <div class="col-4">
            Name:
          </div>
          <div class="col-8">
            <input class="form-control" type="text" v-model="lookupList.name">
          </div>
        </div>

        <div class="row py-1">
          <div class="col-4">
            Options:
          </div>
          <div class="col-8 ">
            <div class="row no-gutters py-1">
              <div class="col-10 pr-2">
                <b-form-input class="form-control pr-5 d-inline-block" type="text" size="sm" placeholder="Specify New Item" v-model.trim="lookupChoiceName"></b-form-input>
              </div>
              <div class="col-2 text-center">
                <b-button class="" size="sm" variant="primary" @click="addLookupChoice()">Add</b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row no-gutters py-1">
          <div class="col-12">
            <drop-list
              :items="lookupList.choices"
              @reorder="$event.apply(lookupList.choices)"
              mode="cut"
              :row="true"
              :no-animations="true"
            >
              <h4 class="text-center my-3" v-if="!lookupList.choices || !lookupList.choices.length">No Options Defined</h4>
              <template v-slot:item="{item}">
                <drag 
                  tag="div" 
                  class="row choice-row pl-3" 
                  :key="item" 
                  :data="item" 
                  handle=".choice-drag-handle"
                  @cut="remove(lookupList.choices, item)"
                >
                  <drag-horizontal-icon class="drag-handle choice-drag-handle" title="Drag Choice"></drag-horizontal-icon>
                  <div class="col-10 pl-4">
                    {{item}}
                  </div>
                  <div class="col-2">
                    <a class="float-right" href="#" @click.prevent="remove(lookupList.choices, item)"><TrashCanIcon title="" fillColor="red" ></TrashCanIcon></a> 
                  </div>
                </drag>
              </template>
              <template v-slot:feedback="{data}">
                <div class="feedback" :key="data.id || data.key"/>
              </template>
              <template v-slot:reordering-feedback="{}">
                <div class="reordering-feedback" key="feedback"/>
              </template>
            </drop-list>
          </div>
        </div>

      </div>
      <template v-slot:modal-footer="{ cancel, hide }">
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="saveLookupList(hide)">{{ lookupList.id ? 'Save' :  'Add' }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { Client, Operation, LookupListDto } from "../../code/EngineeringChecklist.Api";
import { DropList, Drag } from "vue-easy-dnd";

import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
import DragHorizontalIcon from 'vue-material-design-icons/DragHorizontalVariant.vue';

export default Vue.extend({
  name: 'ManageLookupLists',
  components: {
    DropList, Drag ,
    PlusBoxIcon,TrashCanIcon,LeadPencilIcon,DragHorizontalIcon,
  },
  props: {
  },
  data() {
    return {
      loading: true,
      lookupLists: [],
      lookupList: null,
      lookupChoiceName: '',
      patches: []
    }
  },
  computed: {
  
  },
  methods: {
    patch: function(op, path, value) {
      let patch = _.find(this.patches, {op: op, path: path });
      
      if (patch) {
        patch.value = value;
      } else {
        patch = new Operation({op: op, path: path, value: value});
        this.patches.push(patch);
      }
    },
    processPatches: function() {

      if (this.patches && this.patches.length) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        c.checklistAreas_Patch(this.area.id, this.patches)
          .then(function(){

            this.patches = [];
          }.bind(this))
          .catch(function(error){

          })

      }
    },

    remove(array, item) {
      let index = array.indexOf(item);
      if (index >= 0) {
        array.splice(index, 1);
      }
    },
    reorderChoices: function(e) {
      e.apply(this.lookupList.choices)
    },

    loadLookupLists() {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      this.loading = true;
      c.lookupLists_GetAll()
        .then(function(data){
          let lists = _.map(data, (item)=> {
            item.choices = JSON.parse(item.options || '[]');
            return item;
          });

          this.lookupLists = lists;
        }.bind(this))
        .catch(error => {
          console.log(error.response);
        })
        .finally(
          function() {
            this.loading = false;
          }.bind(this)
        );
    },
    showLookupListDialog(lookupList) {
      if (!lookupList) {
        let lookupList = new LookupListDto({id: 0 });
        lookupList.choices = [];
        this.lookupList = lookupList;
      } else {
        this.lookupList = lookupList;
      }

      this.$refs.lookupListModalRef.show();
    },
    addLookupChoice: function() {
      
      if(this.lookupList && this.lookupList.choices && this.lookupChoiceName) {
        let existing =  this.lookupList.choices.indexOf(this.lookupChoiceName);

        if (existing == -1) {
          this.lookupList.choices.push(this.lookupChoiceName);
          this.lookupChoiceName = "";
        }
      }
    },


    saveLookupList(hideModal) {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      this.loading = true;
      this.lookupList.options = JSON.stringify(this.lookupList.choices || []);

      if (this.lookupList && this.lookupList.id) {
        //Update

        c.lookupLists_Put(this.lookupList.id, this.lookupList)
          .then(function(data) {

          }.bind(this))
          .catch(error => {
            console.log(error.response);
          })
          .finally(function() {
            this.loading = false;
          }.bind(this));

      } else {
        //Insert

        c.lookupLists_Post(this.lookupList)
          .then(function(newLookupList) {
            newLookupList.choices = JSON.parse(newLookupList.options);
            this.lookupLists.push(newLookupList);
          }.bind(this))
          .catch(error => {
            console.log(error.response);
          })
          .finally(
            function() {
              this.loading = false;
            }.bind(this));
      }

      if (hideModal) {
        hideModal();
      }
    },
    removeMechanism(mechanism) {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);
      
      this.$bvModal.msgBoxConfirm('Are you sure?', { 
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(function(value) {

        if (value) {
          if (mechanism && mechanism.id) {
            c.repetitiveChecklists_Delete(mechanism.id)
              .then(function(data){
                this.$bvToast.toast(`Save failed for: ${error.message}`, {
                  title: `Mechanism Template: ${mechanism.name} removed.`,
                  variant: "success"
                });
              }.bind(this))
              .catch(function(error) {
                this.$bvToast.toast(`Save failed for: ${error.message}`, {
                  title: `Mechanism Template: ${mechanism.name} failed to delete.`,
                  variant: "danger"
                });
                this.mechanismChecklists.push(mechanism);
              }.bind(this));
          } 
          
          this.remove(this.mechanismChecklists, mechanism);    
        }
      }.bind(this))
    },
  },
  watch: {

  },
  created() {
    this.loadLookupLists();
  }
});
</script>

<style lang="scss" scoped>
.choice-row{ position: relative;}
</style>