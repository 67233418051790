<template>
  <div>
    <FilterIcon title="Conditional Display Criteria" :fill="'currentColor'"></FilterIcon>
    <FilterGroup class="filter-inline-viewer" :rules="rules" :group="conditions"></FilterGroup>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import FilterGroup from './FilterGroup.vue'
import FilterIcon from 'vue-material-design-icons/Filter.vue';

export default Vue.extend({
  name: 'FilterInlineViewer',
  components: {
    FilterGroup,
    FilterIcon
  },
  props: {
    rules: {type: Array, required: true},
    conditions: {type: Object, required: true},
  },
  data() {
    return {}
  },
  computed: {
    // hasConditions: function() {
      
    //   return !_.isEmpty(this.conditions) && this.conditions.children && this.conditions.children.length;
    // },
  },
  methods: {
  },
  watch: {
  },
  created() {
  }
});
</script>

