import Vue from "vue";
import Router from "vue-router";

import Jobs from "./views/Jobs.vue";
import ActiveProjects from "./views/ActiveProjects.vue";
import ProjectView from "./views/ProjectView.vue";
import AdminView from "./views/AdminView.vue";

import ManageJob from "./views/admin/ManageJob.vue";
import ManageProject from "./views/admin/ManageProject.vue";
import ManageChecklistProject from "./views/admin/ManageChecklistProject.vue";
import ManageRepetitiveChecklist from "./views/ManageRepetitiveChecklist.vue";

import Login from "./views/Login.vue";
import NotFound from "./views/NotFound.vue";
import NotAuthorized from "./views/NotAuthorized.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: ActiveProjects
    },
    {
      path: "/projects/",
      name: "active-projects",
      component: ActiveProjects
    },
    {
      path: "/jobs/",
      name: "jobs",
      component: Jobs
    },
    {
      path: "/project/:projectId?",
      name: "project",
      component: ProjectView,
      props: true
    },
    // {
    //   path: "/admin/",
    //   name: "manage-checklists",
    //   component: ManageChecklists
    // },
    {
      path: "/admin/",
      name: "admin-view",
      component: AdminView
    },
    {
      path: "/admin/job/:jobId?",
      name: "manage-job",
      component: ManageJob,
      props: true
    },
    {
      path: "/admin/project/:projectId?",
      name: "manage-project",
      component: ManageProject,
      props: true
    },
    {
      path: "/admin/projectchecklist/:projectId?",
      name: "manage-checklist-project",
      component: ManageChecklistProject,
      props: true
    },
    {
      path: "/admin/checklist/:checklistId?",
      name: "manage-repetitive-checklist",
      component: ManageRepetitiveChecklist,
      props: true
    },
    
    {
      path: "/login",
      name: "login",
      component: Login
    },
    // {
    //   path: "/401",
    //   name: "not-authenticated",
    //   component: NotAuthenticated
    // },
    {
      path: "/403",
      name: "not-authorized",
      component: NotAuthorized
    },
    {
      path: "*",
      name: "page-not-found",
      component: NotFound
    }
  ]
});

export default router;
