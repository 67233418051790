<template>
  <div class="checklist">
    <div class="container">
      <div class="row">
        <div class="col-6 project-name">
          Project:
          <label v-if="project.jobId">{{ project.name }}</label>
          <input v-else v-model.trim.lazy="project.name" @change="patch('replace', '/name', $event.target.value)" placeholder="Enter Project name" :disabled="readonly" />
        </div>
        <div class="col-3 project-type text-right">
          <label class="mr-1">Type:</label>{{ project.projectTypeCode | capitalize }}
        </div>
        <div class="col-3 text-right" v-if="authLevel > 0">
          <router-link v-if="!readonly && isAdmin" :to="{ name: 'manage-checklist-project', params: { projectId: project.id }}" class="btn btn-sm btn-outline-primary btn-edit-project ml-2">
            <LeadPencilIcon title="Edit Project" /> Edit
          </router-link>
          <b-button class="btn-remove-project ml-2" variant="outline-secondary" size="sm" @click="lockProject(project, !project.readonly)">
            <LockOpenVariantIcon v-if="project.readonly" title="Unlock Project" />
            <LockIcon v-else title="Lock Project" />
            {{project.readonly ? ' Unlock' : ' Lock'}}
          </b-button>
          <b-button class="btn-remove-project ml-2" variant="outline-warning" size="sm" @click="archiveProject(project, !project.archived)">
            <ArchiveArrowUpIcon v-if="project.archived" title="Unarchive Project" />
            <ArchiveIcon v-else title="Archive Project" />
            {{project.archived ? ' Unarchive' : ' Archive'}}
          </b-button>
        </div>
      </div>
      <div class="row my-3">
        <!-- <div class="col-12">
          <b-form-textarea class="project-description"
                           size="sm"
                           rows="2"
                           max-rows="10"
                           placeholder="Project Description"
                           v-model="project.description"
                           @change="patch('replace', '/description', $event)"
                           :readonly="readonly"></b-form-textarea>
        </div> -->
        <div class="col-12">
          <conditionals :checklist="project" :readonly="project.readonly"></conditionals>
        </div>
      </div>
      
      <div class="card">
        <div class="card-header p-2">
          <h5 class="d-inline">Checklists</h5>
          <b-button class="btn-add-checklist icon-btn float-right" variant="primary" size="sm" @click="showAddChecklists('Standard')" v-if="!readonly">
            <PlusBoxIcon /> Add Standard Checklist
          </b-button>
        </div>
        <div class="card-body">
          <div class="container-fluid">
            <div class="row summary-checklist-header">
              <div class="col-7"></div>
              <div class="col-2 text-center"># Issues</div>
              <div class="col-2 text-center">% Compl.</div>
              <div class="col-1 text-center">Hide</div>
            </div>
            <div class="checklist-project" v-for="checklistProject in checklistProjects" :key="checklistProject.id">  
              <div class="row summary-checklist-header no-gutters" v-if="hasRelatedProjects" >
                <div class="col-7">
                  <h5 class="d-inline">
                    {{checklistProject.id}} {{ checklistProject.projectTypeCode }} | {{ checklistProject.name}}
                    <!-- <router-link v-if="!readonly" :to="{ name: 'project', params: { projectId: checklistProject.id }}" >
                      {{checklistProject.id}} {{ checklistProject.projectTypeCode }} | {{ checklistProject.name}}
                    </router-link> -->
                  </h5>
                </div>
                <div class="col-2 text-center">
                  <b-badge :variant="checklistProject.unresolvedIssues ? 'danger' : 'secondary'">{{checklistProject.unresolvedIssues}}</b-badge>
                </div>
                <div class="col-2 text-center">
                  {{ checklistProject.progress | numeral('0%')}}
                  <b-progress height="6px" class="" :max="100">
                    <b-progress-bar :value="checklistProject.progress" variant="success"></b-progress-bar>
                  </b-progress>
                </div>
              </div>
              <template v-if="checklistProject.id == project.id">
                <ProjectSummaryChecklist v-for="checklist in standardChecklists" :key="checklist.id" :checklist="checklist" :checklistProjectRules="allRules"></ProjectSummaryChecklist>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AddRepeatChecklist ref="AddStdChecklistModal" 
                        :project="project" 
                        :checklistType="ChecklistType.Standard" 
                        :forceSuffix="false" 
                        @add-checklist="saveRepeatChecklist">
    </AddRepeatChecklist>
  </div>
</template>

<script>
  import Vue from "vue";
  import { mapGetters, mapState } from "vuex";

  import { Client, Operation, ChecklistType } from "../code/EngineeringChecklist.Api";
  import { filterObject } from "@/code/conditionals";
  import { InitializeChecklist } from "../code/Initializers"

  import Conditionals from "./Conditionals.vue";
  import FilterInlineViewer from "./admin/FilterInlineViewer.vue";
  import AddRepeatChecklist from "./modals/AddRepeatChecklist.vue"

  import ProjectSummaryChecklist from "./ProjectSummaryChecklist.vue";

  import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
  import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
  import ArchiveIcon from 'vue-material-design-icons/Archive.vue';
  import ArchiveArrowUpIcon from 'vue-material-design-icons/ArchiveArrowUp.vue';
  import LockIcon from 'vue-material-design-icons/Lock.vue';
  import LockOpenVariantIcon from 'vue-material-design-icons/LockOpenVariant.vue';
  import ClipboardTextOffIcon from 'vue-material-design-icons/ClipboardTextOff.vue';
  import ClipboardEditIcon from 'vue-material-design-icons/ClipboardEdit.vue';
  import ClipboardCheckIcon from 'vue-material-design-icons/ClipboardCheck.vue';

  export default Vue.extend({
    name: "ProjectSummary",
    components: {
      Conditionals,
      ProjectSummaryChecklist,
      //FilterInlineViewer,
      AddRepeatChecklist,
      PlusBoxIcon,
      LeadPencilIcon,
      ArchiveIcon, ArchiveArrowUpIcon,
      LockIcon, LockOpenVariantIcon,
      //ClipboardTextOffIcon, ClipboardEditIcon, ClipboardCheckIcon
    },
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
    },
    props: {
      project: { type: Object, required: true },
      readonly: { type: Boolean, required: true }
    },
    data() {
      return {
        ChecklistType,
        patches: [],
        loading: false,
        templatesLoaded: false,
        repetitiveTemplates: [],
        addChecklistDialog: {
          visible: false,
          selectedTemplateId: null,
          type: 'Standard',
          newName: ''
        },
        selectedTemplate: null,
      };
    },
    computed: {
      ...mapState(['job']),
      ...mapGetters('context', ['isAdmin', 'authLevel']),
      rules: function () {
        return this.project.rules || [];
      },
      allRules: function () {
        return this.project.allRules || [];
      },
      visibleStandardChecklists: function () {
        return _.sortBy(_.filter(this.project.checklists, { type: ChecklistType.Standard, visible: true }) || [], ['sortOrder']);
      },
      standardChecklists: function () {
        return _.sortBy(_.filter(this.project.checklists, { type: ChecklistType.Standard }) || [], ['sortOrder']);
      },
      hasRelatedProjects: function () {
        return this.job && this.job.checklistProjects && this.job.checklistProjects.length > 1;
      },
      checklistProjects: function () {
        return this.job && this.job.checklistProjects ? this.job.checklistProjects : [ this.project ];
      },
    },
    methods: {
      patch: function (op, path, value) {

        //Only allow updates to the readonly flag
        if (this.readonly && path && path.indexOf('readonly') == -1) {
          return;
        }


        let patch = _.find(this.patches, { op: op, path: path });

        if (patch) {
          patch.value = value;
        } else {
          patch = new Operation({ op: op, path: path, value: value });
          this.patches.push(patch);
        }
      },
      processProjectPatches: function () {


        if (this.patches && this.patches.length) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);

          c.checklistProjects_Patch(this.project.id, this.patches)
            .then(function (updatedProject) {
              if (updatedProject) {
                delete updatedProject.checklists;
                Object.assign(this.project, updatedProject);
              }
              this.patches = [];
            }.bind(this))
            .catch(function (error) {
              this.$bvToast.toast(error.message, {
                title: `Saving ${this.project.name} failed`,
                variant: "danger"
              });
            })
        }
      },
      patchChecklist: function (checklist, patch) {
        if (!checklist || !checklist.id) {
          console.log('Invalid checklist, cannot be patched');
          return;
        }
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        var patches = [
          patch
        ]

        return c.checklists_Patch(checklist.id, patches)
          .then(function (updatedChecklist) {
            if (updatedChecklist) {
              delete updatedChecklist.areas;
              Object.assign(checklist, updatedChecklist);
            }
          }.bind(this))
          .catch((error) => {
            console.log(error);
          })
      },
      gotoChecklist(checklist) {
        setTimeout(function () {
          this.$emit('goto-checklist', checklist)
        }.bind(this), 250);
      },
      lockProject(project, locked) {

        this.$bvModal.msgBoxConfirm(`Are you sure you want to ${locked ? 'lock' : 'unlock'} project ${project.name}?`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          if (value) {
            this.patch('replace', '/readonly', !!locked);
          }
        }.bind(this))
      },
      archiveProject(project, archived) {

        this.$bvModal.msgBoxConfirm(`Are you sure you want to ${archived ? 'archive' : 'unarchive'} project ${project.name}?`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {

          if (value) {
            this.patch('replace', '/archived', !!archived);
          }
        }.bind(this))


      },
      hideChecklist(checklist, hidden) {

        //checklist.hidden = hidden;

        //this.$bvModal.msgBoxConfirm(`Are you sure you want to ${hidden ? 'hide' : 'restore'} checklist '${checklist.name}'?`, {
        //  title: 'Please Confirm',
        //  size: 'sm',
        //  buttonSize: 'sm',
        //  okVariant: 'warning',
        //  okTitle: 'YES',
        //  cancelTitle: 'NO',
        //  footerClass: 'p-2',
        //  hideHeaderClose: false,
        //  centered: true
        //}).then(function (value) {
        //  if (value) {
        //  }
        //}.bind(this))

        this.patchChecklist(checklist, new Operation({ op: 'replace', path: '/hidden', value: !!hidden }));
          //.then(function () {
          //  this.$bvToast.toast(`Sucessfully ${hidden ? 'hid' : 'restored'} checklist: '${checklist.name}'`, {
          //    title: `Checklist ${hidden ? 'Hidden' : 'Restored'}.`,
          //    variant: "success"
          //  });
          //}.bind(this));

      },
      repeatTemplateSelected: function (mechId) {
        let mechTemplate = _.find(this.repetitiveTemplates, { id: mechId });

        if (mechTemplate) {

          let prefix = mechTemplate.name.replace(/.?template/gi, ''),
            suffix = '',
            siblingCount = _.reduce(this.standardChecklists, function (total, item) {
              if (item.name && item.name.startsWith(prefix)) {
                total += 1;
              }
              return total;
            }, 0);

          if (siblingCount) {
            suffix = String(siblingCount + 1).padStart(2, '0');
          }

          this.addChecklistDialog.newName = `${prefix} ${suffix}`;
        }

      },
      showAddChecklists: function () {
        this.$refs['AddStdChecklistModal'].show()
      },
      saveRepeatChecklist: function (addOpts) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        c.repetitiveChecklists_CopyChecklistToProject(
          addOpts.templateId, this.project.id, addOpts.name, addOpts.checklistType)
          .then(function (newChecklist) {

            InitializeChecklist(newChecklist);

            this.project.checklists.push(newChecklist);

            this.gotoChecklist(newChecklist);

          }.bind(this))
          .catch((error) => {
            console.log(error);
          })
      },
    },
    watch: {
      rules: {
        deep: true,
        handler: function(newValue, oldValue) {

          for (const checklist of this.standardChecklists) {
            //Allow for the visibility to be overriden through the hidden property null or undefined will default to the filterObject logic
            if (checklist && checklist.hidden !== null && checklist.hidden !== undefined)
              this.$set(checklist, 'visible', !checklist.hidden);
            else {
              this.$set(checklist, 'visible', filterObject(checklist, newValue));
            }
          }

          let newOptions = JSON.stringify(newValue);
          if (this.project.options != newOptions) {
            this.patch('replace', '/options', newOptions);
          }
        }
      },
      patches: function (newValue, oldValue) {
        if (newValue && newValue.length) {
          this.debouncedProcessProjectPatches();
        }
      },
    },
    created() {
      this.debouncedProcessProjectPatches = _.debounce(this.processProjectPatches, 1500, {
        leading: false,
        trailing: true
      });
    }
  });
</script>

<style lang="scss">
  .project-name {
    font-size: 1.6em;
    font-weight: bold;
    width: 100%;
  }
  .project-type {
    font-size: 1.125em;
    font-weight: bold;
  }
  .summary-checklist-header,
  .summary-checklist-row {
    border-bottom: 1px dashed #DDD;

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background-color: #EFEFEF;
    }

    button {
      //color: #333;

      h5 {
        margin: 0.2em 0 0;
      }

      &:hover {
        //color: midnightblue;
        font-weight: bold;
        //text-decoration: none;
      }
    }
  }


  // .summary-row {
  //   border-top: 3px solid #BBB;
  // }
</style>