<template>
  <div class="checklist">
    <div class="container">
      <div class="row">
        <div class="col-8">

          <h2>
            <label v-if="!editingName || isReadonly" @click="editName">{{checklistName}}</label>
            <input v-show="editingName" v-model.trim.lazy="checklist.name" placeholder="Enter checklist name" :disabled="isReadonly" ref="nameInput" @blur="editingName=false;" />
          </h2>
        </div>
        <div class="col-2"></div>
        <div class="col-2 text-center">
          <b-badge v-if="checklist.status.issue" variant="danger">{{checklist.status.issue}}</b-badge> {{checklist.status.accepted / checklist.status.count | numeral('0.[0]%')}}
          <b-progress height="6px" class="" :max="checklist.status.count">
            <b-progress-bar :value="checklist.status.accepted" variant="success"></b-progress-bar>
          </b-progress>
        </div>
        <div class="col-12">
          <conditionals :checklist="checklist" :readonly="isReadonly"></conditionals>
        </div>
      </div>
      <template v-if="project && project.projectTypeCode == 'Machining'">
        <div class="row">
          <div class="col-12">
            <div class="card my-1">

              <div class="card-body p-1">
                <div class="container">
                  <div class="row">
                    <div class="col-4">
                      <label class="col-form-label">Component/Detail</label>
                      <input type="text" class="form-control" v-model.trim.lazy="component" />
                    </div>
                    <div class="col-4">
                      <label class="col-form-label">Machine</label>
                      <select class="form-control" v-model="machineId">
                        <option :value="null">Select Machine</option>
                        <option v-for="mach in facilityMachines" :key="mach.id" :value="mach.id">{{mach.name}}</option>
                      </select>
                    </div>
                    <div class="col-4">
                      <label class="col-form-label">Initiated By: {{createdBy}}</label>
                      <label class="col-form-label">Initiated On: {{createdOn | moment("DD-MMM-YYYY h:mm a")}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </template>
      <template v-for="area in uniqueAreas">
        <checklist-area v-if="!area.groupName"
                      :key="area.id"
                      :checklist="checklist"
                      :rules="allRules"
                      :area="area"
                      :readonly="isReadonly">
        </checklist-area>
        <checklist-area-matrix v-else
                              :key="area.id"
                              :checklist="checklist"
                              :rules="allRules"
                              :area="area"
                              :areas="area.groupName && areaGroups ? areaGroups[area.groupName] : null"
                              :readonly="isReadonly">
        </checklist-area-matrix>
      </template>

      <checklist-sign-off v-if="checklist.allowSignOff" :checklist="checklist" :readonly="isReadonly || !!checklist.leaderSignOffAt"></checklist-sign-off>
      <checklist-manager-sign-off v-if="checklist.allowSignOff && authLevel > 0" :checklist="checklist" :readonly="isReadonly"></checklist-manager-sign-off>
    </div>
  </div>
</template>

<script>
import { result } from "lodash";
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { ChecklistAreaDto, Client, Operation, QuestionDto } from "../code/EngineeringChecklist.Api";
import { filterObject } from "../code/conditionals"

import ChecklistArea from "./Area.vue";
import ChecklistAreaMatrix from "./AreaMatrix.vue";
import ChecklistSignOff from "./ChecklistSignOff.vue";
import ChecklistManagerSignOff from "./ChecklistManagerSignOff.vue";
import Conditionals from "./Conditionals.vue";

export default Vue.extend({
  name: "Checklist",
  components: {
    ChecklistArea, ChecklistAreaMatrix,
    ChecklistSignOff, ChecklistManagerSignOff,
    Conditionals,
  },
  props:{
    checklist: { type: Object, required: true },
    readonly: {type: Boolean, required: true },
    blockUpdates: { type: Boolean, default: false }
  },
  data() {
    return {
      editingName: false,
      patches: [],
      facilityMachines: [],
    };
  },
  computed: {
    ...mapState(["project", "selectedFacility"]),
    ...mapGetters('context', ['authLevel']),
    isReadonly: function() {
      return this.readonly || !!(this.checklist && (this.checklist.signOffAt || this.checklist.leaderSignOffAt) && this.authLevel == 0);
    },
    checklistName: function () {
      return this.checklist.name ? this.checklist.name + " Checklist": "";
    },
    machineId: {
      get: function () {
        return this.checklist ? this.checklist.machineId : "";
      },
      set: function (newValue) {
        this.checklist.machineId = newValue;
      }
    },
    machineName: function () {
      let m = _.find(this.facilityMachines, { id: this.machineId })
      return m ? m.name : "";
    },
    component: {
      get: function () {
        return this.checklist ? this.checklist.component : "";
      },
      set: function (newValue) {
        this.checklist.component = newValue;
      }
    },
    name: {
      get: function() {
        return this.checklist ? this.checklist.name : "";
      },
      set: function(newValue){
        this.checklist.name = newValue;
      }
    },
    description: {
      get: function() {
        return this.checklist ? this.checklist.description : "";
      },
      set: function(newValue){
        this.checklist.description = newValue;
      }
    },
    createdBy: {
      get: function () {
        return this.checklist ? this.checklist.createdBy : "";
      },
      set: function (newValue) {
        this.checklist.createdBy = newValue;
      }
    },
    createdOn: {
      get: function () {
        return this.checklist ? this.checklist.createdOn : "";
      },
      set: function (newValue) {
        this.checklist.createdOn = newValue;
      }
    },
    rules: function() {
      return this.checklist.rules;
    },
    allRules: function () {
      return [
        { "id": "active-facility", "label": "Active Facility", "value": this.selectedFacility },
        ...this.rules
      ];
    },
    filteredAreas: function() {
      return _.sortBy(_.filter(this.checklist.areas, function(area) {
        
        return area.visible && area.questions && area.questions.length;

      }.bind(this)), ["sortOrder"]);
    },
    statusVal: function() {
      return _.reduce(this.uniqueAreas, function(total, area) {
        if (area && area.status) {
          total.blank += area.status.blank;
          total.issue += area.status.issue;
          total.issueNum += area.status.issueNum;
          total.accepted += area.status.accepted;
          total.count += area.status.count;
        }

        return total;
      }, {blank: 0, issue: 0, issueNum: 0, accepted: 0, count: 0});
    },
    uniqueAreas: function () {
      let uniqueAreas = _.uniqBy(this.filteredAreas, area => `${area.groupName ? area.groupName : area.id}`);
      return uniqueAreas;
      //return this.checklist ? _.sortBy(this.checklist.areas, ["sortOrder", "id"]) : [];
    },
    areaGroups: function () {
      let groupAreas = _.filter(this.filteredAreas, area => area.groupName);
      return _.groupBy(groupAreas, 'groupName');
    },
  },
  methods: {
    editName: function () {
      if (!this.readonly) {
        this.editingName = true;
        this.$nextTick(() => this.$refs.nameInput.focus())
      }
    },
    patch: function(op, path, value) {
      if (this.blockUpdates || this.readonly) {
        return;
      }

      let patch = _.find(this.patches, {op: op, path: path });
      
      if (patch) {
        patch.value = value;
      } else {
        patch = new Operation({op: op, path: path, value: value});
        this.patches.push(patch);
      }
    },
    processChecklistPatches: function() {

      if (this.patches && this.patches.length) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);

        c.checklists_Patch(this.checklist.id, this.patches)
          .then(function(updatedChecklist) {
            if (updatedChecklist) {
              delete updatedChecklist.areas;
              Object.assign(this.checklist, updatedChecklist);
            }
            this.patches = [];
          }.bind(this))
          .catch(function(error){
            this.$bvToast.toast(error.message, {
              title: `Saving ${this.checklist.name} checklist failed`,
              variant: "danger"
            });
          })
      }
    },
    fetchMachines: function () {

      this.loading = true;

      this.$store
        .dispatch('fetchSelectedFacilityMachines')
        .then(function (data) {
          this.facilityMachines = data || [];
        }.bind(this))
        .finally(() => {
          this.loading = false;
        });
      //var c = new Client(process.env.VUE_APP_API_URL, this.$http);
      //return c.dWMachine_Get(facilityCode)
      //  .then((facilityMachines) => {
      //    this.facilityMachines = facilityMachines || []
      //
      //    return facilityMachines;
      //  })
      //  .finally(() => {
      //    this.loading = false;
      //  });
    }
  },
  watch: {
    machineId: function (newValue, oldValue) {
      this.patch('replace', '/machineId', newValue);
    },
    machineName: function (newValue, oldValue) {
      this.patch('replace', '/machineName', newValue);
    },
    component: function (newValue, oldValue) {
      this.patch('replace', '/component', newValue);
    },
    name: function(newValue, oldValue) {
      this.patch('replace', '/name', newValue);
    },
    description: function(newValue, oldValue) {
      this.patch('replace', '/description', newValue);
    },
    patches: function(newValue, oldValue) {
      if (newValue && newValue.length) {
        this.debouncedProcessChecklistPatches();
      }
    },
    rules: {
      deep: true,
      handler: function(newValue, oldValue) {

        for (const area of this.checklist.areas) {
          this.$set(area, 'visible', filterObject(area, newValue));
        }

        let newOptions = JSON.stringify(newValue);
        if (this.checklist.options != newOptions) {
          this.patch('replace', '/options', newOptions);
        }
      }
    },
    // allRules: function (newValue, oldValue) {

    // },
    statusVal: function(newValue, oldValue) {
      this.$set(this.checklist, 'status', newValue);
    },

  },
  created() {

    //Debounce the tool changes, only verify if the tool has changed 250ms after the last call
    this.debouncedProcessChecklistPatches = _.debounce(this.processChecklistPatches, 1000, {
      leading: false,
      trailing: true
    });

    this.fetchMachines();
  }
});
</script>

<style lang="scss">


</style>
