<template>
  <b-modal :title="modalTitle"
           centered
           size="md"
           v-model="visible">
    <b-overlay :show="!templatesLoaded" rounded="lg">
      <div class="container" v-if="templates && templates.length">
        <div class="row">
          <div class="col-4">
            Template:
          </div>
          <div class="col-8">
            <b-form-select v-model="selectedTemplateId"
                           :options="templates"
                           text-field="name"
                           value-field="id"
                           :options-field='null'>
              <template #first>
                <b-form-select-option :value="null">Select Component Template</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
        
        <div class="row" v-if="useSuggestions">
          <div class="col-4">
            {{suggestionsLabel}}:
          </div>
          <div class="col-8">
            <multiselect v-model.lazy="selectedSuggestion"
                         
                         :close-on-select="true"
                         :placeholder="suggestionsLabel"
                         :searchable="true"
                         :multiple="false"
                         :taggable="customSuggestions"
                         :options="selectedTemplate.suggestions"
                         :show-no-results="false"
                         tag-placeholder="Add Option"
                         @tag="addCustomSuggestion"></multiselect>
          </div>
        </div>
        <div class="row" v-if="selectedTemplate">
          <div class="col-4">
            Checklist Name:
          </div>
          <div class="col-8">
            <b-input class="form-control" type="text" v-model="newName" :readonly="!userEditableName"></b-input>
            <small v-if="invalidNewName">Duplicate checklist name, must be unique!</small>
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <h5>No Checklist Templates Available</h5>(Facility: {{$store.state.selectedFacility
            }} Type: {{this.projectType}})
          </div>
    </b-overlay>
    <template v-slot:modal-footer="{ cancel}">
      <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
      <b-button size="sm" variant="primary" @click="ok()" :disabled="!isValid" >Add</b-button>
    </template>
  </b-modal>
</template>

<script>
  import Vue from 'vue';
  import { Client, ChecklistType } from "../../code/EngineeringChecklist.Api";
  import Multiselect from "vue-multiselect";

  export default Vue.extend({
    name: 'AddRepeatChecklist',
    components: {
      Multiselect
    },
    props: {
      project: { type: Object, required: true },
      checklistType: { type: String, default: ChecklistType.Repetitive },
      okCallback: { type: Function, default: null },
      cancelCallback: { type: Function, default: null },
      forceSuffix: { type: Boolean, default: false },
      projectTypeOverride: { type: String, default: null }
    },
    data() {
      return {
        templatesLoaded: false,
        visible: false,
        templates: [],
        selectedTemplateId: null,
        selectedSuggestion: '',
        newName: '',
      }
    },
    computed: {
      modal: function () {
        return this.$children[0];
      },
      projectType: function () {
        return this.projectTypeOverride || this.project.projectTypeCode;
      },
      projectChecklists: function () {
        return _.sortBy(_.filter(this.project.checklists, { type: this.checklistType }) || [], ['sortOrder']);
      },
      templateType: function () {
        return this.checklistType == ChecklistType.Standard ? ChecklistType.Standard : 'Repeat';
      },
      modalTitle: function () {
        return 'Add ' + _.capitalize(this.templateType) + ' Checklist';
      },
      selectedTemplate: function () {
        if (this.selectedTemplateId && this.templates && this.templates.length) {
          return _.find(this.templates, { id: this.selectedTemplateId });
        } else {
          return null;
        }
      },
      userEditableName: function () {
        return this.selectedTemplate ? this.selectedTemplate.userEditableName : false;
      },
      useSuggestions: function () {
        return this.selectedTemplate ? this.selectedTemplate.useSuggestions : false;
      },
      customSuggestions: function () {
        return this.selectedTemplate ? this.selectedTemplate.customSuggestions : false;
      },
      suggestionsLabel: function () {
        return this.selectedTemplate && this.selectedTemplate.suggestionsLabel ? this.selectedTemplate.suggestionsLabel : 'Options';
      },
      newNameVal: function () {
        if (this.selectedTemplate) {

          let t = this.selectedTemplate,
            nameFormat = t.defaultNameFormat;

          if (!nameFormat) {
            //If format not defined use the name of the template minus template
            nameFormat = _.trim(t.name.replace(/.?template/gi, ''));
          } else if (this.useSuggestions) {
            if (!this.selectedSuggestion) {
              return 'Select ' + this.suggestionsLabel;
            }
            nameFormat = _.trim(nameFormat.replace(/\{sugg\}/gi, this.selectedSuggestion));
          }

          let prefix = nameFormat,
            suffix = '',
            siblingCount = _.reduce(this.projectChecklists, function (total, item) {
              if (item.name && item.name.startsWith(prefix)) {
                total += 1;
              }
              return total;
            }, 0);

          let name = '',
            nameCollision = false;

          do {

            nameCollision = false;
            if (siblingCount || this.forceSuffix) {
              suffix = String(siblingCount + 1).padStart(2, '0');
            }

            name = `${prefix} ${suffix}`

            if (_.find(this.projectChecklists, { name: name })) {
              siblingCount += 1;
              nameCollision = true;
            }

          } while (nameCollision);

          return name;
        } else {
          return this.newName;
        }
      },
      invalidNewName: function () {
        return !!_.find(this.projectChecklists, { name: this.newName });
      },
      isValid: function () {
        return this.selectedTemplate && (!this.useSuggestions || (this.useSuggestions && this.selectedSuggestion)) && !this.invalidNewName;
      }
  },
  methods: {
    addCustomSuggestion: function (customSugg) {
      let newSugg = _.capitalize(customSugg);
      this.selectedTemplate.suggestions.push(newSugg);
      this.selectedSuggestion = newSugg;
    },
    show: function () {
      this.reset();
      this.modal.show();
    },
    hide: function () {
      this.modal.hide();
    },
    reset: function () {
      this.selectedTemplateId = null;
      this.selectedSuggestion = '';
      this.newName = '';
    },
    ok: function () {

      this.$emit('add-checklist', {
        checklistType: this.checklistType,
        templateId: this.selectedTemplateId,
        name: this.newName
      });

      this.hide();
    },
    loadTemplates: function () {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);

      return c.repetitiveChecklists_GetAll(this.$store.state.selectedFacility, this.projectType, this.checklistType)
        .then((data) => {
          this.templates = data;

          this.templatesLoaded = true;
          return data;
        })
        .catch((error) => {
          console.log(error);
        })
    },
  },
  watch: {
    newNameVal: function (newval, oldval) {
      this.newName = newval;
    },
    checklistType: function (newval, oldval) {
      this.loadTemplates();
    },
    projectType: function (newval, oldval) {
      if (newval) {
        this.loadTemplates();
      }
    },
  },
  created() {
    this.loadTemplates();
  }
});
</script>

