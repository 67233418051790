<template>
  <div class="row template-projects-row">
    <div class="col-12">
      <div class="card my-1">
        <div class="card-title area-title p-2 mb-0">
          <h5 class="d-inline">Templates</h5>
        </div>
        <div class="card-body p-1">
          <div class="container projects-list py-1 px-2" >
            <TemplateProject 
              v-for="proj in visibleTemplateProjects"
              :key="proj.checklistProjectId"
              :templateProject="proj"
              :jobName="jobName"
            ></TemplateProject>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Client } from "../code/EngineeringChecklist.Api";
import { filterObject } from "../code/conditionals";

import TemplateProject from "./TemplateProject.vue";

export default Vue.extend({
  name: 'TemplateProjects',
  components: {
    TemplateProject,
  },
  props: {
    jobName: {type: String, required: true},
    selectedTemplate: {type: Object, required: true},
    addJobDialog: {type: Object, required: true},
    //templateProjects: {type: Array, required: true},
    //jobRules: {type: Array, required: true }
  },
  data() {
    return {
      allTemplateProjects: [],

      loadingTemplateProjects: false,

    }
  },
  computed: {
    templateProjects: function() {
      return this.allTemplateProjectsLoaded ? this.selectedTemplate.templateJobs : [];
    },
    templateRules: function() {
      return this.allTemplateProjectsLoaded ? this.selectedTemplate.rules: [];
    },
    visibleTemplateProjects: function() {
      _.forEach(this.templateProjects, function(templateProject) {
        this.$set(templateProject, 'visible', filterObject(templateProject, this.templateRules));
      }.bind(this));
      
      return this.templateProjects.filter(templateProject => templateProject.visible);
    },
    allTemplateProjectsLoaded: function() {
      return this.allTemplateProjects.length > 0;
    }
  },
  methods: {
    fetchTemplateChecklistProjects: function () {
      var c = new Client(process.env.VUE_APP_API_URL, this.$http);
      
      this.loadingTemplateProjects = true;

      c.checklistProjects_GetAll(null,true,false,null,null)
        .then(function (checklistProjects) {
          for (const templateChecklist of checklistProjects) {
            if (templateChecklist && templateChecklist.options) {
              templateChecklist.rules = JSON.parse(templateChecklist.options);
            }
          }
          
          this.allTemplateProjects = checklistProjects || [];
        }.bind(this))
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          this.loadingTemplateProjects = false;
        }.bind(this));
    },
    initializeSelectedTemplate: function() {
      if (this.selectedTemplate && this.allTemplateProjectsLoaded) {
            
        for (let templateChecklist of this.selectedTemplate.templateJobs) {
          if (templateChecklist && templateChecklist.conditions && !templateChecklist.conditionalObj) {
            this.$set(templateChecklist, 'conditionalObj', JSON.parse(templateChecklist.conditions));
          }
          this.$set(templateChecklist, 'visible', filterObject(templateChecklist, this.selectedTemplate.rules));
          this.$set(templateChecklist, 'projectName', templateChecklist.projectName || this.jobName + ' - ' + templateChecklist.projectTypeCode);
          
          if (!templateChecklist.checklistProject) {
            const checklistProject = _.find(this.allTemplateProjects, { id: templateChecklist.checklistProjectId });
            if (templateChecklist && templateChecklist.rules) {
              checklistProject.rules = templateChecklist.rules;
            } else {
              this.$set(templateChecklist, 'rules', checklistProject.rules || []);
            }

            this.$set(templateChecklist, 'checklistProject', checklistProject);
          }
        }

      }
    }
    
  },
  watch: {
    allTemplateProjectsLoaded: function( newValue, oldValue) {
      if (newValue) {
        this.initializeSelectedTemplate();
      }
    }
  },
  created() {
    this.fetchTemplateChecklistProjects();
  }
});
</script>

<style lang="scss">

</style>