import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'


// import VueLodash from 'vue-lodash'
// import _ from 'lodash'

// import {find} from 'vue-lodash'

import Axios from 'axios';
import context from './context'
import { JobDto, ChecklistProjectDto, ChecklistDto, ChecklistAreaDto,  QuestionDto, Client, LookupListDto, FacilityDto, QuestionMoreInfoDto, MachineDto, ProjectTypeDto } from '../code/EngineeringChecklist.Api';
// import moment from 'moment';

Vue.use(Vuex)

const LANG_KEY = 'language'
const FACILITY_KEY = 'facilityCode'

export const initLocale = (() => {
  let lang = window.localStorage.getItem(LANG_KEY) || window.navigator.language

  if (lang && String(lang).indexOf('-') > 0) {
    lang = lang.substr(0, 2);
  }

  return lang || 'en'
})()

const initFacility = (() => {
  const facility = window.localStorage.getItem(FACILITY_KEY)
  return facility || null
})()

export interface DialogResult {
  result: any,
  cancelHide: boolean
}

export interface StoreState {
  // $app: Vue,
  // dialogs: {[key: string]: any },

  lookupLists: Array<LookupListDto> | Promise<LookupListDto[]> | null,
  facilities: Array<FacilityDto> | Promise<FacilityDto[]> | null,
  facilityMachines: { [key: string]: Array<MachineDto> | Promise<MachineDto[]> },
  projectTypes: Array<ProjectTypeDto> | Promise<ProjectTypeDto[]> | null,
  jobs: Array<JobDto> | null,
  project: ChecklistProjectDto | null,
  selectedQuestion: QuestionDto | null,
  issueEntry: boolean,
  issueRequired: boolean,
  sisterQuestions: Array<QuestionDto> | null,
  selectedMoreInfo: QuestionMoreInfoDto | null,  
  activeImageUrl: String | null,
  selectedLocale: string | null,
  selectedFacility: string | null
}

export const store = new Vuex.Store({
  modules: {
    context
  },
  state: {
    lookupLists: null,
    facilities: null,
    facilityMachines: {},
    // dialogs: { 
    //   ChecklistProjectEditor: null,
    //   ChecklistAreaEditor: null
    // },
    jobs: null,
    project: null,
    selectedQuestion: null,
    issueEntry: false,
    issueRequired: false,
    sisterQuestions: null,
    selectedMoreInfo: null,
    activeImageUrl: null,
    selectedLocale: initLocale,
    selectedFacility: null
    
  } as StoreState,
  mutations: {
    setJobs(state: StoreState, payload: Array<JobDto> | null) {
      store.state.jobs = payload;
    },
    updateJobProjectStatus(state: StoreState, payload: ChecklistProjectDto) {
      if (store.state.jobs) {
        const job = _.find(store.state.jobs, { id: payload.jobId as any });
        
        if (job != null && job != null) {
          const project = _.find(job.checklistProjects, { id: payload.id });
          if (project) {
            project.progress = payload.progress;
            project.unresolvedIssues = payload.unresolvedIssues;
          }
        }
      }
    },
    setProject(state: StoreState, payload: ChecklistProjectDto | null) {
      store.state.project = payload;
    },
    setProjectTypes(state: StoreState, payload: Array<ProjectTypeDto> | null) {
      store.state.projectTypes = payload;
    },
    setFacility(state: StoreState, payload: string) {
      window.localStorage.setItem(FACILITY_KEY, payload)
      state.selectedFacility = payload;
    },
    setLocale(state: StoreState, payload: { lang: string, i18n: any }) {
      window.localStorage.setItem(LANG_KEY, payload.lang)
      state.selectedLocale = payload.lang;
      payload.i18n.locale = payload.lang || 'en';
    },
    setImageUrl(state: StoreState, url: String | null) {
      state.activeImageUrl = url;
    },
    setMoreInfo(state: StoreState, payload: QuestionMoreInfoDto | null) {
      state.selectedMoreInfo = payload;
    },
    selectQuestion(state: StoreState, payload: { question: QuestionDto, issueEntry?: boolean, issueRequired?: boolean, sisterQuestions?: Array<QuestionDto>}) {
      state.selectedQuestion = payload.question;
      state.issueEntry = payload.issueEntry || false;
      state.issueRequired = payload.issueRequired || false;
      state.sisterQuestions = payload.sisterQuestions || [];
    },
    addFacility(state: StoreState, payload: FacilityDto) {
      if (state.facilities && Array.isArray( state.facilities )) {
        state.facilities.push(payload);
      }
    },
    removeFacility(state: StoreState, payload: FacilityDto) {
      if (state.facilities && Array.isArray(state.facilities)) {
        const index = state.facilities.indexOf(payload);
        if (index >= 0) {
          state.facilities.splice(index, 1);
        }
      }
    },

    // showDialog(state: StoreState, payload: {vm: Vue, id: string, props: {value: any, title?: string, okCallback?: Function, cancelCallback?: Function} } ) {

    //   if (payload.id && state.dialogs.hasOwnProperty(payload.id)) {
    //     Vue.set(state.dialogs, String(payload.id), payload.props);
        
    //     // let vm = this._vm as unknown as Vue;
    //     payload.vm.$bvModal.show(payload.id);
    //   } else {
    //     console.error('Invalid Dialog');
    //   }

    // },      


  },
  actions: {
    // Authenticate the user with Active Directory
    /*async AzureAuthentication({ commit, getters, dispatch }) {
      try {
        let existingTokenResponse = getters.mainTokenResponse;
        let newTokenResponse;
          if (msalInstance) {
            // The user has already logged in. We try to get his token silently
            if (existingTokenResponse)
              newTokenResponse = await msalInstance.acquireTokenSilent({
                account: existingTokenResponse.account,
                scopes: msalInstance.extendedConfiguration.auth.scopes,
              });
            // The user has not logged in. We check if he comes back from a redirect with a token
            else newTokenResponse = await msalInstance.handleRedirectPromise();

            // No token found, we redirect the user
            if (!newTokenResponse) {
              await msalInstance.loginRedirect(msalInstance.loginRequest);
              return false;
            }
            // There is an existing token, we authentify the user
            else if (newTokenResponse) {
              // We add the access token as an authorization header for our Axios requests to our API
              this._vm.axios.defaults.headers.common["Authorization"] = "Bearer " + newTokenResponse.accessToken;
              //if (msalInstance.extendedConfiguration.graph) {
              //  // The graph is set, we check if the user has already a picture in the local storage
              //  // if he does not we grab a token silently for our graph scope and call Microsoft graph to get the picture
              //  if (!localStorage.getItem("userPicture")) {
              //    let graphTokenResponse = await msalInstance.getSilentToken(
              //      newTokenResponse.account,
              //      msalInstance.extendedConfiguration.graph.scopes
              //    );
              //    let graphResponse = await msalInstance.callMSGraph(
              //      msalInstance.extendedConfiguration.graph.url,
              //      graphTokenResponse.accessToken
              //    );
              //    dispatch("AzureSetPicture", graphResponse);
              //  }
              //}

            return true;
          }

        }
      } catch (error) {
        console.error(error);
      }
    },*/
    changeFacility ({ commit, getters, state }: { commit: any, getters: any, state: StoreState }, payload: string) {
      commit('setFacility', payload)
    },
    changeLocale({ commit, getters, state }: { commit: any, getters: any, state: StoreState }, payload: { lang: string, i18n: any }) {
      commit('setLocale', payload)
    },
    fetchMoreInfo({ commit, getters, state }: { commit: any, getters: any, state: StoreState }, moreInfoId: number) {
      const c = new Client(process.env.VUE_APP_API_URL, Vue.prototype.$http);

      c.moreInfo_GetById(moreInfoId)
        .then(function (data: QuestionMoreInfoDto) {
          commit('setMoreInfo', data)
          return data;
        }.bind(this))
        .catch(error => {
          console.log(error.response);
        });
    },
    fetchFacilities({ commit, getters, state }: { commit: any, getters: any, state: StoreState }, payload: any) {
      const c = new Client(process.env.VUE_APP_API_URL, Vue.prototype.$http);

      if (state.facilities) {
        if (_.isArray(state.facilities)) {
          return Promise.resolve(state.facilities)
        } else {
          return state.facilities
        }
      }

      return c.facilities_GetAll(false)
        .then(function (data: Array<FacilityDto>) {
          const facilities = _.map(data, (item) => {
            return item;
          });

          state.facilities = facilities;
          return facilities;
        }.bind(this))
        .catch(error => {
          console.log(error.response);
        })
    },
    fetchLookupLists({ commit, getters, state }: { commit: any, getters: any, state: StoreState }, payload: any) {
      const c = new Client(process.env.VUE_APP_API_URL, Vue.prototype.$http);
      
      if (state.lookupLists) {
        return state.lookupLists;
      }

      const p = c.lookupLists_GetAll().then(function (data: Array<LookupListDto>) {
          const lists = _.map(data, (item) => {
            //@ts-ignore
            item.choices = JSON.parse(item.options || '[]');
            return item;
          });

          state.lookupLists = lists;
          return lists;
        }.bind(this))
        .catch(error => {
          console.log(error.response);
        });

      Vue.set(state, "lookupLists", p);

      return p;
    },
    fetchSelectedFacilityMachines({ commit, getters, state }: { commit: any, getters: any, state: StoreState }, payload: any) {

      if (!state.selectedFacility) {
        console.log("Facility not selected, unable to retreive machines");
        return [];
      }

      const c = new Client(process.env.VUE_APP_API_URL, Vue.prototype.$http);

      //Return the data or a promise to the data
      if (state.facilityMachines[state.selectedFacility]) {
        return state.facilityMachines[state.selectedFacility];
      } else {

        const p = c.dWMachine_Get(state.selectedFacility)
          .then(function (data: Array<MachineDto>) {

            //The || "" should never happen, just there to bypass validation issues
            Vue.set(state.facilityMachines, state.selectedFacility || "", data);
            return data;

          }.bind(this))
          .catch(error => {
            console.log(error.response);
          })

        Vue.set(state.facilityMachines, state.selectedFacility || "", p);

        return p;
      }
    },
    fetchProjectTypes({ commit, getters, state }: { commit: any, getters: any, state: StoreState }, payload: any) {
      const c = new Client(process.env.VUE_APP_API_URL, Vue.prototype.$http);
      
      if (state.projectTypes) {
        return state.projectTypes;
      }

      const p = c.projectType_GetAll(false)
        .then(function (data: Array<ProjectTypeDto>) {

          commit('setProjectTypes', data);

          return data;
        }.bind(this))
        .catch(error => {
          console.log(error.response);
        });

      Vue.set(state, "projectTypes", p);

      return p;
    },
  },
  getters: {
    // activeJob: (state: StoreState, getters: any) => {
    //   return state.job;
    // },
    activeFacilities: (state: StoreState, getters: any) => {
      return _.orderBy(state.facilities, 'code', 'asc') || [];
    },
    activeProjectTypes: (state: StoreState, getters: any) => {
      return state.projectTypes instanceof Promise ? [] : state.projectTypes || [];
    },
    getProjectType: (state: StoreState, getters: any) => (code: string) => {
      return _.find(getters.activeProjectTypes, { code: code });
    },
    projectName: (state: StoreState, getters: any) => {
      return state.project ? state.project.name : '';
    },
    langs: (state: StoreState, getters: any) => {
      return [{ key: 'en', value: 'English' }, { key: 'es', value: 'Spanish' }];
    },
    locale: (state: StoreState, getters: any) => {
      return state.selectedLocale ? state.selectedLocale : 'en';
    }
  }
})
