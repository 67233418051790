<template>
  <div class="job-container">
    <b-overlay :show="loading" rounded="lg" />
    <JobComp :job="job" v-if="!loading"></JobComp>
  </div>
</template>

<script>
import Vue from "vue";
import { Vuex, mapState, mapMutations, mapGetters } from "vuex";
import { InitializeChecklistProject, InitializeChecklist } from "../../code/Initializers";
import { Client } from "../../code/EngineeringChecklist.Api";

import JobComp from "../../components/admin/Job.vue";

export default Vue.extend({
  name: "ManageJob",
  components: {
    JobComp,
  },
  data() {
    return {
      activeTabIndex: 0,
      loading: true,
      job: null,
    };
  },
  computed: {
    checklists: function() {
      //return this.job ? _.sortBy(_.filter(this.job.checklists, { hidden: false }) ,["sortOrder"]) : [];
      return this.job ? _.sortBy(this.job.templateChecklistProjects, ["sortOrder"]) : [];
    }
  },
  methods: {
    activateTab(checklist) {
      
      let index = this.checklists.indexOf(checklist);
      if (index >= 0) {
        this.activeTabIndex = index + 1;
      }
    }
  },
  watch: {

  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.jobId !== undefined) {

      var c = new Client(process.env.VUE_APP_API_URL, Vue.prototype.$http);
      var job = await c.jobTemplate_GetById(to.params.jobId);
      
      return next(vm => {

        job.options = job.options || '[]';
        job.rules = _.map(JSON.parse(job.options), function (item, index) {
          if (!item.hasOwnProperty('value')) {
            item.value = null
          }
          return item;
        });
        
        job.templateJobs.forEach((checklistProject) => {
          checklistProject.conditionsObj = (checklistProject && checklistProject.conditions) ? JSON.parse(checklistProject.conditions) : {};
        });

        vm.job = job;
        vm.loading = false;

        //vm.$store.commit('setProject', job);
      });
    }

    return next();
  },
  // beforeRouteLeave(to, from, next) {
  //   if (this.hasChanged) {
  //     if (
  //       confirm(
  //         "Tool Changes have not been saved, are you sure you want to leave?"
  //       )
  //     ) {
  //       next();
  //     } else {
  //       next(false);
  //     }
  //   } else {
  //     next();
  //   }
  // },
  async beforeRouteUpdate(to, from, next) {

  },
  created() {

  }
});
</script>

<style lang="scss">

</style>
