<template>
  <div id="app">
    <b-overlay :show="!initialized"  rounded="lg" class="h-100">
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="primary"></b-spinner>
          <b-spinner type="grow" variant="primary"></b-spinner>
          <b-spinner small type="grow" variant="primary"></b-spinner>
        </div>
      </template>
      <Navbar v-if="initialized" />
      <router-view v-if="initialized" />
    </b-overlay>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import { mapGetters } from 'vuex';

export default {
  name: 'app',
  components: {
    Navbar
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('context', ['isAuthenticated']),
    initialized: function () {
      return this.$store.state.context.initialized;
    },
  },
  methods: {
  },
  watch: {
    $route(to, from) {
      document.title = to.meta.title || "SyBridge Checker";
    }
  },
  created() {
  }
}
</script>

<style lang="scss">
html > body {
  // Fix a font issue on raspberry pi, huge spaces between words only on bootstrap components
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app {
  height: 100vh;
  // width: 100vw;
  
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  // margin-top: 60px;
}

.scrollable {
  overflow-y: auto;
}

.material-design-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-item-align: center;
  align-self: center;
  position: relative;
  height: 1em;
  width: 1em;
}

.material-design-icon > .material-design-icon__svg {
  height: 100%;
  width: 100%;
  // fill: currentColor;
  position: absolute;
  bottom: -0.125em;
}

.btn-link.btn-link {
  padding: 0;
}

.btn {
  .material-design-icon {
    margin: -0.25em;
    height: 1.5em;
    width:  1.5em;
  }

  &.icon-btn {
    .material-design-icon{
      margin-right: 0.125em;
    }
  }
}


</style>
