<template>
  <div class="manage-users">
    <b-overlay :show="loading" rounded="sm">
      <div class="card">
        <div class="card-header p-2">
          <h4 class="m-0 mr-5 d-inline-block">Checklist Project Templates</h4>

          <b-button class="btn-add-project icon-btn float-right " variant="primary" size="sm" @click="showSelectedProject()">
            <PlusBoxIcon /> Add
          </b-button>

          <b-form-radio-group class="float-right mx-1 mr-4"
                              id="btn-radios-archived"
                              button-variant="outline-primary"
                              v-model="filterArchived"
                              buttons
                              size="sm">
            <b-form-radio :value="false">Active</b-form-radio>
            <b-form-radio :value="true">Archived</b-form-radio>
          </b-form-radio-group>
          <select class="form-control-sm float-right mx-1" v-model="filterFacilityCode">
            <option :value="null">All Facilities</option>
            <option v-for="facility in $store.getters.activeFacilities" :key="facility.code" :value="facility.code">{{facility.name}}</option>
          </select>
          <select class="form-control-sm float-right mx-1" v-model="filterProjType">
            <option :value="null">All Types</option>
            <option v-for="activeType in activeProjectTypes" :key="activeType.code" :value="activeType.code">{{activeType.name}}</option>
          </select>
        </div>
        <div class="card-body p-2">
          <div class="container-fluid" v-if="projects.length">
            <div class="row">
              <div class="col-6">Checklist Project</div>
              <div class="col-2 text-center">Proj Types</div>
              <div class="col-2 text-center">Facilities</div>
              <div class="col-2 text-center"></div>
            </div>
            <div class="row py-1" v-for="proj in projects" :key="proj.id">
              <div class="col-6">
                <router-link :to="{ name: 'manage-checklist-project', params: { projectId: proj.id }}" class="">{{proj.name}}</router-link>
              </div>

              <div v-if="proj.projectTypesAllowed && proj.projectTypesAllowed.length" class="col-2 text-center">
                <div v-for="pType in proj.projectTypesAllowed" :key="pType">{{pType}}</div>
              </div>
              <div v-else class="col-2 text-center">ALL</div>
              <div v-if="proj.facilityRestrictions && proj.facilityRestrictions.length" class="col-2 text-center">
                <div v-for="facility in proj.facilityRestrictions" :key="facility">{{facility}}</div>
              </div>
              <div v-else class="col-2 text-center">ALL</div>
              <div class="col-2 text-center">
                <b-button class="btn-remove-project m-0" variant="outline-warning" size="sm" @click="archiveProject(proj, !proj.archived)">
                  <ArchiveArrowUpIcon v-if="proj.archived" title="Unarchive Project" />
                  <ArchiveIcon v-else title="Archive Project" />
                </b-button>
                <b-button v-if="proj.archived" class="btn-remove-project mx-1" variant="outline-danger" size="sm" @click="removeProject(proj)">
                  <TrashCanIcon title="Remove Project forever!" />
                </b-button>
                <b-button v-else class="btn-remove-project mx-1" variant="outline-primary" size="sm" @click="cloneProject(proj)">
                  <ContentDuplicateIcon title="Clone Project" />
                </b-button>
                
              </div>
            </div>
          </div>
          <div class="empty-card py-4" v-else>
            <h4 class="text-center"> No Project Templates</h4>
          </div>
        </div>
      </div>
    </b-overlay>
    <b-modal title="New Project Template"
             centered
             no-close-on-backdrop
             ref="projectModalRef"
             size="md">
      <div class="container" v-if="selectedProject">
        <div class="row">
          <div class="col-4">
            Name:
          </div>
          <div class="col-8">
            <input class="form-control" type="text" v-model="selectedProject.name">
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            Description:
          </div>
          <div class="col-8">
            <b-form-textarea id="textarea-small"
                             class="form-control"
                             size="sm"
                             placeholder="Project Description"
                             v-model="selectedProject.description"></b-form-textarea>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer="{ cancel, hide }">
        <b-button size="sm" variant="secondary" @click="cancel()">Cancel</b-button>
        <b-button size="sm" variant="primary" @click="saveProjectTemplate(hide)">Add</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { Client, Operation, ChecklistProjectDto } from "../../code/EngineeringChecklist.Api";
  import PlusBoxIcon from 'vue-material-design-icons/PlusBox.vue';
  import TrashCanIcon from 'vue-material-design-icons/TrashCan.vue';
  import LeadPencilIcon from 'vue-material-design-icons/LeadPencil.vue';
  import ArchiveIcon from 'vue-material-design-icons/Archive.vue';
  import ArchiveArrowUpIcon from 'vue-material-design-icons/ArchiveArrowUp.vue';
  import ContentDuplicateIcon from 'vue-material-design-icons/ContentDuplicate.vue';
import { mapGetters } from 'vuex';
  
  export default Vue.extend({
    name: 'ManageFunctionTemplates',
    components: {
      PlusBoxIcon,
      TrashCanIcon,
      ArchiveIcon,
      ArchiveArrowUpIcon,
      ContentDuplicateIcon
    },
    props: {
    },
    data() {
      return {
        loading: true,
        projects: [],
        selectedProject: null,
        patches: [],
        filterArchived: false,
        filterFacilityCode: null,
        filterProjType: null
      }
    },
    computed: {
      ...mapGetters(['activeProjectTypes']),
    },
    methods: {
      patch: function (op, path, value) {
        let patch = _.find(this.patches, { op: op, path: path });
        if (patch) {
          patch.value = value;
        } else {
          patch = new Operation({ op: op, path: path, value: value });
          this.patches.push(patch);
        }
      },
      processPatches: function () {
        if (this.patches && this.patches.length) {
          var c = new Client(process.env.VUE_APP_API_URL, this.$http);
          c.checklistAreas_Patch(this.area.id, this.patches)
            .then(function () {
              this.patches = [];
            }.bind(this))
            .catch(function (error) {
            })
        }
      },
      patchProject: function (project, patch) {
        if (!project || !project.id) {
          console.log('Invalid project, cannot be patched');
          return;
        }
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        var patches = [
          patch
          // new Operation({op: 'replace', path: '/progress', value: Math.round((status.accepted / status.count) * 100) }),
          // new Operation({op: 'replace', path: '/unresolvedIssues', value: status.issue})
        ]
        return c.checklistProjects_Patch(project.id, patches)
          .catch((error) => {
            console.log(error);
          })
      },
      remove(array, item) {
        let index = array.indexOf(item);
        if (index >= 0) {
          array.splice(index, 1);
        }
      },
      loadProjects() {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        this.loading = true;
        c.checklistProjects_GetAll(null, true, this.filterArchived, this.filterFacilityCode, this.filterProjType).then(function (data) {
          this.projects = data;
        }.bind(this))
          .catch(error => {
            console.log(error.response);
          })
          .finally(
            function () {
              this.loading = false;
            }.bind(this)
          );
      },
      showSelectedProject(project) {
        if (!project) {
          this.selectedProject = new ChecklistProjectDto({ id: 0, isTemplate: true, projectType: '' });
        } else {
          this.selectedProject = project;
        }
        this.$refs.projectModalRef.show();
      },
      saveProjectTemplate() {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        this.loading = true;
        if (this.selectedProject && this.selectedProject.id) {
          //Update
          c.checklistProjects_Put(this.selectedProject.id).then(function (data) {
            var index = this.projects.indexOf(this.selectedProject)
            if (index > -1) {
              this.projects[index] = data;
            } else {
              this.projects.push(data);
            }
            this.$refs.projectModalRef.hide();
          }.bind(this))
            .catch(error => {
              console.log(error.response);
            })
            .finally(
              function () {
                this.loading = false;
              }.bind(this)
            );
        } else {

          this.selectedProject.projectTypesAllowed = this.selectedProject.projectTypesAllowed || [];

          //Insert
          c.checklistProjects_Post(this.selectedProject).then(function (data) {
            this.projects.push(data);
            this.$refs.projectModalRef.hide();
          }.bind(this))
            .catch(error => {
              console.log(error.response);
            })
            .finally(
              function () {
                this.loading = false;
              }.bind(this)
            );
        }
      },
      archiveProject(project, archived) {
        this.$bvModal.msgBoxConfirm(`Are you sure you want to ${archived ? 'archive' : 'unarchive'} project '${project.name}'?`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {
          if (value) {
            this.patchProject(project, new Operation({ op: 'replace', path: '/archived', value: !!archived }))
              .then(function () {
                this.$bvToast.toast(`Sucessfully ${archived ? 'archived' : 'unarchived'} project: '${project.name}'`, {
                  title: `Project ${archived ? 'Archived' : 'Unarchived'}.`,
                  variant: "success"
                });
                this.remove(this.projects, project);
              }.bind(this));
          }
        }.bind(this))
      },
      cloneProject(project) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        this.$bvModal.msgBoxConfirm(`Are you sure you want to clone '${project.name}'?`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {
          if (value) {
            if (project && project.id) {
              c.checklistProjects_CloneTemplate(project.id, project.name + " Copy", "", true)
                .then(function (data) {
                  this.$bvToast.toast(`Template Project: ${project.name} has been cloned.`, {
                    title: 'Successfully cloned project',
                    variant: "success"
                  });

                  this.projects.push(data);
                }.bind(this))
                .catch(function (error) {
                  this.$bvToast.toast(`Cloned failed for: ${error.message}`, {
                    title: `Project: ${project.name} failed to clone.`,
                    variant: "danger"
                  });
                }.bind(this));
            }
          }
        }.bind(this))
      },
      removeProject(project) {
        var c = new Client(process.env.VUE_APP_API_URL, this.$http);
        this.$bvModal.msgBoxConfirm(`Are you sure you want to permanently remove '${project.name}'? This cannot be undone!`, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        }).then(function (value) {
          if (value) {
            if (project && project.id) {
              c.checklistProjects_Delete(project.id)
                .then(function (data) {
                  this.$bvToast.toast(`Template Project: ${project.name} removed.`, {
                    title: 'Successfully removed project',
                    variant: "success"
                  });
                }.bind(this))
                .catch(function (error) {
                  this.$bvToast.toast(`Save failed for: ${error.message}`, {
                    title: `Project: ${project.name} failed to delete.`,
                    variant: "danger"
                  });
                  this.projects.push(project);
                }.bind(this));
            }
            this.remove(this.projects, project);
          }
        }.bind(this))
      },
    },
    watch: {
      filterArchived: function () {
        this.loadProjects();
      },
      filterFacilityCode: function () {
        this.loadProjects();
      },
      filterProjType: function () {
        this.loadProjects();
      }
    },
    created() {
      this.loadProjects();
    }
  });
</script>
<style lang="scss">
</style>
